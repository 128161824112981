import { useResourceContext } from 'react-admin';
import { rbac } from '@utils/rbac';

export const useRbacEdit = (): boolean => {
  const resource = useResourceContext();
  const isSuperadmin = rbac.isSuperadmin();

  if (isSuperadmin) return true;

  return rbac.canEdit(resource);
};

export const useRbacReadonly = (): boolean => {
  const resource = useResourceContext();

  return rbac.readonlyAccess(resource);
};
