import React, { InputHTMLAttributes } from 'react';
import { clsx } from 'clsx';
import cls from './input-text.module.css';

interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  labelWidth?: number;
  withoutLabel?: boolean;
  error?: string;
  centered?: boolean;
  maxLength?: number;
  postfix?: string;
}

export const InputText = ({
  name,
  label,
  value,
  labelWidth,
  withoutLabel,
  onChange,
  placeholder,
  error,
  disabled,
  className,
  centered,
  maxLength,
  postfix,
}: InputTextProps) => {
  return (
    <div className={cls._}>
      {!withoutLabel && (
        <label
          className={cls.label}
          htmlFor={name}
          style={{ minWidth: labelWidth ? labelWidth : 100 }}
        >
          {label}
        </label>
      )}
      <div className={cls.content}>
        <div className={cls.block}>
          <input
            className={clsx(cls.value, className, centered && cls.centered)}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
          />
          {postfix && (
            <span className={cls.postfix}>{postfix}</span>
          )}
        </div>
        {error && (
          <span className={cls.error}>{error}</span>
        )}
      </div>
    </div>
  );
};
