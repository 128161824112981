import { clsx } from 'clsx';
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import HtmlOutlined from '@mui/icons-material/HtmlOutlined';

import { http } from '@network';
import { DownloadIcon } from '@components/FileImage';
import cls from './file-html.module.css';

type Props = {
  url: string;
  downloadFilename?: string;
  className?: string;
}

const downloadHtml = async (url: string) => {
  const res = await http.get<string>(url, { responseType: 'text' });
  return res.data;
};

const downloadBase64 = async (url: string) => {
  const res = await http.get<Buffer>(url, { responseType: 'arraybuffer' });
  return bufferToBase64(res.data);
};

const openHtml = (source: string, downloadFilename?: string) => {
  setTimeout(() => {
    const win = window.open('', '_blank', 'popup') as any;
    win.document.body.innerHTML = source;
    win.document.title = downloadFilename || 'Document preview';
  }, 0);
};

export const FileHtml = ({ url, downloadFilename, className }: Props) => {
  const [src, setSrc] = useState<string>();
  const [base64, setBase64] = useState<string>();

  const onPreview = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    let source = src;
    if (!source) {
      source = await downloadHtml(url);
      setSrc(source);
    }

    openHtml(source, downloadFilename);
  };

  const onDownload = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    let source = base64;
    if (!source) {
      source = await downloadBase64(url);
      setBase64(source);
    }

    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = source;
      link.download = downloadFilename || 'Document.html';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      openHtml(source);
    }
  };

  return (
    <div className={clsx(cls.box, className, 'file-image-box')}
      onClick={onPreview} title="preview">
      <div className={clsx(cls.preview, 'file-image-preview')}>
        {src ? (
          <span><HtmlOutlined /></span>
        ) : (
          <span><HtmlOutlined /></span>
        )}
      </div>

      {downloadFilename && (
        <div className={clsx(cls.download, 'file-image-download')}
          data-x="download" title="download">
          <IconButton onClick={onDownload}>
            <DownloadIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

function bufferToBase64(arrayBuffer: Buffer): string {
  // @ts-ignore
  const b64encoded = btoa([].reduce.call(
    new Uint8Array(arrayBuffer),
    (p: any, c: any) => p + String.fromCharCode(c),
    '',
  ));
  const mimetype = 'application/html';

  return `data:${mimetype};base64,${b64encoded}`;
}
