import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Row, Col, Input, Select, InputNumber, DatePicker } from 'antd';
import moment from 'moment-timezone';
import IMask from 'imask';
import { RcFile } from 'antd/lib/upload';
import { Gutter } from 'antd/lib/grid/row';
import Checkbox from '@mui/material/Checkbox';
import html2canvas from 'html2canvas';
import clsx from 'clsx';

import { CreditCrop, CxCredit, CxFarm } from '@types';
import { BlueButton, FilePreview, FileUpload, InitialRow, OrangeOutlineButton } from '@components';
import { CxOptions, s3http } from '@network';
import { filterOption } from '@utils';
import { DownloadIconMini } from '@components/DownloadIconMini';
import { useModalState } from '@hooks';

const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_FORMAT_DB = 'YYYY-MM-DD';
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: new Date().getFullYear() },
  },
  format: (date: Date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});

interface Props {
  insurance: CreditCrop;
  cxCredit: CxCredit;
  updateInsurance: (insurance: CreditCrop) => void;
  cxOptions: CxOptions;
  creditFarms: CxFarm[];
  setCreditFarms: (farms: CxFarm[]) => void;
  usedFarmIds: number[];
  idx: number;
  isDownloading?: boolean;
}

export const PolicyInsuranceForm = (props: Props) => {
  const { insurance, updateInsurance } = props;
  const cls = useStyles();

  return (
    <div className={cls.container}>
      <div className={cls.cultivo}>
        <Checkbox
          checked={insurance.isProtectedCultivation}
          onChange={e => updateInsurance({
            ...insurance,
            isProtectedCultivation: e.target.checked,
          })}
          size="medium"
        />
        <span>Cultivo Protegido</span>
      </div>

      {insurance.isProtectedCultivation ? (
        <PolicyProtectedForm {...props} />
      ) : (
        <PolicyUnprotectedForm {...props} />
      )}
    </div>
  );
};

const PolicyUnprotectedForm = (props: Props) => {
  const { insurance, cxCredit, updateInsurance, cxOptions, idx } = props;
  const cls = useStyles();
  const rowGutter = [30, 0] as [Gutter, Gutter];

  const printRef = React.useRef<any>();
  const { is: isDownloading, show: setDownloading, hide: unsetDownloading } = useModalState();

  const uploadInsuranceFile = async (rcFile: RcFile): Promise<string> => {
    const file = await s3http.uploadFile(rcFile as File);
    updateInsurance({ ...insurance, insuranceFile: file });
    return file.adminUrl;
  };

  const handleDownloadForm = async () => {
    setDownloading();
    await new Promise((resolve) => setTimeout(resolve, 200));

    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = `Insurance Policy ${cxCredit.code} #${idx + 1}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
    unsetDownloading();
  };

  return (
    <Row gutter={[35, 10]}>
      <Col sm={18} className={cls.fields}>
        <BlueButton className={cls.btnDownload} onClick={handleDownloadForm}>
          <DownloadIconMini />
        </BlueButton>
        <div className={clsx(isDownloading && cls.isDownloading)} ref={printRef}>
          <Row gutter={rowGutter}>
            <Col sm={12}>
              <InitialRow title="Expediente:" labelSize={6}>
                <Input className={cls.formInput} value={cxCredit.code} />
              </InitialRow>
            </Col>
            <Col sm={12}>
              <InitialRow title="Cultivo*:" labelSize={6}>
                <Select
                  onChange={financedCropId => updateInsurance({ ...insurance, financedCropId })}
                  value={insurance.financedCropId}
                  className={cls.select}
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  options={cxOptions.crops.map(o => ({ value: o.id, label: o.nameMx }))}
                />
              </InitialRow>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col sm={12}>
              <InitialRow title="Acreditado:" labelSize={6}>
                <Input className={cls.formInput}
                  value={insurance.accreditedName}
                  onChange={e => updateInsurance({ ...insurance, accreditedName: e.target.value })}
                />
              </InitialRow>
            </Col>
            <Col sm={12}>
              <InitialRow title="Monto:" labelSize={6}>
                <InputNumber
                  min={0}
                  max={1000000000}
                  maxLength={13}
                  onBeforeInputCapture={e => {
                    const value = e.currentTarget.value;
                    if (value && value.includes('.')) {
                      const [, second] = value.split('.');
                      if (second.length >= 2) {
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }
                  }}
                  formatter={(val) => `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(val) => val ? +val.replace(/\$\s?|(,*)/g, '') : 0}
                  value={insurance.creditAmount}
                  onChange={val => updateInsurance({ ...insurance, creditAmount: val || 0 })}
                  className={cls.formInput}
                  bordered={false}
                  controls={false}
                />
              </InitialRow>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col sm={12}>
              <InitialRow title="Municipio:" labelSize={6}>
                <Input
                  className={cls.formInput}
                  value={insurance.municipality}
                  onChange={e => updateInsurance({ ...insurance, municipality: e.target.value })}
                />
              </InitialRow>
            </Col>
            <Col sm={12}>
              <InitialRow title="Estado:" labelSize={6}>
                <Select
                  onChange={stateId => updateInsurance({ ...insurance, stateId })}
                  value={insurance.stateId}
                  className={cls.select}
                  showSearch
                  optionFilterProp="children"
                  filterOption={filterOption}
                  options={cxOptions.states.map(o => ({ value: o.id, label: o.name }))}
                />
              </InitialRow>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col sm={24}>
              <InitialRow title="Ubicación:" labelSize={6}>
                <PolicyFarms {...props} isDownloading={isDownloading} />
              </InitialRow>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col sm={12}>
              <InitialRow title="Superficie total:" labelSize={12}>
                <InputNumber
                  min={0}
                  max={1000000000}
                  maxLength={13}
                  prefix={<div className={cls.inputPrefix}>Ha</div>}
                  value={insurance.financedHaCx}
                  onChange={val => updateInsurance({ ...insurance, financedHaCx: val || 0 })}
                  className={cls.formInput}
                  bordered={false}
                  controls={false}
                />
              </InitialRow>
            </Col>
            <Col sm={12}>
              <InitialRow title="Rendimiento:" labelSize={12}>
                <InputNumber
                  min={0}
                  max={1000000000}
                  maxLength={13}
                  prefix={<div className={cls.inputPrefix}>Tons/Ha</div>}
                  value={insurance.expectedWeightCx}
                  onChange={val => updateInsurance({ ...insurance, expectedWeightCx: val || 0 })}
                  className={cls.formInput}
                  bordered={false}
                  controls={false}
                />
              </InitialRow>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col sm={12}>
              <InitialRow title="Fecha de Siembra:" labelSize={12}>
                <DatePicker
                  placeholder="DD/MM/YYYY"
                  className={cls.formInput}
                  suffixIcon={null}
                  allowClear={true}
                  value={insurance.sawingDate ? moment(insurance.sawingDate) : null}
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    const input = event.target as HTMLInputElement;
                    if (!input.value) {
                      return updateInsurance({ ...insurance, sawingDate: null });
                    }
                    input.value = MASKED.resolve(input.value);
                    setTimeout(() => {
                      const momentDate = moment(input.value, DATE_FORMAT, true);
                      momentDate.isValid() && updateInsurance({
                        ...insurance,
                        sawingDate: momentDate.format(DATE_FORMAT_DB),
                      });
                    }, 200);
                  }}
                  onChange={selectedValue => {
                    if (!selectedValue) {
                      return updateInsurance({ ...insurance, sawingDate: null });
                    }
                    const momentDate = moment(selectedValue, DATE_FORMAT, true);
                    momentDate.isValid() && updateInsurance({
                      ...insurance,
                      sawingDate: momentDate.format(DATE_FORMAT_DB),
                    });
                  }}
                  picker="date"
                  format={DATE_FORMAT}
                />
              </InitialRow>
            </Col>
            <Col md={12}>
              <InitialRow title="Cosecha Escalonada:" labelSize={12}>
                <Checkbox
                  checked={insurance.harvestStepwise}
                  onChange={e => updateInsurance({
                    ...insurance,
                    harvestStepwise: e.target.checked,
                  })}
                  size="medium"
                />
              </InitialRow>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col sm={12}>
              <InitialRow title="Fecha de Cosecha:" labelSize={12}>
                <DatePicker
                  placeholder="DD/MM/YYYY"
                  className={cls.formInput}
                  suffixIcon={null}
                  allowClear={true}
                  value={insurance.harvestDate ? moment(insurance.harvestDate) : null}
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    const input = event.target as HTMLInputElement;
                    if (!input.value) {
                      return updateInsurance({ ...insurance, harvestDate: null });
                    }
                    input.value = MASKED.resolve(input.value);
                    setTimeout(() => {
                      const momentDate = moment(input.value, DATE_FORMAT, true);
                      momentDate.isValid() && updateInsurance({
                        ...insurance,
                        harvestDate: momentDate.format(DATE_FORMAT_DB),
                      });
                    }, 200);
                  }}
                  onChange={selectedValue => {
                    if (!selectedValue) {
                      return updateInsurance({ ...insurance, harvestDate: null });
                    }
                    const momentDate = moment(selectedValue, DATE_FORMAT, true);
                    momentDate.isValid() && updateInsurance({
                      ...insurance,
                      harvestDate: momentDate.format(DATE_FORMAT_DB),
                    });
                  }}
                  picker="date"
                  format={DATE_FORMAT}
                />
              </InitialRow>
            </Col>
            <Col md={12}>
              <InitialRow title="Sistema de Riego:" labelSize={12}>
                <Input
                  className={cls.formInput}
                  value={insurance.irrigationSystem}
                  onChange={e => updateInsurance({
                    ...insurance,
                    irrigationSystem: e.target.value,
                  })}
                />
              </InitialRow>
            </Col>
          </Row>
        </div>
      </Col>

      <Col sm={6}>
        <div className={cls.document} style={{ marginTop: 50 }}>
          <div className={cls.documentTitle}>Policy #{idx + 1}</div>
          {insurance.insuranceFile ? (
            <FilePreview file={insurance.insuranceFile} wrapClass={cls.preview}
              downloadFilename={`Insurance Policy ${cxCredit.code} #${idx + 1}`} />
          ) : (
            <FileUpload uploadAction={uploadInsuranceFile} type="pdf" className={cls.upload} />
          )}
        </div>
      </Col>
    </Row>
  );
};

const PolicyProtectedForm = (props: Props) => {
  const { insurance, cxCredit, updateInsurance, idx } = props;
  const cls = useStyles();
  const pos = idx + 1;

  const uploadQuotationFile = async (rcFile: RcFile): Promise<string> => {
    const file = await s3http.uploadFile(rcFile as File);
    updateInsurance({ ...insurance, quotationFile: file });
    return file.adminUrl;
  };

  const uploadInsuranceFile = async (rcFile: RcFile): Promise<string> => {
    const file = await s3http.uploadFile(rcFile as File);
    updateInsurance({ ...insurance, insuranceFile: file });
    return file.adminUrl;
  };

  const downloadQuoteDoc = () => {
    const link = document.createElement('a');
    const fileName = 'Covered_Crop_Quote_Insurance.xlsx';
    const fileUrl = `https://verqor-public.s3.us-west-2.amazonaws.com/${fileName}`;

    if (typeof link.download === 'string') {
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(fileUrl, '_blank');
    }
  };

  return (
    <>
      <div className={cls.protected}>
        <div className={cls.protectedText}>
          <span>Para cultivo protegido, completa</span>
          <span className={cls.protectedLink} onClick={downloadQuoteDoc}>este documento</span>
          <span>y súbelo aquí al terminar.</span>
        </div>
      </div>
      <Row gutter={[35, 10]}>
        <Col sm={5}>
          <div className={cls.document}>
            <div className={cls.documentTitle}>Quotation #{pos}</div>
            {insurance.quotationFile ? (
              <FilePreview file={insurance.quotationFile} wrapClass={cls.preview}
                downloadFilename={`Quotation ${cxCredit.code} #${pos}`} />
            ) : (
              <FileUpload uploadAction={uploadQuotationFile}
                type="pdf+xlsx" className={cls.upload} />
            )}
          </div>
        </Col>
        <Col sm={14}>
          <div className={cls.fields}>
            <div className={cls.fieldsLabel}>Ubicación:</div>
            <PolicyFarms {...props} />
          </div>
        </Col>
        <Col sm={5}>
          <div className={cls.document}>
            <div className={cls.documentTitle}>Policy #{pos}</div>
            {insurance.insuranceFile ? (
              <FilePreview file={insurance.insuranceFile} wrapClass={cls.preview}
                downloadFilename={`Insurance Policy ${cxCredit.code} #${pos}`} />
            ) : (
              <FileUpload uploadAction={uploadInsuranceFile} type="pdf" className={cls.upload} />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

const PolicyFarms = (props: Props) => {
  const { insurance, updateInsurance, creditFarms, usedFarmIds, isDownloading } = props;
  const [farms, setFarms] = useState<CxFarm[]>(insurance.farms || []);
  const cls = useStyles();

  useEffect(() => {
    if (farms.length !== 0) return;

    const newFarm: CxFarm = {
      id: null,
      key: new Date().getTime().toString(),
      latitude: '',
      longitude: '',
    };
    const vacantFarm = creditFarms.find(farm => !farm.insurancePolicyId);
    const nextFarms = vacantFarm ? [vacantFarm] : [newFarm];

    updateInsurance({ ...insurance, farms: nextFarms });
    setFarms(nextFarms);
  }, [farms]);

  useEffect(() => void setFarms(insurance.farms ?? []), [insurance.farms]);

  const addFarm = () => {
    const newFarm: CxFarm = {
      id: null,
      key: new Date().getTime().toString(),
      latitude: '',
      longitude: '',
    };
    updateInsurance({
      ...insurance,
      farms: (insurance.farms || []).concat([newFarm]),
    });
  };

  const removeFarm = (key: string) => {
    updateInsurance({
      ...insurance,
      farms: (insurance.farms || []).filter(farm => farm.key !== key),
    });
  };

  return (
    <div className={cls.farms}>
      {farms.map((farm, farmIndex) => (
        <Row gutter={[20, 20]} key={farm.key}>
          <Col sm={6}>
            <Input
              placeholder="Latitude"
              className={cls.formInput}
              value={farm.latitude}
              onChange={e => updateInsurance({
                ...insurance,
                farms: (insurance.farms || []).map(insuranceFarm => {
                  if (insuranceFarm.key !== farm.key) return insuranceFarm;
                  insuranceFarm.latitude = e.target.value;
                  return insuranceFarm;
                }),
              })}
              status={farm.latitude ? undefined : 'error'}
            />
          </Col>
          <Col sm={6}>
            <Input
              placeholder="Longitude"
              className={cls.formInput}
              value={farm.longitude}
              onChange={e => updateInsurance({
                ...insurance,
                farms: (insurance.farms || []).map(insuranceFarm => {
                  if (insuranceFarm.key !== farm.key) return insuranceFarm;
                  insuranceFarm.longitude = e.target.value;
                  return insuranceFarm;
                }),
              })}
              status={farm.longitude ? undefined : 'error'}
            />
          </Col>
          <Col sm={12}>
            <div className={cls.farmActions}>
              <Select
                value={farm.id}
                onChange={farmId => updateInsurance({
                  ...insurance,
                  farms: (insurance.farms || []).map(insuranceFarm => {
                    if (insuranceFarm.key !== farm.key) return insuranceFarm;
                    insuranceFarm.id = farmId;
                    const existingFarm = creditFarms.find(farm => farm.id === farmId);
                    if (existingFarm) {
                      insuranceFarm.latitude = existingFarm.latitude || '';
                    }
                    if (existingFarm) {
                      insuranceFarm.longitude = existingFarm.longitude || '';
                    }
                    return insuranceFarm;
                  }),
                })}
                className={cls.farmSelect}
                options={[{
                  value: 0,
                  label: 'New Farm',
                }, ...creditFarms.map((creditFarm, i) => ({
                  value: creditFarm.id,
                  label: `Farm #${i + 1}`,
                  disabled: usedFarmIds.includes(creditFarm.id || 0) && creditFarm.id !== farm.id,
                })),
                ]}
              />
              {!isDownloading && (
                <>
                  <OrangeOutlineButton
                    onClick={() => removeFarm(`${farm.key}`)}
                    className={cls.farmBtnRemove}
                  >x</OrangeOutlineButton>
                  {farmIndex === 0 && (
                    <BlueButton
                      onClick={addFarm}
                      className={cls.farmBtnAdd}
                    >+ Add Farm</BlueButton>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
};

const useStyles = makeStyles({
  farms: {
    minHeight: 37,
  },
  farmActions: {
    display: 'flex',
    alignItems: 'center',
  },
  farmSelect: {
    width: 105,
    fontSize: 13,
    marginTop: -5,
    '& .ant-select-selector': {
      border: 'none !important',
      borderBottom: '1px solid rgba(0, 42, 119, 0.10) !important',
    },
  },
  farmBtnRemove: {
    marginLeft: 8,
    minWidth: 28,
    width: 28,
    height: 28,
    padding: 0,
  },
  farmBtnAdd: {
    marginLeft: 8,
    minWidth: 90,
    width: 90,
    height: 28,
    padding: 0,
    fontSize: 14,
    borderRadius: 4,
  },
  container: {},
  protected: {
    margin: '20px 20px 15px',
  },
  protectedText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 15,
    borderBottom: '1px solid #042E6B',
    fontSize: '14px',
    fontWeight: 600,
  },
  protectedLink: {
    color: '#1890FF',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
    margin: '0 6px',
  },
  cultivo: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 20px',
    margin: '0 0 -30px',
  },
  isDownloading: {
    padding: '10px 15px',
  },
  btnDownload: {
    padding: '0 !important',
    minWidth: 45,
    width: 45,
    height: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '-5px 0',
    '& svg': {
      margin: 0,
    },
  },
  fields: {
    '& .ant-row': {
      marginTop: 5,
      minHeight: 32,
    },
    '& .ir-label': {
      fontSize: 14,
    },
  },
  fieldsLabel: {
    fontSize: 16,
    color: '#000',
  },
  formInput: {
    border: 'none',
    borderRadius: 0,
    borderBottom: '1px solid rgba(0, 42, 119, 0.10)',
    width: '100%',
    fontSize: '12px !important',
    '&:focus, &:hover, &[class~="ant-input-number-focused"], & [class~="ant-input-number-focused"], &.ant-input-number-affix-wrapper-focused': {
      boxShadow: 'none',
      borderBottom: '1px solid #002A77 !important',
      '& .ant-input-number-input-wrap': {
        border: 'none !important',
      },
    },
    '& .ant-picker-input': {
      border: 'none !important',
    },
  },
  select: {
    width: '100%',
    border: 'none !important',
    backgroundColor: 'transparent !important',
    '& .ant-select-selector': {
      backgroundColor: 'transparent !important',
      border: 'none !important',
      borderBottom: '1px solid rgba(0, 42, 119, 0.10) !important',
      borderRadius: '0 !important',
    },
  },
  inputPrefix: {
    position: 'absolute',
    top: 6,
    right: 15,
  },
  document: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  documentTitle: {
    fontSize: '14px',
    color: '#000',
    marginBottom: 4,
  },
  upload: {
    width: 128,
    height: 164,
  },
  preview: {
    width: 128,
    height: 164,
    border: '2px solid #042E6B',
    borderRadius: '10px',
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
});
