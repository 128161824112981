import { FC, useEffect, useState } from 'react';
import { Button, Modal, Select, notification } from 'antd';

import { useRequest } from '@hooks';
import { creditHttp } from '@network';
import {
  Doctype,
  Document,
  DocumentFile,
  FileStatus,
  fileStatusMap,
} from '@types';
import cls from './file-statuses.module.css';
import { rbac, ROLES } from '@utils';
import { LightTooltip } from '@components';
import { LockOutlined } from '@ant-design/icons';

interface Props {
  creditId: number;
  file: DocumentFile;
  document: Document;
  setDocument: (document: Document) => void;
  creditContractDoctype?: Doctype;
}

export const FileStatuses: FC<Props> = ({
  creditId,
  file,
  document,
  setDocument,
  creditContractDoctype,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [status, setStatus] = useState<FileStatus>(file.status);
  const [statusOptions, setStatusOptions] = useState<{key: string; label: string}[]>([]);
  const changeStatus = (nextStatus: FileStatus) => setStatus(nextStatus);

  const { submit, loading } = useRequest(async () => {
    if (!status || !document) {
      return;
    }
    const updatedDocument = await creditHttp.changeFileStatus({ creditId, file, status });
    notification.success({ message: 'Status updated!' });
    setDocument(updatedDocument);
    hideModal();
  });

  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  useEffect(() => {
    if (document?.doctypeId === creditContractDoctype?.id) {
      setStatusOptions([
        { key: 'draft', label: 'Draft' },
        { key: 'signed', label: 'Signed' },
      ]);
    } else {
      const options: Array<{key: string, label: string}> = [];
      for (const [key, label] of Object.entries(fileStatusMap)) {
        if (key !== 'draft' && key !== 'signed') {
          options.push({ key, label });
        }
      }

      setStatusOptions(options);
    }
  }, [fileStatusMap, document?.doctypeId, creditContractDoctype?.id]);

  if (!document) {
    return null;
  }

  const hasDocRevisionRole = rbac.hasDocRevisionRole();

  return (
    <>
      <LightTooltip title={hasDocRevisionRole ? '' : `Role is required: ${ROLES.DOC_REVISION}`}>
        <Button
          onClick={hasDocRevisionRole ? showModal : undefined}
          size="small"
          className={cls.btn}
        >
          <span className={cls.btnText}>Status</span>
          {hasDocRevisionRole ? <ArrowDownIcon /> : <LockOutlined className={cls.lockIcon} />}
        </Button>
      </LightTooltip>
      <Modal
        open={isModalVisible}
        title="Change status of file"
        onCancel={hideModal}
        footer={[
          <Button key="submit" type="primary" loading={loading} onClick={submit}>
            Save status
          </Button>,
        ]}
      >
        <Select onChange={changeStatus} className={cls.select} value={status}>
          {statusOptions.map(opt => (
            <Select.Option value={opt.key} key={opt.key}>
              {opt.label}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  );
};

const ArrowDownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
    <path d="M4.08793 5.42919C4.22701 5.42557 4.35983 5.36472 4.46082 5.25838L7.96917 1.50706C8.11326 1.35159 8.1724 1.12244 8.12404 0.905564C8.07567 0.688692 7.92707 0.516756 7.73436 0.454513C7.54165 0.39227 7.33366 0.448931 7.18856 0.603561L4.07039 3.93955L0.952213 0.603562C0.807117 0.448931 0.599122 0.392271 0.406413 0.454514C0.213454 0.516757 0.0651012 0.688692 0.0167356 0.905565C-0.0316299 1.12244 0.0275112 1.35159 0.171604 1.50706L3.67996 5.25838C3.78972 5.37421 3.93682 5.43589 4.08768 5.42919L4.08793 5.42919Z"
      fill="white" />
  </svg>
);
