import { ChangeEvent, useState } from 'react';
import { clsx } from 'clsx';
import { Col, Row } from 'antd';
import { SaveFilled } from '@ant-design/icons';
import {
  CreateProps,
  Create,
  SimpleForm,
  TopToolbar,
  SaveButton,
  useNotify,
  useRedirect,
  useResourceContext,
} from 'react-admin';
import { Button } from '@mui/material';

import {
  Container,
  TextInput,
  PhoneInput,
  ReferenceInput,
  SelectInput,
  Forbidden,
  PageHeader,
  FormRow,
  SwitchInput,
  PasswordInput, NumberInput,
} from '@components';
import { useCreateProps, useQuery, useRbacCreate } from '@hooks';
import { genderOptions, Manager, ManagerTypeValue, typeOfManager } from '@types';
import { useDealerReference } from '@pages/manager/use-dealer-reference';
import { ManagerAvatar } from '@pages/manager/ManagerAvatar';
import { AssociateCompany } from '@pages/manager/AssociateCompany';
import cls from './ManagerCreate.module.css';

export const PASSWORD_MIN_LENGTH = 4;

export const ManagerCreate = (props: CreateProps) => {
  const createProps = useCreateProps();
  const rbacCreate = useRbacCreate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [password, setPassword] = useState<string>('');
  const dealerReference = useDealerReference();
  const query = useQuery();
  const resource = useResourceContext();

  const queryDealerId = query.get('dealerId');
  const isAssociate = resource === 'manager-associate';

  const mainColGrid = {
    xxl: { title: 7, children: 17 },
    sm: { title: 10, children: 14 },
    xs: { title: 24, children: 24 },
  };
  const passwordGrid = {
    xs: { title: 24, children: 24 },
    xxl: { title: 8, children: 16 },
  };

  if (!rbacCreate) {
    return <Forbidden />;
  }

  const copyPassword = (isCreate: boolean) => {
    if (password.length < PASSWORD_MIN_LENGTH) {
      return;
    }

    const message = `${isCreate ? 'Manager is created. ' : ''}Password is copied to clipboard.`;
    navigator.clipboard.writeText(password);

    setTimeout(() => {
      notify(message);
    }, 500);
  };

  const maxLength = 50;
  const validate = (values: any) => {
    const errors: any = {};

    ['name', 'email', 'phone', 'gender', 'buyerId', 'associateId', 'position', 'password', 'confirmPassword']
      .forEach((key: string) => {
        if (values[key]?.length > maxLength) {
          errors[key] = `Max length is ${maxLength} characters`;
        }
        if (key === 'password' || key === 'confirmPassword') {
          if (!values[key]) {
            errors[key] = 'This field is required';
          }
          if (key === 'password') {
            if (values[key]?.length && values[key]?.length < PASSWORD_MIN_LENGTH) {
              errors[key] = `Password must be longer than or equal to ${PASSWORD_MIN_LENGTH} characters`;
            }
          }
          if (key === 'confirmPassword') {
            if (values.password && (!values[key] || values.password !== values[key])) {
              errors[key] = 'Passwords must match';
            }
          }
        } else if (!values[key]) {
          if (key === 'phone') {
            errors[key] = 'Phone number is mandatory';
          } else if (key === 'position') {
            errors[key] = 'Position is mandatory';
          } else if (key === 'associateId') {
            if (isAssociate && !queryDealerId) {
              errors[key] = 'Associate name is mandatory';
            }
          } else if (key === 'buyerId' && isAssociate && !queryDealerId) {
            // no buyerId is required for Solo-Associate manager
          } else {
            errors[key] = 'This field is required';
          }
        }
        // check phone
        if (key === 'phone' && !errors[key]) {
          const phoneLength = (values[key] || '').replace(/\D/g, '').length;
          if (phoneLength < 12 || phoneLength > 13) {
            errors[key] = 'Phone number is not correct';
          }
        }
        // check email
        if (key === 'email' && !errors[key]) {
          const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
          if (!EMAIL_REGEXP.test(values[key] || '')) {
            errors[key] = 'Email is not correct';
          }
        }
      });

    return errors;
  };

  const Actions = () => {
    return (
      <TopToolbar className={cls.actions}>
        <SaveButton
          icon={<SaveFilled className={cls.actionIcon} />}
          label="Save"
          type="button"
          className={clsx(cls.action, cls.actionSave)}
          variant="outlined"
          alwaysEnable
          mutationOptions={{
            onSuccess: (manager: Manager) => {
              copyPassword(true);
              redirect(`/${resource}/${manager.id}`);
            },
          }}
        />
      </TopToolbar>
    );
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const type = resource === 'manager' ? 'Off Taker'
    : resource === 'manager-retailer' ? 'Retail Store' : 'Associate';
  const companyTitle = resource === 'manager' ? 'Buyer'
    : resource === 'manager-retailer' ? 'Retailer' : 'Associate';
  const companyName = resource === 'manager' ? 'Company name'
    : resource === 'manager-retailer' ? 'Retail store' : 'Associate name';

  return (
    <Create {...props} {...createProps} className={clsx(cls.page, 'details-page-wrapper')}>
      <>
        <PageHeader
          title="Manager's information"
          linkText="Back to all records"
          linkUrl={`/${resource}`}
        />

        <SimpleForm
          toolbar={<Actions />}
          className={clsx(cls.form, 'manager-form')}
          validate={validate}
          defaultValues={{
            isEnabled: true,
            isSuperadmin: false,
            position: resource === 'manager-associate' ? 'Socio' : undefined,
            type: ManagerTypeValue.BUYER,
          }}
        >
          <Container max>
            <Row gutter={24} className={cls.wrapper}>
              <Col xs={24} md={12} className={cls.wrapperCol}>
                <div className={clsx(cls.block, cls.blockFullHeight)}>
                  <div className={cls.blockHeader}>Manager</div>
                  <div className={cls.blockMiddle}>
                    <div className={cls.blockMiddleIcon}>
                      <ManagerAvatar />
                    </div>
                    <div className={cls.blockMiddleInfo}>
                      <FormRow
                        title="Name*:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <TextInput name="name" source="name" required autoFocus
                          label={false} inputProps={{ maxLength }} onInput={transformTrimInput} />
                      </FormRow>
                      <FormRow
                        title="Phone*:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <PhoneInput source="phone" name="phone" required
                          label={false} inputProps={{ maxLength }} onInput={transformTrimInput} />
                      </FormRow>
                      <FormRow
                        title="Email*:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <TextInput
                          name="email"
                          source="email"
                          required
                          label={false}
                          type="email"
                          inputProps={{ maxLength }}
                          onInput={transformTrimInput}
                        />
                      </FormRow>
                      <FormRow
                        title="Gender*:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <SelectInput label="" source="gender" choices={genderOptions} />
                      </FormRow>
                      {resource === 'manager' ? <FormRow
                        title="Type of Manager:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <SelectInput label="" source="typeOfManager" choices={typeOfManager} />
                      </FormRow> : <div className={cls.type}>Type of Manager: {type}</div>}
                      <FormRow
                        title="VP:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <NumberInput
                          name="vp"
                          source="vp"
                          label={false}
                          onInput={transformNumberInput}
                        />
                      </FormRow>
                    </div>
                  </div>
                  <div className={cls.checkboxRow}>
                    <SwitchInput
                      name="isEnabled"
                      source="isEnabled"
                      label="Is enabled"
                      className={cls.switcherField}
                    />
                    <SwitchInput
                      disabled={isAssociate}
                      name="isSuperadmin"
                      source="isSuperadmin"
                      label="Supervisor"
                      className={cls.switcherField}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} className={cls.wrapperCol}>
                <div className={cls.block}>
                  <div className={cls.blockHeader}>{companyTitle}</div>
                  <div className={cls.blockMiddle}>
                    <div className={cls.blockCompanyIcon}>
                      <svg xmlns="http://www.w3.org/2000/svg"
                        width="55"
                        height="55"
                        viewBox="0 0 55 55"
                        fill="none">
                        <path
                          d="M27.5 0C12.3161 0 0 12.3161 0 27.5C0 42.6839 12.3161 55 27.5 55C42.6839 55 55 42.6839 55 27.5C55 12.3161 42.6839 0 27.5 0ZM27.5 13.75C33.6875 13.75 38.7161 18.7786 38.7161 24.9661C38.7161 31.1536 33.6875 36.1821 27.5 36.1821C21.3125 36.1821 16.2839 31.1536 16.2839 24.9661C16.2839 18.7786 21.3125 13.75 27.5 13.75ZM27.5 51.4643C21.9214 51.4643 16.775 49.5393 12.6893 46.3179C14.1429 44.2357 16.5393 42.9786 19.0929 42.9786H35.9071C38.4607 42.9786 40.8571 44.2357 42.3107 46.3179C38.225 49.5393 33.0786 51.4643 27.5 51.4643Z"
                          fill="#042E6B" />
                      </svg>
                    </div>
                    <div className={cls.blockCompanyInfo}>
                      {isAssociate ? <AssociateCompany autoUpdate={true} /> : (
                        <FormRow
                          title={`${companyName}*:`}
                          width={mainColGrid}
                          rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                          colTitleClassName={cls.formLabelCol}
                          colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                        >
                          <ReferenceInput
                            name={'buyerId'}
                            source={'buyerId'}
                            reference={dealerReference}
                            isRequired
                            perPage={1000}
                            sort={{ field: 'name', order: 'ASC' }}
                          >
                            <SelectInput optionText="name" required label={false} />
                          </ReferenceInput>
                        </FormRow>
                      )}
                      <FormRow
                        title="Position*:"
                        width={mainColGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.formLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <TextInput source="position" name="position"
                          label={false} inputProps={{ maxLength }} onInput={transformTrimInput} />
                      </FormRow>
                    </div>
                  </div>
                </div>
                <div className={cls.block}>
                  <div className={cls.blockHeader}>
                    <div>Password</div>
                    <Button
                      className={cls.passwordButton}
                      size="small"
                      onClick={() => copyPassword(false)}
                      disabled={password.length < PASSWORD_MIN_LENGTH}
                      type="button"
                    >Copy password</Button>
                  </div>
                  <div className={cls.mainPasswordInfo}>
                    <div className={cls.mainPasswordCol}>
                      <FormRow
                        title="Password*:"
                        width={passwordGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.passwordLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <PasswordInput
                          source="password"
                          name="password"
                          required
                          label={false}
                          onChange={onPasswordChange}
                          inputProps={{ maxLength }}
                        />
                      </FormRow>
                      <FormRow
                        title="Confirm Password*:"
                        width={passwordGrid}
                        rowClassName={clsx(cls.formRow, cls.mainFormRow)}
                        colTitleClassName={cls.passwordLabelCol}
                        colChildrenClassName={clsx(cls.formValueCol, cls.formMainValue)}
                      >
                        <PasswordInput
                          source="confirmPassword"
                          name="confirmPassword"
                          required
                          label={false}
                          inputProps={{ maxLength }}
                        />
                      </FormRow>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </SimpleForm>
      </>
    </Create>
  );
};

const transformTrimInput = (e: any) => {
  if (e.target && e.target.value) {
    e.target.value = (e.target.value || '').replace(/\s\s+/g, ' ').replace(/^\s+/g, '');
  }
};

const transformNumberInput = (e: any) => {
  if (e.target && e.target.value) {
    if (e.target.value > 999999999) {
      e.target.value = 999999999;
    }
    if (e.target.value < 0) {
      e.target.value = 0;
    }
  } else {
    e.target.value = 0;
  }
};
