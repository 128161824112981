import React from 'react';
import { TextInputProps } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { useController } from 'react-hook-form';
import { Switch } from 'antd';
import { clsx } from 'clsx';

export const SwitchInput = (props: TextInputProps & {isFixed?: boolean | null}) => {
  const cls = useStyles();
  const { defaultValue } = props;
  const {
    field: { value, onChange },
  } = useController({ name: props.source });

  const checked = (): boolean => {
    if (value !== undefined) {
      return !!value;
    }
    return defaultValue;
  };

  const handleChange = (e: boolean) => {
    if (props.isFixed) return;
    props.onChange && props.onChange(e);
    onChange(e);
  };

  return (
    <div className={clsx(cls.box, props.className)}>
      <Switch
        defaultChecked={checked()}
        onClick={handleChange}
        onChange={handleChange}
        className={clsx(cls.checkbox, checked() && cls.checkboxChecked)}
        disabled={props.disabled}
      />
      <label className={cls.label}>
        <span>{props.label}</span>
      </label>
    </div>
  );
};

interface SwitchControlledProps {
  value: boolean;
  onChange?: (v: boolean) => void;
  label?: string;
  disabled?: boolean;
  className?: string;
}

export const SwitchControlled = (
  { value, onChange, label, disabled, className }: SwitchControlledProps,
) => {
  const cls = useStyles();

  return (
    <div className={clsx(cls.box, className)}>
      <Switch
        defaultChecked={!!value}
        onClick={onChange ? nextValue => onChange(nextValue) : () => {}}
        onChange={onChange ? nextValue => onChange(nextValue) : () => {}}
        className={clsx(cls.checkbox, value && cls.checkboxChecked)}
        disabled={disabled}
      />
      {label && <label className={cls.label}><span>{label}</span></label>}
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Outfit, sans-serif',
  },
  label: {
    display: 'block',
    color: '#000',
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 1,
    marginLeft: 5,
  },
  checkbox: {
    verticalAlign: 'top',
    height: 20,
    width: 55,
    minWidth: 55,
    background: 'transparent',
    border: '1px solid #002A77',
    opacity: 1,

    '& > div': {
      left: '1px !important',
      top: 1,
      width: 24,
      boxShadow: 'none',
      height: '16px !important',

      '&::before': {
        backgroundColor: '#CCC',
      },
    },
  },
  checkboxChecked: {
    '& > div': {
      left: 'auto !important',
      right: 1,

      '&::before': {
        backgroundColor: '#002A77',
      },
    },
  },
});
