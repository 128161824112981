import { clsx } from 'clsx';
import { RcFile } from 'antd/lib/upload';
import IconButton from '@mui/material/IconButton';
import { CloseCircleFilled } from '@ant-design/icons';

import { s3http } from '@network';
import { useUpdated } from '@hooks';
import { showAxiosError } from '@utils';
import { DocumentFile, SupportDocument } from '@types';
import { ConfirmDelete, FileExtensionPreview, FileUpload } from '@components';
import cls from './SupportFiles.module.css';

interface Props {
  files: SupportDocument[];
  setFiles: (ids: number[]) => void;
  setDocuments: (documents: SupportDocument[]) => void;
  maxFiles?: number;
  error?: any;
  title?: string;
  className?: string;
  previewOnly?: boolean;
}

export const SupportFiles = (
  { files, setFiles, setDocuments, maxFiles, error, title, className, previewOnly }: Props,
) => {
  const updated = useUpdated();

  const uploadAction = async (rcFile: RcFile) => {
    try {
      const file: DocumentFile = await s3http.uploadMediaFile(rcFile as File);
      setFiles([...(Array.isArray(files) ? files.map(f => f.file.id) : []), file.id]);
      setDocuments(Array.isArray(files) ? [...files, { file }] : [{ file }]);

      setImmediate(updated);

      return file.adminUrl;
    } catch (err: any) {
      showAxiosError(err);

      return '';
    }
  };

  const deleteS3File = async (id: number) => {
    const filesList: SupportDocument[] = files.filter((f) => f.file.id !== id);

    try {
      setFiles(filesList.map(f => f.file.id));
      setDocuments([...files.filter(f => f.file.id !== id)]);
      setImmediate(updated);
    } catch (err: any) {
      showAxiosError(err);
    }
  };

  const removeFile = (id: number) => void deleteS3File(id);

  const hasFiles = files?.length > 0;
  const showUpload = !previewOnly
    && ((maxFiles && files?.length < maxFiles) || (!maxFiles && !files?.length));

  return (
    <div className={clsx(cls.wrap, error && cls.error, className)}>
      {title ? <div className={cls.title}>{title}</div> : null}
      <div className={clsx(cls.content, 'sf-content')}>
        {showUpload ? (
          <div className={cls.file}>
            <FileUpload
              uploadAction={uploadAction}
              className={cls.upload}
              wrapperClassName={cls.uploadWrap}
              type="media"
            />
          </div>
        ) : null}
        {hasFiles ? files.map((f: SupportDocument) => (
          <div className={cls.preview} title="preview" key={f.file.id}>
            <FileExtensionPreview
              extension={f.file.extension}
              url={f.file.adminUrl}
              previewUrl={f.file.url}
              downloadFilename={f.file.originalName}
              className={cls.filePreview}
            />
            {!previewOnly ? <IconButton className={cls.removeBtn}>
              <ConfirmDelete title="Are you sure?" cb={() => removeFile(f.file.id)}>
                <CloseCircleFilled className={cls.removeIcon} />
              </ConfirmDelete>
            </IconButton> : null}
          </div>
        )) : null}
      </div>
    </div>
  );
};
