import React, { useState } from 'react';
import { useRedirect, useRefresh } from 'react-admin';
import { Form, message } from 'antd';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import dayjs from 'dayjs';

import { phoneFormatter, showAxiosError } from '@utils';
import { Credit, Doctype, Guarantor } from '@types';
import { creditHttp, GuarantorParams } from '@network';
import { InitialPage } from '@components';
import { CreditDocuments } from './credit-documents/credit-documents';
import {
  CreditGuarantorForm,
} from '@pages/credit/CreditEdit/CreditGuarantor/credit-guarantor-form';

import cls from './credit.guarantor.module.css';

interface CreditGuarantorProps {
  credit: Credit;
  doctypes: Doctype[];
  isSuperadmin: boolean;
  editable: boolean;
  guarantor: Guarantor;
}

export const CreditGuarantor = ({
  credit, doctypes, guarantor, isSuperadmin, editable,
}: CreditGuarantorProps) => {
  const redirect = useRedirect();
  const refresh = useRefresh();

  const [form] = Form.useForm<GuarantorParams>();

  const [formValues]
    = useState<Pick<Guarantor, 'isCompany' | 'fullName' | 'phone' | 'email' | 'marriage' | 'birthdate' | 'rfc' | 'location'>>({
      isCompany: guarantor?.isCompany || false,
      fullName: guarantor?.fullName || '',
      marriage: guarantor?.marriage || '',
      phone: guarantor?.phone ? phoneFormatter.format(guarantor.phone) : '',
      email: guarantor?.email || '',
      birthdate: guarantor?.birthdate ? dayjs(guarantor?.birthdate) : '',
      rfc: guarantor?.rfc || '',
      location: guarantor?.location || {},
    });
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const backTo = `/credit/${credit.id}?initial`;

  const submit = async () => {
    try {
      await creditHttp.updateGuardian(
        guarantor.creditId,
        guarantor.id,
        { ...guarantor, ...form.getFieldsValue() },
      );
      message.success('Updated');
    } catch (err: any) {
      showAxiosError(err);
    }
  };

  const remove = async () => {
    setIsDialogOpen(false);

    try {
      await creditHttp.deleteGuardian(credit.id, guarantor.id);
      refresh();
      redirect(`/credit/${credit.id}?initial`);
    } catch (err: any) {
      showAxiosError(err);
    }
  };

  return (
    <InitialPage title="Guarantor" backTo={backTo} backText="Back to individual credit">
      <div className={cls.content}>
        <Form
          form={form}
          layout="horizontal"
          onFinish={submit}
          className={cls.content}
          initialValues={formValues}
          requiredMark={false}
        >
          <CreditGuarantorForm
            guarantor={guarantor}
            form={form}
            onRemove={() => setIsDialogOpen(true)}
            editable={editable}
          />
        </Form>
      </div>

      <CreditDocuments
        credit={credit}
        doctypes={doctypes}
        isSuperadmin={isSuperadmin}
        editable={editable}
        guarantor={guarantor}
      />
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure to delete this guarantor?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>No</Button>
          <Button onClick={remove} autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>
    </InitialPage>
  );
};
