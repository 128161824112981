import { CreditRisk } from '@types';
import { InitialCard, CreditChat } from '@components';
import cls from './RiskChat.module.css';

export const RiskChat = ({ risk, readonly }: {risk: CreditRisk, readonly?: boolean }) => {
  return (
    <InitialCard left="Chat" autoHeight mediumPadding>
      <CreditChat creditId={risk.creditId} readonly={readonly} className={cls.chatBlock} />
    </InitialCard>
  );
};
