import { clsx } from 'clsx';
import moment from 'moment-timezone';
import { Col, Form, Row } from 'antd';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Edit, EditProps, useGetOne, useNotify, useRedirect, useRefresh } from 'react-admin';

import { axiosErrorToString } from '@utils';
import { financeWithdrawalHttp } from '@network';
import { useEditProps, useRbacEdit, useRecord } from '@hooks';
import { Container, Forbidden, Loader, PageHeader } from '@components';
import {
  CreateFinanceWithdrawalParams,
  FinanceWithdrawal,
  FinanceWithdrawalOptions,
} from '@types';
import { FinanceWithdrawalForm } from '../FinanceWithdrawalForm';
import cls from './finance-withdrawal-edit.module.css';
import { FinanceHistoryButton } from '@pages/finance-deposit/FinanceHistoryButton';

export const FinanceWithdrawalEdit = (props: EditProps) => {
  const financeWithdrawal = useRecord<FinanceWithdrawal>(props);
  const notify = useNotify();
  const redirect = useRedirect();
  const rbacEdit = useRbacEdit();
  const editProps = useEditProps();
  const [formValues, setFormValues] = useState<CreateFinanceWithdrawalParams | null>(null);
  const [options, setOptions] = useState<FinanceWithdrawalOptions | null>(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<CreateFinanceWithdrawalParams>();
  const [submitted, setSubmitted] = useState(false);
  const [clientName, setClientName] = useState('');

  const Title = ({ record }: {record?: FinanceWithdrawal}) =>
    record ? (<span>Withdrawal: {record.code}</span>) : null;

  const { id } = useParams();
  const refresh = useRefresh();
  const { data: record } = useGetOne('finance-withdrawal', { id });

  const getOptions = async () => {
    try {
      const ops: FinanceWithdrawalOptions = await financeWithdrawalHttp.options(id);

      if (!ops) {
        throw new Error('The list of options has not been received');
      }
      setOptions(ops);
    } catch (e) {
      console.error(e);
    }
  };

  const submit = async (params: CreateFinanceWithdrawalParams) => {
    if (loading) return;
    try {
      setLoading(true);
      const model = await financeWithdrawalHttp.update(params, record.id);
      setSubmitted(false);
      addFormData(model);
      notify('Withdrawal has been updated successfully');
      refresh();
    } catch (e) {
      // @ts-ignore
      notify(axiosErrorToString(e), { type: 'error' });
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      const result = await financeWithdrawalHttp.remove(record.id);

      if (result) {
        notify('Withdrawal has been deleted');
        redirect('/finance-withdrawal');
      }
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  const addFormData = (data: FinanceWithdrawal) => {
    setFormValues({
      concept: data.concept,
      amount: data.amount,
      accountId: data.accountId,
      creditId: data.creditId,
      paymentDate: moment(data.paymentDate).format('DD-MM-YYYY'),
      purpose: data.purpose,
      details: data.details,
      sent: data.sent,
      received: data.received,
    });
    data.credit && setClientName(data.credit.accredited);
  };

  useEffect(() => {
    if (record) {
      addFormData(record);
    }
  }, [record]);

  useEffect(() => {
    (async () => {
      await getOptions();
    })();
  }, []);

  if (!options) {
    return <Loader center />;
  }

  if (!rbacEdit) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}
      className={clsx(cls.page, 'details-page-wrapper')}>
      <>
        <PageHeader
          title="Withdrawal"
          linkText="Back to all withdrawals"
          linkUrl="/finance-withdrawal"
          right={<FinanceHistoryButton history={financeWithdrawal.history || []} />}
        />
        <Container max>
          <Row gutter={24} className={cls.wrapper}>
            <Col xs={24} className={cls.wrapperCol}>
              <div className={cls.block}>
                <div className={cls.blockHeader}>Complete information</div>
                {!formValues ? null : (
                  <Form
                    form={form}
                    layout="horizontal"
                    initialValues={formValues}
                  >
                    <FinanceWithdrawalForm
                      financeWithdrawal={financeWithdrawal}
                      loading={loading}
                      setLoading={setLoading}
                      options={options}
                      initial={formValues}
                      onSubmit={submit}
                      form={form}
                      clientName={clientName}
                      setClientName={setClientName}
                      submitted={submitted}
                      setSubmitted={setSubmitted}
                      onDelete={onDelete}
                    />
                  </Form>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    </Edit>
  );
};
