import { clsx } from 'clsx';
import { Link, useRedirect } from 'react-admin';
import React, { JSX, useEffect, useMemo, useState } from 'react';

import { Contract, Credit, Doctype } from '@types';
import { DoctypeDocument } from '@pages/credit/DoctypeDocument';
import { BlueButton, InitialPage, LightTooltip } from '@components';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { doctypeHttp, contractHttp, creditHttp, ContractsVerification, DocumentsCheckResults } from '@network';
import cls from './credit-contracts-details.module.css';

interface CreditContractsDetailsProps {
  credit: Credit;
  setCredit: any;
  isSuperadmin: boolean;
}

const Icon = ({ value }: { value: boolean }) => value
  ? <CheckCircleFilled className={cls.verificationIconValid}/>
  : <CloseCircleFilled className={cls.verificationIconInvalid}/>;

export const CreditContractsDetails = ({
  credit, 
  setCredit,
  isSuperadmin,
}: CreditContractsDetailsProps) => {
  const redirect = useRedirect();
  const [doctypes, setDoctypes] = useState<Doctype[]>([]);
  const [verification, setVerification] = useState<ContractsVerification | null>(null);

  const backTo = `/credit/${credit.id}?initial`;
  const creditDocsLink = `/credit/${credit.id}?scroll=docs`;
  const creditRiskLink = credit.risk ? `/credit-risk/${credit.risk.id}` : '';
  const guarantorsLink = !credit.isGuarantorsRequired ? backTo : credit.guarantors.length
    ? `/credit/${credit.id}?guarantor=${credit.guarantors[0].id}` : `/credit/${credit.id}?guarantor-create`;

  const handleGenContract = async (): Promise<void> => {
    try {
      const contract: Contract = await contractHttp.create({ creditId: credit.id });
      redirect(`/contract/${contract.id}`);
    } catch (e) {
      console.error(e);
    }
  };

  const retentionLetterDoctype = useMemo(() => {
    return doctypes.find(doctype => doctype.isRetentionLetter);
  }, [doctypes]);

  const amortizationTableDoctype = useMemo(() => {
    return doctypes.find(doctype => doctype.isAmortizationTable);
  }, [doctypes]);

  const creditContractDoctype = useMemo(() => {
    return doctypes.find(doctype => doctype.isCreditContract);
  }, [doctypes]);

  const getValidateText = (
    obj: DocumentsCheckResults | undefined, field?: boolean,
  ): JSX.Element => {
    if (!obj) return <></>;

    const data: { title: string, required: number, completed: number }[] = [];
    for (const i in obj) {
      data.push({ title: i, required: obj[i].required, completed: obj[i].completed });
    }

    return field ? (
      <span className={cls.verificationCnt}>&nbsp;
        ({data.reduce((acc, i) => (acc + i.completed), 0)}
        /{data.reduce((acc, i) => (acc + i.required), 0)})
      </span>
    ) : (
      <>
        {data.map((item, i) => (
          <div key={i}>
            {item.title}
            <span className={cls.verificationCnt}>:&nbsp;({item.completed}/{item.required})</span>
          </div>
        ))}
      </>
    );
  };

  useEffect(() => {
    credit && (async () => {
      const res = await doctypeHttp.getAll();
      const verificationRes = await creditHttp.contractsVerification(credit.id);

      if (res) {
        setDoctypes(res);
      }

      setVerification(verificationRes || null);
    })();
  }, [credit]);

  return (
    <InitialPage title="Contracts" backTo={backTo} backText="Back to credit">
      <div className={cls.wrap}>
        {retentionLetterDoctype && (
          <div className={cls.item}>
            <div className={cls.content}>
              <DoctypeDocument
                doctype={retentionLetterDoctype}
                credit={credit}
                setCredit={setCredit}
                isSuperadmin={isSuperadmin}
                editable={true}
              />
            </div>
          </div>
        )}

        {amortizationTableDoctype && (
          <div className={cls.item}>
            <div className={cls.content}>
              <DoctypeDocument
                doctype={amortizationTableDoctype}
                credit={credit}
                setCredit={setCredit}
                isSuperadmin={isSuperadmin}
                editable={true}
              />
            </div>
          </div>
        )}

        {creditContractDoctype && (
          <div className={clsx(cls.item, cls.fullWidth)}>
            <div className={clsx(cls.content, cls.row)}>
              <div className={cls.contract}>
                <DoctypeDocument
                  doctype={creditContractDoctype}
                  credit={credit}
                  setCredit={setCredit}
                  isSuperadmin={isSuperadmin}
                  editable={true}
                  creditContractDoctype={creditContractDoctype}
                />
              </div>
              <div className={cls.integration}>
                <div className={cls.section}>
                  <div className={cls.section_content}>
                    <div className={cls.verification}>
                      <div className={cls.verificationTitle}>Verification</div>
                      <div className={cls.verificationList}>
                        <div className={cls.verificationItem}>
                          <div className={cls.verificationIcon}>
                            <Icon value={verification?.creditDocs.isValid || false}/>
                          </div>
                          <div className={cls.verificationInfo}>
                            <LightTooltip
                              placement="top"
                              title={getValidateText(verification?.creditDocs?.values)}
                              className={cls.tooltip}
                            >
                              <div>
                                <Link to={creditDocsLink}>Full Credit Docs</Link>
                                {getValidateText(verification?.creditDocs?.values, true)}
                              </div>
                            </LightTooltip>
                          </div>
                        </div>
                        <div className={cls.verificationItem}>
                          <div className={cls.verificationIcon}>
                            <Icon value={
                              verification?.guarantorDocs.isValid
                              || !credit.isGuarantorsRequired
                              || false
                            }/>
                          </div>
                          <div className={cls.verificationInfo}>
                            <Link to={guarantorsLink}>Full Guarantor Docs</Link>
                            {getValidateText(verification?.guarantorDocs?.values, true)}
                          </div>
                        </div>
                        <div className={cls.verificationItem}>
                          <div className={cls.verificationIcon}>
                            <Icon value={verification?.risk.isValid || false}/>
                          </div>
                          <div className={cls.verificationInfo}>
                            {creditRiskLink ? (
                              <Link to={creditRiskLink}>Risk Analysis</Link>
                            ) : 'Risk Analysis'}
                          </div>
                        </div>
                        <div className={cls.verificationItem}>
                          <div className={cls.verificationIcon}>
                            <Icon value={verification?.terms.isValid || false}/>
                          </div>
                          <div className={cls.verificationInfo}>
                            {creditRiskLink ? (
                              <Link to={creditRiskLink}>Credit Terms</Link>
                            ) : 'Credit Terms'}
                          </div>
                        </div>
                        <div className={cls.verificationItem}>
                          <div className={cls.verificationIcon}>
                            <Icon value={verification?.retentionLetter.isValid || false}/>
                          </div>
                          <div className={cls.verificationInfo}>Retention Letter</div>
                        </div>
                      </div>
                      <div className={cls.actions}>
                        <BlueButton
                          onClick={handleGenContract}
                          className={cls.genBtn}
                          disabled={!verification || !verification.isContractValid}
                        >{verification?.hasContract ? 'Open Contract' : 'Generate Contract'}</BlueButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </InitialPage>
  );
};
