import React, { useEffect, useState } from 'react';
import { useRefresh } from 'react-admin';
import { Document, Page } from 'react-pdf';
import dayjs from 'dayjs';
import { Modal } from 'antd';
import { CxCredit } from '@types';
import { InitialCard } from '@components';
import { cxHttp, http } from '@network';
import { GetFileBtn } from '@pages/contracts/Components/GetFileBtn';
import cls from './Receipt.module.css';
import { clsx } from 'clsx';

interface Props {
  cxCredit: CxCredit;
}

export const PDFPreview = ({ blobUrl }: any) => {
  const [numPages, setNumPages] = useState<number>();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  if (!blobUrl) {
    return null;
  }

  return (
    <div className={cls.pdfBlock}>
      <Document file={blobUrl} onLoadSuccess={onDocumentLoadSuccess} className={cls.pdfDoc}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            className={cls.pdfPage}
          />
        ))}
      </Document>
    </div>
  );
};

const DownloadBtn = ({ onClick, disabled }: any) => {
  return (
    <div onClick={disabled ? null : onClick} className={clsx(disabled && cls.disabled)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="37" height="20" viewBox="0 0 37 20" fill="none">
        <g filter="url(#filter0_d_3965_24452)">
          <rect x="4" width="28.7805" height="20" rx="5" fill="#042E6B"/>
        </g>
        <path d="M17.2834 4.72766C17.2834 4.32579 17.6224 4 18.0407 4C18.4589 4 18.798 4.32579 18.798 4.72766V12.7319C18.798 13.1338 18.4589 13.4596 18.0407 13.4596C17.6224 13.4596 17.2834 13.1338 17.2834 12.7319V4.72766Z" fill="white"/>
        <path d="M14.2218 10.6997C13.9261 10.4155 13.9261 9.95476 14.2218 9.67059C14.5175 9.38642 14.997 9.38642 15.2928 9.67059L18.5056 12.7578C18.8013 13.042 18.8013 13.5027 18.5056 13.7869C18.2099 14.071 17.7304 14.071 17.4347 13.7869L14.2218 10.6997Z" fill="white"/>
        <path d="M21.7782 10.6997C22.0739 10.4155 22.0739 9.95476 21.7782 9.67059C21.4825 9.38642 21.003 9.38642 20.7072 9.67059L17.4944 12.7578C17.1987 13.042 17.1987 13.5027 17.4944 13.7869C17.7901 14.071 18.2696 14.071 18.5653 13.7869L21.7782 10.6997Z" fill="white"/>
        <rect x="10" y="15" width="17" height="2" rx="1" fill="white"/>
        <rect x="12" y="10" width="7" height="2" rx="1" transform="rotate(90 12 10)" fill="white"/>
        <rect x="27" y="10" width="7" height="2" rx="1" transform="rotate(90 27 10)" fill="white"/>
        <defs>
          <filter id="filter0_d_3965_24452" x="0" y="0" width="36.7805" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3965_24452"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3965_24452" result="shape"/>
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export const ReceiptModal = (
  { creditCode, lastReceipt, isModalVisible, closeModal, lastReceiptPreview }: any,
) => {
  return (
    <Modal
      title={null}
      open={isModalVisible}
      onCancel={closeModal}
      closeIcon={null}
      footer={null}
      wrapClassName={cls.modalWrap}
    >
      <div className={cls.modal}>
        <div className={cls.modalHeader}>
          <div className={cls.modalHeaderDate}>
            <div>Last Update:</div>
            <div className={cls.modalHeaderValue}>
              {lastReceipt?.createdAt ? dayjs(lastReceipt?.createdAt).format('DD/MM/YY') : 'DD/MM/YY'}
            </div>
          </div>
          <div className={cls.modalHeaderAuthor}>
            <div>By:</div>
            <div className={cls.modalHeaderValue}>{lastReceipt?.admin?.name}</div>
          </div>
          <div className={cls.modalHeaderActions}>
            <button className={cls.modalClose} onClick={closeModal}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="10.2427" y="0.343262" width="2" height="14" rx="1" transform="rotate(45 10.2427 0.343262)" fill="white"/>
                <rect x="10.2427" y="0.343262" width="2" height="14" rx="1" transform="rotate(45 10.2427 0.343262)" fill="white"/>
                <rect x="11.657" y="10.2427" width="2" height="14" rx="1" transform="rotate(135 11.657 10.2427)" fill="white"/>
                <rect x="11.657" y="10.2427" width="2" height="14" rx="1" transform="rotate(135 11.657 10.2427)" fill="white"/>
              </svg>
            </button>
            <GetFileBtn
              key={lastReceipt?.file?.adminUrl}
              className={cls.modalDownload}
              url={lastReceipt?.file?.adminUrl}
              fName={`Receipt ${creditCode}`}
            >
              <DownloadBtn />
            </GetFileBtn>
          </div>
        </div>
        <div className={cls.modalContent}>
          <PDFPreview blobUrl={lastReceiptPreview} />
        </div>
      </div>
    </Modal>
  );
};

export const Receipt = ({ cxCredit }: Props) => {
  const refresh = useRefresh();

  const [lastReceipt, setLastReceipt] = useState<any | null>(null);
  const [lastReceiptPreview, setLastReceiptPreview] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = async () => {
    await cxHttp.generateReceipt(cxCredit.id);
    setIsModalVisible(true);
    refresh();
  };

  const closeModal = () => setIsModalVisible(false);

  const downloadReceipts = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    await cxHttp.downloadReceipts(cxCredit.id, cxCredit.code);
  };

  useEffect(() => {
    const getBlobUrl = async (url: string) => {
      const res = await http.get(url, { responseType: 'blob' });
      const blobResponse = new Blob([res.data as any], { type: 'application/pdf' });

      return URL.createObjectURL(blobResponse);
    };

    (async () => {
      const nextLastReceipt = await cxHttp.getLastReceipt(cxCredit.id);

      if (nextLastReceipt) {
        setLastReceipt(nextLastReceipt);

        if (nextLastReceipt?.file) {
          const blobUrl = await getBlobUrl(nextLastReceipt.file?.adminUrl);
          setLastReceiptPreview(blobUrl);
        }
      }
    } )();
  }, [cxCredit]);

  const isEmptyReceipts = cxCredit?.cx?.receipts?.length === 0;

  return (
    <InitialCard
      left="Receipt"
      contentClass={cls.content}
      wrapperClass={cls.cardWrapper}
    >
      <div className={cls.wrap}>
        <GetFileBtn
          className={cls.download}
          url={lastReceipt?.file?.adminUrl}
          fName={`Receipt ${cxCredit.code}`}
          disabled={!lastReceipt?.file?.adminUrl}
        >
          Download
        </GetFileBtn>
        <div className={cls.update} onClick={showModal}>
          <div className={cls.updateTop}>Update</div>
          <div className={cls.updateBottom}>
            Last updated: {lastReceipt?.createdAt ? dayjs(lastReceipt.createdAt).format('DD/MM/YY') : 'DD/MM/YY'}
          </div>
        </div>
        <div className={cls.bottom}>
          <DownloadBtn disabled={isEmptyReceipts} onClick={downloadReceipts} />
        </div>
      </div>

      <ReceiptModal
        creditCode={cxCredit.code}
        lastReceipt={lastReceipt}
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        lastReceiptPreview={lastReceiptPreview}
      />
    </InitialCard>
  );
};
