import { clsx } from 'clsx';
import { useEffect } from 'react';
import { UserStatus } from '@types';
import { CheckboxInput } from '@components';
import { useController } from 'react-hook-form';
import WarningIcon from '@mui/icons-material/Warning';

import cls from './UserStatusForm.module.css';

const userStatuses = ['active', 'inactive'];

interface Props {
  hasLogged?: boolean;
}

export const UserStatusForm = ({ hasLogged }: Props) => {
  const {
    field: { value: status, onChange: setStatus },
  } = useController({ name: 'status' });
  const {
    field: { value: isActive, onChange: setActive },
  } = useController({ name: 'isActive' });
  const {
    field: { value: isInactive, onChange: setInactive },
  } = useController({ name: 'isInactive' });

  useEffect(() => {
    if (isActive && status !== UserStatus.ACTIVE) {
      setStatus(UserStatus.ACTIVE);
      setInactive(false);
    }
    if (isInactive && status !== UserStatus.INACTIVE) {
      setStatus(UserStatus.INACTIVE);
      setActive(false);
    }
  }, [isActive, isInactive]);

  return (
    <div className={cls.form}>
      {userStatuses.includes(status) && (
        <>
          <CheckboxInput source="isActive" label="Active" className={cls.check} />
          <CheckboxInput source="isInactive" label="Inactive" className={cls.check} />
        </>
      )}

      {status === 'not_available' && (
        <>
          <CheckboxInput source="isActive" label="Active" disabled className={cls.check} />
          <CheckboxInput source="isInactive" label="Inactive" disabled className={cls.check} />

          {!hasLogged && (
            <div className={cls.warning}>
              <WarningIcon style={{ color: 'rgb(4, 46, 107)' }} /> Client has not logged in yet
            </div>
          )}
        </>
      )}
    </div>
  );
};


