import { ListProps, TextField } from 'react-admin';
import { useRbacList } from '@hooks';
import {
  DateField,
  DateRangeSelect,
  Forbidden,
  Grid,
  GridList,
  Loader,
  MoneyField,
  SelectArrayInput,
  TextInput,
} from '@components';
import { riskDataHttp } from '@network';
import { useEffect, useState } from 'react';
import { CreditStatusField, creditStatusOptions } from '@pages/credit/CreditStatusField';

export const RiskDataList = (props: ListProps) => {
  const rbacList = useRbacList();
  const [list, setList] = useState<string[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await riskDataHttp.getRiskDataQuestions();
      if (Array.isArray(response)) {
        setList(response);
      }
      setLoading(false);
    })();
  }, []);

  if (!rbacList) {
    return <Forbidden />;
  }

  return isLoading || !list.length ? <Loader mini center /> : (
    <GridList
      {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      hideRemoveButton
    >
      <Grid
        rowClick={false}
        defaultColumns={['credit.code', 'credit.accreditedName', 'credit.status', 'credit.bankApproval',
          'credit.signatureAt', 'credit.paymentAt', ...list,
        ]}
      >
        <TextField source="credit.code" label="Credit code" />
        <TextField source="credit.accreditedName" label="Accredited" />
        <CreditStatusField source="credit.status" label="Credit status" />
        <MoneyField source="credit.bankApproval" label="Credit amount" style={{ display: 'inline-block', width: 100 }} />
        <DateField source="credit.signatureAt" label="Signature date" />
        <DateField source="credit.paymentAt" label="Payment date" />
        {...list.map(i => <TextField key={i} source={i} label={i} sortable={false} />)}
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" label="Search" alwaysOn style={{ width: 200 }} />,
  <SelectArrayInput
    key="status"
    source="status"
    choices={creditStatusOptions}
    className="MS-field"
    style={{ width: 250 }}
    alwaysOn
  />,
  <DateRangeSelect
    key="createdAt"
    source="createdAt"
    label="Signature At/Payment At"
    alwaysOn
    choices={[
      { id: 'signatureAt', name: 'Signature At' },
      { id: 'paymentAt', name: 'Payment At' },
    ]}
  />,
];
