import { useEffect, useState } from 'react';
import {
  useResourceContext, useUpdate, useGetOne, useNotify, useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { Forbidden, InitialPage } from '@components';
import { Doctype } from '@types';
import { useRbacView } from '@hooks';
import { doctypeHttp } from '@network';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { DoctypeForm } from '@pages/doctype/DoctypeForm';

export const DoctypeEdit = () => {
  const rbacView = useRbacView();
  const resource = useResourceContext();
  const [update, { isSuccess, isError, error }] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();

  const { id } = useParams();

  const { data: record } = useGetOne('doctype', { id });

  const backTo = `/${resource}`;

  const [formValues, setFormValues] = useState<Doctype | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const updateHandler = async () => {
    try {
      await update(
        resource,
        { id: record.id, data: {
          ...formValues,
          requirements: formValues?.requirements ? formValues?.requirements : record.requirements,
        } },
      );
    } catch (e) {
      console.error(e);
    }
  };

  const removeHandler = async () => {
    try {
      await doctypeHttp.remove(record.id);
      void redirect(backTo);
    } catch (e) {
      console.error(e);
    } finally {
      setIsDialogOpen(false);
    }
  };

  useEffect(() => {
    if (record) {
      setFormValues(record);
    }
  }, [record]);

  useEffect(() => {
    if (isSuccess) {
      notify('Doctype has been updated');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const { message } = error as any;
      notify(message || 'Something went wrong', { type: 'error' });
    }
  }, [isError]);

  if (!rbacView) {
    return <Forbidden />;
  }

  if (!formValues) {
    return null;
  }

  return (
    <InitialPage
      title={resource === 'doctype-company' ? 'Doctype company edit' : 'Doctype individual edit'}
      backTo={backTo}
      backText="Back to all documents"
    >
      <DoctypeForm
        type="edit"
        id={record.id}
        formValues={formValues}
        setFormValues={setFormValues}
        submit={updateHandler}
        setIsDialogOpen={setIsDialogOpen}
      />

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            Are you sure?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>
              No
          </Button>
          <Button onClick={removeHandler} autoFocus>
              Yes
          </Button>
        </DialogActions>
      </Dialog>
    </InitialPage>
  );
};
