import { FunctionField, FunctionFieldProps } from 'react-admin';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';

import { ProfileProgress, UserStatus } from '@types';

export const profileProgressOptions = [
  { id: ProfileProgress.COMPLETE, name: 'Complete', color: '#36BE29' },
  { id: ProfileProgress.INCOMPLETE, name: 'Incomplete', color: '#a1a1a1' },
];

export const UserProfileProgress = (props: Partial<FunctionFieldProps>) => {
  const cls = useStyles();

  return (
    <FunctionField {...props} render={(record: any) => {
      let status: string | ProfileProgress;

      if (props.source === 'user.profileProgress') {
        status = record.user?.status;
      } else {
        status = record[props.source || 'profileProgress'];
      }

      const statusName = profileProgressOptions.find(o => o.id === status)?.name;
      const color: any = profileProgressOptions.find(o => o.id === status)?.color;

      return record && color ? (
        <div className={cls.box}>
          <Chip
            label={statusName || status}
            size="small"
            style={{
              margin: '0 auto',
              backgroundColor: color ?? undefined,
              width: '100%',
            }}
            className={cls.chip}
          />
        </div>
      ) : null;
    }} />
  );
};

const useStyles = makeStyles({
  box: {
    display: 'flex',
    justifyContent: 'center',
  },
  chip: {
    color: 'white !important',
    width: 'auto !important',
    lineHeight: 16,
    display: 'inline-flex',
    '& *': {
      color: 'white !important',
      lineHeight: 'normal',
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
});
