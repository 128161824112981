import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { dealerTiers, Doctype } from '@types';
import { Button } from '@mui/material';
import { Col, Row } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useNotify } from 'react-admin';
import { clsx } from 'clsx';

import { axiosErrorToString, showAxiosError } from '@utils';
import { convertFileToBase64, FileUpload, InitialCard, SwitchControlled } from '@components';
import { requirementsOptions } from '@pages/doctype/requirements-options';
import { categoryOptions, teamCategoryOptions } from '@pages/doctype/category-options';
import { InputText } from '@components/inputs/InputText';
import { InputSelect } from '@components/inputs/InputSelect';
import { NewDoctype } from '@pages/doctype/DoctypeCreate';
import cls from '@pages/doctype/doctype.module.css';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';

const MAX_IMAGE_SIZE = 5;

interface DoctypeFormProps {
  formValues: NewDoctype;
  setFormValues: Dispatch<SetStateAction<any>>;
  type: 'create' | 'edit';
  submit: () => void;
  setIsDialogOpen?: Dispatch<SetStateAction<boolean>>;
  id?: string;
}

const UploadContent = () => {
  return (
    <div className={cls.uploadContent}>
      <div className={cls.uploadContentBlock}>
        <div className={cls.uploadContentTitle}>Upload Image</div>
        <div className={cls.uploadContentIcon}>
          <svg xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="32"
            viewBox="0 0 26 32"
            fill="none">
            <path d="M21.5 15.27C21.5 13.34 19.93 11.77 18 11.77C16.07 11.77 14.5 13.34 14.5 15.27C14.5 17.2 16.07 18.77 18 18.77C19.93 18.77 21.5 17.2 21.5 15.27ZM18 13.77C18.83 13.77 19.5 14.44 19.5 15.27C19.5 16.1 18.83 16.77 18 16.77C17.17 16.77 16.5 16.1 16.5 15.27C16.5 14.44 17.17 13.77 18 13.77Z"
              fill="#042E6B" />
            <path d="M23 8.27002H17C16.45 8.27002 16 8.72002 16 9.27002C16 9.82002 16.45 10.27 17 10.27H23C23.55 10.27 24 10.72 24 11.27V25.48L20.08 21.56C18.32 19.89 15.54 19.93 13.83 21.65L12.73 22.75L11.82 21.83L8.1 18.14C6.5 16.56 4.19 15.91 2 16.43V11.27C2 10.72 2.45 10.27 3 10.27H9C9.55 10.27 10 9.82002 10 9.27002C10 8.72002 9.55 8.27002 9 8.27002H3C1.34 8.27002 0 9.61002 0 11.27V28.27C0 29.93 1.34 31.27 3 31.27H22C24.21 31.27 26 29.48 26 27.27V11.27C26 9.61002 24.66 8.27002 23 8.27002ZM22 29.27H3C2.45 29.27 2 28.82 2 28.27V18.53L2.27 18.41C3.84 17.97 5.53 18.41 6.68 19.56L10.39 23.27L11.39 24.27H11.44L11.59 24.42C11.9 24.69 12.31 24.83 12.72 24.83C13.12 24.83 13.51 24.69 13.82 24.45L14 24.27L15.22 23.05C16.21 22.11 17.77 22.11 18.76 23.05L23.76 28.05C23.46 28.76 22.77 29.24 22 29.27Z"
              fill="#042E6B" />
            <path d="M10.5209 4.69986L12.0009 3.26986V11.2699C12.0009 11.8199 12.4509 12.2699 13.0009 12.2699C13.5509 12.2699 14.0009 11.8199 14.0009 11.2699V3.19986L15.4809 4.67986C15.8709 5.06986 16.5109 5.06986 16.9009 4.67986C17.2909 4.28986 17.2909 3.65986 16.9009 3.26986L14.0709 0.449864C13.5109 -0.120136 12.5909 -0.150136 12.0009 0.389864L11.9309 0.449864L9.11086 3.26986C8.72086 3.65986 8.72086 4.29986 9.11086 4.68986C9.50086 5.07986 10.1309 5.08986 10.5309 4.69986H10.5209Z"
              fill="#042E6B" />
          </svg>
        </div>
      </div>
      <div className={cls.uploadContentComment}>
        *File size should not exceed {MAX_IMAGE_SIZE}MB
      </div>
    </div>
  );
};

const RemoveIcon = ({ className, cb }: {className: string; cb: any}) => (
  <div className={className} onClick={cb}>
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
      <path fill="#D72B2C"
        d="M17.5039 0C7.83826 0 0 7.83826 0 17.5039C0 27.1695 7.83826 35.0078 17.5039 35.0078C27.1695 35.0078 35.0078 27.1695 35.0078 17.5039C34.9922 7.83826 27.1617 0.00777605 17.5039 0Z" />
      <path fill="white"
        d="M24.0895 10.9101C23.7863 10.6068 23.2964 10.6068 22.9931 10.9101L17.5032 16.4L12.0133 10.9101C11.7101 10.6068 11.2202 10.6068 10.9169 10.9101C10.6136 11.2133 10.6136 11.7032 10.9169 12.0065L16.4068 17.4964L10.9169 22.9863C10.6136 23.2895 10.6136 23.7794 10.9169 24.0827C11.2202 24.386 11.7101 24.386 12.0133 24.0827L17.5032 18.5928L22.9931 24.0827C23.2964 24.386 23.7863 24.386 24.0895 24.0827C24.3928 23.7794 24.3928 23.2895 24.0895 22.9863L18.5996 17.4964L24.0895 12.0065C24.3928 11.7032 24.3928 11.2133 24.0895 10.9101Z" />
    </svg>
  </div>
);

export const DoctypeForm = ({
  id, submit, type, formValues, setFormValues, setIsDialogOpen,
}: DoctypeFormProps) => {
  const notify = useNotify();

  const requirements = requirementsOptions.map(r => r.name);
  const clientCategories = categoryOptions.map(r => r.name);
  const teamCategories = teamCategoryOptions.map(r => r.name);

  const [currentRequirement, setCurrentRequirement] = useState<string | null>(null);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom);
  }, []);

  const onChangeHandler = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    } as Doctype);
  };

  const onRfcChange = (value: boolean) => {
    setFormValues({
      ...formValues,
      isRfc: value,
      isCurp: value ? !value : formValues?.isCurp,
    } as Doctype);
  };

  const onCurpChange = (value: boolean) => {
    setFormValues({
      ...formValues,
      isRfc: value ? !value : formValues?.isRfc,
      isCurp: value,
    } as Doctype);
  };

  const onToggleChange = (value: boolean, name: string) => {
    setFormValues({
      ...formValues,
      [name]: value,
    } as Doctype);
  };

  const onRequirementsChange = (e: any) => {
    const selectedRequirements =
      requirementsOptions.find(o => o.name === e.target.value);
    setFormValues({
      ...formValues,
      requirements: selectedRequirements?.id || '',
    } as Doctype);
  };

  const uploadPicture = async (rcFile: RcFile): Promise<any> => {
    try {
      const base64Img = await convertFileToBase64(rcFile) as string;

      if (base64Img) {
        setFormValues({
          ...formValues,
          image: base64Img as string,
        } as Doctype);
      }
    } catch (err: any) {
      showAxiosError(err);
    }
  };

  const clearImage = () => {
    setFormValues({
      ...formValues,
      image: '',
    } as Doctype);
  };

  const submitHandler = async () => {
    if (formValues?.nameMx.length === 0
      || formValues?.nameEn.length === 0
      || formValues?.category?.length === 0
      || formValues?.requirements?.length === 0
    ) {
      setShowErrors(true);
      return;
    }

    setShowErrors(false);

    try {
      await submit();
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  const getPreview = (src: string) => {
    return (
      <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
        <img src={src} className={cls.img} style={{ maxWidth: 400 }} alt="icon" />
      </ControlledZoom>
    );
  };

  useEffect(() => {
    if (formValues?.requirements) {
      const selectedRequirements =
        requirementsOptions.find(o => o.id === formValues?.requirements);
      setCurrentRequirement(selectedRequirements?.name || '');
    }
  }, [formValues?.requirements]);

  const showNameMxError = formValues?.nameMx?.length === 0 && showErrors;
  const showNameEnError = formValues?.nameEn?.length === 0 && showErrors;
  const showClientCategoryError = formValues?.category?.length === 0 && showErrors;
  const showTeamCategoryError = formValues?.teamCategory?.length === 0 && showErrors;
  const showNameRequirementsError = formValues?.requirements?.length === 0 && showErrors;

  return (
    <>
      <Row className={cls.content} gutter={20}>
        <Col span={12}>
          <InitialCard left="General information" extraPadding>
            <div className={cls.form}>
              <div className={cls.formRow}>
                <InputText name="id" label="ID" labelWidth={160} value={id || ''} disabled />
              </div>
              <div className={cls.formRow}>
                <InputText
                  name="nameMx"
                  label="Title MX"
                  labelWidth={160}
                  value={formValues?.nameMx}
                  onChange={onChangeHandler}
                  className={clsx(showNameMxError && cls.error)}
                />
              </div>
              <div className={cls.formRow}>
                <InputText
                  name="nameEn"
                  label="Title EN"
                  labelWidth={160}
                  value={formValues?.nameEn}
                  onChange={onChangeHandler}
                  className={clsx(showNameEnError && cls.error)}
                />
              </div>
              <div className={cls.formRow}>
                <InputText name="infoMx"
                  label="Subtitle MX"
                  labelWidth={160}
                  value={formValues?.infoMx || ''}
                  onChange={onChangeHandler} />
              </div>
              <div className={cls.formRow}>
                <InputText name="infoEn"
                  label="Subtitle EN"
                  labelWidth={160}
                  value={formValues?.infoEn || ''}
                  onChange={onChangeHandler} />
              </div>
              <div className={cls.formRow}>
                <InputSelect
                  labelWidth={160}
                  label="Applies to type of User"
                  name="requirements"
                  values={requirements}
                  selectedValue={currentRequirement || ''}
                  onChange={onRequirementsChange}
                  className={clsx(showNameRequirementsError && cls.error)}
                />
              </div>
              <div className={cls.formRow}>
                <InputSelect
                  multiple
                  labelWidth={160}
                  label="Excluded for Tier"
                  name="excludedTiers"
                  values={dealerTiers}
                  selectedValue={formValues?.excludedTiers || []}
                  onChange={onChangeHandler}
                />
              </div>
              <div className={cls.formRow}>
                <InputSelect
                  labelWidth={160}
                  label="Client Category"
                  name="category"
                  values={clientCategories}
                  selectedValue={formValues?.category || ''}
                  onChange={onChangeHandler}
                  className={clsx(showClientCategoryError && cls.error)}
                />
              </div>
              <div className={cls.formRow}>
                <InputSelect
                  labelWidth={160}
                  label="Team Category"
                  name="teamCategory"
                  values={teamCategories}
                  selectedValue={formValues?.teamCategory || ''}
                  onChange={onChangeHandler}
                  className={clsx(showTeamCategoryError && cls.error)}
                />
              </div>
            </div>
          </InitialCard>
        </Col>

        <Col span={12}>
          <InitialCard left="Image and Settings" extraPadding>
            {formValues?.image ? (
              <div className={cls.uploadedBox}>
                <RemoveIcon className={cls.removeImg} cb={clearImage} />
                {getPreview(formValues.image)}
              </div>
            ) : (
              <FileUpload
                uploadAction={uploadPicture}
                className={cls.uploadBox}
                type="image"
                content={<UploadContent />}
                size={MAX_IMAGE_SIZE}
              />
            )}
            <div className={cls.formCheckboxes}>
              <div className={cls.formRow}>
                <SwitchControlled value={!!formValues?.isDefault}
                  onChange={(value) => onToggleChange(value, 'isDefault')} />
                <div className={cls.formLabel}>Default of Credit docs</div>
              </div>
              <div className={cls.formRow}>
                <SwitchControlled value={!!formValues?.isGuarantor}
                  onChange={(value) => onToggleChange(value, 'isGuarantor')} />
                <div className={cls.formLabel}>Default of Guarantor docs</div>
              </div>
              <div className={cls.formRow}>
                <SwitchControlled value={!!formValues?.isRfc} onChange={onRfcChange} />
                <div className={cls.formLabel}>Is RFC document</div>
              </div>
              <div className={cls.formRow}>
                <SwitchControlled value={!!formValues?.isCurp} onChange={onCurpChange} />
                <div className={cls.formLabel}>Is CURP document</div>
              </div>
              <div className={cls.formRow}>
                <SwitchControlled value={!!formValues?.isOptional}
                  onChange={(value) => onToggleChange(value, 'isOptional')} />
                <div className={cls.formLabel}>Is optional</div>
              </div>
              <div className={cls.formRow}>
                <SwitchControlled value={!!formValues?.isVisible}
                  onChange={(value) => onToggleChange(value, 'isVisible')} />
                <div className={cls.formLabel}>Is visible for client/partner</div>
              </div>
              <div className={cls.formRow}>
                <SwitchControlled
                  value={!!formValues?.isSendToCredit}
                  onChange={(value) => onToggleChange(value, 'isSendToCredit')}
                />
                <div className={cls.formLabel}>Send to Analysis</div>
              </div>
              <div className={cls.formRow}>
                <SwitchControlled
                  value={!!formValues?.isForLoanTape}
                  onChange={(value) => onToggleChange(value, 'isForLoanTape')}
                />
                <div className={cls.formLabel}>For Loan Tape</div>
              </div>
              <div className={cls.formRow}>
                <SwitchControlled
                  value={!!formValues?.isRestricted}
                  onChange={(value) => onToggleChange(value, 'isRestricted')}
                />
                <div className={cls.formLabel}>Restricted</div>
              </div>
              <div className={cls.formRow}>
                <SwitchControlled
                  value={!!formValues?.isPrefilled}
                  onChange={(value) => onToggleChange(value, 'isPrefilled')}
                />
                <div className={cls.formLabel}>Future Pre-fill</div>
              </div>
            </div>
          </InitialCard>
        </Col>
      </Row>

      <Row className={cls.actions}>
        <Button variant="contained" className={cls.save} onClick={submitHandler}>Save</Button>
        {type === 'edit' && setIsDialogOpen && (
          <Button variant="contained"
            className={cls.delete}
            onClick={() => setIsDialogOpen(true)}>Delete</Button>
        )}
      </Row>
    </>
  );
};
