import clsx from 'clsx';
import Chip from '@mui/material/Chip';
import { FunctionField, ListProps, TextField } from 'react-admin';

import {
  DateField,
  Forbidden,
  Grid,
  GridList,
  MoneyField,
  SelectArrayInput,
  TextInput,
} from '@components';
import { LoanDataActions } from './LoanDataActions';
import { useExporter, useRbacEdit, useRbacList } from '@hooks';
import { creditStatusOptions } from '@pages/credit/CreditStatusField';
import cls from './LoanData.module.css';

export const LoanDataList = (props: ListProps) => {
  const rbacList = useRbacList();
  const rbacEdit = useRbacEdit();
  const exporter = useExporter({
    filename: 'Loan Data',
    props: {
      'customerId': {
        label: 'Customer ID',
      },
      'appId': {
        label: 'App ID',
      },
      'loanId': {
        label: 'Loan ID',
      },
      'productType': {
        label: 'Product Type',
      },
      'disbursementDate': {
        label: 'Disbursement Date',
        transform: 'date',
      },
      'disbursedAmount': {
        label: 'Disbursed Amount',
        transform: 'money',
      },
      'originationFeeValue': {
        label: 'Origination Fee Value',
        transform: 'money',
      },
      'loanCurrency': {
        label: 'Loan Currency',
      },
      'interestRate': {
        label: 'Interest Rate',
        transform: 'money',
      },
      'term': {
        label: 'Term',
      },
      'termUnit': {
        label: 'Term Unit',
      },
      'paymentFrequency': {
        label: 'Payment Frequency',
      },
      'daysInDefault': {
        label: 'Days in Default',
      },
      'creditLimit': {
        label: 'Credit Limit',
      },
      'outstandingBalance': {
        label: 'Outstanding Balance',
        transform: 'money',
      },
      'pledgeTo': {
        label: 'Pledge to',
      },
      'pledgeDate': {
        label: 'Pledge Date',
        transform: 'date',
      },
      'creditStatus': {
        label: 'Loan Status',
        transform: 'status',
      },
      'otherData': {
        label: 'Other Data',
      },
      'newLoanId': {
        label: 'New Loan ID',
      },
      'newLoanDate': {
        label: 'New Loan Date',
        transform: 'date',
      },
      'oldLoanId': {
        label: 'Old Loan ID',
      },
      'dateWhenMoneyWasReceived': {
        label: 'Date when money was received',
        transform: 'date',
      },
      'recoveryValue': {
        label: 'Recovery Value',
        transform: 'money',
      },
    },
  });

  const renderStatus = (record: any) => {
    console.log(record.loanStatus);
    const statusCls = record.loanStatus.replace(' ', '_') || '';
    return (<Chip label={record.loanStatus} size="small" className={clsx(cls.status, statusCls)}/>);
  };

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList
      {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      hideRemoveButton
      exporter={exporter}
      editComponent={rbacEdit ? <LoanDataActions /> : undefined}
    >
      <Grid
        rowClick={false}
        defaultColumns={'customerId appId loanId productType disbursementDate disbursedAmount'.split(' ')}
      >
        <TextField source="customerId" label="Customer Id" />
        <TextField source="appId" label="App Id" />
        <TextField source="loanId" label="Loan ID" />
        <TextField source="productType" label="Product Type" />
        <DateField source="disbursementDate" label="Disbursement Date" />
        <MoneyField source="disbursedAmount" label="Disbursed Amount" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="originationFeeValue" label="Origination Fee Value" style={{ display: 'inline-block', width: 100 }} />
        <TextField source="loanCurrency" label="Loan Currency" />
        <MoneyField source="interestRate" label="Interest Rate" style={{ display: 'inline-block', width: 100 }} />
        <TextField source="term" label="Term" />
        <TextField source="termUnit" label="Term unit" />
        <TextField source="paymentFrequency" label="Payment Frequency" />
        <TextField source="daysInDefault" label="Days in Default" />
        <TextField source="creditLimit" label="Credit Limit" />
        <MoneyField source="outstandingBalance" label="Outstanding Balance" style={{ display: 'inline-block', width: 100 }} />
        <TextField source="pledgeTo" label="Pledge to" />
        <DateField source="pledgeDate" label="Pledge Date" />
        <FunctionField source="loanStatus" label="Loan Status" render={renderStatus}/>
        <TextField source="otherData" label="Other Data" />
        <TextField source="newLoanId" label="New Loan ID" />
        <DateField source="newLoanDate" label="New Loan Date" />
        <TextField source="oldLoanId" label="Old Loan ID" />
        <DateField source="dateWhenMoneyWasReceived" label="Date when money was received" />
        <MoneyField source="recoveryValue" label="Recovery Value" style={{ display: 'inline-block', width: 100 }} />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" label="Search" alwaysOn style={{ width: 200 }} />,
  <SelectArrayInput
    key="status"
    source="status"
    choices={creditStatusOptions}
    className="MS-field"
    style={{ width: 250 }}
    alwaysOn
  />,
];
