import { clsx } from 'clsx';
import { Button, Modal, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { FunctionFieldProps, FunctionField } from 'react-admin';

import { Dealer } from '@types';
import { dealerHttp } from '@network';
import { useRequest, useUpdated } from '@hooks';
import cls from './MergeDealersForm.module.css';

interface FormProps {
  btnClass?: string;
  dealer: Dealer;
  isBig?: boolean;
  closeSidebar?: any;
}

export const MergeDealersForm = (props: Partial<FunctionFieldProps>) => {
  return (
    <FunctionField
      {...props}
      sortable={false}
      render={(record: any) => (
        <MergeForm dealer={record} />
      )}
    />
  );
};

export const MergeForm = ({ dealer, isBig, btnClass, closeSidebar }: FormProps) => {
  const selectRef = useRef<any>();
  const [dealers, setDealers] = useState<Dealer[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mergeDealer, setMergeDealer] = useState<Dealer | undefined>();
  const updated = useUpdated('Dealers are merged!');

  const showModal = () => {
    setIsModalVisible(true);
    setMergeDealer(undefined);
    setTimeout(() => void selectRef.current?.focus(), 500);
  };
  const hideModal = () => setIsModalVisible(false);

  useEffect(() => {
    (async function () {
      const nextDealers = await dealerHttp.getList();
      setDealers(nextDealers);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loading, submit } = useRequest(async () => {
    if (!mergeDealer) {
      setTimeout(() => void selectRef.current?.focus(), 500);

      return;
    }
    await dealerHttp.merge(dealer.id, mergeDealer.id);
    setMergeDealer(undefined);
    hideModal();
    closeSidebar && closeSidebar();
    setImmediate(updated);
  });

  return (
    <div onClick={e => e.stopPropagation()}>
      {isBig ? (
        <Button
          onClick={showModal}
          className={clsx(cls.btn, cls.bigBtn, btnClass)}
          type="primary"
        >
          <svg xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none">
            <rect width="28" height="28" rx="5" fill="none" />
            <path fill="#042E6B"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.9925 10.0325V15.2025C14.9925 16.0025 14.6725 16.7625 14.1125 17.3225L9.7025 21.7425C9.3125 22.1325 8.6825 22.1325 8.2925 21.7425C7.9025 21.3525 7.9025 20.7225 8.2925 20.3325L12.7025 15.9225C12.8925 15.7325 12.9925 15.4825 12.9925 15.2125V10.0425H10.3625C10.1925 10.0425 10.0625 9.9125 10.0625 9.7425C10.0625 9.6625 10.0925 9.5825 10.1525 9.5225L13.6425 6.1425C13.8325 5.9525 14.1425 5.9525 14.3425 6.1425L17.8325 9.5125C17.9525 9.6325 17.9525 9.8225 17.8325 9.9325C17.7725 9.9925 17.7025 10.0225 17.6125 10.0225H14.9825L14.9925 10.0325ZM15.2825 18.7425C14.8925 18.3525 14.8925 17.7225 15.2825 17.3325C15.6725 16.9425 16.3025 16.9425 16.6925 17.3325L19.6925 20.3325C20.0825 20.7225 20.0825 21.3525 19.6925 21.7425C19.3025 22.1325 18.6725 22.1325 18.2825 21.7425L15.2825 18.7425Z" />
          </svg>
          <span className={cls.btnText}>Merge</span>
        </Button>
      ) : (
        <Button
          onClick={showModal}
          className={clsx(cls.btn, cls.smallBtn, btnClass)}
          icon={
            <svg xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none">
              <rect width="28" height="28" rx="5" fill="#002A77" />
              <path fillRule="evenodd"
                clipRule="evenodd"
                d="M14.9925 10.0325V15.2025C14.9925 16.0025 14.6725 16.7625 14.1125 17.3225L9.7025 21.7425C9.3125 22.1325 8.6825 22.1325 8.2925 21.7425C7.9025 21.3525 7.9025 20.7225 8.2925 20.3325L12.7025 15.9225C12.8925 15.7325 12.9925 15.4825 12.9925 15.2125V10.0425H10.3625C10.1925 10.0425 10.0625 9.9125 10.0625 9.7425C10.0625 9.6625 10.0925 9.5825 10.1525 9.5225L13.6425 6.1425C13.8325 5.9525 14.1425 5.9525 14.3425 6.1425L17.8325 9.5125C17.9525 9.6325 17.9525 9.8225 17.8325 9.9325C17.7725 9.9925 17.7025 10.0225 17.6125 10.0225H14.9825L14.9925 10.0325ZM15.2825 18.7425C14.8925 18.3525 14.8925 17.7225 15.2825 17.3325C15.6725 16.9425 16.3025 16.9425 16.6925 17.3325L19.6925 20.3325C20.0825 20.7225 20.0825 21.3525 19.6925 21.7425C19.3025 22.1325 18.6725 22.1325 18.2825 21.7425L15.2825 18.7425Z"
                fill="white" />
            </svg>
          }
          type="primary"
        />
      )}
      <Modal
        open={isModalVisible}
        title={`Merge buyer <${dealer.name}> into selected`}
        okText={'Merge'}
        onOk={submit}
        confirmLoading={loading}
        onCancel={hideModal}
        className={cls.modal}
      >
        <Select
          ref={selectRef}
          className={cls.select}
          showSearch
          allowClear
          placeholder="Select a buyer merge into"
          optionFilterProp="children"
          onChange={id => setMergeDealer(dealers.find(d => d.id === id))}
          filterOption={(input, option: any) => {
            return option.props?.children?.toString().toLowerCase().includes(input.toLowerCase());
          }}
        >
          {dealers.map(d => (
            <Select.Option key={d.id} value={d.id} disabled={d.id === dealer.id}>
              {d.name}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};
