import { Col, Row } from 'antd';
import { useWatch } from 'react-hook-form';
import { Alert } from 'antd';

import {
  Container, IGrid,
  InitialCard,
  InitialRow,
  ManyCheckboxInputStyled,
  SwitchInput,
  TextInput,
} from '@components';
import { inputParser } from '@utils';
import cls from './RoleForm.module.css';

export const validate = (values: any) => {
  const errors: any = {};
  ['name'].forEach((key: string) => {
    if (!values[key]) {
      errors[key] = 'This field is required';
    }
  });
  if (!values['permissionIds']?.length) {
    errors['permissionIds'] = 'This field is required';
  }

  return errors;
};

export const RoleForm = () => {
  const id = useWatch({ name: 'id' });
  const isFixed: boolean | null = useWatch({ name: 'isFixed' });
  const labelGrid: IGrid = { xs: 8, sm: 6 };
  const valueGrid: IGrid = { xs: 16, sm: 18 };

  return (
    <Container max className={cls.formContainer}>
      <InitialCard extraPadding>
        <Row gutter={[24, 24]}>
          {id ? (
            <Col xs={24} sm={24} lg={12} xl={6}>
              <InitialRow title="Id" fontLg labelGrid={labelGrid} valueGrid={valueGrid}>
                <TextInput source="id" readOnly />
              </InitialRow>
            </Col>
          ) : null}
          <Col xs={24} sm={24} lg={12} xl={12}>
            <InitialRow title="Name*" fontLg labelGrid={labelGrid} valueGrid={valueGrid}>
              <TextInput
                source="name"
                placeholder="Name"
                autoFocus
                inputProps={{ maxLength: 50 }}
                parse={inputParser.lettersAndSpace}
                onBeforeInputCapture={isFixed ? e => e.preventDefault() : undefined}
              />
            </InitialRow>
          </Col>
          <Col xs={24} xl={6}>
            <SwitchInput name="isEnabled"
              source="isEnabled"
              label="Is Enabled"
              className={cls.switcher}
              defaultValue={true}
              isFixed={isFixed}
            />
          </Col>
        </Row>
        {isFixed && (
          <Alert
            style={{ marginTop: 15 }}
            message="Role is fixed: no update or removal are allowed"
          />
        )}
        <InitialRow title="Permissions" fontLg fullWidth>
          <ManyCheckboxInputStyled
            source="permissionIds"
            reference="permission?method=ALL"
            label=""
            isFixed={isFixed}
          />
        </InitialRow>
      </InitialCard>
    </Container>
  );
};
