import { clsx } from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';

import { http } from '@network';
import cls from './pdf-icon.module.css';

type Props = {
  url: string;
  downloadFilename?: string
  className?: any;
  downloadIconClassName?: any;
  downloadCenter?: boolean;
  downloadIcon?: JSX.Element;
}

export const PdfIcon: FC<Props> = (
  { url,
    className,
    downloadFilename,
    downloadIconClassName,
    downloadIcon,
  }) => {
  const [blob, setBlob] = useState<any>();

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const res = await http.get(url, { responseType: 'blob' });
      const blobResponse = new Blob([res.data as any], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blobResponse);
      isMounted && setBlob(blobUrl);
    })();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={clsx(cls.box, 'pi-box')}>
      <FilePdfOutlined
        className={clsx(className, 'pi-icon')}
        onClick={() => blob && window.open(blob + '#toolbar=0', '_blank', 'popup')}
      />

      {downloadFilename && blob && (
        <a
          download={downloadFilename}
          href={blob}
          className={clsx(cls.download, 'pi-link')}
        >
          {downloadIcon ? downloadIcon : (
            <DownloadOutlined
              className={clsx(
                downloadIconClassName ? downloadIconClassName : cls.downloadIcon,
                'pi-download',
              )}
            />
          )}
        </a>
      )}
    </div>
  );
};
