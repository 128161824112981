import React from 'react';
import { FunctionFieldProps, FunctionField } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

import { resolvePath, FilePreview } from '@components';
import { File } from '@types';

export const FileField = (props: Partial<FunctionFieldProps> & {forList?: boolean}) => {
  const { prefix, forList, ...restProps } = props;

  return (
    <FunctionField
      {...restProps}
      render={(record: any) => (
        <FileWidget
          record={record}
          prefix={props?.prefix}
          file={resolvePath(record, props.source || '')}
          forList={forList}
        />
      )}
    />
  );
};

interface FileWidgetProps {
  file: File | null;
  record?: any;
  prefix?: string;
  forList?: boolean;
}

// PHONE
export const FileWidget = ({ file, record, prefix, forList }: FileWidgetProps) => {
  const cls = useStyles();
  if (!file) {
    return null;
  }

  let downloadFilename = file.url.split('/').pop() as string;
  const ext = downloadFilename.split('.').pop() as string;

  if (record?.fullName) {
    downloadFilename = `${record?.fullName}.${ext}`;
  }
  if (prefix) {
    downloadFilename = `${prefix}${downloadFilename}`;
  }

  return (
    <div className={cls.box} onClick={e => e.stopPropagation()}>
      <FilePreview
        file={file}
        wrapClass={clsx(cls.preview, forList && cls.previewList)}
        downloadFilename={downloadFilename}
      />
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -40,
  },
  preview: {
    width: 128,
    height: 164,
    border: '2px solid #042E6B',
    borderRadius: 10,
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  previewList: {
    width: 32,
    height: 32,
    borderWidth: 1,
    borderRadius: 4,
    '& img': {
      width: '26px !important',
    },
    '& .ant-skeleton-image': {
      width: '25px !important',
    },
    '& [data-x="download"]': {
      marginLeft: 25,
      marginTop: -16,
      '& button': {
        padding: 0,
      },
    },
  },
});
