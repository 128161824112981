import { FileImage, FileMp4, FilePdf } from '@components';

interface Props {
  extension: 'pdf' | 'mp4' | 'png' | 'jpeg' | 'jpg' | 'gif' | string;
  url: string;
  previewUrl?: string;
  downloadFilename?: string;
  className?: string;
}

export const FileExtensionPreview = (
  { extension, url, previewUrl, downloadFilename, className }: Props,
) => {
  if (extension === 'pdf') {
    return (<FilePdf url={url} downloadFilename={downloadFilename} className={className} />);
  }
  if (extension === 'mp4') {
    return (
      <FileMp4
        url={url}
        previewUrl={previewUrl}
        downloadFilename={downloadFilename}
        className={className} />
    );
  }
  if (['png', 'jpeg', 'jpg', 'gif'].includes(extension)) {
    return (<FileImage url={url} downloadFilename={downloadFilename} className={className} />);
  }

  return null;
};
