import {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { io, Socket } from 'socket.io-client';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from 'moment-timezone';

import { Admin, ChatMessage } from '@types';
import { adminHttp, chatHttp, UnreadedResonse, UpdateAdminParams } from '@network';
import { isProdServer } from '@utils';
import { NotificationType } from '@components';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(isProdServer() ? 'America/Mexico_City' : 'Etc/UTC');
moment.tz.setDefault('Etc/UTC');

interface SettingsState {
  settingsOpened: boolean;
  setSettingsOpened: (isOpened: boolean) => void;
  reportsOpened: boolean;
  setReportsOpened: (isOpened: boolean) => void;
  ticketOpened: boolean;
  setTicketOpened: (isOpened: boolean) => void;
  admin: Admin | null,
  setAdmin: (nextAdmin: Admin | null) => void;
  refreshAdmin: () => Promise<void>;
  updateAdmin: (params: UpdateAdminParams) => Promise<void>;
  isToolbarShown: boolean;
  setIsToolbarShown: (isShown: boolean) => void;
  isCropCreatorActive: boolean,
  setIsCropCreatorActive: (isOpened: boolean) => void;
  isBuyersCreatorActive: boolean;
  setIsBuyersCreatorActive: (isOpened: boolean) => void;
  unreaded: UnreadedResonse;
  mentioned: UnreadedResonse;
  refreshChats: () => Promise<void>;
  lastMessage: ChatMessage | undefined;
  panelName: string | null;
  setPanelName: (panelName: string | null) => void;
  websocketConnect: () => void;
  notificationsMap: NotificationType | null;
  refreshNotifications: () => Promise<void>;
}

const defaultState: SettingsState = {
  settingsOpened: false,
  setSettingsOpened: () => {},
  reportsOpened: false,
  setReportsOpened: () => {},
  ticketOpened: false,
  setTicketOpened: () => {},
  admin: null,
  setAdmin: () => {},
  refreshAdmin: async () => {},
  updateAdmin: async () => {},
  isToolbarShown: true,
  setIsToolbarShown: () => {},
  isCropCreatorActive: false,
  setIsCropCreatorActive: () => {},
  isBuyersCreatorActive: false,
  setIsBuyersCreatorActive: () => {},
  lastMessage: undefined,
  unreaded: null,
  mentioned: null,
  refreshChats: async () => {},
  panelName: null,
  setPanelName: () => {},
  websocketConnect: () => {},
  notificationsMap: null,
  refreshNotifications: async () => {},
};

export const SettingsContext = createContext<SettingsState>(defaultState);
const socketUrl = process.env.REACT_APP_SOCKET || 'localhost:5775';

export const SettingsProvider = ({ children }: PropsWithChildren<any>) => {
  const socket = useRef<Socket>();
  const [admin, setAdmin] = useState<Admin | null>(defaultState.admin);
  const [settingsOpened, setSettingsOpened] = useState<boolean>(false);
  const [reportsOpened, setReportsOpened] = useState<boolean>(false);
  const [ticketOpened, setTicketOpened] = useState<boolean>(false);
  const [isToolbarShown, setIsToolbarShown] = useState<boolean>(defaultState.isToolbarShown);
  const [
    isCropCreatorActive, setIsCropCreatorActive,
  ] = useState<boolean>(defaultState.isCropCreatorActive);
  const [
    isBuyersCreatorActive, setIsBuyersCreatorActive,
  ] = useState<boolean>(defaultState.isBuyersCreatorActive);
  const [unreaded, setUnreaded] = useState<UnreadedResonse>(null);
  const [mentioned, setMentioned] = useState<UnreadedResonse>(null);
  const [lastMessage, setLastMessage] = useState<ChatMessage>();
  const [panelName, setPanelName] = useState<string | null>(null);
  const [notificationsMap, setNotificationsMap] = useState<NotificationType | null>(null);

  const refreshNotifications = async () => {
    const loadedNotifications = await adminHttp.getAllNotifications();
    setNotificationsMap(loadedNotifications);
  };

  const refreshChats = useCallback(async () => {
    const [nextUnreaded, nextMentioned] = await Promise.all([
      chatHttp.getUnreaded(), chatHttp.getMentioned(),
    ]);
    setUnreaded(nextUnreaded);
    setMentioned(nextMentioned);
  }, []);

  function websocketConnect() {
    if (socket.current && socket.current.connected) {
      return;
    }
    socket.current = io(socketUrl, { autoConnect: true, transports: ['websocket'] });
    console.log(`[WEBSOCKET] attempts to connect @ ${socketUrl}`);
    socket.current.on('connect', () => {
      console.log(`[WEBSOCKET] is connected @ ${socketUrl}`);
      socket.current && socket.current.on('chatMessage', (message: ChatMessage) => {
        console.log('[WEBSOCKET] on chatMessage', message);
        refreshChats();
        setLastMessage(message);
      });
      socket.current && socket.current.on('TICKET_CREATED', () => {
        console.log('[WEBSOCKET] on TICKET_CREATED');
        refreshNotifications();
      });
    });
  }

  useEffect(() => {
    if (!admin) {
      refreshAdmin();
      return;
    }

    refreshChats();
    websocketConnect();

    return () => {
      socket.current?.disconnect();
    };
  }, [admin]);

  const refreshAdmin = async () => {
    try {
      const nextAdmin = await adminHttp.me();
      setAdmin(nextAdmin);
    } catch (e) {}
  };
  const updateAdmin = async (params: UpdateAdminParams) => {
    try {
      const nextAdmin = await adminHttp.updateMe(params);
      setAdmin(nextAdmin);
    } catch (e) {}
  };

  return (
    <SettingsContext.Provider value={{
      settingsOpened,
      setSettingsOpened,
      reportsOpened,
      setReportsOpened,
      ticketOpened,
      setTicketOpened,
      admin,
      setAdmin,
      refreshAdmin,
      updateAdmin,
      isToolbarShown,
      setIsToolbarShown,
      isCropCreatorActive,
      setIsCropCreatorActive,
      isBuyersCreatorActive,
      setIsBuyersCreatorActive,
      lastMessage,
      unreaded,
      mentioned,
      refreshChats,
      panelName,
      setPanelName,
      websocketConnect,
      notificationsMap,
      refreshNotifications,
    }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () => useContext(SettingsContext);
