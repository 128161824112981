import { Link } from 'react-admin';
import { Typography } from '@mui/material';
import { clsx } from 'clsx';

import cls from './PageHeader.module.css';

export interface PageHeaderProps {
  title: string;
  linkText?: string;
  linkUrl: string;
  backIcon?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
  className?: string;
  titleClassName?: string;
  linkClassName?: string;
}

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
    <path d="M0.000493667 4.01724C0.00412218 4.15392 0.0649694 4.28444 0.171313 4.38369L3.92263 7.8315C4.0781 7.97311 4.30725 8.03123 4.52412 7.9837C4.741 7.93617 4.91293 7.79013 4.97517 7.60075C5.03742 7.41136 4.98076 7.20696 4.82613 7.06437L1.49013 4L4.82613 0.935633C4.98076 0.793041 5.03742 0.588636 4.97517 0.399252C4.91293 0.209623 4.741 0.0638297 4.52412 0.016299C4.30725 -0.0312311 4.0781 0.0268887 3.92263 0.168495L0.171312 3.61631C0.0554795 3.72417 -0.00620512 3.86874 0.000493667 4.01699L0.000493667 4.01724Z"
      fill="#002A77" />
  </svg>
);

export const PageHeader = ({
  title,
  linkText = 'Back',
  linkUrl,
  backIcon = <Icon />,
  center,
  right,
  className,
  titleClassName,
  linkClassName,
}: PageHeaderProps) => {
  return (
    <div className={clsx(cls.header, className)}>
      <Typography variant="h5" component="h2" className={clsx(cls.title, titleClassName)}>
        {title}
      </Typography>
      {center || right ? (
        <div className={cls.center}>
          <Link to={linkUrl} className={clsx(cls.linkBack, linkClassName)}>
            {backIcon} <span className={cls.linkBackText}>{linkText}</span>
          </Link>
          <div>{center}</div>
          <div>{right}</div>
        </div>
      ) : (
        <Link to={linkUrl} className={clsx(cls.linkBack, linkClassName)}>
          {backIcon} <span className={cls.linkBackText}>{linkText}</span>
        </Link>
      )}
    </div>
  );
};
