import { RcFile } from 'antd/lib/upload';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import { clsx } from 'clsx';

import { Credit, CreditCrop, DocumentFile } from '@types';
import { useUpdated } from '@hooks';
import { s3http } from '@network';
import { showAxiosError } from '@utils';
import { ConfirmDelete, FileExtensionPreview, FileUpload } from '@components';
import { B2BFormProps } from './CreditB2B';

interface Props {
  credit: Credit;
  form: CreditCrop | B2BFormProps;
  setForm: (val: CreditCrop | B2BFormProps) => void;
}

export const CreditInsurances = ({ form, setForm, credit }: Props) => {
  const updated = useUpdated();
  const cls = useStyles();
  const editable = true;

  const uploadAction = async (rcFile: RcFile) => {
    try {
      const file: DocumentFile = await s3http.uploadFile(rcFile as File);
      setForm({
        ...form, insurances: [...form.insurances, {
          id: null,
          fileId: file.id,
          creditId: credit.id,
          adminUrl: file.adminUrl,
          isPdf: file.isPdf,
          url: file.url,
          extension: file.extension,
          createdAt: file.createdAt,
        }],
      });
      setImmediate(updated);

      return file.adminUrl;
    } catch (err: any) {
      showAxiosError(err);

      return '';
    }
  };

  const deleteS3File = async (s3FileId: number) => {
    const files = form.insurances.filter((f) => f.fileId !== s3FileId);
    try {
      setForm({ ...form, insurances: [...files] });
      setImmediate(updated);
    } catch (err: any) {
      showAxiosError(err);
    }
  };

  return (
    <div className={cls.files}>
      {form.insurances.map((file, i: number) => {
        const ext = file.extension;
        let downloadFilename = 'Contract';
        if (form.insurances.length > 1) {
          downloadFilename = `${downloadFilename} #${i + 1}`;
        }
        downloadFilename = `${downloadFilename}.${ext}`;
        const removeFile = () => void deleteS3File(file.fileId);

        return (
          <div className={cls.file} key={i}>
            <div className={cls.preview} title="preview">
              <FileExtensionPreview
                extension={file.extension}
                url={file.adminUrl}
                previewUrl={file.url}
                downloadFilename={downloadFilename}
              />
            </div>
            {editable ? (
              <IconButton className={cls.removeIcon}>
                <ConfirmDelete title="Are you sure?" cb={removeFile}>
                  <svg style={{ display: 'block' }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none">
                    <path d="M7 0C3.13461 0 0 3.13461 0 7C0 10.8654 3.13461 14 7 14C10.8654 14 14 10.8654 14 7C13.9938 3.13461 10.8623 0.00310973 7 0Z"
                      fill="#002A77" />
                    <path d="M9.63374 4.36293C9.51246 4.24165 9.31655 4.24165 9.19527 4.36293L6.9998 6.5584L4.80433 4.36293C4.68305 4.24165 4.48714 4.24165 4.36586 4.36293C4.24458 4.48421 4.24458 4.68012 4.36586 4.8014L6.56133 6.99687L4.36586 9.19234C4.24458 9.31362 4.24458 9.50953 4.36586 9.63081C4.48714 9.75209 4.68305 9.75209 4.80433 9.63081L6.9998 7.43534L9.19527 9.63081C9.31655 9.75209 9.51246 9.75209 9.63374 9.63081C9.75502 9.50953 9.75502 9.31362 9.63374 9.19234L7.43827 6.99687L9.63374 4.8014C9.75502 4.68012 9.75502 4.48421 9.63374 4.36293Z"
                      fill="white" />
                  </svg>
                </ConfirmDelete>
              </IconButton>
            ) : null}
          </div>
        );
      })}

      {form.insurances.length < 5 ? (
        <div className={clsx(cls.file, cls.fileUpload)}>
          <FileUpload uploadAction={uploadAction} className={cls.upload} />
        </div>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles({
  files: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  file: {
    marginRight: 14,
    marginBottom: 14,
    position: 'relative',
    width: 86,
    height: 105,
    borderRadius: 10,
    border: '2px solid #042E6B',
  },
  fileUpload: {
    border: 'none',
  },
  preview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 86,
    height: 105,
    borderRadius: 8,
    border: '1px solid transparent',
  },
  uploadBox: {
    marginTop: 15,
    backgroundColor: 'white',
  },
  uploadText: {
    marginTop: 8,
  },
  upload: {
    width: 82,
  },
  remove: {
    '& button': {
      color: 'white!important',
    },
  },
  removeIcon: {
    display: 'inline-block',
    position: 'absolute',
    top: -2,
    right: -2,
    width: 30,
    height: 30,
    '&:hover': {
      color: 'red',
    },
  },
});
