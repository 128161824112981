import { clsx } from 'clsx';
import React, { useRef } from 'react';
import IconButton from '@mui/material/IconButton';

import { http } from '@network';
import { DownloadIcon } from '@components/FileImage';
import cls from './file-xml.module.css';

type Props = {
  url: string;
  downloadFilename?: string;
  className?: string;
}

const downloadXml = async (url: string) => {
  const res = await http.get(url, { responseType: 'blob' });
  const blobResponse = new Blob([res.data as any], { type: 'text/xml' });
  const blobUrl = URL.createObjectURL(blobResponse);

  return blobUrl;
};

export const FileXml = ({ url, downloadFilename, className }: Props) => {
  const blob = useRef<string>();

  const onPreview = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!blob.current) {
      blob.current = await downloadXml(url);
    }
    setTimeout(() => {
      const win = window.open(blob.current, '_blank', 'popup') as any;
      win.document.title = downloadFilename || 'Document preview';
    }, 0);
  };

  const onDownload = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!blob.current) {
      blob.current = await downloadXml(url);
    }

    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = blob.current;
      link.download = downloadFilename || 'Document.xml';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(blob.current, '_blank', 'popup');
    }
  };

  return (
    <div className={clsx(cls.box, className)} onClick={onPreview} title="preview">
      <div className={cls.icon}>
        {XmlIcon}
      </div>

      {downloadFilename && (
        <div className={clsx(cls.download, 'file-pdf-download')} title="download"
          data-x="download">
          <IconButton onClick={onDownload}>
            <DownloadIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

const XmlIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="xml-file">
    <path fill="none" d="M0 0h64v64H0z"></path>
    <path fill="#ba6d20" d="m13 56-5.01-5H13v5zM51 56l5.01-5H51v5z"></path>
    <path fill="#b2b2b2" d="M51 13h-7.99V5.01L51 13z"></path>
    <path fill="#f1f1f1"
      d="M43 5H14.59A1.59 1.59 0 0 0 13 6.59v51a1.6 1.6 0 0 0 1.59 1.6h34.82A1.6 1.6 0 0 0 51 57.54V13h-8Z"></path>
    <path fill="none"
      stroke="#231f20"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M43 5H14.59A1.59 1.59 0 0 0 13 6.59v51a1.6 1.6 0 0 0 1.59 1.6h34.82A1.6 1.6 0 0 0 51 57.54V13h-8Z"></path>
    <path fill="none"
      stroke="#231f20"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M51 13h-7.99V5.01L51 13zM13 56l-5.01-5H13v5zM51 56l5.01-5H51v5z"></path>
    <path fill="#ee9b40" d="M9 33h46a1 1 0 0 1 1 1v17H8V34a1 1 0 0 1 1-1Z"></path>
    <path fill="none"
      stroke="#231f20"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 33h46a1 1 0 0 1 1 1v17h0H8h0V34a1 1 0 0 1 1-1Z"></path>
    <path fill="#fff"
      d="m25.57 46-1.73-2.54L22.36 46H20.1l2.61-4.21-2.7-4h2.35l1.64 2.5 1.44-2.47h2.26l-2.58 4.12L27.91 46zm12.3-8.12V46h-2v-5.08L34.13 46h-1.71l-1.77-5.09V46h-2v-8.12h2.41l2.21 5.66 2.16-5.66zm3.34 6.61h2.64V46h-4.62v-8.18h2z"></path>
    <path fill="#ee9b40"
      d="M25.67 24.19c.07.13.13.24.18.35a1 1 0 0 1 .07.33.74.74 0 0 1-.27.59 1 1 0 0 1-.7.24 1.5 1.5 0 0 1-.56-.1c-.32-.22-.66-.47-1-.76L22.24 24l-1.47-1.1-1.34-1a.87.87 0 0 1 .13-1.47l.44-.33.87-.68 1.06-.83 1.07-.84.85-.67a4.69 4.69 0 0 1 .47-.34 1.49 1.49 0 0 1 .56-.13 1 1 0 0 1 .7.25.8.8 0 0 1 .27.6 1 1 0 0 1-.25.68c-.24.19-.54.44-.91.72l-1.14.87-1.05.82-.85.67 4 3zm12.7-.02 4-3-.85-.67-1-.82-1.14-.87c-.37-.28-.67-.53-.91-.72a1 1 0 0 1-.25-.68.8.8 0 0 1 .27-.6 1 1 0 0 1 .7-.25 1.49 1.49 0 0 1 .56.13 4.69 4.69 0 0 1 .47.34l.85.67 1.07.84 1.06.83.87.68.45.35a.87.87 0 0 1 .13 1.47l-1.34 1L41.76 24l-1.13.85c-.36.29-.7.54-1 .76a1.5 1.5 0 0 1-.56.1 1 1 0 0 1-.7-.24.74.74 0 0 1-.27-.59 1 1 0 0 1 .07-.33c.05-.11.11-.22.18-.35zm-4.24-10.36a1.13 1.13 0 0 1 .95-.58 1 1 0 0 1 .68.24.86.86 0 0 1 .29.67.88.88 0 0 1-.06.33l-4 9.17c-.36.82-.72 1.66-1.09 2.52s-.74 1.69-1.1 2.52a1.22 1.22 0 0 1-.46.4 1.37 1.37 0 0 1-.49.11 1 1 0 0 1-.67-.23.86.86 0 0 1-.28-.7A1.87 1.87 0 0 1 28 28l3.93-9.06c.35-.84.73-1.7 1.11-2.57z"></path>
  </svg>
);
