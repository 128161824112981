import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { Packer } from 'docx';
import { saveAs } from 'file-saver';
import { Button } from '@mui/material';
import { EyeInvisibleOutlined, EyeOutlined, SaveOutlined } from '@ant-design/icons';
import { Lock } from '@mui/icons-material';

import { dateFormatter, rbac } from '@utils';
import { InitialPage, LightTooltip } from '@components';
import { CreditSurveyAnswer } from './credit-survey-answer';
import {
  AnswerCol,
  AnswerRow,
  Credit,
  creditFixedTitle,
  SurveyQuestion,
} from '@types';
import { DocumentCreator } from '@pages/credit/CreditSurvey/document-creator';
import cls from './credit-survey-detail.module.css';
import { Tooltip } from 'antd';

interface CreditSurveyDetailProps {
  credit: Credit;
  questions: SurveyQuestion[];
  savedQuestions: SurveyQuestion[];
  setQuestions: React.Dispatch<React.SetStateAction<SurveyQuestion[]>>;
  answeredDate: Date | null;
  onUpdate: (list: SurveyQuestion[]) => Promise<void>;
  isFixedSurvey: boolean;
}

export const CreditSurveyDetail = ({
  credit, questions, answeredDate, setQuestions, savedQuestions, onUpdate, isFixedSurvey,
}: CreditSurveyDetailProps) => {
  const [changedIds, setChangedIds] = useState<number[]>([]);
  const [invalidIds, setInvalidIds] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  const visibilityText = (show = true): string => `This question is ${show ? '' : 'not '}visible for farmer`;

  const generateDocx = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const documentCreator = new DocumentCreator();
    const doc = documentCreator.createFromQeustions(questions, credit);

    Packer.toBlob(doc).then(blob => {
      saveAs(blob, `${credit.user.fullName}. Questionario of Credit ${credit.code}.docx`);
      console.log('Document created successfully');
    });
  };

  const checkQuestion = (): void => {
    if (isFixedSurvey) {
      return;
    }
    const errors = questions.filter((q: SurveyQuestion) => {
      if (q.isPlanner) {
        const [plannerCols] = q.rows || [];

        return plannerCols?.cols.filter((c: AnswerCol) => c.customAnswer?.trim() === '').length;
      }

      if (q.isOptional || !q.id) {
        return false;
      } else {
        if (q.withCustomAnswer) {
          return !q.customAnswer?.trim();
        }
        if (!q.isTable) {
          return !q.answerIds.length;
        } else {
          if (q.rows.length) {
            return q.rows.filter((r: AnswerRow) =>
              r.cols.filter((c: AnswerCol) => c.isRequired && c.customAnswer?.trim() === '').length,
            ).length;
          } else {
            return true;
          }
        }
      }
    }).map((q: SurveyQuestion) => q.id as number);
    setInvalidIds(errors);
  };

  const getChanges = () => {
    if (isFixedSurvey) {
      return;
    }
    const addId = (id: number) => {
      setChangedIds(list => {
        const ids = [...list, id];
        return [...new Set(ids)];
      });
    };

    const removeId = (id: number) => {
      setChangedIds(list => {
        return list.filter(i => i !== id);
      });
    };

    questions.forEach((q) => {
      const savedQ = savedQuestions.find(sQ => q.id === sQ.id);
      if (savedQ) {
        if (q.withCustomAnswer) {
          q.customAnswer === savedQ.customAnswer
            ? removeId(q.id) : addId(q.id);
        } else if (!q.isTable && !q.isPlanner) {
          JSON.stringify(q.answerIds) === JSON.stringify(savedQ.answerIds)
            ? removeId(q.id) : addId(q.id);
        } else if (q.isPlanner) {
          JSON.stringify(q.rows) === JSON.stringify(savedQ.rows)
            ? removeId(q.id) : addId(q.id);
        } else if (q.isTable) {
          JSON.stringify(q.rows) === JSON.stringify(savedQ.rows)
            ? removeId(q.id) : addId(q.id);
        }
      } else {
        removeId(q.id);
      }
    });
  };

  const onSubmit = async () => {
    if (isFixedSurvey) {
      return;
    }
    setLoading(true);
    const list: SurveyQuestion[] = questions
      .filter((q: SurveyQuestion) => changedIds.includes(q.id));
    await onUpdate(list)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getChanges();
    checkQuestion();
  }, [questions, savedQuestions]);

  let isFixedText = `Credit is locked for updates due to it's status (${creditFixedTitle})`;
  if (!rbac.canEditCredit()) {
    isFixedText = 'Credit is locked for updates: missing "Credits" permission';
    isFixedSurvey = true;
  }

  return (
    <InitialPage title="Survey"
      backTo={`/credit/${credit.id}`}
      backText="Back to individual credit">
      <div className={cls.head}>
        {answeredDate && (
          <div>Completed at {dateFormatter.toDateSlash(answeredDate)}</div>
        )}
        <div className={cls.headActions}>
          <Button onClick={generateDocx} color="secondary" variant="contained" className={cls.btn}>
            Export Docx
          </Button>
        </div>
      </div>

      <div className={cls.content}>
        <div className={cls.list}>
          {questions.map((question, index) => (
            <div className={cls.item} key={`${question.id}.${question.position}`}>
              <div className={cls.itemMeta}>
                <div className={clsx(
                  cls.itemNumber,
                  invalidIds.includes(question.id) ? cls.invalid : cls.valid,
                  question.isOptional && cls.optional,
                )}>{index + 1}</div>
                <Tooltip
                  title={visibilityText(question.isVisible)}
                  className={cls.docVisibility}
                  placement="topLeft"
                  overlayClassName={cls.overlay}
                >
                  {question.isVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </Tooltip>
                {changedIds.includes(question.id) && !invalidIds.includes(question.id) ? (
                  <div className={cls.itemSave} onClick={() => onUpdate([question])}>
                    <SaveOutlined className={cls.itemIcon} />
                  </div>
                ) : null}
                {isFixedSurvey && (
                  <LightTooltip title={isFixedText}>
                    <div className={cls.itemFixed}><Lock /></div>
                  </LightTooltip>
                )}
              </div>
              <div className={cls.itemContent}>
                <div className={clsx(
                  cls.itemQuestion,
                  invalidIds.includes(question.id) ? cls.invalid : cls.valid,
                )}>
                  {question.isOptional ? (
                    <span className={cls.optional}>optional</span>
                  ) : null}
                  {question.nameMx}
                  {!question.isOptional ? '*' : null}
                </div>
                <div className={cls.answer}>
                  <CreditSurveyAnswer
                    question={question}
                    setQuestions={setQuestions}
                    isValid={!invalidIds.includes(question.id)}
                    isFixedSurvey={isFixedSurvey}
                  />
                </div>
                {question.modifiedAt ? (
                  <div className={cls.author}>
                    Changed by
                    {question.modifiedByAdminName
                      ? ` [admin] ${question.modifiedByAdminName}, ` : ''}
                    {question.modifiedByManagerName
                      ? ` [manager B2B] ${question.modifiedByManagerName}, ` : ''}
                    {` ${dateFormatter.toDateTime(question.modifiedAt, true)}`}
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>

        {rbac.canEditCredit() && (
          <div className={cls.actions}>
            <Button
              className={cls.save}
              variant="contained"
              onClick={onSubmit}
              disabled={!!(!changedIds.length || invalidIds.length) || loading}
            >
              <SaveIcon /> Save
            </Button>
          </div>
        )}
      </div>
    </InitialPage>
  );
};

const SaveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <g clipPath="url(#clip0_1101_4722)">
      <path d="M14.8964 1.75077L13.2321 0.10257C13.1643 0.0389059 13.075 0 12.9786 0H2.25C2.21429 0 2.18571 0 2.15 0C2.15 0 2.14643 0 2.14286 0H0.357143C0.160714 0 0 0.159161 0 0.35369V14.6463C0 14.8408 0.160714 15 0.357143 15H14.6429C14.8393 15 15 14.8408 15 14.6463V2.00189C15 1.90639 14.9607 1.81797 14.8964 1.75077ZM7.5 11.8486C6.11786 11.8486 5 10.7416 5 9.37279C5 8.00401 6.11786 6.89696 7.5 6.89696C8.88214 6.89696 10 8.00401 10 9.37279C10 10.7416 8.88214 11.8486 7.5 11.8486ZM11.4286 3.90474C11.4286 4.49187 10.95 4.96581 10.3571 4.96581H4.64286C4.05 4.96581 3.57143 4.49187 3.57143 3.90474V1.40769C3.57143 1.22377 3.72143 1.07522 3.90714 1.07522L7.91071 1.06814C8.07857 1.06814 8.21429 1.20255 8.21429 1.36878V3.47324C8.21429 3.70667 8.40357 3.89413 8.63929 3.89413H9.575C9.81071 3.89413 10 3.70667 10 3.47324V1.36171C10 1.19547 10.1357 1.06107 10.3036 1.06107H11.0929C11.2786 1.06107 11.4286 1.20962 11.4286 1.39354V3.90474Z" />
    </g>
    <defs>
      <clipPath id="clip0_1101_4722">
        <rect width="15" height="15" />
      </clipPath>
    </defs>
  </svg>
);
