import React, { useEffect, useMemo, useState } from 'react';
import { clsx } from 'clsx';
import { RcFile } from 'antd/lib/upload';
import { DatePicker } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import moment from 'moment-timezone';

import { convertFileToBase64, FileUpload, InputText } from '@components';
import { UploadIcon } from '@components/Crops';
import { showAxiosError } from '@utils';
import { Admin, Crop, dealerTiers, DealerType, State } from '@types';
import { InputSelect, InputSelectAutocomplete } from '@components/inputs/InputSelect';
import { adminHttp, cropHttp, stateHttp, UserChannel, userHttp } from '@network';
import { financeSettingsHttp } from '@network/finance-settings-http';
import cls from './buyer-form.module.css';

dayjs.extend(localeData);

const months = dayjs.months();
const MAX_IMAGE_SIZE = 5;
const MAX_VALUE = 10000;

interface CreatorFormProps {
  formValues: any;
  setFormValues: any;
  showErrors: boolean;
  dealerType: DealerType;
}

export const BuyerForm = ({
  formValues, setFormValues, showErrors, dealerType,
}: CreatorFormProps) => {
  const [crops, setCrops] = useState<Crop[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [sources, setSources] = useState<UserChannel[]>([]);
  const [activations, setActivations] = useState<any[]>([]);
  const [owners, setOwners] = useState<Admin[]>([]);
  const [phone, setPhone] = useState<string>('');
  const showNameError = (formValues.name || '').length === 0 && showErrors;
  const showPhone = dealerType === DealerType.ASSOCIATE;
  const showCrops = [DealerType.BUYER, DealerType.ASSOCIATE].includes(dealerType);
  const showTier = dealerType === DealerType.BUYER;
  const showWebsite = [DealerType.BUYER, DealerType.RETAILER].includes(dealerType);
  const showChannel =
      [DealerType.BUYER, DealerType.RETAILER, DealerType.ASSOCIATE].includes(dealerType);
  const showSize = [DealerType.BUYER].includes(dealerType);
  const showActivation = true;
  const showTierError = showTier && (formValues.tier || '').length === 0 && showErrors;

  useEffect(() => {
    (async () => {
      const allCrops: Crop[] = await cropHttp.getAll();
      setCrops(allCrops);

      const allStates: State[] = await stateHttp.getAll();
      setStates(allStates);

      const allSources: UserChannel[] = await userHttp.getSources();
      setSources(allSources);

      const allActivations = await financeSettingsHttp.getActivations();
      setActivations(allActivations);

      const admins = await adminHttp.getAll();
      setOwners(admins);
    })();
  }, []);

  useEffect(() => {
    setFormValues(prev => ({
      ...prev, phone,
    }));
  }, [phone]);

  const onChangeHandler = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const onChangePhoneHandler = (e: any) => {
    setPhone(e.target.value);
  };
  const onChangeNumberHandler = (e: any) => {
    const value = e.target.value.replace(/\D/g, '');
    setFormValues({
      ...formValues,
      [e.target.name]: +value > MAX_VALUE ? MAX_VALUE : value,
    });
  };
  const onChangeDateHandler = (date: any) => {
    setFormValues({
      ...formValues,
      closeDate: date,
    });
  };
  const onChangeActivation = (activationId: number) => {
    setFormValues({ ...formValues, activationId });
  };

  const onChangeOwner = (ownerId: number) => {
    setFormValues({ ...formValues, ownerId });
  };

  const onImageClear = () => {
    setFormValues({
      ...formValues,
      image: '',
    });
  };

  const uploadPicture = async (rcFile: RcFile): Promise<any> => {
    try {
      const base64Img = await convertFileToBase64(rcFile) as string;

      if (base64Img) {
        setFormValues({
          ...formValues, image: base64Img as string,
        });
      }
    } catch (err: any) {
      showAxiosError(err);
    }
  };
  const maxLength = 66;

  const UploadContent = () => {
    return (
      <div className={cls.uploadContent}>
        <div className={cls.uploadContentBlock}>
          <div className={cls.uploadContentTitle}>Upload Image</div>
          <div className={cls.uploadContentIcon}>
            <UploadIcon />
          </div>
        </div>
        <div className={cls.uploadContentComment}>
          *File size should not exceed {MAX_IMAGE_SIZE}MB
        </div>
      </div>
    );
  };

  // priority options
  const typeOptionsList = {
    [DealerType.BUYER]: ['AAA', 'Trader', 'Exporter', 'Broker'],
    [DealerType.RETAILER]: ['AAA', 'CyE', 'Strategic Partner', 'Trader', 'Exporter', 'Broker', 'Ag-Input supplier', 'Seed', 'Ag-Structure', 'Machinery', 'Ag-Input manufacturer'],
    [DealerType.ASSOCIATE]: ['AAA', 'CyE', 'Strategic Partner', 'Consultant', 'Broker', 'Intern', 'ITC', 'Partner\'s workforce'],
  };
  const typeOptions = typeOptionsList[dealerType];
  if (formValues.priority && !typeOptions.includes(formValues.priority)) {
    typeOptions.unshift(formValues.priority);
  }

  // status options
  const statusOptions = ['In negotiations', 'Inactive', 'Unqualified', 'Contact Pending', 'Starting Contact', 'Lost', 'Signed'];
  if (formValues.status && !statusOptions.includes(formValues.status)) {
    statusOptions.unshift(formValues.status);
  }

  const phoneValue = useMemo(() => formValues.phone
    ? formValues.phone.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4')
    : '', []);

  return (
    <div className={cls.form}>
      <div className={cls.id}>
        {formValues.id ? `ID: ${formValues.id}` : ''}
      </div>

      <div className={cls.block}>
        <div className={cls.col}>
          <div className={cls.row}>
            <InputText
              name="name"
              label="Name*"
              value={formValues.name || ''}
              onChange={onChangeHandler}
              className={clsx(showNameError && cls.error)}
              required
              maxLength={maxLength}
            />
          </div>

          {showCrops && (
            <div className={cls.row}>
              <InputSelect
                name="cropNames"
                label="Unique Crops"
                onChange={onChangeHandler}
                values={crops.map(crop => crop.nameMx)}
                selectedValue={formValues.cropNames || []}
                multiple
              />
            </div>
          )}

          {showPhone && (
            <div className={cls.row}>
              <div className={cls.phone}>
                <div className={cls.label}>Phone</div>
                <div className={cls.phoneInput}>
                  <MaskedInput
                    name="phone"
                    mask="+52 (000) 000-0000"
                    className={cls.input}
                    onChange={onChangePhoneHandler}
                    value={phoneValue}
                  />
                </div>
              </div>
            </div>
          )}

          {showTier && (
            <div className={cls.row}>
              <InputSelect
                name="tier"
                label="Buyer Tier*"
                onChange={onChangeHandler}
                values={dealerTiers}
                selectedValue={formValues.tier || ''}
                className={clsx(showTierError && cls.error)}
              />
            </div>
          )}

          {showActivation && (
            <div className={cls.row}>
              <InputSelectAutocomplete
                label="Activation"
                onChange={onChangeActivation}
                options={activations.map(activation => ({
                  value: activation.id,
                  label: activation.activation,
                }))}
                selectedValue={formValues.activationId || ''}
                displayEmpty
              />
            </div>
          )}

          <div className={cls.row}>
            <InputSelect
              selectedValue={formValues.priority || ''}
              name="priority"
              label="Type"
              onChange={onChangeHandler}
              values={typeOptions}
              displayEmpty
            />
          </div>

          <div className={cls.row}>
            <InputSelect
              selectedValue={formValues.status || ''}
              name="status"
              label="Status"
              onChange={onChangeHandler}
              values={statusOptions}
              displayEmpty
            />
          </div>

          {(dealerType === DealerType.BUYER || dealerType === DealerType.RETAILER) && (
            <div className={cls.row}>
              <InputSelectAutocomplete
                label="Owner"
                onChange={onChangeOwner}
                options={owners.map(item => ({
                  value: item.id,
                  label: item.name,
                }))}
                selectedValue={formValues.ownerId || ''}
                displayEmpty
              />
            </div>
          )}
        </div>

        <div className={cls.col}>
          {dealerType === DealerType.ASSOCIATE && (
            <div className={cls.row}>
              <InputSelectAutocomplete
                label="Owner"
                onChange={onChangeOwner}
                options={owners.map(item => ({
                  value: item.id,
                  label: item.name,
                }))}
                selectedValue={formValues.ownerId || ''}
                displayEmpty
              />
            </div>
          )}
          
          <div className={cls.row}>
            <InputSelect
              selectedValue={formValues.state || ''}
              name="state"
              label="State"
              onChange={onChangeHandler}
              values={states.map(state => state.name)}
              displayEmpty
            />
          </div>

          {showSize && (
            <div className={cls.row}>
              <InputText
                name="size"
                label="Size"
                value={formValues.size || ''}
                onChange={onChangeNumberHandler}
                postfix="Ha"
              />
            </div>
          )}

          <div className={cls.row}>
            <InputText
              name="farmerCount"
              label="Farmer Count"
              value={formValues.farmerCount || ''}
              onChange={onChangeNumberHandler}
              postfix={formValues.farmerCount > 1 ? 'farmers' : 'farmer'}
            />
          </div>

          {showWebsite && (
            <div className={cls.row}>
              <InputText
                name="website"
                label="Website"
                value={formValues.website || ''}
                onChange={onChangeHandler}
              />
            </div>
          )}

          <div className={cls.row}>
            <div className={cls.date}>
              <div className={cls.label}>Close Date</div>
              <div className={cls.dateInput}>
                <DatePicker
                  name="closeDate"
                  className={cls.input}
                  placeholder="DD / MM / YYYY"
                  value={formValues.closeDate ? moment(formValues.closeDate) : undefined}
                  onChange={onChangeDateHandler}
                />
              </div>
            </div>
          </div>

          {showChannel && (
            <div className={cls.row}>
              <InputSelect
                selectedValue={formValues.source || ''}
                name="source"
                label="Channel"
                onChange={onChangeHandler}
                values={sources.map(source => source.name)}
                displayEmpty
              />
            </div>
          )}

          <div className={cls.row}>
            <InputSelect
              selectedValue={formValues.ibm || ''}
              name="ibm"
              label="IBM"
              onChange={onChangeHandler}
              values={months}
              displayEmpty
            />
          </div>
        </div>
      </div>

      {formValues.image?.length ? (
        <div className={cls.uploadedBox} onClick={onImageClear}>
          <div className={cls.uploadedIcon}>
            <UploadIcon />
          </div>
          <img src={formValues.image} alt="" className={cls.image} />
        </div>
      ) : (
        <FileUpload
          uploadAction={uploadPicture}
          className={cls.uploadBox}
          type="image"
          content={<UploadContent />}
          size={MAX_IMAGE_SIZE}
        />
      )}
    </div>
  );
};
