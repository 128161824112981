import { Http } from './http';

class CustomerDataHttp extends Http {
  prefix = 'customer-data';

  async updateBulk(params: UpdateBulkParams): Promise<number> {
    const { data } = await this.put<number>('/update-bulk', params);

    return data;
  }
}

export const customerDataHttp = new CustomerDataHttp();

interface UpdateBulkParams {
  selectedIds: number[];
  prop: string;
  value: string | number | null;
}

