import React from 'react';
import { Identifier, ListProps, RowClickFunction, TextField, useRedirect } from 'react-admin';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
  MoneyField,
  DateField,
  SelectArrayInput,
  DateRangeInput, FileField,
} from '@components';
import { useExporter, useRbacList } from '@hooks';
import { CxInvoiceStatusField } from './CxInvoiceStatusField';

export const CxInvoiceList = (props: ListProps) => {
  const rbacList = useRbacList();
  const redirect = useRedirect();
  const exporter = useExporter({
    filename: 'Invoices',
    props: {
      'creditCode': {
        label: 'Credit',
      },
      'invoiceAccredited': {
        label: 'Accredited name',
      },
      'invoiceNumber': {
        label: 'Invoice Number',
      },
      'status': {
        prop: 'statusName',
        label: 'Invoice Status',
      },
      'format': {
        label: 'Format',
      },
      'createdAt': {
        label: 'Date of upload',
        transform: 'date',
      },
      'total': {
        label: 'Sub-total',
        transform: 'money',
      },
      'totalIva': {
        label: 'Total+IVA',
        transform: 'money',
      },
    },
  });

  const onRowClick: RowClickFunction = (id: Identifier, _: string, record: any) => {
    redirect(`/credit/${record.creditId}?invoices=${id}`);
    return false;
  };

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props} exporter={exporter} filters={[
      <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
      <SelectArrayInput
        label="Status"
        key="status"
        source="status"
        choices={[
          { id: 'pending', name: 'Pending', color: '#888' },
          { id: 'approved', name: 'Approved', color: '#36BE29' },
          { id: 'denied', name: 'Denied', color: '#DD482E' },
        ]}
        alwaysOn
        className="MS-field"
        style={{ width: 280 }}
      />,
      <DateRangeInput
        key="createdAt"
        source="createdAt"
        label="Date of upload"
        alwaysOn
        defaultValue={false}
      />,
    ]}>
      <Grid
        rowClick={onRowClick}
        defaultColumns={
          'creditCode invoiceAccredited invoiceNumber status format createdAt total totalIva'
            .split(' ')}
      >
        <TextField source="creditCode" label="Credit" />
        <TextField source="invoiceAccredited" label="Accredited name" />
        <TextField source="invoiceNumber" label="Invoice Number" />
        <CxInvoiceStatusField source="status" label="Invoice Status" />
        <TextField source="format" label="Format" />
        <FileField source="file" label="Document" forList />
        <DateField source="createdAt" label="Date of upload" />
        <MoneyField source="total" label="Sub-total" />
        <MoneyField source="totalIva" label="Total+IVA" />
      </Grid>
    </GridList>
  );
};
