import { Guarantor, DocumentFile } from '@types';

export interface Contract {
  id: number;
  creditId: number;
  guarantors: Guarantor[];
  isGuarantorsRequired: boolean;
  creditCode: string;
  accredited: string;
  userBirthday: string;
  noFillingCert: any | null;
  userFullname: string;
  rfc: string | null;
  accreditedBank: string | null;
  accountHolder: string | null;
  clabe: string | null;
  userAddress: string | null;
  userPhone: string | null;
  userEmail: string | null;
  membershipRate: number;
  disposalFee: number;
  dateAndPlace: string | null;
  description: string | null;
  interestRateCat: number;
  interestRate: number;
  moratoryRate: number;
  creditAmount: number;
  paymentAmount: number;
  paymentAmountCalc: Record<string, any>;
  creditTerms: string | null;
  signatureDate: string | null;
  contractTerms: string | null;
  paymentDate: string | null;
  date: string | null;
  useOfCredit: string | null;
  place: string | null;
  drawdowns: string | null;
  promissoryRate: string;
  editProps: any[];
  status: ContractStatus;
  createdAt: string;
  updatedAt: string;
  draftDocument: null | {
    files: DocumentFile[];
  };
  signedDocument: null | {
    files: DocumentFile[];
  };
  notarialDeed: string;
  notarialDeedText: string;
  noFillingCertText: string;
  creditAmountText: string;
  paymentAmountText: string;
  promissoryRateText: string;
}

export enum ContractStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  SIGNATURE_IN_PROCESS = 'signature_in_process',
  SIGNED = 'signed',
}

export const ContractStatusName = {
  [ContractStatus.PENDING]: 'Pending Revision',
  [ContractStatus.COMPLETED]: 'Contract Completed',
  [ContractStatus.SIGNATURE_IN_PROCESS]: 'Signature in Process',
  [ContractStatus.SIGNED]: 'Contract Signed',
};

interface Option {
  id: ContractStatus;
  name: string;
  disabled?: boolean;
}

export const contractStatusOptions: Option[] = [
  { id: ContractStatus.PENDING, name: ContractStatusName[ContractStatus.PENDING] },
  { id: ContractStatus.COMPLETED, name: ContractStatusName[ContractStatus.COMPLETED] },
  { id: ContractStatus.SIGNATURE_IN_PROCESS,
    name: ContractStatusName[ContractStatus.SIGNATURE_IN_PROCESS] },
  { id: ContractStatus.SIGNED, name: ContractStatusName[ContractStatus.SIGNED] },
];
