import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { DocumentFile } from '@types';
import { FileExtensionPreview as Preview } from '@components';
import cls from './FileSupportPreview.module.css';

pdfjs.GlobalWorkerOptions.workerSrc =
  `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface Props {
  file: DocumentFile;
  q?: string;
}

const VideoPreview = ({ file }: Props): React.ReactElement => (
  <div className={cls.videoBlock}>
    <Preview
      extension={file.extension}
      url={file.adminUrl}
      previewUrl={file.url}
      downloadFilename={file.originalName}
      className={cls.filePreview}
    />
  </div>
);

const ImagePreview = ({ file }: Props): React.ReactElement => {
  return (
    <div className={cls.imgBlock}>
      <Preview
        extension={file.extension}
        url={file.adminUrl}
        previewUrl={file.url}
        downloadFilename={file.originalName}
        className={cls.filePreview}
      />
    </div>
  );
};

const PDFPreview = ({ file, q }: Props): React.ReactElement => {
  const [numPages, setNumPages] = useState<number>();

  function onDocumentLoadSuccess({ numPages }: {numPages: number}): void {
    setNumPages(numPages);
  }

  return (
    <div className={cls.pdfBlock}>
      <Document file={file.url} onLoadSuccess={onDocumentLoadSuccess} className={cls.pdfDoc}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            className={cls.pdfPage}
            customTextRenderer={({ str }) => {
              return `<span class="pdf-text">${!q ? str : str.replace(q, `<span class="pdf-highlight">${q}</span>`)}</span>`;
            }}
          />
        ))}
      </Document>
    </div>
  );
};

export const FileSupportPreview = ({ file, q }: Props) => {
  return (
    <div className={cls.wrap}>
      {file.extension === 'pdf' ? <PDFPreview file={file} q={q} /> : null}
      {file.extension === 'mp4' ? <VideoPreview file={file} /> : null}
      {['jpeg', 'png', 'jpg', 'gif'].includes(file.extension) ? <ImagePreview file={file} /> : null}
    </div>
  );
};
