import { useController } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { InitialCard } from '@components';
import { cropHttp } from '@network/crop-http';
import { creditHttp } from '@network/credit-http';
import { CropForm } from './CropForm';
import { Credit, CreditCrop, CreditCropsInsurance, Crop } from '@types';
import cls from './CreditB2B.module.css';

export interface B2BFormProps {
  key: any;
  id: number | null;
  cropProblems: string | null;
  insuranceCompany: string | null;
  expectedWeight: number;
  deliveredWeight: number;
  financedHa: number;
  insurances: CreditCropsInsurance[];
  financedCropId: number | null;
  financedCrop?: any;
  createdAt?: any;
  creditId?: any;
  updatedAt?: any;
}

export const CreditB2B = ({ credit, editable }: {credit: Credit, editable: boolean}) => {
  const { creditCrops } = credit;
  const {
    field: { onChange: changeCrops },
  } = useController({ name: 'creditCrops' });
  const [crops, setCrops] = useState<CreditCrop[] | B2BFormProps[]>(
    creditCrops.map((c: any) => ({ ...c, key: c.id || Date.now() })),
  );
  const [options, setOptions] = useState<Crop[]>([]);
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
  const initial: B2BFormProps = {
    key: Date.now(),
    id: null,
    cropProblems: null,
    insuranceCompany: null,
    expectedWeight: 0,
    deliveredWeight: 0,
    financedHa: 0,
    insurances: [],
    financedCropId: null,
  };

  const onFormSave = (values: B2BFormProps | CreditCrop) => {
    const newCrops = crops.map(c => {
      return (c.key === values.key) ? values : c;
    });
    if (newCrops.length === 1 && !newCrops[0].financedCropId) {
      return;
    }
    changeCrops([...newCrops]);
    setCrops([...newCrops]);
  };

  const onFormAdd = () => {
    setCrops([...crops, initial]);
  };

  const onFormDelete = async (key: number): Promise<void> => {
    const candidate = crops.find(c => c.key === key);
    if (candidate && candidate.id) {
      await creditHttp.removeCreditCrop(credit.id, candidate.id);
    }
    const list = crops.filter(c => c.key !== key);
    setCrops(list);
    changeCrops(list);
  };

  useEffect(() => {
    (async () => {
      const allCrops: Crop[] = await cropHttp.getAll();
      setOptions(allCrops);
    })();
  }, []);

  useEffect(() => {
    if (!crops.length) {
      onFormAdd();
    }
    setIsAddDisabled(
      !!crops.find(c => (!c.id && !c.financedCropId)) || crops.length === 10,
    );
    setIsDeleteDisabled((crops.length === 1 && !crops[0].financedCropId));
  }, [crops]);

  return (
    <InitialCard
      left="Crop Information"
      autoHeight
      extraPadding
      wrapperClass={cls.cardWrapper}
      contentClass={cls.card}
      minHeight={623}
    >
      <div className={cls.box}>
        <Swiper
          centeredSlides={true}
          spaceBetween={0}
          slidesPerView={1}
          keyboard={{ enabled: true }}
          navigation={true}
          modules={[Keyboard, Navigation, Pagination]}
          className={cls.carousel}
          pagination={{ type: 'fraction' }}
          noSwiping
          touchStartPreventDefault={false}
          simulateTouch={false}
        >
          {crops.map((c: CreditCrop | B2BFormProps) => (
            <SwiperSlide key={c.key} virtualIndex={c.key}>
              <CropForm
                crop={c}
                crops={options}
                onFormSave={onFormSave}
                onFormDelete={onFormDelete}
                onFormAdd={onFormAdd}
                canAdd={!isAddDisabled}
                canDelete={!isDeleteDisabled}
                credit={credit}
                editable={editable}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </InitialCard>
  );
};
