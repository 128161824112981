import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Radio } from 'antd';
import { useLocation } from 'react-router-dom';
import { Close, RefreshOutlined } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { DeleteOutlined } from '@ant-design/icons';
import { makeStyles } from '@mui/styles';
import { clsx } from 'clsx';

import { ChatMessages, CreditChat, BlueOutlineButton, LightTooltip, Scroll } from '@components';
import { ChatMessage } from '@types';
import { chatHttp, UnreadedChat } from '@network';
import { useSettingsContext } from '@providers';
import { showAxiosError } from '@utils';
import { useAdminsById } from './use-admins-by-id';

interface Props {
  chats: UnreadedChat[];
  isMentions?: boolean;
}

type ChatAction = 'read' | 'archive' | 'unarchive';

export const ChatsList = ({ chats, isMentions }: Props) => {
  const location = useLocation();
  const cls = useStyles();
  const [selectedCredit, setSelectedCredit] = useState<number | null>();
  const { setPanelName, refreshChats } = useSettingsContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [group, setGroup] = useState<'all' | 'archived' | 'mentions'>('all');
  const adminsById = useAdminsById();

  useEffect(() => {
    setPanelName(null);
    setSelectedCredit(null);
  }, [location.pathname]);

  const sortedChats = useMemo(() => {
    const readedChats: UnreadedChat[] = [];
    const unreadedChats: UnreadedChat[] = [];
    for (const chat of chats) {
      if (group === 'all' && chat.archived) continue;
      if (group === 'archived' && !chat.archived) continue;
      if (group === 'mentions' && (chat.archived || !chat.mentioned)) continue;
      chat.unreaded > 0 ? unreadedChats.push(chat) : readedChats.push(chat);
    }
    const softFn = (a: UnreadedChat, b: UnreadedChat) => {
      const date1 = new Date(a.lastMessage?.createdAt || a.createdAt);
      const date2 = new Date(b.lastMessage?.createdAt || b.createdAt);
      return date2.getTime() - date1.getTime() > 0 ? 1 : -1;
    };
    return unreadedChats.sort(softFn)
      .concat(readedChats.sort(softFn));
  }, [chats, group]);

  const openChat = async (chat: UnreadedChat) => {
    setSelectedCredit(chat.creditId);
    await chatHttp.readChat(chat.chatId);
    await refreshChats();
  };
  const isChatOpened = !!selectedCredit;
  const closeChat = () => setSelectedCredit(undefined);

  const chatsAction = async (action: ChatAction, chatId?: number) => {
    if (loading) return;
    setLoading(true);
    try {
      const chatIds = chatId ? [chatId] : chats.map(chat => chat.chatId);
      if (action === 'read') await chatHttp.readChats(chatIds);
      if (action === 'archive') await chatHttp.archiveChats(chatIds);
      if (action === 'unarchive') await chatHttp.unarchiveChats(chatIds);
      await refreshChats();
    } catch (err: any) {
      showAxiosError(err);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const groupCounter = { archived: 0, mentions: 0, all: 0 };
  for (const chat of chats) {
    if (chat.archived) groupCounter.archived++;
    if (!chat.archived && chat.mentioned) groupCounter.mentions++;
    if (!chat.archived) groupCounter.all++;
  }

  const groupOptions = [
    { label: `Archived (${groupCounter.archived})`, value: 'archived' },
    { label: `Mentions (${groupCounter.mentions})`, value: 'mentions' },
    { label: `All (${groupCounter.all})`, value: 'all' },
  ];
  if (isMentions) groupOptions.pop();

  const title = isMentions ? 'Credit-Risk chat Mentions' : 'Credit-Risk chats';

  return (
    <>
      <div className={cls.title}>{title}</div>
      <div className={cls.controls}>
        <div>
          <BlueOutlineButton
            className={cls.controlsBtn}
            disabled={loading}
            onClick={() => chatsAction('read')}
            size="small"
          >
            Read all
          </BlueOutlineButton>

          <BlueOutlineButton
            className={cls.controlsBtn}
            disabled={loading}
            onClick={() => chatsAction('archive')}
            size="small"
            color="warning"
          >
            Archive all
          </BlueOutlineButton>
        </div>

        <div>
          <Radio.Group
            options={groupOptions}
            onChange={e => setGroup(e.target.value)}
            value={(isMentions && group === 'all') ? 'mentions' : group}
            optionType="button"
            buttonStyle="solid"
          />
        </div>
      </div>

      {sortedChats.length === 0 && (
        <div className={cls.noChats}>Chat not found</div>
      )}

      <div className={cls.chats}>
        <Scroll height={600}>
          {sortedChats.map(chat => (
            <div key={chat.creditId} className={cls.chat} onClick={() => openChat(chat)}>
              <div className={cls.chatHeader}>
                <div className={cls.chatTitle}>{chat.title}</div>
                <div className={cls.chatAccredited}>{chat.accreditedName}</div>
                {chat.archived ? (
                  <LightTooltip title="Unarchive">
                    <IconButton className={cls.archiveBtn} onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      chatsAction('unarchive', chat.chatId);
                    }}>
                      <RefreshOutlined className={cls.archiveIcon} />
                    </IconButton>
                  </LightTooltip>
                ) : (
                  <LightTooltip title="Archive">
                    <IconButton className={cls.archiveBtn} onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      chatsAction('archive', chat.chatId);
                    }}>
                      <DeleteOutlined className={cls.archiveIcon} />
                    </IconButton>
                  </LightTooltip>
                )}
              </div>
              <ChatMessages messages={[chat.lastMessage as ChatMessage]} adminsById={adminsById} />
              {chat.unreaded > 0 && (
                <div className={clsx(cls.counter, cls.counterUnreaded)}>
                  {chat.unreaded > 9 ? '9+' : chat.unreaded}
                </div>
              )}
            </div>
          ))}
        </Scroll>
      </div>

      <Modal
        title="Chat"
        open={isChatOpened}
        footer={false}
        onCancel={closeChat}
        closable
        className={cls.modal}
        closeIcon={<Close style={{ marginTop: 14 }} />}
      >
        {isChatOpened ? (
          <CreditChat
            creditId={selectedCredit}
            height={338}
            isPopup
            closeChat={closeChat}
          />
        ) : null}
      </Modal>
    </>
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: 24,
    color: '#000',
    fontWeight: 500,
    textAlign: 'center',
    padding: '0 0 26px',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .ant-radio-group': {
      columnGap: '0 !important',
      '& label': {
        height: 30,
      },
      '& .ant-radio-button-wrapper-checked': {
        borderColor: '#042E6B !important',
        backgroundColor: '#042E6B !important',
      },
    },
  },
  controlsBtn: {
    marginRight: 12,
    textTransform: 'none',
  },
  counterUnreaded: {
    top: '62px !important',
    right: '55px !important',
    width: 20,
    height: 20,
    lineHeight: '20px',
    fontSize: 13,
  },
  archiveBtn: {
    position: 'absolute',
    bottom: -8,
    right: 36,
  },
  archiveIcon: {
    fontSize: 20,
    color: '#ED6C02',
  },
  noChats: {
    textAlign: 'center',
    marginTop: 30,
    fontSize: 18,
    color: '#000',
  },
  chats: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  chat: {
    display: 'block',
    textAlign: 'left',
    textTransform: 'none',
    paddingLeft: 38,
    paddingRight: 10,
    maxWidth: 500,
    width: 500,
    paddingTop: 15,
    paddingBottom: 1,
    position: 'relative',
    '&:hover': {
      backgroundColor: '#EFEFEF !important',
      cursor: 'pointer',
    },
  },
  chatHeader: {
    marginBottom: -10,
    position: 'relative',
  },
  chatAccredited: {
    fontSize: 12,
    padding: '5px 0px',
    paddingRight: 70,
    lineHeight: '1.2',
    color: '#000',
    fontWeight: '300',
  },
  chatTitle: {
    fontSize: 18,
    lineHeight: '1',
    color: '#000',
  },
  icon: {
    width: '27.5px',
    height: '27.5px',
    borderRadius: '50%',
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 30,
    position: 'relative',
  },
  counter: {
    position: 'absolute',
    top: -5,
    right: -10,
    color: '#fff',
    background: 'red',
    width: 20,
    height: 20,
    lineHeight: '20px',
    textAlign: 'center',
    fontSize: 12,
    borderRadius: '50%',
  },
  empty: {
    marginTop: 40,
    textAlign: 'center',
    color: '#042E6B',
  },
  modal: {
    color: 'red',
  },
});
