import React, { useState } from 'react';
import { useRedirect, useRefresh } from 'react-admin';
import { message, Form } from 'antd';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

import { rbac, showAxiosError } from '@utils';
import { Credit } from '@types';
import { creditHttp, GuarantorParams } from '@network';
import { InitialPage, Forbidden } from '@components';
import { CreditGuarantorForm, PHONE_CODE_REG_EXP } from './credit-guarantor-form';
import cls from '@pages/credit/CreditEdit/CreditGuarantor/credit.guarantor.module.css';

const initialValues = {
  fullname: '',
  marriage: '',
  phone: '+52',
  email: '',
  rfc: '',
  isCompany: '',
  birthdate: '',
  companyShare: 0,
};

interface CreditGuarantorProps {
  credit: Credit;
}

export const CreditGuarantorCreate = ({
  credit,
}: CreditGuarantorProps) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [form] = Form.useForm<GuarantorParams>();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const backTo = `/credit/${credit.id}?initial`;

  const submit = async () => {
    try {
      await form.validateFields();
      const guarantor = await creditHttp.createGuarantor(
        credit.id, {
          ...form.getFieldsValue(),
          phone: form.getFieldValue('phone')?.replace(PHONE_CODE_REG_EXP, '').replace(' ', '') || '',
        },
      );

      if (guarantor) {
        message.success('Updated');
        refresh();
        redirect(`/credit/${credit.id}?guarantor=${guarantor.id}`);
      }

    } catch (err: any) {
      const { errorFields } = err;

      if (errorFields?.length > 0) {
        return;
      } else {
        showAxiosError(err);
      }
    }
  };

  const remove = async () => {
    setIsDialogOpen(false);
    redirect(`/credit/${credit.id}?initial`);
  };

  const canEdit = rbac.canEditCredit();
  if (!canEdit) {
    return <Forbidden />;
  }

  return (
    <InitialPage title="Guarantor" backTo={backTo} backText="Back to individual credit">
      <Form
        form={form}
        layout="horizontal"
        onFinish={submit}
        className={cls.content}
        initialValues={initialValues}
        requiredMark={false}
      >
        <CreditGuarantorForm
          form={form}
          onRemove={() => setIsDialogOpen(true)}
          editable={canEdit}
        />
      </Form>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to delete this guarantor?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>
            No
          </Button>
          <Button onClick={remove} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </InitialPage>
  );
};
