import { TextField, ListProps, FunctionField, FunctionFieldProps } from 'react-admin';
import { Button, Rating } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import IconButton from '@mui/material/IconButton';
import CheckedIcon from '@mui/icons-material/CheckCircle';
import UncheckedIcon from '@mui/icons-material/Cancel';

import { adminHttp, commentHttp } from '@network';
import { useExporter, useRbacList, useUpdated } from '@hooks';
import { Grid, GridList, Forbidden, DateField } from '@components';

export const CommentList = (props: ListProps) => {
  const rbacList = useRbacList();
  const exporter = useExporter({
    filename: 'Product Comments',
    props: {
      'rating': {
        label: 'Rating',
        prop: 'rating',
      },
      'product': {
        label: 'Product',
        prop: 'product.name',
      },
      'text': {
        label: 'Text',
        prop: 'text',
      },
      'author': {
        label: 'User',
      },
      'responsibleAdmin': {
        label: 'Responsible Manager',
      },
      'status': {
        label: 'Status',
        transform: (status) => {
          return status === true ? 'Approved' : status === false ? 'Declined' : '';
        },
      },
      'createdAt': {
        label: 'Created At',
        prop: 'createdAt',
        transform: 'date',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={[]}
      sort={{ field: 'id', order: 'DESC' }}
      exporter={exporter}
    >
      <Grid
        defaultColumns={'rating product text author responsibleAdmin status createdAt'.split(' ')}
      >
        <CommentRating source="rating" label="Rating" />
        <CommentProduct source="product" label="Product" />
        <TextField source="text" label="Text" />
        <TextField source="author" label="User" />
        <TextField source="responsibleAdmin" label="Responsible Manager" />
        <CommentModerateActions label="Status" source="status" />
        <TextField source="createdAt" label="Created At" />
      </Grid>
    </GridList>
  );
};

export const CommentRating = (props: Partial<FunctionFieldProps>) => {
  return (
    <FunctionField
      {...props}
      sortable={false}
      render={(record: any) => <Rating name="read-only" value={record.rating} readOnly />}
    />
  );
};

export const CommentModerateActions = (props: Partial<FunctionFieldProps>) => {
  const updated = useUpdated();

  const moderateStatus = async (record: any, status: boolean) => {
    await commentHttp.moderateStatus({ ...record, status })
      .then(async () => {
        const admin = await adminHttp.me();
        localStorage.setItem('admin', JSON.stringify(admin));
        updated();
      });
  };

  return (
    <FunctionField
      {...props}
      sortable={false}
      width={'100%'}
      display={'inline-flex'}
      justifyContent={'center'}
      render={(record: any) => {
        return (
          <>
            {record.status === null && (
              <div style={{ display: 'flex' }}>
                <Button size="small"
                  variant="contained"
                  startIcon={<DoneIcon />}
                  onClick={() => moderateStatus(record, true)}>
                  Approve
                </Button>

                <Button
                  size="small"
                  variant="contained"
                  startIcon={<BlockIcon />}
                  onClick={() => moderateStatus(record, false)}
                  style={{
                    background: '#d32f2f',
                    marginLeft: 16,
                  }}
                >
                  Decline
                </Button>
              </div>
            )}

            {record.status === true && (
              <IconButton
                className={'comment-check'}
                onClick={() => moderateStatus(record, false)}
              >
                <CheckedIcon style={{ color: 'green' }} fontSize="small" />
              </IconButton>
            )}

            {record.status === false && (
              <IconButton
                className={'comment-check'}
                onClick={() => moderateStatus(record, true)}
              >
                <UncheckedIcon style={{ color: '#f5222d' }} fontSize="small" />
              </IconButton>
            )}
          </>
        );
      }}
    />

  );
};
CommentModerateActions.defaultProps = { label: 'Status' };

export const CommentProduct = (props: any) => {
  return (
    <FunctionField
      {...props}
      sortable={false}
      label="Product"
      render={(record: any) => <div>{record.product?.name || ''}</div>}
    />
  );
};
