import { useRequest } from '@hooks';
import { Input, Select } from 'antd';
import { useNotify } from 'react-admin';
import { useListContext } from 'ra-core';
import React, { useEffect, useRef, useState } from 'react';

import { loanDataHttp } from '@network';
import { useModalContext } from '@providers';
import { BlueButton, BlueOutlineButton } from '@components';
import cls from './LoanData.module.css';

enum BulkProps {
  'Loan Currency', // text
  'Payment Frequency', // text
  'Credit Limit', // integer
  'Other Data', // text
}

type BulkProp = keyof typeof BulkProps;

const bulkPropsList = Object.keys(BulkProps).filter(k => isNaN(+k) || isNaN(parseFloat(k)));

export const LoanDataActions = () => {
  const notify = useNotify();
  const { hideModal } = useModalContext();
  const { selectedIds, refetch } = useListContext();
  const textRef = useRef<any>();
  const intRef = useRef<any>();

  const [prop, setProp] = useState<BulkProp>();
  const [value, setValue] = useState<string | number | null>(null);

  const isText = 'Credit Limit' !== prop;
  const isCreditLimit = 'Credit Limit' === prop;

  const resetValues = () => {
    setValue(null);
  };

  const onCancel = () => {
    setProp(undefined);
    resetValues();
    hideModal();
  };

  const { loading, submit } = useRequest(async () => {
    if (!Array.isArray(selectedIds) || selectedIds.length === 0) {
      return notify('No records are selected');
    }
    if (!prop) {
      return notify('No property is selected');
    }

    const updatedTotal = await loanDataHttp.updateBulk({ selectedIds, prop, value });

    if (selectedIds.length !== updatedTotal) {
      notify(`Updated: ${updatedTotal} of ${selectedIds.length} records. Some records can't be changed because of the contract status of related credit.`);
    } else {
      notify(`Updated records: ${updatedTotal}`);
    }

    onCancel();
    refetch();
  });

  const bulkPropsSorted = bulkPropsList
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  useEffect(() => {
    resetValues();
    prop && setTimeout(() => {
      textRef.current?.focus();
      intRef.current?.focus();
    }, 300);
  }, [prop]);

  return (
    <div className={cls.box}>
      <div className={cls.title}>Properties to update</div>
      <Select value={prop} onChange={v => setProp(v)} className={cls.select}
        placeholder="Select a property to edit">
        {bulkPropsSorted.map(propName => (
          <Select.Option key={propName} value={propName}>
            {propName}
          </Select.Option>
        ))}
      </Select>

      <div className={cls.content}>
        <div className={cls.title}>
          <div>{prop}</div>
        </div>
        {prop && isText ? <Input.TextArea
          className={cls.textarea}
          value={value || ''}
          onChange={e => setValue(e.target.value)}
          ref={textRef}
          autoFocus
        /> : null}
        {prop && isCreditLimit ? <Input
          className={cls.text}
          value={!isNaN(Number(value)) && value ? value : ''}
          onChange={e => {
            const val = e.target.value;
            /^\d*$/.test(val) && setValue(val);
          }}
          ref={textRef}
          autoFocus
        /> : null}
      </div>

      <div className={cls.controls}>
        <BlueButton className={cls.btn} onClick={submit} disabled={loading}>Update</BlueButton>
        <BlueOutlineButton className={cls.btn} onClick={onCancel}>Cancel</BlueOutlineButton>
      </div>
    </div>
  );
};
