import { clsx } from 'clsx';
import { useState } from 'react';
import { Col, Input, Row, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { SupportManual } from '@types';
import { FileSupportPreview } from '@pages/support/components';
import { Container, InitialCard, InitialRow } from '@components';
import cls from './ManualsView.module.css';

const { Paragraph } = Typography;

export const ManualsView = ({ record }: {record?: SupportManual}) => {
  const labelSize = 7;
  const [q, setQ] = useState('');

  if (!record?.id) {
    return null;
  }

  const { file } = record.documents[0];

  return (
    <Container max>
      {file.extension === 'pdf' ? <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} lg={12}>
          <Input
            placeholder="Search"
            className={cls.search}
            suffix={<SearchOutlined className={cls.iconSearch} />}
            onChange={e => setQ(e.target.value)}
            allowClear
          />
        </Col>
      </Row> : null}
      <Row gutter={[24, 24]} className={cls.row}>
        <Col xs={24} sm={24}>
          <InitialCard extraPadding wrapperClass={cls.card}>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} lg={12} xl={10}>
                <InitialRow title="Title"
                  fontLg
                  labelSize={labelSize}
                  className={clsx(cls.fieldRow, cls.txtField)}>
                  <Paragraph ellipsis className={cls.value}>{record?.name}</Paragraph>
                </InitialRow>
                <InitialRow title="Platform"
                  fontLg
                  labelSize={labelSize}
                  className={clsx(cls.fieldRow, cls.txtField)}>
                  <Paragraph ellipsis className={cls.value}>{record?.platform}</Paragraph>
                </InitialRow>
                <InitialRow title="Team"
                  fontLg
                  labelSize={labelSize}
                  className={clsx(cls.fieldRow, cls.txtField)}>
                  <Paragraph ellipsis className={cls.value}>{record?.team}</Paragraph>
                </InitialRow>
              </Col>
              <Col xs={24} sm={24} lg={12} xl={14}>
                <InitialRow title="Description"
                  fontLg
                  fullWidth
                  className={clsx(cls.fieldRow, cls.descField)}>
                  <div className={cls.value}>{record?.description}</div>
                </InitialRow>
              </Col>
            </Row>
          </InitialCard>
        </Col>
      </Row>
      <Row gutter={[24, 24]} className={cls.row}>
        <Col xs={24} sm={24}>
          <InitialCard extraPadding wrapperClass={cls.card}>
            <FileSupportPreview file={file} q={q} />
          </InitialCard>
        </Col>
      </Row>
    </Container>
  );
};
