import { Form } from 'antd';
import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { MaskedInput } from 'antd-mask-input';

import { CreditFarm } from '@types';
import { creditFarmHttp } from '@network';
import { useRequest } from '@hooks/use-request';
import cls from './credit-farms.module.css';

interface Props {
  creditId: number;
  onAddFarm: (newFarm: CreditFarm) => void;
  activeFarm?: CreditFarm;
  editable?: boolean;
  onChange?: () => Promise<void>
}

interface FormProps {
  latitude: string;
  longitude: string;
}

export const FarmAddForm = ({ creditId, onAddFarm, activeFarm, editable, onChange }: Props) => {
  const [form] = Form.useForm<FormProps>();

  useEffect(() => {
    form.setFieldValue('latitude', activeFarm?.latitude || '');
    form.setFieldValue('longitude', activeFarm?.longitude || '');
  }, [activeFarm]);

  const { submit: saveFarm, loading } = useRequest(async () => {
    let values: FormProps;
    try {
      values = await form.validateFields();
    } catch (e) {
      return;
    }

    const createdFarm = await creditFarmHttp.addFarm({
      creditId,
      latitude: values.latitude,
      longitude: values.longitude,
    });
    onAddFarm(createdFarm);
    form.resetFields();
    onChange && onChange();
  });

  const showSaveButton = editable && (!activeFarm || !activeFarm.id);

  return (
    <div className={cls.form}>
      <Form
        form={form}
        layout="inline"
        requiredMark={false}
        initialValues={{
          latitude: activeFarm?.latitude || '',
          longitude: activeFarm?.longitude || '',
        }}
      >
        <Form.Item
          label={false}
          name="latitude"
          rules={[
            {
              required: true,
              message: 'Latitude is required',
            },
            {
              validator: async (_, value) => {
                if (!value) return;
                const num = parseFloat(value);
                if (isNaN(num)) {
                  throw new Error('Latitude must be a valid number');
                }
                if (num < -90 || num > 90) {
                  throw new Error('Latitude must be between -90 and 90');
                }
              },
            },
          ]
          }
          className={cls.formItem}
        >
          <MaskedInput
            placeholder="Latitude"
            maxLength={18}
            mask={/^[\.\-0-9]+$/i}
            id="farm-latitude"
            onKeyDown={showSaveButton ? undefined : (e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </Form.Item>
        <Form.Item
          label={false}
          name="longitude"
          rules={[
            {
              required: true,
              message: 'Longitude is required',
            },
            {
              validator: async (_, value) => {
                if (!value) return;
                const num = parseFloat(value);
                if (isNaN(num)) {
                  throw new Error('Longitude must be a valid number');
                }
                if (num < -180 || num > 180) {
                  throw new Error('Longitude must be between -180 and 180');
                }
              },
            },
          ]
          }
          className={cls.formItem}
        >
          <MaskedInput
            placeholder="Longitude"
            maxLength={18}
            mask={/^[\.\-0-9]+$/i}
            onKeyDown={showSaveButton ? undefined : (e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </Form.Item>

        {showSaveButton ? (
          <Button variant="contained" color="secondary" onClick={saveFarm} disabled={loading}
            className={cls.formBtn}>
            Save coordinates
          </Button>
        ) : null}
      </Form>
    </div>
  );
};
