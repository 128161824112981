export interface Doctype {
  id: number;
  nameEn: string;
  nameMx: string;
  infoEn: string | null;
  infoMx: string | null;
  category: string | null;
  categoryName: string | null;
  teamCategory: string;
  image: string;
  position: number;
  isEnabled: boolean;
  isDefault: boolean;
  isGuarantor: boolean;
  isRfc: boolean;
  isCurp: boolean;
  isOptional: boolean;
  isSendToCredit: boolean;
  isForLoanTape: boolean;
  isVisible: boolean;
  excludedTiers: string[];
  requirements: string;
  isRetentionLetter?: boolean;
  isAmortizationTable?: boolean;
  isCreditContract?: boolean;
  isRestricted: boolean;
  isPrefilled: boolean;
}

export enum DoctypeTeamCategory {
  SALES = 'Sales',
  RISK = 'Risk',
}

export enum DoctypeRequirementsType {
  ALL = 'all',
  INDIVIDUAL = 'individual',
  COMPANY = 'company'
}
