import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { clsx } from 'clsx';

import { DownloadIcon, InfoTooltip, LightTooltip } from '@components';
import { rbac, ROLES } from '@utils';
import { creditHttp, CreditReportTypes } from '@network';
import { useRequest } from '@hooks';
import { Credit, Doctype, DoctypeTeamCategory } from '@types';
import { DoctypeDocument } from '../DoctypeDocument';
import { CreditReportFiles, CreditReportsType } from '@pages/credit/CreditReport';
import cls from './credit-widget.module.css';

interface Props {
  credit: Credit;
  setCredit: Dispatch<SetStateAction<Credit>>;
  doctypes: Doctype[];
  isSuperadmin: boolean;
  editable: boolean;
}

export const CreditWidget = ({ credit, setCredit, doctypes, isSuperadmin, editable }: Props) => {
  const [tab, setTab] = React.useState(0);
  const [salesFilled, setSalesFilled] = React.useState(0);
  const [riskFilled, setRiskFilled] = React.useState(0);

  const [reportFiles, setReportFiles] = useState<CreditReportsType>({
    [CreditReportTypes.EKATENA_REPORT]: [],
    [CreditReportTypes.BUREAU_REPORT]: [],
    [CreditReportTypes.SINECTA_REPORT]: [],
  });

  const doctypeSales: Record<number, boolean> = {};
  const doctypeRisk: Record<number, boolean> = {};

  for (const doctype of doctypes) {
    if (doctype.teamCategory === DoctypeTeamCategory.SALES) {
      doctypeSales[doctype.id] = true;
    } else if (doctype.teamCategory === DoctypeTeamCategory.RISK) {
      doctypeRisk[doctype.id] = true;
    }
  }

  const hasRestrictedAccess = rbac.hasRestrictedAccess();

  const changeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);

    credit && (async () => {
      const nextCredit = await creditHttp.getCredit(credit.id);
      setCredit(nextCredit);
    })();
  };

  const updateCounters = async () => {
    const [updatedCredit, loadedFiles] = await Promise.all([
      creditHttp.getCredit(credit.id),
      creditHttp.getReportFiles(credit.id),
    ]);

    const salesDcs = updatedCredit.documents.filter(d =>
      doctypeSales[d.doctypeId] && (d.files.length || d.number || d.isOptionalChecked),
    );
    const salesDcsUniqueIds = new Set(salesDcs.map(i => i.doctypeId));
    setSalesFilled(salesDcsUniqueIds.size);

    const riskDcs = updatedCredit.documents.filter(d =>
      doctypeRisk[d.doctypeId] && (d.files.length || d.number || d.isOptionalChecked),
    );
    const riskDcsUniqueIds = new Set(riskDcs.map(i => i.doctypeId));
    setRiskFilled(riskDcsUniqueIds.size);

    if (loadedFiles) {
      setReportFiles(loadedFiles);
    }
  };

  const filteredDoctypes = doctypes.filter(doctype => {
    if (tab === 0 && doctype.teamCategory === DoctypeTeamCategory.SALES) {
      return true;
    }
    if (tab === 1 && doctype.teamCategory === DoctypeTeamCategory.RISK) {
      return true;
    }
    return false;
  });

  const { submit: downloadDocuments, loading: downloadLoading } = useRequest(async () => {
    await creditHttp.downloadCreditDocuments(credit.id);
  });

  useEffect(() => {
    updateCounters();
  }, [credit, tab, doctypes]);

  const reportsValidationList = useMemo(() => {
    return [
      !!reportFiles.EKATENA_REPORT.length,
      !!reportFiles.BUREAU_REPORT.length,
      !!reportFiles.SINECTA_REPORT.length,
    ];
  }, [reportFiles]);

  const isSalesTab = tab === 0;
  const isRiskTab = tab === 1;
  const reportsUploadedCount = reportsValidationList.filter(Boolean).length;
  const salesCounterTotal = Object.keys(doctypeSales).length;
  const riskCounterTotal = Object.keys(doctypeRisk).length + reportsValidationList.length;

  return (
    <div className={cls.container} id="credit-docs">
      <div className={cls.header}>
        <Tabs value={tab} onChange={changeTab} className={cls.tabs}>
          <Tab label={(
            <div className={cls.tabLabel}>
              {DoctypeTeamCategory.SALES}
              <span className={cls.tabCounter}>{salesFilled}/{salesCounterTotal}</span>
            </div>
          )} {...tabProps(0, tab)} />
          <Tab label={(
            <div className={cls.tabLabel}>
              {DoctypeTeamCategory.RISK}
              <span className={cls.tabCounter}>
                {riskFilled + reportsUploadedCount}/{riskCounterTotal}
              </span>
            </div>
          )} {...tabProps(1, tab)} />
        </Tabs>

        {hasRestrictedAccess ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={downloadDocuments}
            disabled={downloadLoading}
            className={cls.downloadBtn}>
            <div className={cls.downloadBtnIcon}>
              {downloadLoading
                ? <CircularProgress color="inherit" size={20} />
                : <DownloadIcon />}
            </div>
            Download all documents
          </Button>
        ) : (
          <LightTooltip title={`Requires role: ${ROLES.RESTRICTED_ACCESS}`}>
            <div className={cls.downloadBtn}>
              <InfoTooltip lockIcon title="" />
              Download all documents
            </div>
          </LightTooltip>
        )}
      </div>

      <div className={cls.documents}>
        {filteredDoctypes.map(doctype => (
          <div className={cls.document} key={doctype.id}>
            <DoctypeDocument
              doctype={doctype}
              credit={credit}
              setCredit={setCredit}
              updateCounters={updateCounters}
              isSuperadmin={isSuperadmin}
              downloadable={isSalesTab}
              editable={isRiskTab ? hasRestrictedAccess : editable}
              isRestricted={doctype.isRestricted && !hasRestrictedAccess}
            />
          </div>
        ))}
        {isRiskTab && (
          <div className={cls.reports}>
            <CreditReportFiles
              creditId={credit.id}
              files={reportFiles}
              setReportFiles={setReportFiles}
              updateCounters={updateCounters}
              isRestricted={!hasRestrictedAccess}
              hideStatus
            />
          </div>
        )}
      </div>
    </div>
  );
};

function tabProps(index: number, selectedIndex: number) {
  return {
    id: `credit-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: clsx(cls.tab, index === selectedIndex && cls.tabSelected),
  };
}
