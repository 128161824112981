import { clsx } from 'clsx';
import { Link } from 'react-admin';
import { RightCircleFilled } from '@ant-design/icons';

import { User } from '@types';
import { InitialCard } from '@components';
import { ProfileLink } from '@pages/user/ProfileLink';
import cls from './UserSalesforce.module.css';
import { dateFormatter } from '@utils';
import { useEffect, useState } from 'react';
import { salesforceHttp, SfProps } from '@network';

export const UserSalesforce = ({ user }: { user: User }) => {
  const [data, setData] = useState<SfProps | null>();

  useEffect(() => {
    (async () => {
      const res = await salesforceHttp.getByUser(user.id);
      if (res) {
        setData(res);
      }
    })();
  }, []);

  return (
    <InitialCard
      left={
        <Link to={`/user/${user.id}?salesforce`} className={cls.cardTitle}>
          <span>Salesforce</span>
          <RightCircleFilled />
        </Link>
      }
      contentClass={clsx(cls.content)}
      wrapperClass={clsx(cls.wrap)}
      extraPadding
    >
      <div className={cls.top}>
        <div className={cls.title}>Contact</div>
        <div className={cls.link}>
          <ProfileLink
            url={user.sfContactLink}
            text={user.sfContactLink ? `Link to Contact # ${user.sfContactId}` : '–'}
            className={cls.link}
          />
        </div>
        <div className={cls.info}>
          <div className={cls.infoRow}>
            <div className={cls.infoTitle}>Account</div>
            {user.sfAccountLink ? <a
              href={user.sfAccountLink}
              target={'_blank'}
              className={cls.infoItem}
              rel="noreferrer"
            >Link to Account</a> : null}
          </div>
          <div className={cls.infoRow}>
            <div className={cls.infoTitle}>Credits</div>
            {user.credits?.length ? user.credits.map((c) => (
              <a
                href={c.sfOpportunityLink || '#'}
                className={cls.infoItem}
                key={c.id}
                target={'_blank'}
                rel="noreferrer"
              >Credit {c.code}</a>
            )) : null}
          </div>
        </div>
      </div>

      <div className={cls.footer}>
        <div>Salesforce exported at:</div>
        <div className={cls.footerDate}>
          {data?.requestAt ? dateFormatter.toDateTime(data?.requestAt, true) : '–'}
        </div>
      </div>
    </InitialCard>
  );
};
