import moment from 'moment-timezone';
import { clsx } from 'clsx';
import { EditProps, Link } from 'react-admin';

import { Forbidden, InitialPage } from '@components';
import { LoginTry } from '@types';
import { useRbacList, useRecord } from '@hooks';
import cls from './LoginTry.module.css';

export const LoginTryEdit = (props: EditProps) => {
  moment.locale('en');
  const record = useRecord<LoginTry>(props);
  const rbacList = useRbacList();

  const backTo = '/login-try';

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <InitialPage title="Historial login try" backTo={backTo} backText="Back to Login Try">
      <div className={cls.wrap}>
        <div className={clsx(cls.box, cls.user)}>
          <div className={cls.userIcon}>
            <svg xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="51"
              viewBox="0 0 45 51"
              fill="none">
              <path
                d="M22.5 0C10.0735 0 0 10.1302 0 22.626C0.000989446 34.0808 8.46273 43.5432 19.4466 45.0425L22.4951 50.1429L25.6029 45.0336C36.562 43.5124 44.999 34.063 45 22.626C44.999 10.1302 34.9265 0 22.5 0ZM22.5 5.02788C28.0231 5.02788 32.4993 9.52985 32.5003 15.0836C32.5003 20.6374 28.0231 25.1394 22.5 25.1394C16.9769 25.1394 12.5007 20.6374 12.4997 15.0836C12.4997 9.52985 16.9769 5.02788 22.5 5.02788ZM25.1606 43.829C24.939 43.8568 24.7698 44.0036 24.6817 44.1922C24.6491 44.2259 24.6125 44.2537 24.5867 44.2954L22.5049 47.7187L20.3895 44.1793C20.2906 44.0135 20.1273 43.9193 19.9522 43.8905C19.8859 43.8538 19.8127 43.826 19.7335 43.8151C14.1016 43.0788 9.16425 40.1208 5.81102 35.8481C10.4406 32.2868 16.22 30.1683 22.5 30.1673C28.78 30.1673 34.5594 32.2868 39.189 35.8471C35.815 40.1476 30.8371 43.1155 25.1606 43.828V43.829Z"
                fill="white" />
            </svg>
          </div>
          <div className={cls.userInfo}>
            <div className={cls.boxTitle}>User</div>
            <div className={cls.userProps}>
              <div className={cls.userProp}>
                <div className={cls.userPropLabel}>
                  Username
                </div>
                <div className={cls.userPropValue}>
                  {record?.userFullName || record?.login}
                </div>
              </div>
              <div className={cls.userProp}>
                <div className={cls.userPropLabel}>
                  Source
                </div>
                <div className={cls.userPropValue}>
                  {record?.partnerClass}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={clsx(cls.box, cls.try)}>
          <div className={cls.boxTitle}>Login tries</div>
          <div className={cls.boxContent}>
            <div className={cls.row}>
              <div className={cls.rowHead}>
                <div className={cls.rowHeadLabel}>IP Address</div>
                <div className={cls.rowHeadDate}>
                  {record?.createdAt && moment(record.createdAt).format('lll')}
                </div>
              </div>
              <div className={cls.rowContent}>
                {record?.ip === '::1' ? 'unknown' : record?.ip}
              </div>
            </div>
          </div>
        </div>

        {record?.partnerClass === 'Admin' && (
          <div className={clsx(cls.box, cls.content)}>
            <div className={cls.history}>
              <div className={cls.boxTitle}>Admin history</div>
              <div className={cls.boxContent}>
                {record?.history?.length === 0 && (
                  <div>No data</div>
                )}
                {record?.history?.map(item =>
                  <LoginTryHistoryRow key={item.id} record={record} item={item} />,
                )}
              </div>
            </div>

            <div className={cls.tasks}>
              <div className={cls.boxTitle}>Task</div>
              <div className={cls.boxContent}>
                {record?.tasks?.map(item =>
                  <LoginTryHistoryRow key={item.id} record={record} item={item} />,
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </InitialPage>
  );
};

const LoginTryHistoryRow = ({ record, item }: any) => {
  const difference = (item.statusBefore !== 'null' && item.statusAfter)
    ? `${item.statusBefore} → ${item.statusAfter}`
    : '';
  const [adminLink, adminLinkTitle] = item.adminLink
    ? item.adminLink.split(' ')
    : [null, null];

  return (
    <div className={cls.row}>
      <div className={cls.rowHead}>
        <div className={cls.rowHeadLabel}>by {`[${record?.login}] ${record?.admin?.name}`}</div>
        <div className={cls.rowHeadDate}>
          {record?.createdAt && moment(item.createdAt).format('lll')}
        </div>
      </div>
      <div className={cls.rowContent}>
        <div>
          {item.title}{difference ? ` : ${difference}` : ''}
        </div>
        {item.adminLink && (
          <Link className={cls.rowLink} to={adminLink}>
            {adminLinkTitle || 'Record'}
          </Link>
        )}
      </div>
    </div>
  );
};
