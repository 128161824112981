import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import jsonexport from 'jsonexport/dist';
import { clsx } from 'clsx';
import { Col, DatePicker, Input, Row, Select, Spin, Tooltip } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { DefaultOptionType } from 'antd/lib/select';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import PlusIcon from '@mui/icons-material/Add';
import moment from 'moment-timezone';

import { Credit, CreditFarm } from '@types';
import { creditFarmHttp, cropHttp, cxHttp, s3http, stateHttp } from '@network';
import { moneyFormatter, rbac } from '@utils';
import { InitialCard } from '@components/InitialCard';
import {
  ConfirmDelete,
  FileControlled,
  FileUpload,
} from '@components';
import { FieldReportMap } from '@pages/cx/Components/FieldReport/FieldReportMap';
import cls from './FieldReport.module.css';

const initialFarm = {
  id: 0,
  cropIds: [],
  cropsValues: [],
  state: '',
  variety: '',
  totalArea: '',
  siteHa: '',
  cycle: '',
  sowingDate: '',
  effectiveDate: '',
  latitude: '',
  longitude: '',
  createdByAdmin: true,
};

const cycleOptions: DefaultOptionType[] = [
  { label: 'PV', value: 'PV' },
  { label: 'OT', value: 'OT' },
];

interface FileProps {
  id: number;
  adminUrl: string;
  extension: string;
  key: string;
}

interface FCompProps {
  file: FileProps | null;
  setFile: React.Dispatch<React.SetStateAction<FileProps | null>>;
  name: string;
  type: 'pdf' | 'image';
  className?: string;
}

interface Props {
  credit: Credit;
  showMap?: boolean;
}

export const defaultMapPosition = { lat: 19.3315987, lng: -99.2069962 };
const MAX_TOTAL_AREA_VALUE = 1000000;
const MAX_SITE_HA_VALUE = 1000000;
const MAX_FARMS_COUNT = 10;

const File = ({ file, setFile, name, type, className }: FCompProps) => {
  const ext = type === 'pdf' ? 'pdf' : file?.extension;

  const uploadAction = async (rcFile: RcFile, setFile: any): Promise<string> => {
    const { id, adminUrl } = await s3http.uploadFile(rcFile as File);
    setFile({ id, adminUrl, ext });

    return adminUrl;
  };
  const disabled = !rbac.canEditCredit();

  return file ? (
    <FileControlled
      wrapClass={clsx(cls.filePreview, type === 'pdf' && cls.filePdf, className)}
      adminUrl={file.adminUrl}
      isPdf={type === 'pdf'}
      downloadFilename=""
      onDelete={() => setFile(null)}
      disabled={disabled}
      zoom
    />
  ) : (
    <FileUpload
      type={type}
      uploadAction={f => uploadAction(f, setFile)}
      className={cls.uploadBtn}
      wrapperClassName={clsx(cls.uploader, className)}
      disabled={disabled}
    />
  );
};

const Farm = ({ farm, credit, setFarms, showMap }) => {
  const [farmValues, setFarmValues] = useState(farm);
  const [stateOptions, setStateOptions] = useState<DefaultOptionType[]>([]);
  const [cropOptions, setCropOptions] = useState<DefaultOptionType[]>([]);

  const cropsMap = useRef<Record<string, number>>({});

  const updateFieldReportFile = (fieldReportFile) => {
    setFarmValues({ ...farmValues, fieldReportFile });
  };

  const updatePictureFile = (picture) => {
    setFarmValues({ ...farmValues, picture });
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFarmValues({ ...farmValues, [e.target.name]: e.target.value });
  };

  const onSiteHaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value.replace(/\D/g, ''), 10) || 0;

    setFarmValues({
      ...farmValues,
      siteHa: value < MAX_SITE_HA_VALUE ? value : MAX_SITE_HA_VALUE,
    });
  };

  const onTotalAreaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value.replace(/\D/g, ''), 10) || 0;

    setFarmValues({
      ...farmValues,
      totalArea: value < MAX_TOTAL_AREA_VALUE ? value : MAX_TOTAL_AREA_VALUE,
    });
  };

  const onStateSelect = (value: any) => {
    setFarmValues({ ...farmValues, state: value });
  };

  const onCropSelect = (value: any) => {
    setFarmValues({ ...farmValues, cropsValues: value });
  };

  const onCycleSelect = (value: any) => {
    setFarmValues({ ...farmValues, cycle: value });
  };

  const onSowingDateChange = (value) => {
    setFarmValues({ ...farmValues, sowingDate: value.toDate() });
  };

  const onEffectiveDateChange = (value) => {
    setFarmValues({ ...farmValues, effectiveDate: value.toDate() });
  };

  const onEndOfTermDateChange = (value) => {
    setFarmValues({ ...farmValues, endOfTermDate: value.toDate() });
  };

  const onMapClick = (latitude, longitude) => {
    setFarmValues(values => ({ ...values, latitude, longitude }));
  };

  useEffect(() => {
    setFarms((prevFarms: CreditFarm[]) => {
      return prevFarms.map(currentFarm => {
        if (currentFarm.id === farm.id) {
          const cropIds = (farmValues.cropsValues || []).map(title => cropsMap.current[title]);

          return {
            ...currentFarm,
            ...farmValues,
            cropIds,
          };
        }

        return currentFarm;
      });
    });
  }, [farmValues]);

  useEffect(() => {
    (async () => {
      const stateList = await stateHttp.getAll();
      const cropList = await cropHttp.getAll();

      setStateOptions((stateList || [])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(state => ({
          label: state.name,
          value: state.name,
        })));
      setCropOptions((cropList || [])
        .sort((a, b) => a.nameMx.localeCompare(b.nameMx))
        .map(crop => ({
          label: crop.nameMx,
          value: crop.nameMx,
        })));

      for (const crop of cropList) {
        cropsMap.current[crop.nameMx] = crop.id;
      }

      setFarmValues(state => ({
        ...state,
        cropsValues: (state.cropsTitle || '').split(', ').filter(Boolean),
      }));
    })();
  }, []);

  return (
    <div>
      <div key={farm.id} className={cls.farm}>
        <div className={cls.file}>
          <File
            file={farmValues.fieldReportFile}
            setFile={updateFieldReportFile}
            name={`Cx_${credit.id}_field_report`}
            type="pdf"
          />
        </div>
        <div className={cls.info}>
          <div className={cls.col}>
            <div className={cls.property}>
              <label className={cls.label}>Site ID</label>
              <div className={cls.value}>
                {farm.id ? farm.id : ''}
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>Crop</label>
              <div className={cls.value}>
                <Select
                  options={cropOptions}
                  onChange={onCropSelect}
                  mode="multiple"
                  value={farmValues.cropsValues}
                  maxTagCount={2}
                />
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>Variety</label>
              <div className={cls.value}>
                <Input name="variety"
                  value={farmValues.variety}
                  onChange={onInputChange}
                  autoComplete="off" />
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>Total Ha</label>
              <div className={cls.value}>
                <Input name="totalArea"
                  value={farmValues.totalArea}
                  onChange={onTotalAreaChange}
                  autoComplete="off" />
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>Site Ha</label>
              <div className={cls.value}>
                <Input name="siteHa"
                  value={farmValues.siteHa}
                  onChange={onSiteHaChange}
                  autoComplete="off" />
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>Cycle</label>
              <div className={cls.value}>
                <Select options={cycleOptions} onChange={onCycleSelect} value={farmValues.cycle} />
              </div>
            </div>
          </div>
          <div className={cls.col}>
            <div className={cls.property}>
              <label className={cls.label}>Date of Sowing</label>
              <div className={cls.value}>
                <DatePicker
                  placeholder="DD/MM/YYYY"
                  name="sowingDate"
                  onChange={onSowingDateChange}
                  format="DD/MM/YYYY"
                  defaultValue={farmValues.sowingDate ? moment(farmValues.sowingDate) : undefined}
                />
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>Effective Date</label>
              <div className={cls.value}>
                <DatePicker
                  placeholder="DD/MM/YYYY"
                  name="effectiveDate"
                  onChange={onEffectiveDateChange}
                  format="DD/MM/YYYY"
                  defaultValue={farmValues.effectiveDate
                    ? moment(farmValues.effectiveDate) : undefined
                  }
                />
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>End of Term</label>
              <div className={cls.value}>
                <DatePicker
                  placeholder="DD/MM/YYYY"
                  name="endOfTermDate"
                  onChange={onEndOfTermDateChange}
                  format="DD/MM/YYYY"
                  defaultValue={farmValues.endOfTermDate
                    ? moment(farmValues.endOfTermDate) : undefined
                  }
                />
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>Full Name</label>
              <div className={cls.value}>
                <Tooltip title={credit.user?.fullName || ''}>
                  <span>{credit.user?.fullName || ''}</span>
                </Tooltip>
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>Site Owner</label>
              <div className={cls.value}>
                <Input name="siteOwner"
                  value={farmValues.siteOwner}
                  onChange={onInputChange}
                  autoComplete="off" />
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>Accredited</label>
              <div className={cls.value}>
                <Tooltip title={credit.accreditedName || ''}>
                  <span>{credit.accreditedName || ''}</span>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className={cls.col}>
            <div className={cls.property}>
              <label className={cls.label}>Credit Amount</label>
              <div className={cls.value}>
                {moneyFormatter.format(credit.bankApproval || 0)}
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>Yield/Ha</label>
              <div className={cls.value}>
                <Input name="yield"
                  value={farmValues.yield}
                  onChange={onInputChange}
                  autoComplete="off" />
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>Municipality</label>
              <div className={cls.value}>
                {farm.city || ''}
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>State</label>
              <div className={cls.value}>
                <Select options={stateOptions} onChange={onStateSelect} value={farmValues.state} />
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>Coordinates</label>
              <div className={cls.coordinates}>
                <Input name="latitude"
                  value={farmValues.latitude}
                  onChange={onInputChange}
                  autoComplete="off" />
                <Input name="longitude"
                  value={farmValues.longitude}
                  onChange={onInputChange}
                  autoComplete="off" />
              </div>
            </div>
            <div className={cls.property}>
              <label className={cls.label}>Irrigation</label>
              <div className={cls.value}>
                <Input name="irrigation" value={farmValues.irrigation} onChange={onInputChange} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showMap ? (
        <Row gutter={[20, 20]} className={cls.bottom}>
          <Col xs={24} sm={24} md={12}>
            {rbac.canEditCredit() && (<>
              <div className={cls.bottomHead}>You can set the coordinates manually</div>
              <FieldReportMap farm={farm} onMapClick={onMapClick} />
            </>)}
          </Col>
          <Col xs={24} sm={24} md={12}>
            <div className={cls.bottomHead}>Satellite picture</div>
            <File
              file={farmValues.picture}
              setFile={updatePictureFile}
              name={`Cx_${credit.id}_field_picture`}
              type="image"
              className={cls.picture}
            />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export const FieldReport = ({ credit, showMap }: Props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const [activeFarmIndex, setActiveFarmIndex] = useState<number>(0);
  const [availableToRemoveIds, setAvailableToRemoveIds] = useState<number[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const currentId = useRef<number | null>(null);

  const [farms, setFarms] = useState<CreditFarm[]>([]);

  const addFarm = () => {
    setFarms([...farms, { ...initialFarm, creditId: credit.id }]);
    setActiveFarmIndex(farms.length);
  };

  const previousFarm = () => {
    if (activeFarmIndex > 0) {
      setActiveFarmIndex(current => current - 1);
    }
  };

  const nextFarm = () => {
    if (activeFarmIndex + 1 < (Object.values(farms).length || 0)) {
      setActiveFarmIndex(current => current + 1);
    }
  };

  const remove = () => {
    if (currentId.current === null) return;

    const newState = farms.filter(farm => farm.id !== currentId.current);
    setFarms(newState.length === 0 ? [initialFarm] : newState);

    if (activeFarmIndex <= farms.length - 1) {
      previousFarm();
    }
  };

  const save = async () => {
    setIsSaving(true);

    try {
      await cxHttp.updateFieldReport(credit.id, farms);

      notify('Field Report saved successfully');
      refresh();
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  const download = async () => {
    await generateCSV(farms, credit);
  };

  const removeFarm = async () => {
    if (currentId.current === null) return;

    try {
      await creditFarmHttp.removeFarm(currentId.current);
      remove();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (credit?.farms) {
      const nextFarms = credit.farms.length ? credit.farms : [initialFarm];
      setFarms(nextFarms);
      setAvailableToRemoveIds(credit.farms
        .filter((farm) => farm.createdByAdmin)
        .map(farm => farm.id),
      );
    }
  }, [credit]);

  const isRemoveAvailable =
    availableToRemoveIds.includes(currentId.current || 0)
    || currentId.current !== 0
    || farms.filter(farm => farm.id).length !== 0;
  const isSaveAvailable = JSON.stringify(farms) !== JSON.stringify([initialFarm]);
  const isPreviousAvailable = activeFarmIndex > 0;
  const isNextAvailable = activeFarmIndex + 1 < (Object.values(farms).length || 0);
  const isDownloadAvailable = farms.filter(farm => farm.id).length > 0;
  const isAddAvailable = farms.every(farm => farm.id) && farms.length < MAX_FARMS_COUNT;

  const canEdit = rbac.canEditCredit();

  return (
    <div>
      <InitialCard
        left="Field Report"
        contentClass={cls.content}
        wrapperClass={cls.cardWrapper}
      >
        <div className={cls.head}>
          <div className={cls.code}>
            <span>Verqor Code:</span>
            {credit.code}
          </div>
          <button type="button"
            onClick={download}
            className={cls.download}
            disabled={!isDownloadAvailable}>
            <svg xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="13"
              viewBox="0 0 17 13"
              fill="none">
              <path d="M7.2834 0.727662C7.2834 0.325785 7.62244 0 8.04067 0C8.45891 0 8.79795 0.325785 8.79795 0.727662V8.73195C8.79795 9.13382 8.45891 9.45961 8.04067 9.45961C7.62244 9.45961 7.2834 9.13382 7.2834 8.73195V0.727662Z"
                fill="white" />
              <path d="M4.2218 6.69966C3.92607 6.41549 3.92607 5.95476 4.2218 5.67059C4.51754 5.38642 4.99702 5.38642 5.29275 5.67059L8.50561 8.7578C8.80135 9.04197 8.80135 9.5027 8.50561 9.78687C8.20988 10.071 7.7304 10.071 7.43466 9.78687L4.2218 6.69966Z"
                fill="white" />
              <path d="M11.7782 6.69966C12.0739 6.41549 12.0739 5.95476 11.7782 5.67059C11.4825 5.38642 11.003 5.38642 10.7072 5.67059L7.49439 8.7578C7.19865 9.04197 7.19865 9.5027 7.49439 9.78687C7.79012 10.071 8.2696 10.071 8.56534 9.78687L11.7782 6.69966Z"
                fill="white" />
              <rect y="11" width="17" height="2" rx="1" fill="white" />
              <rect x="2"
                y="6"
                width="7"
                height="2"
                rx="1"
                transform="rotate(90 2 6)"
                fill="white" />
              <rect x="17"
                y="6"
                width="7"
                height="2"
                rx="1"
                transform="rotate(90 17 6)"
                fill="white" />
            </svg>
          </button>
          {canEdit && (
            <button type="button" onClick={save} className={cls.save} disabled={!isSaveAvailable}>
              {isSaving ? <Spin size="small" /> : <SaveIcon />}
            </button>
          )}
          {canEdit && (
            <ConfirmDelete cb={removeFarm} title={'Are you sure?'}>
              <button type="button" disabled={!isRemoveAvailable} className={cls.remove}>
                <svg xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="16"
                  viewBox="0 0 12 16"
                  fill="none">
                  <path d="M8.32511 2.40625C8.35111 2.51427 8.46113 2.60028 8.57314 2.60028H10.7034C11.0895 2.60028 11.4035 2.91432 11.4035 3.30037C11.4035 3.62042 11.1895 3.88845 10.8975 3.97246L10.5314 14.0258C10.5014 14.8439 9.82131 15.5 9.0012 15.5H2.40232C1.58421 15.5 0.90212 14.8419 0.872116 14.0258L0.506067 3.97246C0.214029 3.88845 0 3.61842 0 3.30037C0 2.91432 0.314042 2.60028 0.700093 2.60028H2.83038C2.94039 2.60028 3.05041 2.51227 3.07841 2.40625L3.22843 1.80617C3.41445 1.06608 4.13855 0.5 4.90065 0.5H6.50287C7.26497 0.5 7.99107 1.06608 8.17509 1.80617L8.32511 2.40625ZM5.20069 5.80071V12.6016C5.20069 12.8777 5.42472 13.1017 5.70076 13.1017C5.9768 13.1017 6.20083 12.8777 6.20083 12.6016V5.80071C6.20083 5.52467 5.9768 5.30064 5.70076 5.30064C5.42472 5.30064 5.20069 5.52467 5.20069 5.80071ZM2.90039 5.81471L3.10041 12.6156C3.10841 12.8917 3.33844 13.1097 3.61448 13.1017C3.89052 13.0937 4.10855 12.8636 4.10055 12.5876L3.90052 5.78671C3.89252 5.51067 3.66249 5.29264 3.38645 5.30064C3.11041 5.30864 2.89239 5.53867 2.90039 5.81471ZM7.501 5.7847L7.30097 12.5856C7.29297 12.8616 7.511 13.0917 7.78704 13.0997C8.06308 13.1077 8.29311 12.8897 8.30111 12.6136L8.50113 5.81271C8.50913 5.53667 8.29111 5.30664 8.01507 5.29864C7.73903 5.29064 7.509 5.50867 7.501 5.7847ZM4.57061 2.59828H6.82891C6.88292 2.59828 6.91692 2.55427 6.90492 2.50227L6.81491 2.14422C6.7849 2.0262 6.62088 1.89819 6.50087 1.89819H4.89865C4.77864 1.89819 4.61461 2.0262 4.58461 2.14422L4.4946 2.50227C4.4806 2.55627 4.5146 2.59828 4.57061 2.59828Z"
                    fill="white" />
                </svg>
              </button>
            </ConfirmDelete>
          )}
          <button type="button"
            onClick={previousFarm}
            className={cls.prev}
            disabled={!isPreviousAvailable}>
            <ChevronLeft />
          </button>
          <div>Farm #{activeFarmIndex + 1}</div>
          <button type="button" onClick={nextFarm} className={cls.next} disabled={!isNextAvailable}>
            <ChevronRight />
          </button>
          {canEdit && (
            <button type="button" onClick={addFarm} className={cls.add} disabled={!isAddAvailable}>
              <PlusIcon />
            </button>
          )}
        </div>
        <div>
          {farms.map((farm, index: number) => {
            if (index !== activeFarmIndex) return null;
            currentId.current = farm.id;

            return (
              <Farm key={`${index}-${farm.id}`}
                farm={farm}
                credit={credit}
                setFarms={setFarms}
                showMap={showMap}
              />
            );
          })}
        </div>
      </InitialCard>
    </div>
  );
};

const generateCSV = async (farms: CreditFarm[], cxCredit: Credit) => {
  const mappedFarms = farms.map((farm) => ({
    'Site ID': farm.id,
    'Credit ID': farm.creditId,
    'Crop': (farm.crops || []).map(crop => crop.nameMx).join('; '),
    'Variety': farm.variety,
    'Total Ha': farm.totalArea,
    'Site Ha': farm.siteHa,
    'Cycle': farm.cycle,
    'Date of Sowing': farm.sowingDate,
    'Effective Date': farm.effectiveDate,
    'End of Term': farm.endOfTermDate,
    'Full Name': cxCredit.user?.fullName,
    'Site Owner': farm.siteOwner,
    'Accredited': cxCredit.accreditedName,
    'Credit Amount': cxCredit.bankApproval,
    'Yield/Ha': farm.yield,
    'Municipality': farm.city,
    'State': farm.state,
    'Coordinates': `${farm.latitude}, ${farm.longitude}`,
    'Irrigation': farm.irrigation,
  }));

  try {
    const csvData = await new Promise<string>((resolve, reject) => {
      jsonexport(mappedFarms, (err, csvString) => {
        if (err) {
          return reject(err);
        }
        resolve(csvString);
      });
    });

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'farms.csv');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('CSV failed:', error);
  }
};

const SaveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none">
    <g clipPath="url(#clip0_3965_24559)">
      <path d="M14.8964 2.25077L13.2321 0.60257C13.1643 0.538906 13.075 0.5 12.9786 0.5H2.25C2.21429 0.5 2.18571 0.5 2.15 0.5C2.15 0.5 2.14643 0.5 2.14286 0.5H0.357143C0.160714 0.5 0 0.659161 0 0.85369V15.1463C0 15.3408 0.160714 15.5 0.357143 15.5H14.6429C14.8393 15.5 15 15.3408 15 15.1463V2.50189C15 2.40639 14.9607 2.31797 14.8964 2.25077ZM7.5 12.3486C6.11786 12.3486 5 11.2416 5 9.87279C5 8.50401 6.11786 7.39696 7.5 7.39696C8.88214 7.39696 10 8.50401 10 9.87279C10 11.2416 8.88214 12.3486 7.5 12.3486ZM11.4286 4.40474C11.4286 4.99187 10.95 5.46581 10.3571 5.46581H4.64286C4.05 5.46581 3.57143 4.99187 3.57143 4.40474V1.90769C3.57143 1.72377 3.72143 1.57522 3.90714 1.57522L7.91071 1.56814C8.07857 1.56814 8.21429 1.70255 8.21429 1.86878V3.97324C8.21429 4.20667 8.40357 4.39413 8.63929 4.39413H9.575C9.81071 4.39413 10 4.20667 10 3.97324V1.86171C10 1.69547 10.1357 1.56107 10.3036 1.56107H11.0929C11.2786 1.56107 11.4286 1.70962 11.4286 1.89354V4.40474Z"
        fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_3965_24559">
        <rect width="15" height="15" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
