import { InitialCard, InitialRow, TextAreaInput } from '@components';
import cls from './CropProblems.module.css';
import React from 'react';

export const CropProblems = ({ editable }: {editable: boolean}) => {
  return (
    <InitialCard
      left="Comments and Progress"
      autoHeight
      extraPadding
      wrapperClass={cls.cardWrapper}
      contentClass={cls.card}
    >
      <InitialRow title="" titleClassName={cls.title} fullWidth>
        <TextAreaInput
          source="cropProblems"
          placeholder="Comment"
          className={cls.textarea}
          maxLength={400}
          disabled={!editable}
        />
      </InitialRow>
    </InitialCard>
  );
};
