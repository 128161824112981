import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import clsx from 'clsx';

import { moneyFormatter } from '@utils';
import { FinanceActivation, financeSettingsHttp } from '@network/finance-settings-http';
import cls from './ActivationCpl.module.css';

interface ActivationCplProps {
  className?: string;
}

export const ActivationCpl = ({ className }: ActivationCplProps) => {
  const activationId = useWatch({ name: 'activationId' });

  return <ActivationIdCpl activationId={activationId} className={className} />;
};

interface ActivationIdCplProps {
  activationId?: number | null;
  className?: string;
}

export const ActivationIdCpl = ({ activationId, className }: ActivationIdCplProps) => {
  const [activation, setActivation] = useState<FinanceActivation>();

  useEffect(() => {
    if (activationId) {
      (async () => {
        const nextActivation = await financeSettingsHttp.getActivationById(activationId);
        setActivation(nextActivation);
      })();
    } else {
      setActivation(undefined);
    }
  }, [activationId]);

  return (
    <div className={clsx(cls.box, className)}>
      <div className={cls.title}>CPL</div>
      {activation ? (
        <div className={cls.value}>{moneyFormatter.format(activation.cpl)}</div>
      ) : (
        <div className={cls.value}>No activation</div>
      )}
    </div>
  );
};
