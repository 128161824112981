import { useLocation } from 'react-router-dom';
import React, { forwardRef, useEffect } from 'react';
import {
  AppBar as RaAppBar,
  TitlePortal,
  RefreshIconButton,
  UserMenu,
  useUserMenu,
  useLogout,
  useRedirect,
} from 'react-admin';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import AccountIcon from '@mui/icons-material/AccountCircle';
import { makeStyles } from '@mui/styles';

import { ChatHeader, Notifications } from '@components';
import { useSettingsContext } from '@providers/settings-provider';
import { VerqorLogo } from './VerqorLogo';
import { ReportsModal } from './ReportsModal';
import { ToolbarTicket } from '@pages/support/components';

export const AppBar = () => {
  const cls = useStyles();

  return (
    <RaAppBar color="secondary" className={cls.appbar} userMenu={<Menu />} toolbar={<Toolbar />}>
      <TitlePortal />
      <VerqorLogo />
      <ReportsModal />
    </RaAppBar>
  );
};

const Toolbar = () => {
  const cls = useStyles();
  const { setSettingsOpened, isToolbarShown, admin } = useSettingsContext();
  const location = useLocation();

  useEffect(() => void setSettingsOpened(false), [location.pathname]);

  if (!isToolbarShown) {
    return null;
  }

  return (
    <>
      {admin && <ToolbarTicket />}
      {admin && <ChatHeader />}
      {admin && <Notifications />}
      <RefreshIconButton className={cls.refreshBtn} icon={RefreshIcon} />
    </>
  );
};

const RefreshIcon = (
  <div style={{ width: 22, height: 21 }}>
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.1512 12.4995C20.0378 17.6953 15.0155 21.0296 9.94062 19.9421L9.02127 19.7451L9.42467 17.8626L10.344 18.0596C14.9215 19.0897 19.4862 15.6475 19.6576 10.5846C19.7618 7.49594 17.9706 4.59181 15.2037 3.36883C10.6736 1.35453 5.68756 4.11573 4.63308 8.81221C4.52214 9.3299 4.45 9.84606 4.44221 10.376L5.15964 9.49604C5.48629 9.09349 6.07623 9.03285 6.46911 9.35332L6.4875 9.35726C6.88554 9.69852 6.9504 10.2933 6.62173 10.7052L4.56024 13.3252C4.32417 13.6192 3.95084 13.746 3.59431 13.6597C3.44721 13.6282 3.31738 13.561 3.20279 13.4675L0.656239 11.3959C0.256181 11.064 0.193332 10.4599 0.522005 10.0479C0.850677 9.63593 1.44263 9.56588 1.84269 9.89773L2.55378 10.4833C2.61569 4.5404 7.93565 -0.137916 13.948 1.06186C16.0318 1.47886 17.9378 2.63551 19.2603 4.31687C21.1584 6.72213 21.7554 9.72476 21.144 12.4881L21.1512 12.4995Z"
        fill="#002A77" />
    </svg>
  </div>
);

const LogoutMenuItem = forwardRef((props: any, ref) => {
  const redirect = useRedirect();
  const { onClose } = useUserMenu();
  const raLogoutAction = useLogout();
  const { setAdmin } = useSettingsContext();
  const cls = useStyles();

  const logout = async () => {
    onClose();
    await raLogoutAction();
    setAdmin(null);
    redirect('/login');
    setTimeout(() => redirect('/login'), 0);
  };

  return (
    <MenuItem onClick={logout} ref={ref} {...props} className={cls.menuItem}>
      <ListItemIcon>
        <div className={cls.menuIcon}>
          <svg width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5074 0.973333V10.0067C11.5074 10.5378 11.0572 10.98 10.5115 10.98C9.96581 10.98 9.51562 10.54 9.51562 10.0067V0.973333C9.51562 0.442222 9.95444 0 10.5115 0C11.0685 0 11.5074 0.44 11.5074 0.973333Z"
              fill="#FF4D4F" />
            <path d="M20.1155 10.6029C20.1155 13.1963 19.0424 15.554 17.303 17.2518C15.5636 18.9496 13.1513 20.0007 10.5001 20.0007C5.20703 20.0007 0.884766 15.8007 0.884766 10.6029C0.884766 7.49182 2.44906 4.73183 4.82277 3.03405C5.25932 2.7096 5.88458 2.81405 6.2029 3.24071C6.53485 3.66738 6.44163 4.27849 5.99145 4.60071C4.10884 5.9496 2.87423 8.12516 2.87423 10.6029C2.87423 12.6629 3.73596 14.5163 5.11608 15.8785C6.4962 17.2274 8.40382 18.0563 10.5001 18.0563C14.7178 18.0563 18.1261 14.7252 18.1261 10.6029C18.1261 8.12738 16.9187 5.9496 15.0225 4.60071C14.5723 4.27627 14.4791 3.68071 14.7974 3.24071C15.1294 2.81405 15.7523 2.7096 16.1889 3.03405C18.5763 4.73183 20.1155 7.49405 20.1155 10.6029Z"
              fill="#FF4D4F" />
          </svg>
        </div>
      </ListItemIcon>
      <ListItemText className={cls.menuText}>Log out</ListItemText>
    </MenuItem>
  );
});

const Menu = () => (
  <UserMenu icon={<AccountIcon style={{ fontSize: 33 }} />}>
    <LogoutMenuItem />
  </UserMenu>
);

const useStyles = makeStyles({
  appbar: {
    height: 60,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.25)',
    '& .RaAppBar-menuButton': {
      width: 29,
      height: 29,
      borderRadius: '50%',
      backgroundColor: '#E1E5ED',
      marginLeft: 8,
      marginRight: 20,
      '& svg': {
        fontSize: 20,
        color: '#002A77',
      },
      '&:hover svg': {
        color: 'white',
      },
      '&:hover': {
        backgroundColor: '#F38336',
      },
      '&[aria-label="Open menu"] svg': {
        color: 'white',
      },
      '&[aria-label="Open menu"]:hover svg': {
        color: 'white',
      },
      '&[aria-label="Open menu"]': {
        backgroundColor: '#F38336',
      },
    },
  },
  refreshBtn: {
    position: 'absolute',
    right: 10,
    top: 8,
    width: 29,
    height: 29,
    backgroundColor: '#E1E5ED',
    padding: '0 7px 11px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  menuItem: {
    padding: '5px 20px',
  },
  menuIcon: {
    width: 29,
    height: 29,
    backgroundColor: '#E1E5ED',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  menuText: {
    fontSize: 15,
    fontWeight: 400,
    color: '#FF4D4F',
  },
  settingsBtn: {
    position: 'absolute',
    width: 200,
    height: 27,
    lineHeight: 27,
    zIndex: -1,
    bottom: -32,
    right: 10,
    borderRadius: '0 0 10px 10px',
    border: '1px solid #042E6B',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  settingsLabel: {
    color: '#74706B',
    fontSize: 12,
    fontWeight: 400,
  },
  settingsArrow: {
    position: 'absolute',
    right: 5,
    top: 0,
    color: '#042E6B',
    fontSize: 25,
  },
});
