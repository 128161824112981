import { clsx } from 'clsx';
import React, { useRef } from 'react';
import IconButton from '@mui/material/IconButton';

import { http } from '@network';
import { DownloadIcon } from '@components/FileImage';
import cls from './file-xlsx.module.css';

type Props = {
  url: string;
  downloadFilename?: string;
  className?: string;
}

const downloadXlsx = async (url: string) => {
  const res = await http.get(url, { responseType: 'blob' });
  const blobResponse = new Blob([res.data as any], { type: 'text/xlsx' });
  const blobUrl = URL.createObjectURL(blobResponse);

  return blobUrl;
};

export const FileXlsx = ({ url, downloadFilename, className }: Props) => {
  const blob = useRef<string>();

  const onPreview = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!blob.current) {
      blob.current = await downloadXlsx(url);
    }
    setTimeout(() => {
      const win = window.open(blob.current, '_blank', 'popup') as any;
      win.document.title = downloadFilename || 'Document preview';
    }, 0);
  };

  const onDownload = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!blob.current) {
      blob.current = await downloadXlsx(url);
    }

    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = blob.current;
      let fileName = downloadFilename || 'Document.xlsx';
      if (!fileName.includes('.xlsx')) {
        fileName = `${fileName}.xlsx`;
      }
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(blob.current, '_blank', 'popup');
    }
  };

  return (
    <div className={clsx(cls.box, className)} onClick={onPreview} title="preview">
      <img alt="Verqor logo" src="/icons/xlsx.png" className={cls.icon} />

      {downloadFilename && (
        <div className={cls.download} title="download" data-x="download">
          <IconButton onClick={onDownload}>
            <DownloadIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};
