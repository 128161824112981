import { clsx } from 'clsx';
import moment from 'moment';
import { RcFile } from 'antd/lib/upload';
import { useInput, useNotify } from 'react-admin';
import { useState, JSX, useEffect, PropsWithChildren } from 'react';
import { Checkbox, DatePicker, Modal, Tabs as TabsAntd } from 'antd';
import { CloseCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';

import { cxHttp, s3http } from '@network';
import { CxSet, DocumentFile, ShortDocFile } from '@types';
import { FileControlled, FileUpload, InitialCard, Loader } from '@components';
import cls from './Tabs.module.css';

const { confirm } = Modal;

interface Props {
  cxId: number;
  title: string;
  source: string;
  checkSource?: string;
}

interface TabProps {
  label: string | JSX.Element;
  closeIcon: JSX.Element;
  children: any;
  key: string;
}

interface TabComponentProps {
  cxId: number;
  idx: string;
  checkSource?: string;
  fullSrc: string;
  sets: CxSet[];
  setSets: (val: CxSet[]) => void;
}

interface TabHeaderProps {
  sets: CxSet[];
  idx: string;
}

const MAX_ITEMS = 12;
const MAX_FILES = 5;

const SaveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
    <g clipPath="url(#clip0_3965_24563)">
      <path d="M14.8964 2.25077L13.2321 0.60257C13.1643 0.538906 13.075 0.5 12.9786 0.5H2.25C2.21429 0.5 2.18571 0.5 2.15 0.5C2.15 0.5 2.14643 0.5 2.14286 0.5H0.357143C0.160714 0.5 0 0.659161 0 0.85369V15.1463C0 15.3408 0.160714 15.5 0.357143 15.5H14.6429C14.8393 15.5 15 15.3408 15 15.1463V2.50189C15 2.40639 14.9607 2.31797 14.8964 2.25077ZM7.5 12.3486C6.11786 12.3486 5 11.2416 5 9.87279C5 8.50401 6.11786 7.39696 7.5 7.39696C8.88214 7.39696 10 8.50401 10 9.87279C10 11.2416 8.88214 12.3486 7.5 12.3486ZM11.4286 4.40474C11.4286 4.99187 10.95 5.46581 10.3571 5.46581H4.64286C4.05 5.46581 3.57143 4.99187 3.57143 4.40474V1.90769C3.57143 1.72377 3.72143 1.57522 3.90714 1.57522L7.91071 1.56814C8.07857 1.56814 8.21429 1.70255 8.21429 1.86878V3.97324C8.21429 4.20667 8.40357 4.39413 8.63929 4.39413H9.575C9.81071 4.39413 10 4.20667 10 3.97324V1.86171C10 1.69547 10.1357 1.56107 10.3036 1.56107H11.0929C11.2786 1.56107 11.4286 1.70962 11.4286 1.89354V4.40474Z"
        fill="#E1E5ED" />
    </g>
    <defs>
      <clipPath id="clip0_3965_24563">
        <rect width="15" height="15" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

const ModalText = () => <div className={cls.modal}>
  <div className={cls.modalContent}>
    Crops in open fields can be monitored by Sat Images
  </div>
</div>;

const TabHeader = ({ children, sets, idx }: PropsWithChildren<TabHeaderProps>): JSX.Element => {
  const tab: CxSet | undefined = sets.find((s: CxSet) => s.idx === idx);
  const dateArr = tab?.setDate?.split('-') || [];
  const date = dateArr.length ? `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}` : '---';

  return (
    <div className={cls.tabHeader}>
      <div className={cls.tabHeaderTitle}>
        <div className={clsx(cls.tabHeaderCnt, tab?.files?.length && cls.tabHeaderCntActive)}>
          {tab?.files?.length || ''}
        </div>
        {children}
      </div>
      <div className={cls.tabHeaderDate}>{date}</div>
    </div>
  );
};

const Tab = (
  { cxId, idx, fullSrc, sets, setSets, checkSource = '' }: TabComponentProps,
) => {
  const { field: { value: checkbox } } = useInput({ source: checkSource });
  const [tab, setTab] = useState<CxSet | null>(sets.find((s: CxSet) => s.idx === idx) || null);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const onDel = (id: number) => {
    if (!tab) return;
    const files: (ShortDocFile | DocumentFile)[] = tab.files
      .filter((f: ShortDocFile | DocumentFile) => f.id !== id);
    const updTab = { ...tab, files };
    const newSets = sets.map((s: CxSet) => (s.idx === idx) ? updTab : s);
    setTab((prev: CxSet | null) => prev ? ({ ...prev, files }) : null);
    setSets(newSets);
  };

  const uploadAction = async (rcFile: RcFile): Promise<string> => {
    if (!tab) return '';
    setLoading(true);
    const name = rcFile.name.split('.');
    const ext = name.length ? name[name.length - 1] : 'jpeg';
    const { id, adminUrl, previewBase64 } = await s3http.uploadFile(rcFile as File, 120);
    const files: (ShortDocFile | DocumentFile)[] = [
      ...tab.files, { id, adminUrl, ext, name: rcFile.name, previewBase64 },
    ];
    const updTab = { ...tab, files };
    const newSets = sets.map((s: CxSet) => (s.idx === idx) ? updTab : s);
    setTab(updTab);
    setSets(newSets);
    setLoading(false);
    return adminUrl;
  };

  const onDateChange = (e: any) => {
    if (e) {
      const updTab: CxSet = { ...tab, setDate: e.format('YYYY-MM-DD') } as CxSet;
      const newSets: CxSet[] = sets.map((s: CxSet) => (s.idx === idx && e) ? updTab : s);
      setTab(updTab as CxSet);
      setSets(newSets);
    }
  };

  const savePart = async (): Promise<void> => {
    if (loading || (checkSource && !checkbox) || (!tab || !tab.files.length)) return;
    setLoading(true);
    try {
      const params = {};
      if (checkSource) {
        const cbFieldArr = checkSource.split('.');
        const cbField = cbFieldArr[cbFieldArr.length - 1];
        params[cbField] = checkbox;
      }
      const fieldArr = fullSrc.split('.');
      const field = fieldArr[fieldArr.length - 1];
      params[field] = sets;
      await cxHttp.partSetsUpdate(cxId, params);
      notify(`Set-${tab?.set || ''} was saved successfully.`);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return !tab ? <Loader /> : (
    <div className={cls.files}>
      <div className={cls.filesTop}>
        <div className={cls.date}>
          <DatePicker
            onChange={onDateChange}
            format="DD-MM-YYYY"
            value={tab.setDate ? moment(tab.setDate, 'YYYY-MM-DD') : undefined}
            disabled={!!(checkSource && !checkbox)}
            className={cls.dateField}
          />
        </div>
        <div className={clsx(
          cls.saveBtn,
          loading || !!(checkSource && !checkbox) || !tab || !tab.files.length ? cls.saveDisabled : '',
        )} onClick={savePart}>
          {loading ? <Loader mini center className={cls.loader} /> : <SaveIcon />}
        </div>
      </div>
      <div className={cls.filesRow}>
        {tab?.files.length ? (
          tab.files.map((f: any, fileIndex) => {
            return (<div className={cls.filesCol} key={fileIndex}>
              <FileControlled
                wrapClass={clsx(cls.filePreview, f.extension === 'pdf' && cls.filePdf)}
                adminUrl={f.adminUrl}
                previewBase64={f.previewBase64}
                isPdf={f.extension === 'pdf'}
                downloadFilename={`Set-${tab?.set} document #${fileIndex + 1}.${f.extension}`}
                onDelete={() => onDel(f.id)}
                disabled={!!(checkSource && !checkbox)}
                showLoading
                zoom
              />
            </div>);
          })
        ) : null}
        {tab && tab.files.length < MAX_FILES ? (
          <div className={cls.filesCol}>
            <FileUpload
              uploadAction={uploadAction}
              className={cls.uploadBtn}
              wrapperClassName={cls.uploader}
              disabled={!!(checkSource && !checkbox) || loading}
            />
          </div>
        ) : null}
      </div>
      <div className={cls.filesBottom}>*Up to {MAX_FILES} docs per set</div>
    </div>
  );
};

export const Tabs = ({ cxId, title, source, checkSource = '' }: Props) => {
  const { field: { value: checkbox, onChange: setCheckbox } } = useInput({ source: checkSource });
  const { field: { value: setsValue, onChange: setSetsValue } } = useInput({ source });
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const [tabs, setTabs] = useState<TabProps[]>([]);
  const [sets, setSets] = useState<CxSet[]>([]);
  const [mode, setMode] = useState<string>('');
  const titleBlock = checkSource ? (
    <>
      <span className={cls.title}>{title}</span>
      <Checkbox
        checked={checkbox}
        onChange={e => setCheckbox(e.target.checked)}
        className={cls.checkbox}
      />
    </>
  ) : title;

  const setItem = (label: string, key: string, fullSrc: string): TabProps => ({
    label: <TabHeader sets={sets} idx={key}>{label}</TabHeader>,
    closeIcon: <CloseCircleFilled className={cls.tabCloseIcon} />,
    children: <Tab
      cxId={cxId}
      idx={key}
      checkSource={checkSource}
      fullSrc={fullSrc}
      sets={sets}
      setSets={setSets}
    />,
    key,
  });

  const removeSet = (idx: string) => {
    if (checkSource && !checkbox) return;
    if (sets.length > 1) {
      const newSets = sets
        .filter((s: CxSet) => s.idx !== idx)
        .map((s: CxSet, i: number) => ({ ...s, set: i + 1 }));
      setSets(newSets);
      setActiveKey(newSets[newSets.length - 1].idx);
    } else {
      setSets([{ set: 1, setDate: null, files: [], idx: (Date.now()).toString() }]);
    }
    setMode('del');
  };

  const showDeleteConfirm = (kfd: string) => {
    confirm({
      title: 'Are you sure delete tab?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        removeSet(kfd);
      },
    });
  };

  const updateTabs = () => {
    setTabs(sets.map((set: CxSet) => setItem(`Set-${set.set}`, set.idx, source)));
  };

  const onEdit = (targetKey: string, action: 'add' | 'remove') => {
    if (checkSource && !checkbox) return;
    if (action === 'add') {
      addSet(sets.length + 1, []);
    } else {
      showDeleteConfirm(targetKey);
    }
  };

  const addSet = (
    set: number, files: DocumentFile[] | ShortDocFile[], setDate: string | null = null,
  ) => {
    setSets([...sets, { set, setDate, files, idx: (Date.now()).toString() }]);
    setMode('add');
  };

  const hideAddTab = (): boolean => {
    if (tabs.length >= MAX_ITEMS) return true;
    if (checkSource && !checkbox) return true;
    if (sets.length && !sets.every((s) => s.files.length)) return true;

    return false;
  };

  useEffect(() => {
    if (setsValue) {
      if (setsValue.length) {
        setSets(setsValue.map((s: CxSet, idx: number) => ({ ...s, idx: `${idx}${(Date.now()).toString()}` })));
      } else {
        addSet(1, []);
      }
    }
  }, []);

  useEffect(() => {
    updateTabs();
    setSetsValue(sets);
  }, [sets]);

  useEffect(() => {
    if (mode === 'add' || mode === 'del') {
      setActiveKey(sets[sets.length - 1].idx);
    }
    setMode('');
  }, [mode]);

  return (
    <InitialCard
      left={titleBlock}
      right={checkSource && !checkbox ? <ModalText /> : undefined}
      contentClass={clsx(cls.cardContent, checkSource && !checkbox && cls.contentBlur)}
      wrapperClass={clsx(cls.cardWrapper, checkSource && !checkbox && cls.cardBlur)}
    >
      <div className={cls.wrap}>
        <div className={cls.top}>*Up to {MAX_ITEMS} sets</div>
        <TabsAntd
          type="editable-card"
          size="small"
          hideAdd={hideAddTab()}
          onChange={setActiveKey}
          activeKey={activeKey}
          addIcon={<div className={cls.newIcon}>new</div>}
          className={cls.tabs}
          disabled={checkSource && !checkbox}
          // @ts-ignore
          onEdit={onEdit}
          items={tabs}
        />
      </div>
    </InitialCard>
  );
};
