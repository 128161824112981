import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { LightTooltip } from '@components/LightTooltip';

interface Props {
  title: NonNullable<React.ReactNode>;
  className?: string;
  inline?: boolean;
  formItem?: boolean;
  lockIcon?: boolean;
}

export const InfoTooltip = ({ title, className, formItem, inline, lockIcon }: Props) => {
  const cls = useStyles();

  return (
    <LightTooltip extraWidth title={title}>
      <div className={clsx(
        cls.box,
        formItem && cls.formItem,
        inline && cls.inline,
        className,
      )}>
        {lockIcon ? (
          <LockRoundedIcon className={cls.icon} />
        ) : (
          <InfoRoundedIcon className={cls.icon} />
        )}
      </div>
    </LightTooltip>
  );
};

const useStyles = makeStyles({
  box: {
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  formItem: {
    position: 'absolute',
    left: -40,
    top: 2,
  },
  inline: {
    marginLeft: -30,
  },
  icon: {
    color: '#042E6B',
    fontSize: 22,
  },
});
