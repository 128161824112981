import { ContractStatus as StatusType, ContractStatusName } from '@types';
import cls from './ContractStatus.module.css';
import clsx from 'clsx';

interface Props {
  status: StatusType;
  className?: string;
}

export const ContractStatus = ({ status, className }: Props) => (
  <div className={clsx(cls.wrap, cls[status], className)}>
    {ContractStatusName[status || StatusType.PENDING]}
  </div>
);
