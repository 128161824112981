import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import ChatIcon from '@mui/icons-material/Chat';
import { useLocation } from 'react-router-dom';

import { useSettingsContext } from '@providers';
import { Sidepanel } from '../Sidepanel';
import { ChatsList } from './chats-list';

export const ChatHeader = () => {
  const location = useLocation();
  const cls = useStyles();
  const { unreaded, panelName, setPanelName } = useSettingsContext();

  useEffect(() => void setPanelName(null), [location.pathname]);

  const count = unreaded ? unreaded.unreadedChats : 0;
  const sidepanelName = 'Chats';
  const togglePanel = () => setPanelName(panelName === sidepanelName ? null : sidepanelName);

  if (unreaded === false) {
    return null;
  }
  const chats = Object.values(unreaded?.chats || []);

  return (
    <>
      <div className={cls.icon} onClick={togglePanel}>
        <ChatIcon style={{ fill: '#002a77' }} />
        {count > 0 && (
          <div className={cls.counter}>{count > 9 ? '9+' : count}</div>
        )}
      </div>

      <Sidepanel name={sidepanelName}>
        {chats.length > 0 ? (
          <ChatsList chats={chats} />
        ) : (
          <div className={cls.empty}>You have no new messages</div>
        )}
      </Sidepanel>
    </>
  );
};

const useStyles = makeStyles({
  chats: {
    display: 'flex',
    flexDirection: 'column',
  },
  chatBtn: {
    display: 'block',
    textAlign: 'left',
    textTransform: 'none',
    paddingLeft: 40,
  },
  chatTitleBlock: {
    marginBottom: -10,
  },
  chatAccredited: {
    fontSize: 12,
    padding: '5px 0px',
    lineHeight: '1.2',
    color: '#000',
    fontWeight: '300',
  },
  chatTitle: {
    fontSize: 20,
    lineHeight: '1',
    color: '#000',
  },
  icon: {
    width: '27.5px',
    height: '27.5px',
    borderRadius: '50%',
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 30,
    position: 'relative',
  },
  counter: {
    position: 'absolute',
    top: -5,
    right: -10,
    color: '#fff',
    background: 'red',
    width: 20,
    height: 20,
    lineHeight: '20px',
    textAlign: 'center',
    fontSize: 12,
    borderRadius: '50%',
  },
  counterUnreaded: {
    top: 34,
    right: 42,
    width: 28,
    height: 28,
    lineHeight: '28px',
    fontSize: 14,
  },
  empty: {
    marginTop: 40,
    textAlign: 'center',
    color: '#042E6B',
  },
  modal: {
    color: 'red',
  },
});
