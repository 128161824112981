import { Http } from './http';
import { CreditCrop, Crop, CxCredit, State, CxSet, CxReceipt, CreditFarm } from '@types';

class SalesforceHttp extends Http {
  prefix = 'salesforce';

  async getByUser(id: number): Promise<SfProps> {
    const res = await this.get<SfProps>(`/by-user/${id}`);

    return res.data;
  }
}

export const salesforceHttp = new SalesforceHttp();

interface SfRequestProps {
  Email: string | null;
  Phone: string | null;
  Title: string | null;
  Inactive__c: boolean;
  OwnerId: string | null;
  LastName: string | null;
  FirstName: string | null;
  AccountId: string | null;
  MiddleName: string | null;
  MobilePhone: string | null;
  Reason_for_rejection__c: string | null;
}

export interface SfProps {
  id: number;
  userId: number;
  creditId: number | null;
  leadId: number | null;
  requestAt: string | null;
  responseJson: string;
  errorJson: string | null;
  requestJson: SfRequestProps;
}
