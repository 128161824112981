import React, { useEffect } from 'react';
import { Filter, List, ReferenceField, TextField } from 'react-admin';

import {
  Grid,
  ListPagination,
  ManyField,
  PhoneField,
  ReferenceInput,
  SelectInput,
  TextInput,
} from '@components';
import { UserStatusField, userStatusOptions } from '@pages/user/UserStatusField';

interface UsersListProps {
  selectedUsersIds: number[];
  setSelectedUsersIds: (selectedIds: number[]) => void;
  className?: string;
  listClassName?: string;
}

export const UsersList = (
  { selectedUsersIds, setSelectedUsersIds, className, listClassName }: UsersListProps,
) => {
  const BulkActionButtons = ({ selectedIds }: any) => {
    useEffect(() => {
      setSelectedUsersIds(selectedIds);
    }, [selectedIds]);

    return <></>;
  };

  return (
    <div
      style={{
        overflowX: 'auto',
        width: '100%',
        marginTop: selectedUsersIds.length ? 0 : 48,
      }}
      className={className}
    >
      <List
        filters={<Filters />}
        resource="user"
        empty={false}
        pagination={<ListPagination />}
        sort={{ field: 'id', order: 'DESC' }}
        actions={<></>}
        className={listClassName}
      >
        <Grid
          defaultColumns={'code fullName status phone stateId'.split(' ')}
          bulkActionButtons={<BulkActionButtons />}
          rowClick="toggleSelection"
          style={{
            marginTop: selectedUsersIds.length ? 48 : 0,
          }}
        >
          <TextField source="fullName" />
          <UserStatusField source="status" />
          <PhoneField source="phone" />
          <ManyField source="dealers" reference="dealer.name" label="Buyers" sortable={false} />
          <ReferenceField source="stateId" reference="state">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="cityId" reference="city">
            <TextField source="name" />
          </ReferenceField>
        </Grid>
      </List>
    </div>
  );
};

const Filters = (props: any) => (
  <Filter {...props}>
    <TextInput source="q" label="Search" alwaysOn />
    <SelectInput source="status" choices={userStatusOptions} alwaysOn />
    <ReferenceInput
      source="stateId"
      reference="state"
      label="State"
      allowEmpty
      alwaysOn
      perPage={9999999999}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="buyerId"
      reference="dealer"
      label="Buyers"
      allowEmpty
      alwaysOn
      perPage={9999999999}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="city" label="City" alwaysOn />
  </Filter>
);
