import { ListProps, TextField } from 'react-admin';

import {
  DateField,
  Forbidden,
  Grid,
  GridList,
  MoneyField,
  TextInput,
} from '@components';
import { useExporter, useRbacEdit, useRbacList } from '@hooks';
import { HistoricalPaymentActions } from './HistoricalPaymentActions';

export const HistoricalPayment = (props: ListProps) => {
  const rbacList = useRbacList();
  const rbacEdit = useRbacEdit();
  const exporter = useExporter({
    filename: 'Historial Payment',
    props: {
      'creditCode': {
        label: 'Loan ID',
      },
      'paymentAt': {
        label: 'True Payment Date',
        transform: 'date',
      },
      'paymentAmount': {
        label: 'True Total Payment',
        transform: 'money',
      },
      'paymentCurrency': {
        label: 'True Payment Currency',
      },
      'principalPayment': {
        label: 'True Principal Payment',
        transform: 'money',
      },
      'interestPayment': {
        label: 'True Interest Payment',
        transform: 'money',
      },
      'feePayment': {
        label: 'True Fee Payment',
        transform: 'money',
      },
      'otherPayment': {
        label: 'True Other Payment',
        transform: 'money',
      },
      'taxPayment': {
        label: 'True Tax Payment',
        transform: 'money',
      },
      'rebate': {
        label: 'True Rebate',
        transform: 'money',
      },
      'outstandingLoanValue': {
        label: 'True Outstanding Loan Value',
        transform: 'money',
      },
      'paymentStatus': {
        label: 'True Payment Status',
        transform: 'money',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList
      {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      hideRemoveButton
      exporter={exporter}
      editComponent={rbacEdit ? <HistoricalPaymentActions /> : undefined}
    >
      <Grid
        rowClick={false}
        defaultColumns={'creditCode paymentAt paymentAmount paymentCurrency'.split(' ')}
      >
        <TextField source="creditCode" label="Loan ID" />
        <DateField source="paymentAt" label="True Payment Date" />
        <MoneyField source="paymentAmount" label="True Total Payment" style={{ display: 'inline-block', width: 100 }} />
        <TextField source="paymentCurrency" label="True Payment Currency" />
        <MoneyField source="principalPayment" label="True Principal Payment" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="interestPayment" label="True Interest Payment" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="feePayment" label="True Fee Payment" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="otherPayment" label="True Other Payment" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="taxPayment" label="True Tax Payment" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="rebate" label="True Rebate" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="outstandingLoanValue" label="True Outstanding Loan Value" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="paymentStatus" label="True Payment Status" style={{ display: 'inline-block', width: 100 }} />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" label="Search" alwaysOn style={{ width: 200 }} />,
];
