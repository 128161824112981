import React from 'react';
import { clsx } from 'clsx';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  ListProps,
  TextField,
  FunctionField,
  useRedirect,
} from 'react-admin';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
  SelectArrayInput,
  DateField,
  PhoneField,
  MoneyField,
  NullableBooleanInput,
  DateRangeSelect,
} from '@components';
import { CxCredit, CxData } from '@types';
import { cxHttp } from '@network';
import { exportToCsv } from '@utils';
import { useExporter, useRbacList } from '@hooks';
import { DonePendingField } from '../DonePendingField';
import { CreditStatusField, cxStatusOptions } from '@pages/credit/CreditStatusField';
import cls from './CxList.module.css';

export const CxList = (props: ListProps) => {
  const rbacList = useRbacList();
  const redirect = useRedirect();
  const exporter = useExporter({
    filename: 'CX',
    props: {
      'code': {
        label: 'Credit code',
      },
      'user.fullName': {
        label: 'Full Name',
      },
      'status': {
        label: 'Status',
        prop: 'statusEn',
      },
      'accreditedName': {
        label: 'Accredited',
      },
      'user.phone': {
        label: 'Phone',
        transform: 'phone',
      },
      'signatureAt': {
        label: 'Signature Date',
        transform: 'date',
      },
      'paymentAt': {
        label: 'Payment Date',
        transform: 'date',
      },
      'bankApproval': {
        label: 'Credit Amount',
        transform: 'money',
      },
      'balance': {
        label: 'Balance',
        transform: 'money',
      },
      'cx.hasFarms': {
        label: 'Farms',
        transform: 'done',
      },
      'cx.hasWelcomeCall': {
        label: 'Welcome Call',
        transform: 'done',
      },
      'cx.hasFollowUp': {
        label: 'Follow Up',
        transform: 'done',
      },
      'cx.hasCoveredCrop': {
        label: 'Covered Crop',
        transform: (hasCoveredCrop: boolean) => !hasCoveredCrop ? 'Done' : 'Pending',
      },
      'cx': {
        label: 'Is Covered',
        transform: (record: CxData) => !record.hasCoveredCrop || record.setsCoveredCrop.length === 0
          ? 'Disabled' : 'Enabled',
      },
    },
  });

  const mappingFuncWelcomeCall = (item: CxCredit) => {
    const itemExport = {
      'Credit Code': item.code,
      'Accredited': item.accredited ?? '',
      'Credit Status': item.statusEn ?? '',
    };
    for (const question of item.cx.questionsWelcomeCall) {
      itemExport[question.name] = question.answer || '';
    }
    return itemExport;
  };

  const mappingFuncFollowUp = (item: CxCredit) => {
    const itemExport = {
      'Credit Code': item.code,
      'Accredited': item.accreditedName ?? '',
      'Credit Status': item.statusEn ?? '',
    };
    for (const question of item.cx.questionsFollowUp) {
      itemExport[question.name] = question.answer || '';
    }
    return itemExport;
  };

  if (!rbacList) {
    return <Forbidden />;
  }

  const onExport = async (queryParams: any) => {
    const positions = await cxHttp.export(queryParams);

    exportToCsv(positions, 'CX Welcome Call', mappingFuncWelcomeCall);
    setTimeout(() => exportToCsv(positions, 'CX Follow Up', mappingFuncFollowUp), 100);
  };

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      hideRemoveButton
      exporter={exporter}
      onExport={onExport}
    >
      <Grid
        rowClick="edit"
        defaultColumns={['code', 'status', 'accreditedName', 'user.phone', 'balance',
          'cx.hasFarms', 'cx.hasWelcomeCall', 'cx.hasFollowUp',
        ]}
      >
        <TextField source="code" label="Credit Code" />
        <FunctionField source="user.fullName" label="Full name" render={(record: CxCredit) => {
          return <span
            className={cls.link}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              redirect(`/user/${record.user.id}?initial`);
            }}
          >{record.user.fullName}</span>;
        }}/>
        <CreditStatusField source="status" label="Status" />
        <TextField source="accreditedName" label="Accredited" />
        <PhoneField source="user.phone" label="Phone" />
        <DateField source="signatureAt" label="Signature Date" />
        <DateField source="paymentAt" label="Payment Date" />
        <MoneyField source="bankApproval" label="Credit Amount"
          style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="balance" label="Balance"
          style={{ display: 'inline-block', width: 100 }} />
        <DonePendingField source="cx.hasFarms" label="Farms" />
        <DonePendingField source="cx.hasWelcomeCall" label="Welcome Call" />
        <DonePendingField source="cx.hasFollowUp" label="Follow Up" />
        <DonePendingField source="cx.hasCoveredCrop" label="Covered Crop" reverse />
        <FunctionField source="cx" label="Is Covered" render={(record: CxCredit) => (
          <div className={cls.checkWrap}>{
            !record.cx.hasCoveredCrop || record.cx.setsCoveredCrop.length === 0
              ? <CloseCircleOutlined className={clsx(cls.check, cls.unchecked)} />
              : <CheckCircleOutlined className={clsx(cls.check, cls.checked)} />
          }</div>
        )}/>
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" label="Search" alwaysOn style={{ width: 200 }} />,
  <SelectArrayInput
    key="status"
    source="status"
    choices={cxStatusOptions}
    className="MS-field"
    style={{ width: 230 }}
    alwaysOn
  />,
  <DateRangeSelect
    key="createdAt"
    source="createdAt"
    label="Signature Date/Payment Date/Welcome Call/Follow Up"
    alwaysOn
    choices={[
      { id: 'signatureAt', name: 'Signature Date' },
      { id: 'paymentAt', name: 'Payment Date' },
      { id: 'welcomeCallFinishedAt', name: 'Welcome Call' },
      { id: 'followUpFinishedAt', name: 'Follow Up' },
    ]}
  />,
  <NullableBooleanInput key="hasFarms" source="hasFarms" label="Farms"
    trueLabel="Done" falseLabel="Pending" />,
  <NullableBooleanInput key="hasWelcomeCall" source="hasWelcomeCall" label="Welcome Call"
    trueLabel="Done" falseLabel="Pending" />,
  <NullableBooleanInput key="hasFollowUp" source="hasFollowUp" label="Follow Up"
    trueLabel="Done" falseLabel="Pending" />,
  <NullableBooleanInput key="hasCoveredCrop" source="hasCoveredCrop" label="Covered Crop"
    trueLabel="Done" falseLabel="Pending" />,
];
