import React from 'react';
import { FunctionField, ListProps, TextField } from 'react-admin';

import {
  DateField,
  Forbidden,
  Grid,
  GridList,
  MoneyField,
  SelectArrayInput,
  TextInput,
} from '@components';
import { userSourceMap } from '@types';
import { useExporter, useRbacEdit, useRbacList } from '@hooks';
import { CustomerDataListActions } from './CustomerDataListActions';
import { CreditStatusField, creditStatusOptions } from '@pages/credit/CreditStatusField';

export const CustomerDataList = (props: ListProps) => {
  const rbacList = useRbacList();
  const rbacEdit = useRbacEdit();
  const exporter = useExporter({
    filename: 'Customer Data list',
    props: {
      'customerId': {
        label: 'Customer ID',
      },
      'creditCreatedAt': {
        label: 'Date of Application',
        transform: 'date',
      },
      'creditStatus': {
        label: 'Application Status',
        transform: 'status',
      },
      'creditCode': {
        label: 'App ID',
      },
      'productType': {
        label: 'Product Type',
      },
      'userSource': {
        label: 'Sales Channel',
        transform: (value: string) => value ? userSourceMap[value] : '',
      },
      'userCity': {
        label: 'Location City',
      },
      'userState': {
        label: 'Location State',
      },
      'userCountry': {
        label: 'Location Country',
      },
      'creditRiskScore': {
        label: 'Internal Credit Score',
      },
      'creditAccredited': {
        label: 'Client Type',
      },
      'useOfCredit': {
        label: 'Product Use',
      },
      'responsibleAdmin.name': {
        label: 'Sales Agent',
      },
      'userMoffinBureauScore': {
        label: 'Credit Bureau',
      },
      'creditRequestAmount': {
        label: 'Amount Requested',
        transform: 'money',
      },
      'riskCreditTerm': {
        label: 'Term Requested',
      },
      'userGender': {
        label: 'Gender (Individual)',
      },
      'houseHoldDependentsCount': {
        label: 'Number of household dependents',
      },
      'businessIndustry': {
        label: 'Business industry',
      },
      'employeesCount': {
        label: 'Number of Employees',
      },
      'occupancy': {
        label: 'Occupancy',
      },
      'userBirthdate': {
        label: 'Birthdate/DOF',
        transform: 'date',
      },
      'income': {
        label: 'Occupancy',
      },
      'incomeCurrency': {
        label: 'Income Currency',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList
      {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      hideRemoveButton
      exporter={exporter}
      editComponent={rbacEdit ? <CustomerDataListActions /> : undefined}
    >
      <Grid
        rowClick={false}
        defaultColumns={'customerId creditCreatedAt creditStatus creditCode productType userSource'.split(' ')}
      >
        <TextField source="customerId" label="Customer Id" />
        <DateField source="creditCreatedAt" label="Date of Application" />
        <CreditStatusField source="creditStatus" label="Application Status" />
        <TextField source="creditCode" label="App ID" />
        <TextField source="productType" label="Product Type" />
        <FunctionField
          source="userSource"
          label="Sales Channel"
          render={(record: any) => record.userSource ? userSourceMap[record.userSource] : ''}
        />
        <TextField source="userCity" label="Location City" />
        <TextField source="userState" label="Location State" />
        <TextField source="userCountry" label="Location Country" />
        <TextField source="creditRiskScore" label="Internal Credit Score" />
        <TextField source="creditAccredited" label="Client Type" />
        <TextField source="useOfCredit" label="Product Use" />
        <TextField source="responsibleAdmin.name" label="Sales Agent" />
        <TextField source="userMoffinBureauScore" label="Credit Bureau" />
        <MoneyField source="creditRequestAmount" label="Amount Requested" style={{ display: 'inline-block', width: 100 }} />
        <TextField source="riskCreditTerm" label="Term Requested" />
        <TextField source="userGender" label="Gender (Individual)" />
        <TextField source="houseHoldDependentsCount" label="Number of household dependents" />
        <TextField source="businessIndustry" label="Business industry" />
        <TextField source="employeesCount" label="Number of Employees" />
        <TextField source="occupancy" label="Occupancy" />
        <DateField source="userBirthdate" label="Birthdate/DOF" />
        <TextField source="income" label="Income/Business Revenue" />
        <TextField source="incomeCurrency" label="Income Currency" />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" label="Search" alwaysOn style={{ width: 200 }} />,
  <SelectArrayInput
    key="status"
    source="status"
    choices={creditStatusOptions}
    className="MS-field"
    style={{ width: 250 }}
    alwaysOn
  />,
];
