import { Http } from './http';
import { DocumentFile } from '@types';

class S3Http extends Http {

  async uploadFileToUrl(
    fileObj: File, url: string, queryParams?: Record<string, string | number>,
  ): Promise<DocumentFile> {
    const formData = new FormData();
    formData.append('file', fileObj);

    const res = await this.post<DocumentFile>(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      params: queryParams,
    });

    return res.data;
  }

  async uploadFile(fileObj: File, previewMaxWidth?: number): Promise<DocumentFile> {
    const queryParams = previewMaxWidth ? { withPreview: '1', previewMaxWidth } : undefined;

    return this.uploadFileToUrl(fileObj, '/s3', queryParams);
  }

  async uploadPublicFile(fileObj: File): Promise<DocumentFile> {
    return this.uploadFileToUrl(fileObj, '/s3?isPublic=1');
  }

  async uploadFinanceFile(fileObj: File): Promise<DocumentFile> {
    return this.uploadFileToUrl(fileObj, '/s3?isFinance=1');
  }

  async uploadMediaFile(fileObj: File): Promise<DocumentFile> {
    return this.uploadFileToUrl(fileObj, '/s3?isAdminMedia=1');
  }
}

export const s3http = new S3Http();
