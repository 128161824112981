import { Button } from '@mui/material';
import { Edit, EditProps, SimpleForm } from 'react-admin';

import { Contract, ContractStatus as StatusType, ContractStatus } from '@types';
import { Progress } from './Progress';
import { ContractsForm } from './ContractsForm';
import { useEditProps, useRbacEdit, useRecord } from '@hooks';
import { ExportIcon, Forbidden, InitialPage } from '@components';
import cls from './Contracts.module.css';
import { GetFileBtn } from '@pages/contracts/Components/GetFileBtn';

const Title = ({ record }: {record?: Contract}) =>
  record ? (<span>Contract: {record.id}</span>) : null;

const ProgressStep = {
  [ContractStatus.PENDING]: 0,
  [ContractStatus.COMPLETED]: 1,
  [ContractStatus.SIGNATURE_IN_PROCESS]: 2,
  [ContractStatus.SIGNED]: 3,
};

export const ContractsEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacEdit = useRbacEdit();
  const record = useRecord<Contract>(props);

  if (!rbacEdit) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <InitialPage
        title="Individual Contract"
        backTo="/contract"
        backText="Back to all contracts"
        headerCenter={
          <Progress
            options={{ labels: ['step 1', 'step 2', 'step 3', 'step 4'] }}
            active={ProgressStep[record?.status || ContractStatus.PENDING]}
          />
        }
        className={cls.contractInitPage}
        headerRight={
          <>
            <GetFileBtn
              url={record?.signedDocument?.files[0]?.adminUrl}
              fName={`${record?.creditCode}_draftContract.docx`}
              viewOnly={false}
              type="docx"
            >
              <Button
                variant="outlined"
                color="inherit"
                className={cls.exportBtn}
                disabled={!record || record?.status !== StatusType.SIGNED}
              ><ExportIcon/>Export</Button>
            </GetFileBtn>
            <div className={cls.code}>{record?.creditCode || ''}</div>
          </>
        }
      >
        <SimpleForm toolbar={false}>
          <ContractsForm record={record} />
        </SimpleForm>
      </InitialPage>
    </Edit>
  );
};
