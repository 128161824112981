import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useRefresh } from 'react-admin';
import { Row, Col } from 'antd';

import { Credit } from '@types';
import { creditHttp } from '@network';
import { showAxiosError } from '@utils';
import { GuarantorCard } from './guarantor-card';
import { Checkbox } from '@mui/material';
import cls from './credit-guarantors.module.css';
import { useRbacEdit } from '@hooks';

export const CreditGuarantors = ({ credit }: {credit: Credit}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGuarantorsRequired, setIsGuarantorsRequired] = useState<boolean>(false);
  const refresh = useRefresh();
  const rbacEdit = useRbacEdit();
  const guarantors = credit.guarantors || [];

  const onDelete = async (guarantorId: number) => {
    try {
      await creditHttp.deleteGuarantor(credit.id, guarantorId);
      refresh();
    } catch (err: any) {
      showAxiosError(err);
    }
  };

  const setGuarantorRequireHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const isRequired = !e.target.checked;

    try {
      setIsLoading(true);
      const updatedCredit = await creditHttp.setGuarantorRequired(
        { creditId: credit.id, isRequired },
      );
      setIsGuarantorsRequired(updatedCredit.isGuarantorsRequired || false);
      refresh();
    } catch (err: any) {
      showAxiosError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (credit.isGuarantorsRequired !== undefined) {
      setIsGuarantorsRequired(credit.isGuarantorsRequired);
    }
  }, [credit]);

  return (
    <div className={cls.box}>
      <div className={cls.head}>
        <div className={cls.title}>Guarantors</div>
        <div className={cls.tick}>
          <Checkbox
            style={{ width: 40 }}
            size="medium"
            onChange={setGuarantorRequireHandler}
            disabled={isLoading || !rbacEdit}
            checked={!isGuarantorsRequired}
          /> This credit does not require guarantors
        </div>
      </div>

      <Row gutter={[20, 20]} className={cls.guarantors}>
        {guarantors.map((guarantor, idx) => (
          <Col xs={24} sm={12} md={6} key={guarantor.id}>
            <GuarantorCard
              guarantor={guarantor}
              onDelete={onDelete}
              editable={rbacEdit}
              linkTo={`/credit/${credit.id}?guarantor=${guarantor.id}`}
              isDisabled={!credit.isGuarantorsRequired}
            />
          </Col>
        ))}
        {credit.isGuarantorsRequired && rbacEdit ? (
          <Col xs={24} sm={12} md={6}>
            <Link to={`/credit/${credit.id}?guarantor-create`} className={cls.addLink}>
              <div className={cls.iconPlus}>+</div>
            </Link>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};
