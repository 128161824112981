import React from 'react';

import { File } from '@types';
import { FileImage, FileMp4, FilePdf, FileXlsx, FileXml } from '@components';

interface Props {
  file: File;
  wrapClass?: string;
  className?: string;
  previewUrl?: string;
  downloadFilename?: string;
}

export const FilePreview = ({
  file, wrapClass, className, previewUrl, downloadFilename,
}: Props) => {
  const fileName = downloadFilename ?? 'Document';

  const FileWidget = (
    <>
      {file?.isMp4 && (
        <FileMp4
          url={file.adminUrl}
          previewUrl={previewUrl ?? file.adminUrl}
          downloadFilename={fileName}
          className={className}
        />
      )}
      {file?.isPdf && (
        <FilePdf url={file.adminUrl} downloadFilename={fileName} className={className} />
      )}
      {file?.isXlsx && (
        <FileXlsx url={file.adminUrl} downloadFilename={fileName} className={className} />
      )}
      {file?.isXml && (
        <FileXml url={file.adminUrl} downloadFilename={fileName} className={className} />
      )}
      {file?.isImage && (
        <FileImage url={file.adminUrl} downloadFilename={fileName} className={className} />
      )}
    </>
  );

  return (
    <>
      {wrapClass ? (
        <div className={wrapClass}>{FileWidget}</div>
      ) : (
        <>{FileWidget}</>
      )}
    </>
  );
};
