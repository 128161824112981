import { useEffect, useState } from 'react';
import { Admin } from '@types';
import { adminHttp } from '@network';

export const useAdminsById = () => {
  const [adminsById, setAdminsById] = useState<Record<number, Admin>>({});

  useEffect(() => {
    (async () => {
      const allAdmins = await adminHttp.getAll();
      const nextAdmins: Record<number, Admin> = {};
      for (const admin of allAdmins) {
        nextAdmins[admin.id] = admin;
      }
      setAdminsById(nextAdmins);
    })();
  }, []);

  return adminsById;
};
