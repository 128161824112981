import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { CheckboxGroupInput } from 'ra-ui-materialui';

import { http } from '@network';
import cls from './ManyCheckboxInputStyled.module.css';

interface Props {
  source: string;
  label?: string;
  hideLabel?: boolean;
  reference?: string;
  optionKey?: string;
  optionLabel?: string;
  className?: string;
  isFixed?: boolean | null;
}

export const ManyCheckboxInputStyled = ({
  source, label, reference, optionKey, optionLabel, className, isFixed,
}: Props) => {
  const [options, setOptions] = useState<CheckOption[]>([]);

  useEffect(() => {
    (async () => {
      const response = await http.get<any>(reference || source, {
        params: { _start: 0, _end: 9999999 },
      });
      const nextOptions: CheckOption[] = [];
      if (response.data && Array.isArray(response.data)) {
        (response.data ?? []).forEach((model: any) => {
          nextOptions.push({
            id: model[optionKey ?? 'id'],
            name: model[optionLabel ?? 'nameMx'] || model['name'] || model['title'],
          });
        });
      }
      setOptions(nextOptions);
    })();
  }, []);

  const inputLabel = label === undefined
    ? source.slice(0, 1).toUpperCase().concat(source.slice(1)) : label;

  return (
    <CheckboxGroupInput
      name={source}
      source={source}
      label={inputLabel}
      choices={options}
      className={clsx(cls.checkbox, className)}
      disabled={!!isFixed}
    />
  );
};

interface CheckOption {
  id: string,
  name: string,
}
