import { Dealer } from './Dealer';

export interface Manager {
  id: number;
  name: string;
  email: string;
  dealerId: number;
  buyerId: number;
  isEnabled: boolean;
  isSuperadmin: boolean;
  phone: string | null;
  createdAt: string;
  registeredAt: string | null;
  image: string | null;
  type: ManagerType;
  dealer: Dealer | null;
  associate: Dealer | null;
}

export type ManagerType = 'buyer' | 'retailer' | 'associate';

export const typeOfManager = ['Off Taker', 'Other'].map(opt => ({
  id: opt,
  name: opt,
}));

export enum ManagerTypeValue {
  BUYER = 'buyer',
  RETAILER = 'retailer',
  ASSOCIATE = 'associate',
}

export enum ManagerTypeName {
  buyer = 'Off Taker',
  retailer = 'Retail Store',
  associate = 'Associate'
}

export const managerTypeOptions = [
  ManagerTypeValue.BUYER, ManagerTypeValue.RETAILER, ManagerTypeValue.ASSOCIATE,
].map((opt: ManagerType) => ({
  id: opt,
  name: ManagerTypeName[opt],
}));
