import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { notification } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation, Pagination } from 'swiper/modules';
import IconButton from '@mui/material/IconButton';
import { LoadingOutlined } from '@ant-design/icons';
import { clsx } from 'clsx';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { CreditCrop, CxCredit, CxFarm } from '@types';
import { cxHttp, CxOptions } from '@network';
import { PolicyInsuranceForm } from './PolicyInsuranceForm';
import { useRequest } from '@hooks';

interface Props {
  cxCredit: CxCredit;
}

export const PolicyInsurances = ({ cxCredit }: Props) => {
  const creditId = cxCredit.id;
  const address = Array.isArray(cxCredit.user.location)
    ? cxCredit.user.location[0]
    : null;

  const defaultInsurance = {
    key: '0',
    stateId: cxCredit.user.stateId,
    creditCode: cxCredit.code,
    accreditedName: cxCredit.accreditedName,
    creditAmount: cxCredit.bankApproval,
    municipality: address ? (address.neighbourhood || address.city) : '',
  } as CreditCrop;

  const initialInsurances = cxCredit.creditCrops.length > 0
    ? cxCredit.creditCrops
    : [defaultInsurance];

  const swiperRef = useRef<any>(null);
  const [updated, setUpdated] = useState(false);
  const [cxOptions, setCxOptions] = useState<CxOptions>({ crops: [], states: [] });
  const [insurances, setInsurances] = useState<CreditCrop[]>(initialInsurances);
  const [creditFarms, setCreditFarms] = useState<CxFarm[]>([]);
  const [usedFarmIds, setUsedFarmIds] = useState<number[]>([]);
  const cls = useStyles();

  if (insurances[0] && insurances[0].stateId) {
    defaultInsurance.stateId = insurances[0].stateId;
  }

  const loadFarms = async () => {
    const { farms } = await cxHttp.getCxCredit(creditId);
    setCreditFarms(farms || []);
  };

  useEffect(() => {
    (async () => {
      let loadedInsurances = await cxHttp.getInsurances(creditId);
      loadedInsurances = loadedInsurances.map(ins => {
        ins.creditCode = ins.creditCode || defaultInsurance.creditCode;
        ins.accreditedName = ins.accreditedName || defaultInsurance.accreditedName;
        ins.creditAmount = ins.creditAmount || defaultInsurance.creditAmount;
        ins.municipality = ins.municipality || defaultInsurance.municipality;
        ins.stateId = ins.stateId || defaultInsurance.stateId;
        return ins;
      });
      const nextInsurances = loadedInsurances.length > 0 ? loadedInsurances : [defaultInsurance];
      setInsurances(nextInsurances);
      await loadFarms();
    })();
  }, [creditId]);

  useEffect(() => {
    (async () => {
      const nextOptions = await cxHttp.getOptions();
      setCxOptions(nextOptions);
    })();
  }, []);

  useEffect(() => {
    const farmIds: number[] = [];
    for (const ins of insurances) {
      for (const farm of (ins.farms ?? [])) {
        if (farm.id) farmIds.push(farm.id);
      }
    }
    setUsedFarmIds(farmIds);
  }, [insurances]);

  const { loading: isSaving, submit: saveInsurances } = useRequest(async () => {
    for (const ins of insurances) {
      if (!ins.financedCropId && !ins.isProtectedCultivation) {
        return notification.error({ message: 'Cultivo is mandatory' });
      }
      ins.farms = ins.farms ?? [];
      for (const farm of ins.farms) {
        if (!farm.latitude) {
          return notification.error({ message: 'Ubicación Latitude is mandatory' });
        }
        if (!farm.longitude) {
          return notification.error({ message: 'Ubicación Londitude is mandatory' });
        }
      }
    }
    const updatedInsurances = await cxHttp.updateInsurances(creditId, insurances);
    setInsurances(updatedInsurances);
    setUpdated(false);
    notification.success({ message: 'Insurance Policies are saved', duration: 5 });
    await loadFarms();
  });

  const updateInsurance = (updatedInsurance: CreditCrop) => {
    let stateId: number | null | undefined = null;
    let nextInsurances: CreditCrop[] = insurances.map((insurance) => {
      if (updatedInsurance.key === insurance.key) {
        stateId = updatedInsurance.stateId;
        return updatedInsurance;
      }
      return insurance;
    });
    if (stateId) {
      nextInsurances = nextInsurances.map(insurance => {
        insurance.stateId = stateId;
        return insurance;
      });
    }
    setInsurances(nextInsurances);
    setUpdated(true);
  };

  const addInsurance = () => {
    if (insurances.length >= 15) {
      return notification.error({ message: 'Limit is 15' });
    }
    const newInsurance = { ...defaultInsurance, id: null, key: Date.now().toString() };
    const nextInsurances: CreditCrop[] = [...insurances, newInsurance];
    setInsurances(nextInsurances);
    setTimeout(() => void swiperRef.current?.slideTo(nextInsurances.length), 100);
    setUpdated(true);
  };

  const removeInsurance = () => {
    const currentSlideIdx = swiperRef.current?.realIndex ?? -1;
    const nextInsurances = insurances.filter((_, idx) => idx !== currentSlideIdx);
    setInsurances(nextInsurances);
    setUpdated(true);
  };

  return (
    <div className={cls.container}>
      <div className={cls.header}>
        <div className={cls.headerTitle}>Insurance Policies</div>
        <div className={cls.headerControls}>
          <IconButton
            className={clsx(cls.btnSave, updated && cls.btnSaveUpdated)}
            onClick={saveInsurances}
          >
            {isSaving ? <LoadingOutlined /> : <SaveIcon />}
          </IconButton>
          <IconButton className={cls.btnRemove} onClick={removeInsurance}>
            <DeleteIcon />
          </IconButton>
          <div className={cls.headerText}>Policy #</div>
          <IconButton className={cls.btnAdd} onClick={addInsurance}>
            +
          </IconButton>
        </div>
      </div>

      <Swiper
        onSwiper={(swiper: any) => {
          swiperRef.current = swiper;
        }}
        centeredSlides={true}
        spaceBetween={0}
        slidesPerView={1}
        keyboard={{ enabled: true }}
        navigation={true}
        modules={[Keyboard, Navigation, Pagination]}
        className={cls.carousel}
        pagination={{ type: 'fraction' }}
        noSwiping
        touchStartPreventDefault={false}
        simulateTouch={false}
        alwaysShowSwipe={true}
      >
        {insurances.map((insurance, idx) => (
          <SwiperSlide key={insurance.key} virtualIndex={idx}>
            <PolicyInsuranceForm
              insurance={insurance}
              updateInsurance={updateInsurance}
              cxCredit={cxCredit}
              cxOptions={cxOptions}
              creditFarms={creditFarms}
              setCreditFarms={setCreditFarms}
              usedFarmIds={usedFarmIds}
              idx={idx}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    border: '3px solid #042E6B',
    borderRadius: '8px',
    padding: '15px 25px 15px 35px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    color: '#042E6B',
    fontSize: '25px',
  },
  headerControls: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 2,
  },
  headerText: {
    color: '#000',
    fontSize: 15,
    width: 100,
    marginRight: 68,
    textAlign: 'center',
  },
  carousel: {
    position: 'initial',
    '& .swiper-button-prev': {
      display: 'flex',
      top: 49,
      right: 210,
      width: 29,
      height: 20,
      borderRadius: 4,
      backgroundColor: '#042E6B',
      '&::after': {
        color: 'white !important',
      },
    },
    '& .swiper-button-next': {
      display: 'flex',
      top: 49,
      right: 68,
      width: 29,
      height: 20,
      borderRadius: 4,
      backgroundColor: '#042E6B',
      '&::after': {
        color: 'white !important',
      },
    },
    '& .swiper-pagination': {
      right: 100,
      display: 'block !important',
    },
  },
  btnSave: {
    marginRight: 10,
    width: 29,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#042E6B !important',
    borderRadius: 4,
    opacity: 0.45,
    '&:hover': {
      opacity: 1,
      boxShadow: '0 4px 8px 0 rgba(48, 55, 61, 0.2)',
    },
  },
  btnSaveUpdated: {
    opacity: 0.8,
  },
  btnRemove: {
    marginRight: 28,
    width: 29,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FF0000 !important',
    borderRadius: 4,
    opacity: 0.45,
    '&:hover': {
      opacity: 1,
    },
  },
  btnAdd: {
    marginRight: 0,
    width: 29,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#042E6B !important',
    borderRadius: 4,
    opacity: 0.45,
    color: 'white',
    '&:hover': {
      opacity: 1,
    },
  },
});

const SaveIcon = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3965_24270)">
      <path d="M14.8964 2.25077L13.2321 0.60257C13.1643 0.538906 13.075 0.5 12.9786 0.5H2.25C2.21429 0.5 2.18571 0.5 2.15 0.5C2.15 0.5 2.14643 0.5 2.14286 0.5H0.357143C0.160714 0.5 0 0.659161 0 0.85369V15.1463C0 15.3408 0.160714 15.5 0.357143 15.5H14.6429C14.8393 15.5 15 15.3408 15 15.1463V2.50189C15 2.40639 14.9607 2.31797 14.8964 2.25077ZM7.5 12.3486C6.11786 12.3486 5 11.2416 5 9.87279C5 8.50401 6.11786 7.39696 7.5 7.39696C8.88214 7.39696 10 8.50401 10 9.87279C10 11.2416 8.88214 12.3486 7.5 12.3486ZM11.4286 4.40474C11.4286 4.99187 10.95 5.46581 10.3571 5.46581H4.64286C4.05 5.46581 3.57143 4.99187 3.57143 4.40474V1.90769C3.57143 1.72377 3.72143 1.57522 3.90714 1.57522L7.91071 1.56814C8.07857 1.56814 8.21429 1.70255 8.21429 1.86878V3.97324C8.21429 4.20667 8.40357 4.39413 8.63929 4.39413H9.575C9.81071 4.39413 10 4.20667 10 3.97324V1.86171C10 1.69547 10.1357 1.56107 10.3036 1.56107H11.0929C11.2786 1.56107 11.4286 1.70962 11.4286 1.89354V4.40474Z"
        fill="#E1E5ED" />
    </g>
    <defs>
      <clipPath id="clip0_3965_24270">
        <rect width="15" height="15" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

const DeleteIcon = () => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.32511 2.40625C8.35111 2.51427 8.46113 2.60028 8.57314 2.60028H10.7034C11.0895 2.60028 11.4035 2.91432 11.4035 3.30037C11.4035 3.62042 11.1895 3.88845 10.8975 3.97246L10.5314 14.0258C10.5014 14.8439 9.82131 15.5 9.0012 15.5H2.40232C1.58421 15.5 0.90212 14.8419 0.872116 14.0258L0.506067 3.97246C0.214029 3.88845 0 3.61842 0 3.30037C0 2.91432 0.314042 2.60028 0.700093 2.60028H2.83038C2.94039 2.60028 3.05041 2.51227 3.07841 2.40625L3.22843 1.80617C3.41445 1.06608 4.13855 0.5 4.90065 0.5H6.50287C7.26497 0.5 7.99107 1.06608 8.17509 1.80617L8.32511 2.40625ZM5.20069 5.80071V12.6016C5.20069 12.8777 5.42472 13.1017 5.70076 13.1017C5.9768 13.1017 6.20083 12.8777 6.20083 12.6016V5.80071C6.20083 5.52467 5.9768 5.30064 5.70076 5.30064C5.42472 5.30064 5.20069 5.52467 5.20069 5.80071ZM2.90039 5.81471L3.10041 12.6156C3.10841 12.8917 3.33844 13.1097 3.61448 13.1017C3.89052 13.0937 4.10855 12.8636 4.10055 12.5876L3.90052 5.78671C3.89252 5.51067 3.66249 5.29264 3.38645 5.30064C3.11041 5.30864 2.89239 5.53867 2.90039 5.81471ZM7.501 5.7847L7.30097 12.5856C7.29297 12.8616 7.511 13.0917 7.78704 13.0997C8.06308 13.1077 8.29311 12.8897 8.30111 12.6136L8.50113 5.81271C8.50913 5.53667 8.29111 5.30664 8.01507 5.29864C7.73903 5.29064 7.509 5.50867 7.501 5.7847ZM4.57061 2.59828H6.82891C6.88292 2.59828 6.91692 2.55427 6.90492 2.50227L6.81491 2.14422C6.7849 2.0262 6.62088 1.89819 6.50087 1.89819H4.89865C4.77864 1.89819 4.61461 2.0262 4.58461 2.14422L4.4946 2.50227C4.4806 2.55627 4.5146 2.59828 4.57061 2.59828Z"
      fill="white" />
  </svg>
);
