import { FC } from 'react';
import { Upload, UploadFile } from 'antd';
import { clsx } from 'clsx';
import IconButton from '@mui/material/IconButton';

import { FileImage, FilePdf } from '@components';
import { beforeUpload, beforeUploadContracts, beforeUploadType } from '@utils';
import { Credit, Doctype, Document, Guarantor } from '@types';
import { Badge } from '@components';
import { FileStatuses } from '../FileStatuses';
import { ConfirmDelete } from '@components/ConfirmDelete';
import cls from './document-files.module.css';
import { SentBy } from '@pages/credit/SentBy';

const { Dragger } = Upload;

interface Props {
  credit: Credit;
  doctype: Doctype;
  guarantor?: Guarantor;
  document?: Document;
  setDocument: (document: Document) => void;
  onUpload: (uploadFiles: UploadFile[]) => void;
  onDelete: (fileId: number) => Promise<void>;
  loading: boolean;
  editable: boolean;
  downloadable?: boolean;
  creditContractDoctype?: Doctype;
  type?: 'pdf' | 'image' | 'docs';
}

export const DocumentFiles: FC<Props> = ({
  credit,
  doctype,
  guarantor,
  document,
  setDocument,
  onUpload,
  onDelete,
  editable,
  downloadable,
  creditContractDoctype,
  type,
}) => {
  const files = document?.files || [];

  const isCreditContract = doctype.isCreditContract;

  return (
    <>
      <div className={cls.files}>
        {files.map((file, i) => {
          let downloadFilename = guarantor
            ? `${credit.user.fullName} (${guarantor.fullName}) - ${doctype.nameMx}`
            : `${credit.user.fullName} - ${doctype.nameMx}`;
          if (files.length > 1) {
            downloadFilename = `${downloadFilename} #${i + 1}`;
          }
          downloadFilename = `${downloadFilename}.${file.extension}`;

          return (
            <div className={cls.fileBox} key={file.id}>
              <div className={cls.file}>
                <div className={cls.preview} title="preview">
                  {file.isPdf ? (
                    <FilePdf
                      url={file.adminUrl}
                      downloadFilename={downloadFilename}
                      isRestricted={!editable && !downloadable}
                    />
                  ) : (
                    <FileImage
                      url={file.adminUrl}
                      downloadFilename={downloadFilename}
                      isRestricted={!editable && !downloadable}
                      hidePreview={!['jpg', 'jpeg', 'gif', 'png'].includes(file.extension)}
                    />
                  )}
                </div>
                {editable ? (
                  <IconButton className={cls.removeIcon}>
                    <ConfirmDelete title="Are you sure?" cb={() => onDelete(file.fileId)}>
                      <svg style={{ display: 'block' }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none">
                        <path d="M7 0C3.13461 0 0 3.13461 0 7C0 10.8654 3.13461 14 7 14C10.8654 14 14 10.8654 14 7C13.9938 3.13461 10.8623 0.00310973 7 0Z"
                          fill="#002A77" />
                        <path d="M9.63374 4.36293C9.51246 4.24165 9.31655 4.24165 9.19527 4.36293L6.9998 6.5584L4.80433 4.36293C4.68305 4.24165 4.48714 4.24165 4.36586 4.36293C4.24458 4.48421 4.24458 4.68012 4.36586 4.8014L6.56133 6.99687L4.36586 9.19234C4.24458 9.31362 4.24458 9.50953 4.36586 9.63081C4.48714 9.75209 4.68305 9.75209 4.80433 9.63081L6.9998 7.43534L9.19527 9.63081C9.31655 9.75209 9.51246 9.75209 9.63374 9.63081C9.75502 9.50953 9.75502 9.31362 9.63374 9.19234L7.43827 6.99687L9.63374 4.8014C9.75502 4.68012 9.75502 4.48421 9.63374 4.36293Z"
                          fill="white" />
                      </svg>
                    </ConfirmDelete>
                  </IconButton>
                ) : null}
              </div>

              <div className={clsx(cls.fileRow, cls.fileRowH)}>
                <span className={cls.statusLabel}>
                  {file.inRevision && <Badge status="pending" label="Pending" />}
                  {file.isDenied && <Badge status="warn" label="Denied" />}
                  {file.isApproved && <Badge status="ok" label="Approved" />}
                  {file.isDraft && <Badge status="draft" label="Draft" />}
                  {file.isSigned && <Badge status="ok" label="Signed" />}
                </span>
                <span className={cls.sendBy}>
                  <SentBy type={file.managerId ? 'manager' : file.adminId ? 'admin' : 'user'} />
                </span>
              </div>
              <div className={cls.fileRow}>
                {document && editable ? (
                  <FileStatuses
                    creditId={credit.id}
                    file={file}
                    document={document}
                    setDocument={setDocument}
                    creditContractDoctype={creditContractDoctype}
                  />
                ) : null}
              </div>
            </div>
          );
        })}

        {editable ? (
          <div className={clsx(cls.fileBox, cls.fileBoxUpload)}>
            <Dragger
              maxCount={10}
              multiple
              listType="picture-card"
              showUploadList={false}
              beforeUpload={isCreditContract
                ? beforeUploadContracts
                : type
                  ? beforeUploadType(type, 1)
                  : beforeUpload
              }
              customRequest={async ({ onSuccess }) => setTimeout(() => {
                onSuccess && onSuccess('ok');
              }, 0)}
              onChange={({ fileList }) => onUpload(fileList)}
              onPreview={() => {}}
              className={cls.upload}
            >
              <div className={cls.uploadText}>+</div>
            </Dragger>
          </div>
        ) : null}
      </div>
    </>
  );
};
