import { Col, Row } from 'antd';
import { useWatch } from 'react-hook-form';

import {
  Container,
  InitialCard,
  InitialRow,
  ManyCheckboxInputStyled,
  PasswordInput,
  PhoneMaskedInput,
  SwitchInput,
  TextInput,
} from '@components';
import { dateFormatter, inputParser, rbac } from '@utils';
import { Admin } from '@types';
import cls from './AdminForm.module.css';

export const validate = (values: any) => {
  const reqList = ['username', 'name', 'email'];
  if (!values.id) {
    reqList.push('password');
    reqList.push('confirmPassword');
  }
  const errors: any = {};
  reqList.forEach((key: string) => {
    if (!values[key]) {
      errors[key] = 'This field is required';
    }
  });
  if ((values.id && values['password'] && values['confirmPassword'] !== values['password'])
    || (!values.id && values['password'] !== values['confirmPassword'])) {
    errors['confirmPassword'] = 'Passwords are not match';
  }
  if (!values['roleIds']?.length) {
    errors['roleIds'] = 'This field is required';
  }

  return errors;
};

export const AdminForm = ({ record }: {record?: Admin}) => {
  const createdAt = useWatch({ name: 'createdAt' });
  const labelSize = 7;
  const labelSizeLg = 10;

  const isSuperadmin = rbac.isSuperadmin();
  const showRemove = record?.isRemoved && isSuperadmin;

  return (
    <Container max>
      <Row gutter={[24, 24]}>
        <Col xs={24} xl={12}>
          <InitialCard left="Identity" extraPadding>
            <InitialRow title="Username*" fontLg labelSize={labelSize}>
              <TextInput source="username"
                placeholder="Username"
                autoFocus
                inputProps={{ maxLength: 30 }}
                parse={inputParser.onlyEngLetters} />
            </InitialRow>
            <InitialRow title="Name*" fontLg labelSize={labelSize}>
              <TextInput source="name"
                placeholder="Name"
                inputProps={{ maxLength: 30 }}
                parse={inputParser.lettersAndSpace} />
            </InitialRow>
            <InitialRow title="Phone" fontLg labelSize={labelSize}>
              <PhoneMaskedInput source="phone" placeholder="Phone" />
            </InitialRow>
            <InitialRow title="Email*" fontLg labelSize={labelSize}>
              <TextInput name="email" source="email" type="email" />
            </InitialRow>
            <Row gutter={[35, 10]}>
              <Col sm={12}>
                <SwitchInput name="isEnabled" source="isEnabled" label="Is Enabled"
                  className={cls.switcher} defaultValue={true} />
              </Col>
              <Col sm={12}>
                <SwitchInput name="isSuperadmin" source="isSuperadmin" label="Is Superadmin"
                  className={cls.switcher} defaultValue={false} disabled={!isSuperadmin} />
              </Col>
            </Row>
            {showRemove ? (
              <Row gutter={[35, 0]}>
                <Col sm={12}>
                  <SwitchInput name="isRemoved" source="isRemoved" label="Is Removed"
                    className={cls.switcher} />
                </Col>
              </Row>
            ) : null}
          </InitialCard>
        </Col>
        <Col xs={24} xl={12}>
          <InitialCard left="Change Password" extraPadding contentClass={cls.passwordCard}>
            <div className={cls.password}>
              <InitialRow title={`Password${createdAt ? '' : '*'}`} fontLg labelSize={labelSizeLg}>
                <PasswordInput source="password" required={!createdAt} />
              </InitialRow>
              <InitialRow title={`Confirm Password${createdAt ? '' : '*'}`} fontLg labelSize={labelSizeLg}>
                <PasswordInput source="confirmPassword" required={!createdAt} />
              </InitialRow>
            </div>
            {createdAt ? (
              <InitialRow title="Created at" fontLg labelSize={labelSize}>
                {dateFormatter.toDateSlash(createdAt)} {dateFormatter.toTime(createdAt)}
              </InitialRow>
            ) : null}
          </InitialCard>
        </Col>
        <Col xs={24}>
          <InitialCard left="Roles" extraPadding>
            <ManyCheckboxInputStyled source="roleIds" reference="role" label="" />
          </InitialCard>
        </Col>
      </Row>
    </Container>
  );
};
