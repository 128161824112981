import {
  DateField,
  Forbidden,
  Grid,
  GridList,
  MoneyField,
  TextInput,
} from '@components';
import { ListProps, TextField } from 'react-admin';
import { useExporter, useRbacEdit, useRbacList } from '@hooks';
import { PaymentScheduleActions } from './PaymentScheduleActions';

export const PaymentSchedule = (props: ListProps) => {
  const rbacList = useRbacList();
  const rbacEdit = useRbacEdit();
  const exporter = useExporter({
    filename: 'Customer Data list',
    props: {
      'creditCode': {
        label: 'App ID',
      },
      'paymentAt': {
        label: 'Payment Date',
        transform: 'date',
      },
      'statementAt': {
        label: 'Statement Date',
        transform: 'dateAndEmpty',
      },
      'minPaymentAmount': {
        label: 'Minimum Payment',
        transform: 'moneyAndEmpty',
      },
      'totalPaymentAmount': {
        label: 'Total Payment',
        transform: 'money',
      },
      'paymentCurrency': {
        label: 'Payment Currency',
      },
      'principalPayment': {
        label: 'Principal Payment',
        transform: 'money',
      },
      'interestPayment': {
        label: 'Interest Payment',
        transform: 'money',
      },
      'feePayment': {
        label: 'Fee Payment',
        transform: 'money',
      },
      'otherPayment': {
        label: 'Other Payment',
        transform: 'moneyAndEmpty',
      },
      'taxPayment': {
        label: 'Tax Payment',
        transform: 'money',
      },
      'allRebates': {
        label: 'All Rebates',
        transform: 'moneyAndEmpty',
      },
      'outstandingLoanValue': {
        label: 'Outstanding Loan Value',
        transform: 'money',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList
      {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      hideRemoveButton
      exporter={exporter}
      editComponent={rbacEdit ? <PaymentScheduleActions /> : undefined}
    >
      <Grid
        rowClick={false}
        defaultColumns={'creditCode paymentAt statementAt minPaymentAmount totalPaymentAmount paymentCurrency'.split(' ')}
      >
        <TextField source="creditCode" label="App ID" />
        <DateField source="paymentAt" label="Payment Date" />
        <DateField source="statementAt" label="Statement Date" emptyText="N/A" />
        <MoneyField source="minPaymentAmount" label="Minimum Payment" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="totalPaymentAmount" label="Total Payment" style={{ display: 'inline-block', width: 100 }} />
        <TextField source="paymentCurrency" label="Payment Currency" />
        <MoneyField source="principalPayment" label="Principal Payment" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="interestPayment" label="Interest Payment" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="feePayment" label="Fee Payment" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="otherPayment" label="Other Payment" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="taxPayment" label="Tax Payment" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="allRebates" label="All Rebates" style={{ display: 'inline-block', width: 100 }} />
        <MoneyField source="outstandingLoanValue" label="Outstanding Loan Value" style={{ display: 'inline-block', width: 100 }} />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" label="Search" alwaysOn style={{ width: 200 }} />,
];
