import { FunctionField, ListProps, TextField } from 'react-admin';
import React from 'react';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden, DateField, MoneyField, SelectArrayInput, DateRangeInput,
} from '@components';
import { Contract, contractStatusOptions, Credit, Guarantor } from '@types';
import { useExporter, useRbacList } from '@hooks';
import cls from './Contracts.module.css';
import { formatHoursToPhrase } from '@utils';
import { ContractStatus } from '@pages/contracts/Components/ContractStatus';
import { CreditStatusField, creditStatusOptions } from '@pages/credit/CreditStatusField';

export const ContractsList = (props: ListProps) => {
  const rbacList = useRbacList();

  const exporter = useExporter({
    filename: 'Verqor Farmers',
    props: {
      'id': {
        label: 'ID',
      },
      'status': {
        label: 'Contract Status',
      },
      'creditCode': {
        label: 'Credit Code',
      },
      'creditStatus': {
        label: 'Credit Status',
      },
      'accredited': {
        label: 'Accredited',
      },
      'signatureDate': {
        label: 'Signature Date',
        transform: 'date',
      },
      'creditAmount': {
        label: 'Credit Amount',
        transform: 'money',
      },
      'paymentAmount': {
        label: 'Payment Amount',
        transform: 'money',
      },
      'paymentDate': {
        label: 'Payment Date',
        transform: 'date',
      },
      'rfc': {
        label: 'RFC',
      },
      'guarantors': {
        label: 'Guarantors',
        transform: (guarantors: Guarantor[]) => {
          return guarantors.map(g => g.fullName).filter(Boolean).join(', ');
        },
      },
    } });

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList {...props}
      filters={Filters}
      sort={{ field: 'id', order: 'DESC' }}
      hideRemoveButton
      exporter={exporter}
      className={cls.gridList}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'id creditCode accredited rfc guarantors'.split(' ')}
      >
        <TextField source="id" />
        <FunctionField
          source="status"
          label="Contract Status"
          render={(record: Contract) => (
            <ContractStatus status={record.status} className={cls.contractStatus} />
          )}
        />
        <TextField source="creditCode" />
        <CreditStatusField source="creditStatus" />
        <TextField source="accredited" />
        <TextField source="rfc" label="RFC" />
        <DateField source="signatureDate" label="Signature Date" />
        <MoneyField source="creditAmount" label="Credit Amount" />
        <MoneyField source="paymentAmount" label="Payment Amount" />
        <DateField source="paymentDate" label="Payment Date" />
        <FunctionField resource="contract" source="guarantors" label="Guarantors"
          render={(record: any) => record.guarantors.length > 0 ? (
            record.guarantors.map((g: Guarantor) => g.fullName).join(', ')
          ) : ''}
        />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
  <SelectArrayInput
    key="status"
    source="status"
    label="Contract Status"
    choices={contractStatusOptions}
    className="MS-field"
    style={{ width: 250 }}
    alwaysOn
  />,
  <DateRangeInput
    key="signatureDate"
    source="signatureDate"
    label="Signature Date"
    defaultValue={false}
    alwaysOn
  />,
  <SelectArrayInput
    key="creditStatus"
    source="creditStatus"
    choices={creditStatusOptions}
    className="MS-field"
    style={{ width: 250 }}
    alwaysOn
  />,
];
