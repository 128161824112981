import { Dealer, DealerPartial, DealerType } from '@types';
import { Spin } from 'antd';
import { SaveFilled } from '@ant-design/icons';
import { clsx } from 'clsx';
import { useLayoutEffect, useState } from 'react';
import { useNotify, useRefresh, useResourceContext } from 'react-admin';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

import { dealerHttp } from '@network';
import { axiosErrorToString } from '@utils';
import { BuyerForm } from '../BuyerForm';
import { MergeForm } from '../MergeDealersForm';
import cls from './buyer-creator.module.css';

const PHONE_MASK = '+52 (___) ___-____';

interface Props {
  type: 'create' | 'edit';
  close: () => void;
  values: Dealer | null;
}

const initialFormValues: DealerPartial = {
  name: '',
  phone: '',
  email: '',
  image: '',
  tier: 'C',
  cropNames: [],
  activationId: null,
  ownerId: null,
};

export const BuyerCreator = ({ type, close, values }: Props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<Dealer | DealerPartial>(values || initialFormValues);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const dealerType = resource === 'dealer' ? DealerType.BUYER
    : resource === 'dealer-retailer' ? DealerType.RETAILER : DealerType.ASSOCIATE;
  const dealerTitle = dealerType.slice(0, 1).toUpperCase() + dealerType.slice(1);

  const submit = async () => {
    if ((formValues.name || '').length === 0) {
      setShowErrors(true);
      return;
    }

    const params: DealerPartial = {
      name: formValues.name || '',
      phone: formValues.phone === PHONE_MASK ? '' : formValues.phone,
      image: formValues.image || '',
      email: formValues.email || '',
      tier: formValues.tier || 'C',
      cropNames: formValues.cropNames || [],
      activationId: formValues.activationId || null,
      priority: formValues.priority || null,
      segment: formValues.segment || null,
      status: formValues.status || null,
      ownerId: formValues.ownerId || null,
      website: formValues.website || null,
      state: formValues.state || null,
      size: formValues.size || null,
      farmerCount: formValues.farmerCount || null,
      closeDate: formValues.closeDate || null,
      source: formValues.source || null,
      ibm: formValues.ibm || null,
    };

    try {
      setIsLoading(true);
      await dealerHttp.create(params, resource);

      refresh();
      closeHandler();
    } catch (e) {
      notify(axiosErrorToString(e));
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (): Promise<void> => {
    if (!('id' in formValues)) {
      setShowErrors(true);

      return;
    }

    if (formValues.name?.length === 0) {
      setShowErrors(true);

      return;
    }

    const params: Dealer = {
      ...formValues,
      phone: formValues.phone === PHONE_MASK ? '' : formValues.phone,
    };

    try {
      setIsLoading(true);
      await dealerHttp.update(params, resource);

      refresh();
      closeHandler();
    } catch (e) {
      notify(axiosErrorToString(e));
    } finally {
      setIsLoading(false);
    }
  };

  const remove = async () => {
    if (!('id' in formValues)) {
      setShowErrors(true);

      return;
    }

    try {
      setIsLoading(true);
      await dealerHttp.remove(formValues.id, resource);

      refresh();
      closeHandler();
    } catch (e) {
      notify(axiosErrorToString(e));
    } finally {
      setIsLoading(false);
    }
  };

  const closeHandler = () => {
    setFormValues(initialFormValues);
    close();
  };

  useLayoutEffect(() => {
    document.body.className = 'noscroll';

    return () => {
      document.body.className = '';
    };
  }, []);

  return (
    <div className={cls.block}>
      <div className={cls.wrap}>
        <div className={cls.close}>
          <div className={cls.closeIcon} onClick={closeHandler}>
            <svg xmlns="http://www.w3.org/2000/svg"
              style={{ display: 'inline-block', marginTop: -10, marginRight: -40 }}
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none">
              <path
                d="M17.5039 0C7.83826 0 0 7.83826 0 17.5039C0 27.1695 7.83826 35.0078 17.5039 35.0078C27.1695 35.0078 35.0078 27.1695 35.0078 17.5039C34.9922 7.83826 27.1617 0.00777605 17.5039 0Z"
                fill="#002A77" />
              <path
                d="M24.0903 10.9101C23.787 10.6068 23.2971 10.6068 22.9938 10.9101L17.5039 16.4L12.0141 10.9101C11.7108 10.6068 11.2209 10.6068 10.9176 10.9101C10.6144 11.2133 10.6144 11.7032 10.9176 12.0065L16.4075 17.4964L10.9176 22.9863C10.6144 23.2895 10.6144 23.7794 10.9176 24.0827C11.2209 24.386 11.7108 24.386 12.0141 24.0827L17.5039 18.5928L22.9938 24.0827C23.2971 24.386 23.787 24.386 24.0903 24.0827C24.3935 23.7794 24.3935 23.2895 24.0903 22.9863L18.6004 17.4964L24.0903 12.0065C24.3935 11.7032 24.3935 11.2133 24.0903 10.9101Z"
                fill="#FFFFFF" />
            </svg>
          </div>
        </div>

        <div className={cls.title}>
          {type === 'create' && `Create a new ${dealerTitle}`}
          {type === 'edit' && dealerTitle}
        </div>

        <BuyerForm
          formValues={formValues}
          setFormValues={setFormValues}
          showErrors={showErrors}
          dealerType={dealerType}
        />

        {type === 'create' && (
          <div className={cls.actions}>
            <Button className={cls.submit}
              variant="contained"
              onClick={submit}
              disabled={isLoading}>
              {isLoading && <Spin />}
              Add {dealerTitle}
            </Button>
          </div>
        )}

        {type === 'edit' && (
          <div className={cls.actions}>
            <div className={clsx(cls.actionsRow, cls.actionsTop)}>
              <Button
                className={cls.update}
                variant="contained"
                onClick={update}
                disabled={isLoading}
              >
                {isLoading
                  ? <Spin className={cls.actionIcon} />
                  : <SaveFilled className={cls.actionIcon} />} Save
              </Button>

              {values
                ? <MergeForm dealer={values} isBig btnClass={cls.mergeBtn} closeSidebar={close} />
                : null}
            </div>

            <div className={clsx(cls.actionsRow, cls.actionsBottom)}>
              <Button className={cls.delete}
                variant="contained"
                onClick={() => setIsDialogOpen(true)}
                disabled={isLoading}>
                Delete
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className={cls.overlay} onClick={closeHandler} />

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={remove} autoFocus>Yes</Button>
          <Button onClick={() => setIsDialogOpen(false)}>No</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
