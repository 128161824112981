import React, { useEffect, useRef, useState } from 'react';
import { Skeleton } from 'antd';
import IconButton from '@mui/material/IconButton';

import { http } from '@network';
import { ImageZoom } from '@components';
import { LoadingOutlined } from '@ant-design/icons';
import cls from './file-image.module.css';

type Props = {
  url: string;
  className?: any;
  width?: number;
  height?: number;
  zoom?: boolean;
  downloadFilename?: string;
  disabled?: boolean;
  showLoading?: boolean;
  previewBase64?: string | null;
}

export const FileImage = ({
  url, width, height, className, downloadFilename, zoom, disabled, showLoading, previewBase64,
}: Props) => {
  const isMounted = useRef<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [src, setSrc] = useState<string>();

  const loadSrc = async () => {
    if (src || loading) return;

    isMounted.current = true;
    setLoading(true);

    try {
      const res = await http.get<Buffer>(url, { responseType: 'arraybuffer' });
      const imageSrc = imageBufferToBase64(res.data);
      isMounted.current && setSrc(imageSrc);
      return imageSrc;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (previewBase64) return;

    loadSrc();

    return () => {
      isMounted.current = false;
    };
  }, [url]);

  if (!showLoading && (!src || loading)) {
    return (
      <div className={cls.skeleton}>
        <Skeleton.Image style={{ width, height }} />
      </div>
    );
  }

  if (showLoading && (!src || loading) && !previewBase64) {
    return (
      <div className={cls.skeleton}>
        <LoadingOutlined className={cls.iconLoading} />
      </div>
    );
  }

  const downloadImage = async () => {
    if (!downloadFilename) return;
    const imgHref = await loadSrc() as string;
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = imgHref;
      link.download = downloadFilename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      previewImage(imgHref, downloadFilename);
    }
  };

  return (
    <div className={cls.box}>
      {zoom && !disabled ? (
        <ImageZoom onZoomClick={previewBase64 ? loadSrc : undefined}>
          <img src={src || previewBase64 || undefined} alt="..." className={className} />
        </ImageZoom>
      ) : (
        <img src={previewBase64 || src} alt="..." className={className} />
      )}
      {downloadFilename && !disabled && (
        <div
          className={cls.download}
          onClick={e => {
            e.stopPropagation();
            downloadImage();
          }}
          title="download"
        >
          <DownloadCenterIcon />
        </div>
      )}
    </div>
  );
};

export function imageBufferToBase64(arrayBuffer: Buffer): string {
  // @ts-ignore
  const b64encoded = btoa([].reduce.call(
    new Uint8Array(arrayBuffer),
    (p: any, c: any) => p + String.fromCharCode(c),
    '',
  ));
  const mimetype = 'image/jpeg';

  return `data:${mimetype};base64,${b64encoded}`;
}

const DownloadCenterIcon = () => (
  <IconButton className={cls.downloadCenterIcon} title="Download">
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="32" viewBox="0 0 29 32" fill="none">
      <g clipPath="url(#clip0_4627_1980)">
        <path d="M23.0809 5.21288H28.8444C28.7721 5.09555 28.6759 4.98955 28.5615 4.89955L22.6558 0.306217C22.4961 0.180884 22.3031 0.0895508 22.0925 0.0395508L22.1942 4.47688C22.203 4.88355 22.5963 5.21022 23.0809 5.21288Z"
          fill="#042E6B" />
        <path d="M23.0808 6.316C21.8827 6.31 20.9078 5.50533 20.88 4.50067L20.7767 0H2.958C1.32208 0.004 -0.00158761 1.11867 1.42921e-06 2.49133V29.5087C-0.00158761 30.8813 1.32208 31.996 2.958 32H26.042C27.6779 31.996 29.0016 30.8813 29 29.5087V6.316H23.0808Z"
          fill="#042E6B" />
        <path d="M19.8035 19.5138L16.3227 22.4258C15.9302 22.7538 15.4098 22.9505 14.8568 22.9798C14.8083 22.9851 14.7599 22.9878 14.7114 22.9885C14.6693 22.9905 14.6272 22.9905 14.5851 22.9885C14.0313 22.9678 13.5061 22.7785 13.1065 22.4565L9.56766 19.8018C9.07108 19.4311 9.02659 18.7931 9.46834 18.3765C9.9101 17.9598 10.6705 17.9225 11.167 18.2931L13.5212 20.0498V11.6698C13.4982 11.1125 14.0178 10.6445 14.6828 10.6251C15.347 10.6058 15.9048 11.0418 15.9278 11.5998C15.9286 11.6231 15.9286 11.6465 15.9278 11.6698V19.9105L18.1088 18.0805C18.5807 17.6878 19.3426 17.6898 19.8106 18.0858C20.2786 18.4818 20.2754 19.1211 19.8043 19.5138H19.8035Z"
          fill="#E1E5ED" />
      </g>
      <defs>
        <clipPath id="clip0_4627_1980">
          <rect width="29" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </IconButton>
);

const previewImage = (source: string, downloadFilename?: string) => {
  setTimeout(() => {
    const image = new Image();
    image.src = source;
    const win = window.open('', '_blank', 'popup') as any;
    win.document.write(image.outerHTML);
    win.document.title = downloadFilename || 'Document preview';
  }, 0);
};
