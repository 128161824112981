import { ReactNode } from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { clsx } from 'clsx';
import { LightTooltip } from '@components';
import { IProfileProgress } from '@network';
import cls from './ProfileProgress.module.css';

interface Props {
  profileProgress: IProfileProgress | null;
}

const Icon = ({ value }: { value: boolean }) => value
  ? <CheckCircleFilled className={clsx(cls.verificationIconValid, cls.icon)}/>
  : <CloseCircleFilled className={clsx(cls.verificationIconInvalid, cls.icon)}/>;

export const ProfileProgress = ({ profileProgress }: Props) => {
  const isValid = profileProgress?.profileProgress === 'complete';

  const tooltipText = (): ReactNode => (
    <div className={cls.ttWrap}>
      {isValid
        ? <div className={cls.ttTitle}>All fields are completed</div>
        : profileProgress && profileProgress.missingFields.length ? (
          <>
            <div className={cls.ttTitle}>Empty fields:</div>
            <ul className={cls.ttList}>
              {profileProgress.missingFields.map(i => (
                <li key={i} className={cls.ttItem}>{i}</li>
              ))}
            </ul>
          </>
        ) : null}
    </div>
  );

  return (
    <LightTooltip placement="top" title={tooltipText()} className={cls.tooltip}>
      <div className={clsx(cls.wrap, isValid ? cls.wrapValid : cls.wrapInvalid)}>
        <div className={cls.title}>Profile Progress</div>
        <div className={cls.row}>
          <div className={clsx(cls.textCol, cls.col)}><Icon value={isValid} /></div>
          <div className={clsx(cls.textCol, cls.col)}>{isValid ? 'Completed' : 'Incomplete'}</div>
          <div className={clsx(cls.qtyCol, cls.col)}>
            {isValid ? '' : `(${profileProgress?.missingFields.length})`}
          </div>
        </div>
      </div>
    </LightTooltip>
  );
};
