import { clsx } from 'clsx';
import { Select } from 'antd';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';

import { InitialPage } from '@components';
import { Credit, OrderCompositionProduct } from '@types';
import { CreateOrderParams, creditHttp, ordersHttp } from '@network';
import { OrderProducts } from '@pages/order/OrderCreate/OrderProducts';
import cls from './order-create.module.css';
import { axiosErrorToString } from '@utils';

export const OrderCreate = () => {
  const redirect = useRedirect();
  const notify = useNotify();

  const [composition, setComposition] = useState<OrderCompositionProduct[]>([]);
  const [_, setInvoiceTotal] = useState<number>(0);
  const [usersList, setUsersList] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [credits, setCredits] = useState<Credit[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedCreditId, setSelectedCreditId] = useState<number | null>(null);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const onUserSelect = (userId: number) => {
    setSelectedUserId(userId);
    const userCredits = usersList.find(user => user.userId === userId)?.credits || [];

    setSelectedCreditId(null);
    setCredits(userCredits.map((credit: any) => ({
      value: credit.creditId,
      label: credit.code,
    })));
  };

  const onCreditSelect = (creditId: number) => {
    setSelectedCreditId(creditId);
  };

  const submit = () => {
    const selectedComposition = composition.filter(c => c.productId);
    if (!selectedUserId || !selectedCreditId) {
      setShowErrors(true);

      return;
    }

    if (selectedComposition.length === 0) {
      notify('Add at least one product', { type: 'error' });

      return;
    }

    const params: CreateOrderParams = {
      userId: selectedUserId,
      creditId: selectedCreditId,
      composition: selectedComposition,
    };

    (async () => {
      try {
        await ordersHttp.createOrder(params);
        redirect('/order');
      } catch (e) {
        console.error(e);
      }
    })();

    setShowErrors(true);
  };

  useEffect(() => {
    (async () => {
      try {
        const loadedUsers = await creditHttp.getAllUsers();
        const sortedUsers = loadedUsers.sort((a, b) => a.fullName?.localeCompare(b.fullName));
        setUsersList(sortedUsers);
        setUsers(sortedUsers.map(u => ({ value: u.userId, label: u.fullName })));
      } catch (e) {
        notify(axiosErrorToString(e), { type: 'error' });
      }
    })();
  }, []);

  const backTo = '/order';

  const showUserError = !selectedUserId && showErrors;
  const showCreditError = !selectedCreditId && showErrors;

  return (
    <InitialPage title="Create order" backTo={backTo} backText="Back to all order">
      <div className={cls._}>
        <div className={cls.head}>
          <div className={cls.avatar}>
            <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none">
              <path d="M44 0C19.7057 0 0 19.7057 0 44C0 68.2943 19.7057 88 44 88C68.2943 88 88 68.2943 88 44C88 19.7057 68.2943 0 44 0ZM44 22C53.9 22 61.9457 30.0457 61.9457 39.9457C61.9457 49.8457 53.9 57.8914 44 57.8914C34.1 57.8914 26.0543 49.8457 26.0543 39.9457C26.0543 30.0457 34.1 22 44 22ZM44 82.3429C35.0743 82.3429 26.84 79.2629 20.3029 74.1086C22.6286 70.7771 26.4629 68.7657 30.5486 68.7657H57.4514C61.5371 68.7657 65.3714 70.7771 67.6971 74.1086C61.16 79.2629 52.9257 82.3429 44 82.3429Z" fill="#042E6B"/>
            </svg>
          </div>
          <div className={cls.info}>
            <div className={cls.infoRow}>
              <div className={cls.label}>User</div>
              <div className={cls.value}>
                <Select
                  onChange={(userId) => onUserSelect(userId)}
                  value={selectedUserId}
                  className={clsx(cls.select, showUserError && cls.error)}
                  showSearch
                  placeholder="Select user"
                  options={users}
                />
              </div>
            </div>
            <div className={cls.infoRow}>
              <div className={cls.label}>Credit</div>
              <div className={cls.value}>
                <Select
                  onChange={(creditId) => onCreditSelect(creditId)}
                  value={selectedCreditId}
                  className={clsx(cls.select, cls.selectBold, showCreditError && cls.error)}
                  showSearch
                  placeholder="Select credit"
                  options={credits}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={cls.products}>
          <div className={cls.title}>
            Positions
          </div>

          <OrderProducts
            composition={composition}
            setComposition={setComposition}
            setInvoiceTotal={setInvoiceTotal}
          />
        </div>

        <div className={cls.actions}>
          <Button className={cls.save} variant="contained" onClick={submit}>Save</Button>
        </div>
      </div>
    </InitialPage>
  );
};
