import { Http } from './http';
import {
  CreditTransaction,
  Order,
  OrderTransaction,
  OrderTransactionPartner,
  OrderTransactionType,
} from '@types';

class OrdersHttp extends Http {
  prefix = 'order';

  async exportPositions(queryParams: any): Promise<ExportPosition[]> {
    const res = await this.get<ExportPosition[]>('/export-positions', {
      params: queryParams,
    });

    return res.data;
  }

  async exportPositionsByCredit(queryParams: any): Promise<ExportPositionByCredit[]> {
    const res = await this.get<ExportPositionByCredit[]>('/export-positions-by-credit', {
      params: queryParams,
    });

    return res.data;
  }

  async getOrdersByUser(userId: number): Promise<Order[]> {
    const res = await this.get<Order[]>(`?userId=${userId}`);

    return res.data;
  }

  async payOperation(params: PayOperationParams): Promise<OrderTransaction> {
    const { orderId, partner, amount } = params;
    const url = `/${orderId}/pay-operation`;
    const res = await this.post<CreditTransaction>(url, { partner, amount });

    return res.data;
  }

  async cancelOrder(orderId: number): Promise<Order> {
    const url = `/${orderId}/cancel`;
    const res = await this.delete<Order>(url);

    return res.data;
  }

  async rollbackOrder(orderId: number): Promise<Order> {
    const url = `/${orderId}/rollback`;
    const res = await this.delete<Order>(url);

    return res.data;
  }

  async removeOrders(orderIds: number[]): Promise<void> {
    const url = '/remove';
    await this.post<RemoveOrdersByIdsParams>(url, { orderIds });

    return;
  }

  async createOrder(params: CreateOrderParams): Promise<void> {
    const url = '';
    await this.post(url, params);

    return;
  }

  async updateOrder(params: UpdateOrderParams): Promise<void> {
    const url = `/${params.orderId}`;
    await this.put(url, params);

    return;
  }
}

export const ordersHttp = new OrdersHttp();

interface PayOperationParams {
  orderId: number;
  partner: OrderTransactionPartner;
  amount: string;
}

interface RemoveOrdersByIdsParams {
  orderIds: number[];
}

export interface ExportPosition {
  product: string;
  brand: string | null;
  category: string | null;
  subcategory: string | null;
  purchasedQuantity: bigint;
  totalExpend: bigint;
  averageUnitPrice: bigint;
}

export interface ExportPositionsResponse {
  positions: {
    name: string;
    qty: number;
    cost: number;
    price: number;
  }[],
  totalCost: number;
}

export interface ExportPositionByCredit {
  credit: string;
  accredited: string;
  buyer: string | null;
  product: string;
  brand: string | null;
  category: string | null;
  subcategory: string | null;
  purchasedQuantity: bigint;
  totalExpend: bigint;
  averageUnitPrice: bigint;
}

export interface CreateOrderParams {
  composition: any[];
  userId: number;
  creditId: number;
}

export interface UpdateOrderParams {
  orderId: number;
  composition: any[];
  userId: number;
  creditId?: number;
  requestHours?: number;
}
