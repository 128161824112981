import { makeStyles } from '@mui/styles';

import { Credit } from '@types';
import { InitialCard, InitialRow } from '@components';
import { Button } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { Link } from 'react-admin';

export const CreditUser = ({ credit }: {credit: Credit}) => {
  const user = credit.user;
  const locations = user.location;
  const [firstLocation] = locations || [];
  const cls = useStyles();

  return (
    <InitialCard left="User" autoHeight extraPadding>
      <div className={cls.box}>
        <InitialRow title="Accredited:">
          {credit.accreditedName}
          <Link to={`/user/${user.id}`}>
            <Button
              icon={<LoginOutlined />}
              type="ghost"
              className={cls.btn}
              title="Go to Users"
            />
          </Link>
        </InitialRow>
        <InitialRow title="Phone:">{user.phone}</InitialRow>
        <InitialRow title="State:">{user.state?.name || '–'}</InitialRow>
        <InitialRow title="City:">{firstLocation?.city || '–'}</InitialRow>
        <InitialRow title="CIEC:">{user.ciec || '–'}</InitialRow>
        <InitialRow title="Responsible Manager:">{user.responsibleAdmin?.name || '–'}</InitialRow>
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    marginTop: 15,
    fontFamily: 'Outfit, sans-serif',
    color: '#272727',
  },
  btn: {
    padding: '0',
    width: 32,
    height: 32,
    marginLeft: 12,
  },
});
