import { Http } from './http';
import { CreditRisk, IFinancialTable } from '@types';
import { RevenueDataProps } from '@pages/credit-risk/analysis/components/RevenueTable';
import { CreditBureauData } from '@pages/credit-risk/analysis/components/CreditBureau';
import { TaxCropDataProps } from '@pages/credit-risk/analysis/components/TaxCropTable';
import { ScoreTableData } from '@pages/credit-risk/analysis/components/ScoreTable';
import { DropdownInfoDataProps } from '@pages/credit-risk/analysis/components/DropdownInfoWidget';
import { TotalVariablesProps } from '@pages/credit-risk/analysis/components/TotalVariablesTable';

class RiskDataHttp extends Http {
  prefix = 'risk-data';

  async getRiskDataQuestions(): Promise<string[]> {
    const res = await this.get<string[]>('/questions');

    return res.data;
  }
}

export const riskDataHttp = new RiskDataHttp();
