import { Http } from './http';
import { CreditCrop, Crop, CxCredit, State, CxSet, CxReceipt, CreditFarm } from '@types';

class CxHttp extends Http {
  prefix = 'cx';

  async export(queryParams: any): Promise<CxCredit[]> {
    const res = await this.get<CxCredit[]>('/export', {
      params: queryParams,
    });

    return res.data;
  }

  async downloadInsurances(creditId: number, creditCode: string) {
    await this.downloadZip(
      `/${creditId}/download-insurances`,
      `Policy Insurances of Credit ${creditCode}.zip`,
    );
  }

  async getOptions(): Promise<CxOptions> {
    const res = await this.get<CxOptions>('/options');

    return res.data;
  }

  async getInsurances(creditId: number): Promise<CreditCrop[]> {
    const res = await this.get<CreditCrop[]>(`/${creditId}/insurances`);

    return res.data;
  }

  async getCxCredit(creditId: number): Promise<CxCredit> {
    const res = await this.get<CxCredit>(`/${creditId}`);

    return res.data;
  }

  async updateInsurances(creditId: number, insurances: CreditCrop[]): Promise<CreditCrop[]> {
    const res = await this.put<CreditCrop[]>(`/${creditId}/insurances`, { insurances });

    return res.data;
  }

  async partSetsUpdate(id: number, params: any): Promise<CxSet[]> {
    const res = await this.put<CxSet[]>(`/${id}/sets`, params);

    return res.data;
  }

  async generateReceipt(id: number) {
    const res = await this.post(`/${id}/generate-receipt`);

    return res.data;
  }

  async getLastReceipt(id: number): Promise<CxReceipt> {
    const res = await this.get<CxReceipt>(`/${id}/get-last-receipt`);

    return res.data;
  }

  async downloadReceipts(creditId: number, creditCode: string) {
    await this.downloadZip(
      `/${creditId}/download-receipts`,
      `Credit ${creditCode} receipts.zip`,
    );
  }

  async getLastReceiptPreview(url: string) {
    const res = await this.get(url);

    return res.data;
  }

  async updateFieldReport(creditId: number, farms: CreditFarm[]) {
    const res = await this.put('/update-field-report', { creditId, farms });

    return res.data;
  }
}

export const cxHttp = new CxHttp();

export interface CxOptions {
  crops: Crop[];
  states: State[];
}
