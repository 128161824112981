import { clsx } from 'clsx';
import { Skeleton } from 'antd';
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';

import { http } from '@network';
import cls from './file-image.module.css';

type Props = {
  url: string;
  downloadFilename?: string;
  className?: string;
  hidePreview?: boolean;
  isRestricted?: boolean;
}

const downloadImage = async (url: string) => {
  const res = await http.get<Buffer>(url, { responseType: 'arraybuffer' });
  return imageBufferToBase64(res.data);
};

const previewImage = (source: string, downloadFilename?: string) => {
  setTimeout(() => {
    const image = new Image();
    image.src = source;
    const win = window.open('', '_blank', 'popup') as any;
    win.document.write(image.outerHTML);
    win.document.title = downloadFilename || 'Document preview';
  }, 0);
};

export const FileImage = ({
  url, downloadFilename, className, hidePreview, isRestricted,
}: Props) => {
  const [src, setSrc] = useState<string>();

  const onPreview = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    let source = src;
    if (!source) {
      source = await downloadImage(url);
      setSrc(source);
    }

    previewImage(source, downloadFilename);
  };

  const onDownload = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (isRestricted) return;

    let source = src;
    if (!source) {
      source = await downloadImage(url);
      setSrc(source);
    }

    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = source;
      link.download = downloadFilename || 'Document.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      previewImage(source);
    }
  };

  return (
    <div className={clsx(cls.box, className, 'file-image-box')} onClick={onPreview} title="preview">
      {!hidePreview && (
        <div className={clsx(cls.preview, 'file-image-preview')}>
          {src ? (
            <img src={src} alt="document" className="file-image-img" />
          ) : (
            <Skeleton.Image style={{ width: 30, height: 20 }} className="file-image-img-default" />
          )}
        </div>
      )}

      {downloadFilename && !isRestricted ? (
        <div className={clsx(cls.download, 'file-image-download')}
          title="download" data-x="download">
          <IconButton onClick={onDownload}>
            <DownloadIcon />
          </IconButton>
        </div>
      ) : null}
    </div>
  );
};

function imageBufferToBase64(arrayBuffer: Buffer): string {
  // @ts-ignore
  const b64encoded = btoa([].reduce.call(
    new Uint8Array(arrayBuffer),
    (p: any, c: any) => p + String.fromCharCode(c),
    '',
  ));
  const mimetype = 'image/jpeg';

  return `data:${mimetype};base64,${b64encoded}`;
}

export const DownloadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="32" viewBox="0 0 29 32" fill="none">
    <g clipPath="url(#clip0_3168_6273)">
      <path d="M23.0807 5.2124H28.8441C28.7718 5.09506 28.6757 4.98906 28.5613 4.89906L22.6556 0.305729C22.4959 0.180396 22.3028 0.0890625 22.0923 0.0390625L22.194 4.4764C22.2027 4.88306 22.596 5.20973 23.0807 5.2124Z"
        fill="#042E6B" />
      <path d="M23.0808 6.316C21.8827 6.31 20.9078 5.50533 20.88 4.50067L20.7767 0H2.958C1.32208 0.004 -0.00158761 1.11867 1.42921e-06 2.49133V29.5087C-0.00158761 30.8813 1.32208 31.996 2.958 32H26.042C27.6779 31.996 29.0016 30.8813 29 29.5087V6.316H23.0808Z"
        fill="#042E6B" />
      <path d="M18.7409 18.6266L16.2084 21.4525C15.9228 21.7708 15.5442 21.9617 15.1418 21.9901C15.1066 21.9953 15.0713 21.9979 15.036 21.9985C15.0054 22.0005 14.9748 22.0005 14.9441 21.9985C14.5412 21.9785 14.1591 21.7948 13.8684 21.4823L11.2936 18.9061C10.9324 18.5463 10.9 17.9272 11.2214 17.5228C11.5428 17.1185 12.096 17.0823 12.4573 17.442L14.1701 19.1467V11.0144C14.1533 10.4735 14.5314 10.0194 15.0152 10.0006C15.4985 9.98184 15.9043 10.405 15.9211 10.9465C15.9216 10.9691 15.9216 10.9917 15.9211 11.0144V19.0115L17.5079 17.2356C17.8512 16.8545 18.4056 16.8565 18.7461 17.2408C19.0866 17.6251 19.0842 18.2455 18.7415 18.6266H18.7409Z"
        fill="#E1E5ED" />
    </g>
    <defs>
      <clipPath id="clip0_3168_6273">
        <rect width="29" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
