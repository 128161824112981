import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';

import { User } from '@types';
import { dateFormatter } from '@utils';
import { ProfileLink } from '@pages/user/ProfileLink';
import { InitialPage, InitialCard, InitialJson } from '@components';
import cls from '@pages/user/UserInitial/UserSalesforce/UserSalesforce.module.css';
import { useEffect, useState } from 'react';
import { salesforceHttp, SfProps } from '@network';

export const UserSalesforcePage = ({ user }: {user: User}) => {
  const cls = useStyles();
  const backTo = `/user/${user.id}?initial`;
  const [data, setData] = useState<SfProps | null>();

  useEffect(() => {
    (async () => {
      const res = await salesforceHttp.getByUser(user.id);
      if (res) {
        setData(res);
      }
    })();
  }, []);

  if (!user) {
    return null;
  }

  return (
    <InitialPage title="Salesforce Export" backTo={backTo} backText="Back to Individual User" gap={24}>
      <Row gutter={[25, 25]}>
        <Col xs={24} sm={24} md={12}>
          <InitialCard left="Profile" autoHeight extraPadding>
            <div className={cls.block}>
              <ProfileLink
                url={user.sfContactLink}
                text={user.sfContactLink ? `Link to Contact # ${user.sfContactId}` : '–'}
              />
            </div>
            {user.sfAccountLink ? <div className={cls.block}>
              <ProfileLink
                url={user.sfAccountLink}
                text={user.sfAccountLink ? 'Link to Account' : '–'}
              />
            </div> : null}
            <Row gutter={[30, 30]} className={cls.footer}>
              Salesforce exported at:
              <span className={cls.footerDate}>
                {data?.requestAt ? dateFormatter.toDateTime(data.requestAt, true) : '–'}
              </span>
            </Row>
          </InitialCard>

          <InitialJson title="Salesforce request" json={data?.requestJson} mt={24} />
        </Col>

        <Col xs={24} sm={24} md={12}>
          {data?.errorJson ? (
            <InitialJson title="Salesforce error" json={data?.errorJson} isError />
          ) : (
            <InitialJson title="Salesforce response" json={data?.responseJson} />
          )}
        </Col>
      </Row>
    </InitialPage>
  );
};

const useStyles = makeStyles({
  block: {
    marginTop: 15,
  },
  footer: {
    marginTop: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 400,
  },
  footerDate: {
    color: '#000',
    fontFamily: 'Outfit, sans-serif',
    marginLeft: 20,
  },
});
