import { makeStyles } from '@mui/styles';
import { Button, Chip } from '@mui/material';
import { Link } from 'react-admin';
import { clsx } from 'clsx';

import { Chat } from '@types';
import { LightTooltip } from '@components';
import { useSettingsContext } from '@providers';

export const ChatTeams = ({ chat, isPopup }: {chat: Chat, isPopup?: boolean}) => {
  const { admin } = useSettingsContext();
  const linkTo = admin?.isRisk && chat.riskId
    ? `/credit-risk/${chat.riskId}`
    : `/credit/${chat.creditId}`;
  const creditCode = chat.title.split(' ').pop();
  const cls = useStyles();
  const invitedTeam = chat.managers?.invited || [];

  return (
    <div className={clsx(cls.box, 'ChatTeams')}>
      {isPopup && (
        <div className={cls.titleLeft}>
          <Link to={linkTo}>
            <Button variant="text" className={cls.link}>
              Credit {creditCode}
            </Button>
          </Link>
        </div>
      )}

      {invitedTeam.length > 0 && (
        <LightTooltip title={(
          <>
            {invitedTeam.map(name => (
              <div key={name}>{name}</div>
            ))}
          </>
        )}>
          <Chip className={cls.chip} label="INVITED" variant="outlined" />
        </LightTooltip>
      )}

      <LightTooltip title={(
        <>
          {(chat.managers?.sales || []).map(name => (
            <div key={name}>{name}</div>
          ))}
        </>
      )}>
        <Chip className={cls.chip} label="SALES TEAM" variant="outlined" />
      </LightTooltip>

      <LightTooltip title={(
        <>
          {(chat.managers?.risk || []).map(name => (
            <div key={name}>{name}</div>
          ))}
        </>
      )}>
        <Chip className={cls.chip} label="RISK TEAM" variant="outlined" />
      </LightTooltip>
      <div className={cls.accredited}>{chat.accreditedName}</div>
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'absolute',
    rowGap: 4,
    right: 0,
    left: 0,
    top: -42,
  },
  chip: {
    marginLeft: 10,
  },
  titleLeft: {
    flex: '1',
  },
  link: {
    marginTop: -5,
    marginRight: 10,
    padding: 0,
    textTransform: 'none',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  accredited: {
    marginTop: -6,
    color: 'black',
    flex: '0 0 100%',
    fontSize: 12,
  },
});
