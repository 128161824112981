import { EditProps, Edit, SimpleForm, useRecordContext } from 'react-admin';

import { useEditProps, useRbacEdit, useRecord } from '@hooks';
import { Forbidden, InitialPage } from '@components';
import { RoleForm, validate } from '@pages/role/RoleForm';
import { AdminRole } from '@types';

const Title = () => {
  const record = useRecordContext();

  return <span>Role ID: {record?.id}</span>;
};

export const RoleEdit = (props: EditProps) => {
  const editProps = useEditProps();
  const rbacEdit = useRbacEdit();
  const role = useRecord<AdminRole>(editProps);

  if (!rbacEdit) {
    return <Forbidden />;
  }

  return (
    <Edit {...props} title={<Title />} {...editProps}>
      <InitialPage
        title="Role"
        backTo="/role"
        backText="Back to all data structured"
      >
        <SimpleForm validate={validate} toolbar={role?.isFixed ? false : undefined}>
          <RoleForm />
        </SimpleForm>
      </InitialPage>
    </Edit>
  );
};
