import React from 'react';
import { DateFieldProps, NumberFieldProps } from 'ra-ui-materialui';
import {
  DateField as RaDateField,
  NumberField as RaNumberField,
  useRecordContext,
} from 'react-admin';

import { getTimeZone } from '@utils';

export const DateField = ((props: DateFieldProps) => {
  const record = useRecordContext();
  const options = props.options || {};
  options.timeZone = getTimeZone(record[props.source ?? '']);

  if (!options.day) {
    options.day = '2-digit';
  }
  if (!options.month) {
    options.month = '2-digit';
  }
  if (!options.year) {
    options.year = 'numeric';
  }
  if (props.showTime && !options.hour) {
    options.hour = '2-digit';
  }
  if (props.showTime && !options.minute) {
    options.minute = '2-digit';
  }
  if (props.showTime && !options.second) {
    options.second = '2-digit';
  }

  return (
    <RaDateField locales="es-ES" {...props} options={options} />
  );
}) as typeof RaDateField;


export const NumberField = ((props: NumberFieldProps) => {
  const options = props.options || {};

  return (
    <RaNumberField locales="es-ES" {...props} options={options} />
  );
}) as typeof RaNumberField;
