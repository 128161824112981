import { RcFile } from 'antd/lib/upload';
import { message } from 'antd';

export function beforeUpload(file: RcFile): boolean {
  const allowedTypes = [
    'application/pdf',
    'image/jpeg',
    'image/jpg',
    'image/png',
  ];
  if (!allowedTypes.includes(file.type)) {
    message.error('You can only upload JPG/PNG/PDF file!');

    return false;
  }

  const sizeMb = file.size / 1024 / 1024;
  if (sizeMb > 20) {
    message.error('Image must be smaller than 20 MB!');

    return false;
  }

  return true;
}

export function beforeMediaUpload(file: RcFile): boolean {
  const allowedTypes = [
    'application/pdf',
    'image/jpeg',
    'image/jpg',
    'image/png',
    'video/mp4',
  ];
  if (!allowedTypes.includes(file.type)) {
    message.error('You can only upload JPG/PNG/PDF/MP4 file!');

    return false;
  }

  const sizeMb = file.size / 1024 / 1024;
  const maxSizeMb = file.type === 'video/mp4' ? 50 : 20;

  if (sizeMb > maxSizeMb) {
    message.error(`File must be smaller than ${maxSizeMb} MB!`);

    return false;
  }

  return true;
}

export type UploadType = 'pdf' | 'xlsx' | 'pdf+xlsx' | 'image' | 'docs' | 'media';

export const beforeUploadType = (type?: UploadType, size = 20) => (file: RcFile): boolean => {
  const allowedTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'image/jpeg',
    'image/jpg',
    'image/png',
  ];

  if (type === 'pdf' && file.type !== 'application/pdf') {
    message.error('You can only upload PDF file');
    return false;
  }

  if (type === 'xlsx' && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    message.error('You can only upload XLSX file');
    return false;
  }

  if (type === 'pdf+xlsx' && file.type !== 'application/pdf' && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    message.error('You can only upload PDF or XLSX file');
    return false;
  }

  if (type === 'image' && !['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
    message.error('You can only upload JPG/JPEG/PNG file');
    return false;
  }

  if (type === 'docs' && ![
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ].includes(file.type)) {
    message.error({
      content: 'You can only upload docx/doc/xlsx/xls file',
      duration: 1,
    });
    return false;
  }

  if (!allowedTypes.includes(file.type)) {
    message.error('You can only upload JPG/JPEG/PNG/PDF file');
    return false;
  }

  const sizeMb = file.size / 1024 / 1024;
  if (sizeMb > size) {
    message.error(`Image must be smaller than ${size} MB`);
    return false;
  }

  return true;
};

export const beforeUploadContracts = (file: RcFile): boolean => {
  const allowedTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'image/jpeg',
    'image/jpg',
    'image/png',
  ];

  if (!allowedTypes.includes(file.type)) {
    message.error('You can only upload JPG/JPEG/PNG/PDF/DOCX file');
    return false;
  }

  return true;
};

export interface FileTypesOptions {
  types?: string[];
  size?: number;
  typeError?: string;
  sizeError?: string;
}

export function beforeUploadFlex(file: RcFile, options?: FileTypesOptions): boolean {
  const allowedTypes = options?.types || [
    'application/pdf',
    'image/jpeg',
    'image/jpg',
    'image/png',
  ];
  if (!allowedTypes.includes(file.type)) {
    message.error(options?.typeError || 'You can only upload JPG/PNG/PDF file!');

    return false;
  }

  const sizeMb = file.size / 1024 / 1024;
  const maxSize = options?.size ? options.size : 20;
  if (sizeMb > maxSize) {
    message.error(options?.sizeError || 'Image must be smaller than 20 MB!');

    return false;
  }

  return true;
}
