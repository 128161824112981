import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { AdminNotification } from '@types';
import { NotificationEntityTypes } from '@network';
import { useSettingsContext } from '@providers';
import { Sidepanel } from '../Sidepanel';
import { NotificationsItem } from './notifications-item';
import cls from './notifications.module.css';
import { ChatsList } from '@components/Chat/chats-list';
import { Divider } from 'antd';

export type NotificationType = Record<NotificationEntityTypes, AdminNotification[]>;

export const Notifications = () => {
  const location = useLocation();
  const {
    panelName,
    setPanelName,
    notificationsMap,
    refreshNotifications,
    mentioned,
  } = useSettingsContext();

  useEffect(() => {
    setPanelName(null);
  }, [location.pathname]);

  const sidepanelName = 'Notifications';
  const togglePanel = () => setPanelName(panelName === sidepanelName ? null : sidepanelName);
  const notifications = Object.keys(notificationsMap || {}) as any;
  const countMentioned = mentioned ? mentioned.unreadedChats : 0;
  const countTotal = countMentioned + notifications.length;

  const count = useMemo(() => {
    return countTotal > 9 ? '9+' : countTotal;
  }, [countTotal]);
  const chats = useMemo(() => {
    return mentioned ? Object.values(mentioned?.chats || {}) : [];
  }, [mentioned]);

  const notificationsList
    = Object.entries(notificationsMap || {}) as [NotificationEntityTypes, AdminNotification[]][];
  const isEmpty = notificationsList.length === 0 && chats.length === 0;

  return (
    <>
      <div className={cls.icon} onClick={togglePanel}>
        <NotificationsIcon style={{ fill: '#002a77' }} />
        {countTotal > 0 && <div className={cls.counter}>{count}</div>}
      </div>

      <Sidepanel name={sidepanelName} title="Notifications">
        {isEmpty && <div className={cls.empty}>You have no notifications</div>}

        {notificationsList.map(([entity, items], index) => (
          <NotificationsItem
            key={index}
            entity={entity}
            items={items}
            refreshNotifications={refreshNotifications}
          />
        ))}

        {chats.length > 0 && (
          <div>
            <Divider />
            <ChatsList chats={chats} isMentions />
          </div>
        )}
      </Sidepanel>
    </>
  );
};
