import { makeStyles } from '@mui/styles';
import { Modal } from 'antd';

import { FinanceHistory } from '@types';
import { BlueButton, Scroll } from '@components';
import { dateFormatter } from '@utils';
import { useModalState } from '@hooks';

interface Props {
  history: FinanceHistory[];
}

export const FinanceHistoryButton = (props: Props) => {
  const cls = useStyles();
  const { is: isOpened, show: showModal, hide: hideModal } = useModalState();
  const allHistory = props.history.sort((a, b) => b.id - a.id);

  if (allHistory.length === 0) return null;

  return (
    <>
      <BlueButton
        variant="contained"
        color="secondary"
        onClick={showModal}
        className={cls.btn}
      >
        History ({allHistory.length})
      </BlueButton>
      <Modal
        width={500}
        open={isOpened}
        title={false}
        footer={false}
        onCancel={hideModal}
      >
        <div className={cls.box}>
          <Scroll height={600}>
            {allHistory.map(history => (
              <div className={cls.item} key={history.id}>
                <div className={cls.header}>
                  <div className={cls.by}>by [admin] {history.adminName}</div>
                  <div>{dateFormatter.toDateTime(history.createdAt, true)}</div>
                </div>
                <div className={cls.footer}>
                  <div>{history.title}</div>
                  {history.statusBefore || history.statusAfter ? (
                    <div>{history.statusBefore} → {history.statusAfter}</div>
                  ) : null}
                </div>
              </div>
            ))}
          </Scroll>
        </div>
      </Modal>
    </>
  );
};

const useStyles = makeStyles({
  btn: {
    textTransform: 'none',
  },
  box: {
    marginTop: 20,
    fontFamily: 'Outfit, sans-serif',
    color: '#272727',
  },
  item: {
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 18,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#002A77',
    fontSize: 12,
    fontWeight: 400,
    borderBottom: '1px solid rgba(4, 46, 107, 0.50)',
    paddingBottom: 6,
  },
  by: {},
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#000',
    fontSize: 13,
    fontWeight: 400,
  },
  showMoreBtn: {
    paddingLeft: 50,
    paddingRight: 50,
    borderRadius: 8,
    textTransform: 'none',
    color: '#FD8A32',
    fontWeight: 400,
  },
});
