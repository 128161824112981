import { Dispatch, SetStateAction } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { adminHttp, NotificationEntityTypes } from '@network';
import { axiosErrorToString, rbac } from '@utils';
import { AdminNotification } from '@types';
import cls from './notifications.module.css';
import { NotificationType } from '@components/Notifications/notifications';

interface NotificationsItemProps {
  entity: NotificationEntityTypes;
  items: AdminNotification[];
  refreshNotifications: () => void;
}

export const NotificationsItem = ({
  entity, items, refreshNotifications,
}: NotificationsItemProps) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const admin = rbac.getAdmin();

  const creditCodesMap: Record<string, boolean> = {};
  const financeCostsMap: Record<string, boolean> = {};
  const financeWithdrawalsMap: Record<string, boolean> = {};
  const financeDepositsMap: Record<string, boolean> = {};

  for (const item of items) {
    if (item.creditCode) {
      creditCodesMap[item.creditCode] = true;
    }
    if (item.financeCost) {
      financeCostsMap[item.financeCost.code] = true;
    }
    if (item.financeWithdrawal) {
      financeWithdrawalsMap[item.financeWithdrawal.code] = true;
    }
    if (item.financeDeposit) {
      financeDepositsMap[item.financeDeposit.code] = true;
    }
  }

  const creditCodes = Object.keys(creditCodesMap);
  const financeCodes = Object.keys(financeCostsMap);
  const financeWithdrawals = Object.keys(financeWithdrawalsMap);
  const financeDeposits = Object.keys(financeDepositsMap);
  const ticketFrom = items[0].ticketFrom;

  const links: Record<NotificationEntityTypes, string> = {
    [NotificationEntityTypes.USER_ASSIGNED]: `/user?displayedFilters=%7B"responsibleAdminId"%3Atrue%7D&filter=%7B"responsibleAdminId"%3A${admin?.id || ''}%7D&order=DESC&page=1&perPage=10&sort=id`,
    [NotificationEntityTypes.USER_CONTACT_OWNER_UPDATED]: `/user?displayedFilters=%7B"contactOwnerId"%3Atrue%7D&filter=%7B"contactOwnerId"%3A${admin?.id || ''}%7D&order=DESC&page=1&perPage=10&sort=id`,
    [NotificationEntityTypes.CREDIT_FILES_PROGRESS]: '/credit?displayedFilters=%7B%22progress%22%3Atrue%7D&filter=%7B%22progress%22%3A%22completed%22%7D&order=DESC&page=1&perPage=10&sort=id',
    [NotificationEntityTypes.CREDIT_STATUS_MODERATION_SIGNED]: '/credit?displayedFilters=%7B%22progress%22%3Atrue%7D&filter=%7B%22status%22%3A%5B%22signed%22%5D%7D&order=ASC&page=1&perPage=10&sort=status',
    [NotificationEntityTypes.NEW_CREDIT]: '/credit?displayedFilters=%7B%22progress%22%3Atrue%7D&filter=%7B%22status%22%3A%5B%22moderation%22%5D%7D&order=DESC&page=1&perPage=10&sort=createdAt',
    [NotificationEntityTypes.NEW_CONTACT_US]: '/feedback/contact',
    [NotificationEntityTypes.CREDIT_STATUS_CX]: '/credit?displayedFilters=%7B%22progress%22%3Atrue%7D&filter=%7B%22status%22%3A%5B%22signed%22%5D%7D&order=ASC&page=1&perPage=10&sort=createdAt',
    [NotificationEntityTypes.CREDIT_STATUS_DELAY_CX]: '/credit?displayedFilters=%7B%7D&filter=%7B%22status%22%3A%5B%22repaid%22%2C%22restructure%22%2C%22delay%22%5D%7D&order=DESC&page=1&perPage=10&sort=createdAt',
    [NotificationEntityTypes.NEW_RESUME]: '/resume?order=DESC&page=1&perPage=25&sort=createdAt',
    [NotificationEntityTypes.NEW_QUOTATION]: '/order?displayedFilters=%7B%7D&filter=%7B%22status%22%3A%5B%22waiting_for_payment%22%5D%7D&order=DESC&page=1&perPage=10&sort=id',
    [NotificationEntityTypes.INVOICE_UPLOADED]: '/credit?displayedFilters=%7B%22invoices%22%3Atrue%7D&filter=%7B%22invoices%22%3A%22uploaded%22%7D&order=DESC&page=1&perPage=10&sort=id',
    [NotificationEntityTypes.CALL_OF_THE_DAY]: '/credit-call?displayedFilters=%7B%7D&filter=%7B%22callDate%22%3A%22overdue%22%7D&order=DESC&page=1&perPage=10&sort=id',
    [NotificationEntityTypes.CONTACT_NEXT_CYCLE]: '/user?displayedFilters=%7B%7D&filter=%7B%22status%22%3A%5B%22contact_next_cycle%22%5D%7D&order=DESC&page=1&perPage=10&sort=id',
    [NotificationEntityTypes.USER_UPLOADED_DOCUMENTS]: `/credit?displayedFilters=%7B%7D&filter=%7B"creditCodes"%3A"${creditCodes?.join(',') || ''}"%7D&order=DESC&page=1&perPage=10&sort=id`,
    [NotificationEntityTypes.FINANCE_COST_CREATED]: `/finance-cost?displayedFilters=%7B%7D&filter=%7B"q"%3A"${financeCodes?.join('; ') || ''}"%7D&order=DESC&page=1&perPage=10&sort=id`,
    [NotificationEntityTypes.FINANCE_WITHDRAWAL_CREATED]: `/finance-withdrawal?displayedFilters=%7B%7D&filter=%7B"q"%3A"${financeWithdrawals?.join('; ') || ''}"%7D&order=DESC&page=1&perPage=10&sort=id`,
    [NotificationEntityTypes.FINANCE_DEPOSIT_CREATED]: `/finance-deposit?displayedFilters=%7B%7D&filter=%7B"q"%3A"${financeDeposits?.join('; ') || ''}"%7D&order=DESC&page=1&perPage=10&sort=id`,
    [NotificationEntityTypes.TICKET_CREATED]: `/support-ticket?displayedFilters=%7B%7D&filter=%7B"sentBy"%3A"${ticketFrom}"%7D&order=DESC&page=1&perPage=25&sort=id`,
  };

  const goToEntity = async () => {
    try {
      await adminHttp.readNotifications(entity);
      redirect(links[entity]);
      refreshNotifications();
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  return (
    <div className={cls.item} onClick={goToEntity}>
      <div className={cls.content}>
        <span className={cls.label}>
          {entity === NotificationEntityTypes.TICKET_CREATED
            && `You have a new ticket from ${ticketFrom}`
          }
          {entity === NotificationEntityTypes.FINANCE_COST_CREATED
            && 'New Expense in Cost Center'
          }
          {entity === NotificationEntityTypes.FINANCE_WITHDRAWAL_CREATED
            && 'New Withdrawal at Finance'
          }
          {entity === NotificationEntityTypes.FINANCE_DEPOSIT_CREATED
            && 'New Deposit at Finance'
          }
          {entity === NotificationEntityTypes.USER_ASSIGNED
            && 'User Assigned'
          }
          {entity === NotificationEntityTypes.USER_CONTACT_OWNER_UPDATED
            && `${items[0].userCode} record assigned to you as “Owner”`}
          {entity === NotificationEntityTypes.CREDIT_FILES_PROGRESS
            && `The credit${items.length === 1 ? ` ${items[0].creditCode}` : 's where'} file is completed`
          }
          {entity === NotificationEntityTypes.CREDIT_STATUS_MODERATION_SIGNED
            && `The credit${items.length === 1 ? ` ${items[0].creditCode}` : 's  where'} status is now "Signed"`
          }
          {entity === NotificationEntityTypes.NEW_CREDIT
            && `New credit${items.length === 1 ? '' : 's'} created`
          }
          {entity === NotificationEntityTypes.NEW_CONTACT_US
            && `New Contact Us ${items.length === 1 ? 'entry' : 'entries'}`
          }
          {entity === NotificationEntityTypes.CREDIT_STATUS_CX
            && `New active credit${items.length === 1 ? ` ${items[0].creditCode}` : 's'}`
          }
          {entity === NotificationEntityTypes.CREDIT_STATUS_DELAY_CX
            && `The credit${items.length === 1 ? ` ${items[0].creditCode}` : 's where status'} is now "Delay"`
          }
          {entity === NotificationEntityTypes.NEW_RESUME
            && `New resume${items.length === 1 ? '' : 's'} received`
          }
          {entity === NotificationEntityTypes.NEW_QUOTATION
            && `New quote${items.length === 1 ? '' : 's'} received`
          }
          {entity === NotificationEntityTypes.INVOICE_UPLOADED
            && `The user${items.length === 1 ? ` ${items[0].userCode} has` : 's have '} uploaded an invoice`
          }
          {entity === NotificationEntityTypes.CALL_OF_THE_DAY
            && `Call${items.length === 1 ? '' : 's'} of the day`
          }
          {entity === NotificationEntityTypes.CONTACT_NEXT_CYCLE
            && `Reminder (Contact Next Cycle) - User${items.length === 1 ? ` ${items[0].userCode} is` : 's are'} now reactivated`
          }
          {entity === NotificationEntityTypes.USER_UPLOADED_DOCUMENTS
            && `The user${items.length === 1 ? ` ${items[0].userCode}` : 's'} uploaded document(s)`
          }
        </span>
        <span className={cls.count}>{items.length}</span>
      </div>
    </div>
  );
};
