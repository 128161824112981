import { Dispatch, SetStateAction, useState } from 'react';
import { useNotify } from 'react-admin';
import { Col, Row } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Button } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import moment, { Moment } from 'moment-timezone';
import { clsx } from 'clsx';

import { axiosErrorToString, showAxiosError } from '@utils';
import { BlogFormValues } from '@pages/blog/BlogCreate';
import { InputText } from '@components/inputs/InputText';
import { convertFileToBase64, FileUpload, InitialCard, SwitchControlled } from '@components';
import { InputDate } from '@components/inputs/InputDate';
import cls from './blog.module.css';
import { useRbacEdit } from '@hooks';

const editorToolbar = [
  'undo',
  'redo',
  'formatselect',
  'bold',
  'italic',
  'backcolor',
  'bullist',
  'numlist',
  'outdent',
  'indent',
  'table',
  'removeformat',
  'code',
  'help',
  'alignleft',
  'aligncenter',
  'alignright',
  'alignjustify',
].join(' ');

const MAX_IMAGE_SIZE = 5;
const TITLE_MAX_LENGTH = 80;
const ANNOUNCE_MAX_LENGTH = 160;

interface BlogFormProps {
  formValues: BlogFormValues | null;
  setFormValues: Dispatch<SetStateAction<BlogFormValues | null>>;
  type: 'create' | 'edit';
  submit: () => void;
  setIsDialogOpen?: Dispatch<SetStateAction<boolean>>;
  link?: string;
  image?: string;
}

const UploadContent = () => {
  return (
    <div className={cls.uploadContent}>
      <div className={cls.uploadContentBlock}>
        <div className={cls.uploadContentTitle}>Upload Image</div>
        <div className={cls.uploadContentIcon}>
          <svg xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="32"
            viewBox="0 0 26 32"
            fill="none">
            <path d="M21.5 15.27C21.5 13.34 19.93 11.77 18 11.77C16.07 11.77 14.5 13.34 14.5 15.27C14.5 17.2 16.07 18.77 18 18.77C19.93 18.77 21.5 17.2 21.5 15.27ZM18 13.77C18.83 13.77 19.5 14.44 19.5 15.27C19.5 16.1 18.83 16.77 18 16.77C17.17 16.77 16.5 16.1 16.5 15.27C16.5 14.44 17.17 13.77 18 13.77Z"
              fill="#042E6B" />
            <path d="M23 8.27002H17C16.45 8.27002 16 8.72002 16 9.27002C16 9.82002 16.45 10.27 17 10.27H23C23.55 10.27 24 10.72 24 11.27V25.48L20.08 21.56C18.32 19.89 15.54 19.93 13.83 21.65L12.73 22.75L11.82 21.83L8.1 18.14C6.5 16.56 4.19 15.91 2 16.43V11.27C2 10.72 2.45 10.27 3 10.27H9C9.55 10.27 10 9.82002 10 9.27002C10 8.72002 9.55 8.27002 9 8.27002H3C1.34 8.27002 0 9.61002 0 11.27V28.27C0 29.93 1.34 31.27 3 31.27H22C24.21 31.27 26 29.48 26 27.27V11.27C26 9.61002 24.66 8.27002 23 8.27002ZM22 29.27H3C2.45 29.27 2 28.82 2 28.27V18.53L2.27 18.41C3.84 17.97 5.53 18.41 6.68 19.56L10.39 23.27L11.39 24.27H11.44L11.59 24.42C11.9 24.69 12.31 24.83 12.72 24.83C13.12 24.83 13.51 24.69 13.82 24.45L14 24.27L15.22 23.05C16.21 22.11 17.77 22.11 18.76 23.05L23.76 28.05C23.46 28.76 22.77 29.24 22 29.27Z"
              fill="#042E6B" />
            <path d="M10.5209 4.69986L12.0009 3.26986V11.2699C12.0009 11.8199 12.4509 12.2699 13.0009 12.2699C13.5509 12.2699 14.0009 11.8199 14.0009 11.2699V3.19986L15.4809 4.67986C15.8709 5.06986 16.5109 5.06986 16.9009 4.67986C17.2909 4.28986 17.2909 3.65986 16.9009 3.26986L14.0709 0.449864C13.5109 -0.120136 12.5909 -0.150136 12.0009 0.389864L11.9309 0.449864L9.11086 3.26986C8.72086 3.65986 8.72086 4.29986 9.11086 4.68986C9.50086 5.07986 10.1309 5.08986 10.5309 4.69986H10.5209Z"
              fill="#042E6B" />
          </svg>
        </div>
        <div className={cls.uploadContentText}>1600x630px</div>
      </div>
      <div className={cls.uploadContentComment}>
        *File size should not exceed {MAX_IMAGE_SIZE}MB
      </div>
    </div>
  );
};

const BlogLink = ({ link }: {link: string}) => {
  return (
    <div className={cls.link}>
      <svg xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        className={clsx(cls.icon, cls.leftIcon)}>
        <circle cx="19" cy="19" r="19" fill="#042E6B" />
        <g clipPath="url(#clip0_286_20)">
          <path d="M19 33C11.272 33 5 26.728 5 19C5 11.272 11.272 5 19 5C26.728 5 33 11.272 33 19C33 26.728 26.728 33 19 33ZM19 7.8C12.812 7.8 7.8 12.812 7.8 19C7.8 25.188 12.812 30.2 19 30.2C25.188 30.2 30.2 25.188 30.2 19C30.2 12.812 25.188 7.8 19 7.8Z"
            fill="white" />
          <path d="M20.5678 14.8H17.4318C15.9782 14.8 14.7998 15.9784 14.7998 17.432V20.568C14.7998 22.0217 15.9782 23.2 17.4318 23.2H20.5678C22.0214 23.2 23.1998 22.0217 23.1998 20.568V17.432C23.1998 15.9784 22.0214 14.8 20.5678 14.8Z"
            fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_286_20">
            <rect width="28" height="28" fill="white" transform="translate(5 5)" />
          </clipPath>
        </defs>
      </svg>
      <span>{link}</span>
      <svg xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        className={clsx(cls.icon, cls.rightIcon)}>
        <circle cx="19" cy="19" r="19" fill="#002A77" />
        <path d="M21.987 20.8821C22.3035 21.1986 22.8148 21.1986 23.1313 20.8821L24.7546 19.2589C26.4184 17.5951 26.4184 14.9005 24.7546 13.2448C23.0908 11.5891 20.3962 11.581 18.7405 13.2448L17.1173 14.868C16.8008 15.1846 16.8008 15.6959 17.1173 16.0124C17.4338 16.329 17.9451 16.329 18.2617 16.0124L19.8849 14.3892C20.9156 13.3584 22.5794 13.3584 23.6102 14.3892C24.6409 15.4199 24.6409 17.0838 23.6102 18.1145L21.987 19.7377C21.6704 20.0543 21.6704 20.5656 21.987 20.8821Z"
          fill="white" />
        <path d="M12.2479 25.7516C13.9117 27.4155 16.6062 27.4155 18.2619 25.7516L19.8852 24.1284C20.2017 23.8119 20.2017 23.3006 19.8852 22.984C19.5686 22.6675 19.0573 22.6675 18.7408 22.984L17.1175 24.6073C16.0868 25.638 14.423 25.638 13.3922 24.6073C12.3615 23.5765 12.3615 21.9127 13.3922 20.882L15.0155 19.2587C15.332 18.9422 15.332 18.4309 15.0155 18.1144C14.6989 17.7978 14.1876 17.7978 13.8711 18.1144L12.2479 19.7376C10.584 21.4014 10.584 24.096 12.2479 25.7516Z"
          fill="white" />
        <path d="M16.6386 22.5056C16.3221 22.8221 15.8108 22.8221 15.4942 22.5056C15.1777 22.1891 15.1777 21.6778 15.4942 21.3612L20.3639 16.4915C20.6805 16.175 21.1918 16.175 21.5083 16.4915C21.8248 16.8081 21.8248 17.3194 21.5083 17.6359L16.6386 22.5056Z"
          fill="white" />
      </svg>
    </div>
  );
};

export const BlogForm = ({
  formValues, setFormValues, type, setIsDialogOpen, submit, link,
}: BlogFormProps) => {
  const notify = useNotify();
  const rbacEdit = useRbacEdit();
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const uploadPicture = async (rcFile: RcFile): Promise<any> => {
    try {
      const base64Img = await convertFileToBase64(rcFile) as string;

      if (base64Img) {
        setFormValues({
          ...formValues,
          image: base64Img as string,
        } as BlogFormValues);
      }
    } catch (err: any) {
      showAxiosError(err);
    }
  };

  const onImageClear = () => {
    setFormValues({
      ...formValues,
      image: '',
    } as BlogFormValues);
  };

  const onChangeHandler = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    } as BlogFormValues);
  };

  const onDescriptionHandler = (value: string) => {
    setFormValues({
      ...formValues,
      description: value,
    } as BlogFormValues);
  };

  const onDateHandler = (date: Moment) => {
    setFormValues({
      ...formValues,
      publishAt: moment(date).format(),
    } as BlogFormValues);
  };

  const switcherToggleHandler = (name: 'isEnabled' | 'isLastNews' | 'isMain' | 'isRecommendation') => {
    setFormValues({
      ...formValues,
      [name]: formValues ? !formValues[name] : false,
    } as BlogFormValues);
  };

  const isTitleLengthValid = (formValues?.title?.length || 0) <= TITLE_MAX_LENGTH;
  const isAnnounceLengthValid = (formValues?.announce?.length || 0) <= ANNOUNCE_MAX_LENGTH;

  const showTitleError = formValues?.title.length === 0 && showErrors;
  const showAuthorError = formValues?.author.length === 0 && showErrors;
  const showAnnounceError = formValues?.announce.length === 0 && showErrors;

  const submitHandler = async () => {
    if (formValues?.title.length === 0
      || formValues?.author.length === 0
      || formValues?.announce.length === 0
      || !isTitleLengthValid
      || !isAnnounceLengthValid
    ) {
      setShowErrors(true);
      notify('Check that all fields are filled in correctly.', { type: 'error' });

      return;
    }

    setShowErrors(false);

    try {
      await submit();
    } catch (e) {
      notify(axiosErrorToString(e));
    }
  };

  return (
    <>
      <div className={cls.form}>
        <Row gutter={24}>
          <Col span={12}>
            <InitialCard left="Schema" extraPadding>
              <div className={cls.field}>
                <div className={cls.fieldHead}>
                  <label htmlFor="title" className={cls.label}>Title</label>
                  <div className={clsx(cls.fieldCounter, !isTitleLengthValid && cls.error)}>
                    {formValues?.title?.length || 0}/{TITLE_MAX_LENGTH}
                  </div>
                </div>
                <textarea
                  id="title"
                  name="title"
                  className={clsx(
                    cls.textarea,
                    (!isTitleLengthValid || showTitleError) && cls.blockError,
                  )}
                  placeholder="Write a title"
                  value={formValues?.title || ''}
                  onChange={onChangeHandler}
                />
              </div>

              <div>
                <div className={cls.rows}>
                  <InputText
                    name="author"
                    label="Author"
                    className={clsx(showAuthorError && cls.fieldError)}
                    onChange={onChangeHandler}
                    value={formValues?.author}
                  />
                  <InputDate
                    name="publishAt"
                    label="Publish at"
                    placeholder="Select a date"
                    onChange={onDateHandler}
                    value={formValues?.publishAt || new Date().toISOString()}
                  />
                </div>

                <div className={cls.switchers}>
                  <div className={cls.switcher}>
                    <SwitchControlled value={formValues?.isEnabled || false}
                      onChange={() => switcherToggleHandler('isEnabled')} />
                    <div className={cls.formLabel}>is enabled</div>
                  </div>
                  <div className={cls.switcher}>
                    <SwitchControlled value={formValues?.isLastNews || false}
                      onChange={() => switcherToggleHandler('isLastNews')} />
                    <div className={cls.formLabel}>is last news</div>
                  </div>
                  <div className={cls.switcher}>
                    <SwitchControlled value={formValues?.isMain || false}
                      onChange={() => switcherToggleHandler('isMain')} />
                    <div className={cls.formLabel}>is main</div>
                  </div>
                  <div className={cls.switcher}>
                    <SwitchControlled value={formValues?.isRecommendation || false}
                      onChange={() => switcherToggleHandler('isRecommendation')} />
                    <div className={cls.formLabel}>is recommendation</div>
                  </div>
                </div>
              </div>

              <div className={cls.field}>
                <div className={cls.fieldHead}>
                  <label htmlFor="announce" className={cls.label}>Announce / Description</label>
                  <div className={clsx(cls.fieldCounter, !isAnnounceLengthValid && cls.error)}>
                    {formValues?.announce?.length || 0}/{ANNOUNCE_MAX_LENGTH}
                  </div>
                </div>
                <textarea
                  id="announce"
                  name="announce"
                  className={clsx(
                    cls.textarea,
                    (!isAnnounceLengthValid || showAnnounceError) && cls.blockError,
                  )}
                  placeholder="Write a description"
                  onChange={onChangeHandler}
                  value={formValues?.announce || ''}
                />
              </div>
            </InitialCard>
          </Col>
          <Col span={12}>
            <InitialCard left="Snippet" extraPadding>
              {formValues?.image ? (
                <div className={cls.uploadedBox} onClick={onImageClear}>
                  <img src={formValues?.image} alt="" className={cls.image} />
                </div>
              ) : (
                <FileUpload
                  uploadAction={uploadPicture}
                  className={cls.uploadBox}
                  type="image"
                  content={<UploadContent />}
                  size={MAX_IMAGE_SIZE}
                />
              )}

              <div className={cls.field}>
                <label htmlFor="imageAlt" className={cls.label}>Image alt text</label>
                <textarea
                  id="imageAlt"
                  name="imageAlt"
                  className={cls.textarea}
                  placeholder="Write an image title"
                  onChange={onChangeHandler}
                  value={formValues?.imageAlt || ''}
                />
              </div>
              {link && (
                <div className={cls.field}>
                  <div className={cls.label}>Permanent link</div>
                  <BlogLink link={link} />
                </div>
              )}
            </InitialCard>
          </Col>
        </Row>
        <Row gutter={24} className={cls.article}>
          <Col span={24}>
            <InitialCard left="Article" extraPadding>
              <div className={cls.field}>
                <label htmlFor="keywords" className={cls.label}>Keywords</label>
                <input
                  id="keywords"
                  name="keywords"
                  className={cls.input}
                  placeholder="Enter the keywords"
                  onChange={onChangeHandler}
                  value={formValues?.keywords || ''}
                />
              </div>
              <div className={cls.editor}>
                <Editor
                  value={formValues?.description || ''}
                  onEditorChange={onDescriptionHandler}
                  apiKey="oph1re1rbxqlrcim9vh1z8z42fo2mtx7jvvbkuupzh9nn5q6"
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: 'advlist autolink lists link image charmap table code help',
                    toolbar_mode: 'wrap',
                    toolbar: editorToolbar,
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                />
              </div>
            </InitialCard>
          </Col>
        </Row>
      </div>

      {rbacEdit && (
        <Row className={cls.actions}>
          <Button variant="contained" className={cls.save} onClick={submitHandler}>
            Save
          </Button>
          {type === 'edit' && setIsDialogOpen && (
            <Button variant="contained"
              className={cls.delete}
              onClick={() => setIsDialogOpen(true)}
            >
              Delete
            </Button>
          )}
        </Row>
      )}
    </>
  );
};
