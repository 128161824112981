import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { Col, Form, Input, Row, Tooltip, UploadFile } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined, FieldNumberOutlined } from '@ant-design/icons';
import { clsx } from 'clsx';

import { ROLES, showAxiosError } from '@utils';
import { creditHttp, s3http } from '@network';
import { Credit, CreditStatus, Doctype, Document, DocumentStatus, Guarantor, User } from '@types';
import { Badge, InfoTooltip } from '@components';
import { DocumentFiles } from '../DocumentFiles';
import { DocumentHistoryWidget } from '../DocumentHistory';
import { DocumentCommentForm } from '../DocumentCommentForm';
import { DocumentStatuses } from '../DocumentStatuses';
import cls from './doctype-document.module.css';

interface Props {
  doctype: Doctype;
  credit: Credit;
  setCredit?: Dispatch<SetStateAction<Credit>>;
  guarantor?: Guarantor;
  isSuperadmin: boolean;
  editable: boolean;
  initialNumber?: string;
  creditContractDoctype?: Doctype;
  type?: 'pdf' | 'image' | 'docs';
  updateCounters?: () => Promise<void>;
  isRestricted?: boolean;
  downloadable?: boolean;
}

export const DoctypeDocument = ({
  doctype,
  credit,
  guarantor,
  isSuperadmin,
  editable,
  initialNumber,
  setCredit,
  creditContractDoctype,
  type,
  updateCounters,
  isRestricted,
  downloadable,
}: Props) => {
  const [form] = Form.useForm<{number: string}>();
  const [document, setDocument] = useState<Document | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const usedFiles = useRef<Record<string, boolean>>({});

  const documents = guarantor ? (guarantor?.documents || []) : (credit?.documents || []);
  const user = credit?.user;
  const showOptionalButton = true;
  const isNumberDoctype = doctype.isRfc || doctype.isCurp;

  const notModeration = credit.status !== CreditStatus.MODERATION;
  const notCompletedFile = credit.status !== CreditStatus.COMPLETED_FILE;

  let disabledOptionalButton = (notModeration && notCompletedFile)
    || (document ? !document?.isOptionalCheckedAvailable : false);
  if (!editable && !isSuperadmin) disabledOptionalButton = true;
  if (isSuperadmin) disabledOptionalButton = false;

  const updateCredit = async () => {
    if (setCredit) {
      const newCredit = await creditHttp.getCredit(credit.id);
      setCredit(newCredit);
    }
  };

  const setDocumentAndNumber = () => {
    const foundDocument = documents.find(doc => doc.doctypeId === doctype.id);
    setDocument(foundDocument);

    if (isNumberDoctype) {
      let number = foundDocument?.number || initialNumber || '';
      if (!number && user) {
        number = autocompleteRfc(user);
      }
      form.setFieldsValue({ number });
    }
  };

  useEffect(() => void setDocumentAndNumber(), []);

  useEffect(() => void setDocumentAndNumber(), [documents, guarantor]);

  const setOptionalDocument = async () => {
    setLoading(true);
    try {
      const updatedDocument = await creditHttp.setOptionalDocument({
        creditId: credit.id,
        doctypeId: doctype.id,
        isOptionalChecked: document?.isOptionalChecked ? false : true,
        guarantorId: guarantor ? guarantor.id : undefined,
      });

      setDocument(updatedDocument);
      updateCredit();
      updateCounters && updateCounters();
    } catch (err: any) {
      console.error(err);
      showAxiosError(err);
    } finally {
      setLoading(false);
    }
  };

  const onUpload = async (uploadFiles: UploadFile[]) => {
    // check unique
    const files: UploadFile[] = [];
    uploadFiles.forEach(file => {
      const uid = file.uid;
      if (!usedFiles.current[uid]) {
        usedFiles.current[uid] = true;
        files.push(file);
      }
    });
    if (files.length === 0) {
      return;
    }
    // uploading
    setLoading(true);
    try {
      const s3files = await Promise.all(
        files.map(file => s3http.uploadFile(file.originFileObj as File)),
      );
      const newIds = s3files.map(s3file => s3file.id);
      const s3Ids = (document?.files || []).map(file => file.fileId);
      const updatedDocument = await creditHttp.saveDocuments({
        creditId: credit.id,
        guarantorId: guarantor?.id,
        doctypeId: doctype.id,
        fileIds: [...s3Ids, ...newIds],
        status: doctype.id === creditContractDoctype?.id ? 'docs_draft' as DocumentStatus : DocumentStatus.DOCS_SENT,
      });
      setDocument(updatedDocument);
      updateCredit();
      updateCounters && updateCounters();
    } catch (err: any) {
      console.error(err);
      showAxiosError(err);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (s3FileId: number) => {
    const s3Ids = (document?.files || []).map(file => file.fileId);
    try {
      const updatedDocument = await creditHttp.saveDocuments({
        creditId: credit.id,
        guarantorId: guarantor?.id,
        doctypeId: doctype.id,
        fileIds: s3Ids.filter(id => id !== s3FileId),
      });
      setDocument(updatedDocument);
      updateCredit();
      updateCounters && updateCounters();
    } catch (err: any) {
      showAxiosError(err);
    }
  };

  async function onNumberUpdate() {
    let values: any;

    try {
      values = await form.validateFields();
    } catch (e) {
      return;
    }

    if (!values || !values.number) {
      return;
    }

    try {
      const updatedDocument = await creditHttp.saveDocuments({
        creditId: credit.id,
        guarantorId: guarantor?.id,
        doctypeId: doctype.id,
        fileIds: [],
        number: values.number,
      });
      setDocument(updatedDocument);
      updateCredit();
      updateCounters && updateCounters();
    } catch (err: any) {
      showAxiosError(err);
    }
  }

  const historyStatuses = (document?.history || []).filter(h => h.statusAfter !== h.statusBefore);
  const historyComments = (document?.history || []).filter(h => !!h.comment);

  const filesCount = (document?.files || []).length;
  const showRfcInput = doctype?.isRfc && filesCount === 0;
  const showCurpInput = doctype?.isCurp && filesCount === 0;
  const showFilesInput = filesCount > 0 || (!doctype.isRfc && !doctype.isCurp);
  const sendToAnalysisText = 'Required for Analysis';
  const visibilityText = `This document is ${doctype.isVisible ? '' : 'not '}visible for farmer`;

  if (isRestricted) {
    return (
      <div className={cls.doctype}>
        <div className={cls.row}>
          <div className={cls.doctypeLeft}>
            <div className={cls.doctypeTitle}>
              {doctype.nameMx}
            </div>
          </div>
          <div className={cls.doctypeRight}>
            <InfoTooltip lockIcon title={`Requires role: ${ROLES.RESTRICTED_ACCESS}`} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cls.doctype}>
      <div className={cls.row}>
        <div className={cls.doctypeLeft}>
          <div className={cls.doctypeTitle}>
            {doctype.nameMx}
          </div>
        </div>
        <div className={cls.doctypeRight}>
          {doctype.isSendToCredit && (
            <Tooltip
              title={sendToAnalysisText}
              className={cls.docSendToCredit}
              placement="top"
              overlayClassName={cls.overlay}
            >
              <svg xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none">
                <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
                  fill="#042E6B" />
                <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.2917 10.625C14.5052 10.625 10.625 14.5052 10.625 19.2917C10.625 24.0781 14.5052 27.9583 19.2917 27.9583C24.0781 27.9583 27.9583 24.0781 27.9583 19.2917C27.9583 14.5052 24.0781 10.625 19.2917 10.625ZM8.625 19.2917C8.625 13.4006 13.4006 8.625 19.2917 8.625C25.1827 8.625 29.9583 13.4006 29.9583 19.2917C29.9583 25.1827 25.1827 29.9583 19.2917 29.9583C13.4006 29.9583 8.625 25.1827 8.625 19.2917Z"
                  fill="white" />
                <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M26.7816 26.8863C27.1722 26.4958 27.5082 26.094 27.5082 26.094L32.0821 30.6679C32.4726 31.0584 32.4726 31.6916 32.0821 32.0821C31.6916 32.4726 31.0584 32.4726 30.6679 32.0821L26 27.5853C26 27.5853 26.3911 27.2768 26.7816 26.8863Z"
                  fill="white" />
              </svg>
            </Tooltip>
          )}
          <Tooltip
            title={visibilityText}
            className={cls.docVisibility}
            placement="top"
            overlayClassName={cls.overlay}
          >
            {doctype.isVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </Tooltip>
          {!document && <Badge className={cls.statusBadge} status="default" label=" " />}
          {document?.inRevision &&
            <Badge className={cls.statusBadge} status="info" label={document.statusEn} />}
          {document?.isModerated &&
            <Badge className={cls.statusBadge} status="ok" label={document.statusEn} />}
          {document?.isIncomplete &&
            <Badge className={cls.statusBadge} status="warn" label={document.statusEn} />}
          {document?.isDraft &&
            <Badge className={cls.statusBadge} status="draft" label={document.statusEn} />}
          {document?.isSigned &&
            <Badge className={cls.statusBadge} status="ok" label={document.statusEn} />}
        </div>
      </div>

      <Form form={form}>
        {showRfcInput ? (
          <Form.Item
            name="number"
            label={false}
            rules={[
              { required: true, len: 13, message: 'RFC should be 13 characters' },
            ]}
          >
            <Input
              placeholder={'Taxpayer Identification Number'}
              maxLength={13}
              minLength={13}
              suffix={<FieldNumberOutlined className={cls.inputIcon} />}
              onInput={transformNumberInput}
              onBlur={() => form.validateFields()}
              onChange={onNumberUpdate}
              className={cls.formInput}
            />
          </Form.Item>
        ) : null}
        {showCurpInput ? (
          <Form.Item
            name="number"
            label={false}
            rules={[
              { required: true, len: 18, message: 'CURP should be 18 characters' },
            ]}
          >
            <Input
              maxLength={18}
              minLength={18}
              suffix={<FieldNumberOutlined className={cls.inputIcon} />}
              onInput={transformNumberInput}
              onBlur={() => form.validateFields()}
              onChange={onNumberUpdate}
              className={cls.formInput}
            />
          </Form.Item>
        ) : null}
      </Form>

      {showOptionalButton ? (
        <button
          type="button"
          className={clsx(cls.controlsOptional, document?.isOptionalChecked && cls.checked)}
          onClick={setOptionalDocument}
          disabled={loading || disabledOptionalButton}
          title={disabledOptionalButton ? 'Active only on Applying credits' : undefined}
        >Does not apply</button>
      ) : null}

      {showFilesInput ? (
        <DocumentFiles
          credit={credit}
          guarantor={guarantor}
          doctype={doctype}
          document={document}
          setDocument={setDocument}
          onUpload={onUpload}
          onDelete={onDelete}
          loading={loading}
          editable={editable}
          downloadable={downloadable}
          creditContractDoctype={creditContractDoctype}
          type={type}
        />
      ) : null}

      <Row gutter={[20, 20]} className={cls.info}>
        <Col xs={24} sm={12}>
          <div className={cls.infoBlock}>
            <DocumentStatuses
              creditId={credit.id}
              document={document}
              setDocument={setDocument}
              editable={editable}
            />
          </div>
          <div className={cls.infoBlock}>
            <DocumentHistoryWidget
              creditId={credit.id}
              history={historyStatuses}
              title="Status changed"
              setDocument={setDocument}
              isSuperadmin={isSuperadmin}
            />
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className={cls.infoBlock}>
            <DocumentCommentForm
              creditId={credit.id}
              document={document}
              setDocument={setDocument}
              editable={editable}
            />
          </div>
          <div className={cls.infoBlock}>
            <DocumentHistoryWidget
              creditId={credit.id}
              history={historyComments}
              title="Comments"
              setDocument={setDocument}
              isSuperadmin={isSuperadmin}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const transformNumberInput = (e: any) => {
  if (e.target && e.target.value) {
    e.target.value = e.target.value.toUpperCase().replace(/[^0-9A-Z]/g, '');
  }
};

const autocompleteRfc = (user: User): string => {
  const l1 = (user.middleName || '')[0];
  const l2 = (user.middleName || '')[2];
  const l3 = user.lastName[0];
  const l4 = user.firstName[0];
  const birthday = moment(user.birthday);
  let birthdayAdd = '';
  if (birthday && birthday.isValid()) {
    birthdayAdd = birthday.format('YYMMDD');
  }

  return `${l1}${l2}${l3}${l4}${birthdayAdd}`.toUpperCase();
};
