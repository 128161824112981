import { clsx } from 'clsx';
import html2canvas from 'html2canvas';
import React, { useEffect, useState } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { Input, InputNumber, Modal, Select, Typography } from 'antd';
import {
  AlignLeftOutlined,
  DollarOutlined,
  EditOutlined,
  OrderedListOutlined,
  PercentageOutlined, PushpinOutlined,
} from '@ant-design/icons';

import { useModalState } from '@hooks';
import { BlueButton, InfoTooltip, InitialCard, LightTooltip } from '@components';
import { CreditRisk, ReplyQuestion, RiskQuestionType } from '@types';
import cls from './Terms.module.css';

const { Paragraph } = Typography;
const { TextArea } = Input;

interface NumberFieldProps {
  item: ReplyQuestion;
  onChange: (val: number | null, id: number) => void;
  isFixedReplies: boolean;
}

interface TextFieldProps {
  item: ReplyQuestion;
  onChange: (value: string, id: number) => void;
  isFixedReplies: boolean;
}

interface SelectFieldProps {
  item: ReplyQuestion;
  onChange: (value: number, id: number) => void;
  isFixedReplies: boolean;
}

const NumberField = ({ item, onChange, isFixedReplies }: NumberFieldProps) => {
  return (
    <>
      {item.type === RiskQuestionType.MONEY
        ? <DollarOutlined className={cls.fieldIcon} />
        : <PercentageOutlined className={cls.fieldIcon} />
      }
      <InputNumber
        min={0}
        max={item.type === RiskQuestionType.MONEY ? 1000000000 : 100}
        readOnly={isFixedReplies}
        formatter={
          (value) => item.type === RiskQuestionType.MONEY
            ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : `${value}`
        }
        parser={(value) => value ? (
          item.type === RiskQuestionType.MONEY
            ? +value.replace(/\$\s?|(,*)/g, '')
            : +value
        ) : 0}
        value={item.value ? +item.value : 0}
        bordered={false}
        onChange={e => onChange(e, item.questionId)}
        className={cls.numberInput}
      />
    </>
  );
};

const TextField = ({ item, onChange, isFixedReplies }: TextFieldProps) => {
  return (
    <>
      <EditOutlined className={cls.fieldIcon} />
      <Input
        bordered={false}
        readOnly={isFixedReplies}
        value={item.value || ''}
        onChange={e => onChange(e.target.value, item.questionId)}
        className={cls.shortInput}
        status={!item.value?.trim() ? 'error' : undefined}
        required
        maxLength={100}
      />
    </>
  );
};

const SelectField = ({ item, onChange, isFixedReplies }: SelectFieldProps) => {
  return (
    <>
      <OrderedListOutlined className={cls.fieldIcon} />
      {isFixedReplies ? (
        <span className={cls.fixedText}>{item.value}</span>
      ) : (
        <Select
          defaultValue={item.answerId}
          onChange={(value) => onChange(value, item.questionId)}
          options={item.answers.map((a) => ({ label: a.title, value: a.id }))}
          value={item.answerId}
          className={clsx(cls.selectField, item.isFixed && cls.fixed)}
          disabled={item.isFixed}
          popupClassName={cls.selectFieldPopup}
        />
      )}
    </>
  );
};

export const Terms = ({ risk }: {risk: CreditRisk; readonly?: boolean}) => {
  const printRef = React.useRef<any>();
  const userFullName = risk.credit.user.fullName;
  const buyer = useWatch({ name: 'buyer' });
  const supplier = useWatch({ name: 'supplier' });
  const [terms, setTerms] = useState<ReplyQuestion[]>([]);
  const [longText, setLongText] = useState<ReplyQuestion | null>(null);
  const { is: isFull, show: showFull, hide: hideFull } = useModalState();
  const {
    field: { value: replyTerms, onChange },
  } = useController({ name: 'replyTerms' });

  const handleDownloadForm = async () => {
    showFull();
    await new Promise((resolve) => setTimeout(resolve, 200));

    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = `Credit Terms of ${risk.credit.code}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
    hideFull();
  };

  const onNumberFieldChange = (value: number | null, id: number) => {
    const newList: ReplyQuestion[] = terms
      .map((term: ReplyQuestion) => term.questionId === id
        ? { ...term, value: value !== undefined && value !== null ? value.toString() : '0' }
        : term);
    setTerms(newList);
    onChange(newList);
  };

  const onShortFieldChange = (value: string, id: number) => {
    const newList: ReplyQuestion[] = terms.map((term: ReplyQuestion) => term.questionId === id
      ? { ...term, value: value.trimStart() }
      : term);
    setTerms(newList);
    onChange(newList);
  };

  const onSelectFieldChange = (value: number, id: number) => {
    const newList = terms
      .map((term: ReplyQuestion) => term.questionId === id ? { ...term, answerId: value } : term);
    setTerms(newList);
    onChange(newList);
  };

  const onLongFieldChange = (value: string, id: number) => {
    const newList = terms.map((term: ReplyQuestion) => term.questionId === id
      ? { ...term, value: value.trimStart() }
      : term);
    setTerms(newList);
    longText && setLongText({ ...longText, value });
    onChange(newList);
  };

  const setFixedFields = (list: ReplyQuestion[]): ReplyQuestion[] => {
    const newList = list.map((term: ReplyQuestion) => {
      if (term.tag === 'client') return { ...term, value: userFullName };
      if (term.tag === 'buyer') return { ...term, value: buyer };
      if (term.tag === 'supplier') return { ...term, value: supplier };
      return term;
    });
    setTerms(newList);
    return newList;
  };

  useEffect(() => {
    if (replyTerms && Array.isArray(replyTerms) && replyTerms.length) {
      setFixedFields(replyTerms);
    }
  }, [replyTerms]);

  useEffect(() => {
    onChange(setFixedFields(terms));
  }, [userFullName, buyer, supplier]);

  const isFixed = risk.isFixed;
  const isFixedText = 'Credit Terms are locked because it has a Contract with status "Signature in process" or "Contract Signed"';
  const fixedTags = ['client', 'buyer', 'supplier'];

  return (
    <>
      <InitialCard
        left="Credit Terms"
        autoHeight
        mediumPadding
        lockText={isFixed ? isFixedText : null}
      >
        <div className={cls.box}>
          <div className={cls.form} ref={printRef}>
            {terms.filter(item => fixedTags.includes(item.tag)).map(item => (
              <div className={cls.row} key={item.questionId}>
                <div className={clsx(cls.col, cls.colLabel)}>{item.title}</div>
                <div className={clsx(cls.col, cls.colValue, cls.fixed)}>
                  <PushpinOutlined className={cls.fieldIcon} />
                  <span className={cls.fixedField}>
                    {item.tag === 'client' ? risk.credit.accreditedName : item.value}
                  </span>
                </div>
              </div>
            ))}
            {terms.filter(item => !fixedTags.includes(item.tag)).map(item => (
              <div className={cls.row} key={item.questionId}>
                <div className={clsx(cls.col, cls.colLabel)}>
                  <div>{item.title}</div>
                  {item.title === 'Interest Rate' && !isFull ? (
                    <InfoTooltip title="Interest Rate (Loan Contract) = X / 1.16" />
                  ) : null}
                  {item.title === 'Moratory Interest Rate' && !isFull ? (
                    <InfoTooltip title="Moratory Interest Rate (Loan Contract) = X / 1.16" />
                  ) : null}
                </div>
                <div
                  className={clsx(cls.col, cls.colValue, cls[item.type])}
                  onClick={item.type === RiskQuestionType.LONG_ANSWER
                    ? () => setLongText(item) : undefined}
                >
                  {item.type === RiskQuestionType.MONEY || item.type === RiskQuestionType.PERCENTAGE
                    ? <NumberField
                      item={item}
                      onChange={onNumberFieldChange}
                      isFixedReplies={isFixed}
                    />
                    : null}
                  {item.type === RiskQuestionType.SHORT_ANSWER
                    ? <TextField
                      item={item}
                      onChange={onShortFieldChange}
                      isFixedReplies={isFixed}
                    />
                    : null}
                  {item.type === RiskQuestionType.LONG_ANSWER ? (
                    <>
                      <AlignLeftOutlined className={cls.fieldIcon} />
                      <Paragraph ellipsis className={cls.longFieldView}>{item.value}</Paragraph>
                    </>
                  ) : null}
                  {item.type === RiskQuestionType.SINGLE_CHOICE ? (
                    <SelectField
                      item={item}
                      onChange={onSelectFieldChange}
                      isFixedReplies={isFixed}
                    />
                  ) : null}
                </div>
              </div>
            ))}
          </div>

          <div className={cls.actions}>
            <BlueButton
              outlined
              onClick={handleDownloadForm}
              disabled={isFull}
            >Download credit terms</BlueButton>
          </div>
        </div>
      </InitialCard>

      <Modal
        title={longText?.title}
        open={!!longText}
        footer={false}
        onCancel={() => setLongText(null)}
        className={cls.longTextModal}
      >
        {longText ? (
          <TextArea
            rows={8}
            className={cls.longInput}
            readOnly={isFixed}
            maxLength={10000}
            value={longText.value || ''}
            onChange={e => onLongFieldChange(e.target.value, longText.questionId)}
          />
        ) : null}
      </Modal>
    </>
  );
};
