import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { moneyFormatter } from '@utils';
import { reportsHttp } from '@network';
import { useSettingsContext } from '@providers';
import { InitialCard } from '@components';

interface Props {
  setLoader: (num: number, value: boolean) => void;
}

export const RenewalMetrics = ({ setLoader }: Props) => {
  const cls = useStyles();

  const { admin } = useSettingsContext();
  const [numbers, setNumbers] = useState<Record<string, any>>({});
  
  const loadNumbers = async () => {
    setLoader(1, true);
    try {
      const nextNumbers = await reportsHttp.renewalMetricsReport();
      setNumbers(nextNumbers);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(1, false);
    }
  };

  useEffect(() => {
    if (admin) {
      loadNumbers();
    }
  }, []);

  const blocks: {key: string, title: string, value?: number, amount?: number}[] = [
    {
      key: 'totalOfRenewedCredits',
      title: 'Total of renewed credits',
    },
    {
      key: 'renewalRate',
      title: 'Renewal Rate per signed credit',
    },
    {
      key: 'renewalApprovalRate',
      title: 'Renewal Approval Rate',
    },
  ];

  return (
    <InitialCard left="Renewal Metrics" extraPadding autoHeight mt={22}>
      <br />
      <div className={cls.list}>
        {blocks.map(block => {
          const blockNumbers = numbers ? numbers[block.key] : undefined;

          return (
            <div key={block.key} className={cls.item}>
              <div className={cls.title}>{block.title}</div>
              <div className={cls.values}>
                <div className={cls.value}>
                  {blockNumbers?.type === 'percent' ? new Intl.NumberFormat('en-US', {
                    style: 'percent',
                    minimumFractionDigits: 2,
                  }).format(blockNumbers?.value || 0) : blockNumbers?.value}
                </div>
                {blockNumbers?.amount &&
                  <div className={cls.amount}>{moneyFormatter.format(blockNumbers?.amount)}</div>
                }
              </div>
            </div>
          );
        })}
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  list: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: 20,
  },
  item: {
    width: 'calc(100% / 3)',
    maxWidth: 318,
    border: '1px solid #042E6B',
    padding: '15px 2px',
    borderRadius: 8,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FFF',
    textAlign: 'center',
  },
  title: {
    marginTop: 4,
  },
  values: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    justifyItems: 'center',
    minHeight: 80,
  },
  value: {
    fontWeight: 700,
    fontSize: 22,
    marginTop: 6,
    marginBottom: 4,
  },
  amount: {
    marginBottom: 4,
    fontWeight: 700,
    fontSize: 18,
    color: '#002A77',
  },
});
