import { Credit } from '@types';
import { InitialPage } from '@components';
import { FieldReport } from '@pages/cx/Components/FieldReport';

interface Props {
  setCredit?: (newCredit: Credit) => void;
  credit: Credit;
}

export const CreditFarmsPage = ({ credit }: Props) => {
  const backTo = `/credit/${credit.id}?initial`;

  return (
    <InitialPage title="Farms of credit" backTo={backTo} backText="Back to credit">
      <div style={{ paddingTop: 20 }}>
        <FieldReport credit={credit} showMap />
      </div>
    </InitialPage>
  );
};
