import clsx from 'clsx';
import { Col, Row } from 'antd';
import { useState } from 'react';
import { Button } from '@mui/material';
import { InfoCircleFilled } from '@ant-design/icons';
import { Link, maxLength, minLength, required, ReferenceInput, useNotify } from 'react-admin';

import { IProfileProgress, userHttp } from '@network';
import { useWindowSize } from '@hooks';
import { axiosErrorToString } from '@utils';
import { DealerType, genderOptions, marriageOptions, User } from '@types';
import {
  ActivationCpl,
  DateInput,
  InitialCard,
  InitialLine,
  LightTooltip,
  ManyTagsInput,
  PhoneMaskedInput,
  SelectInput,
  SuggestsInput,
  SwitchInput,
  TextInput,
} from '@components';
import { UserTags } from '../UserTags';
import { UserStatusForm } from '../UserStatusForm';
import { ProfileProgress } from '../ProfileProgress';
import { rejectReasonOptions } from '@pages/credit/CreditEdit/CreditInfo';
import cls from './UserInfo.module.css';

export const inactivityReasons = [
  { id: 'Not answering anymore', name: 'Not answering anymore' },
  { id: 'Not eligible for renewal', name: 'Not eligible for renewal' },
  { id: 'Impossible to contact (7)', name: 'Impossible to contact (7)' },
  { id: 'We are late', name: 'We are late' },
  { id: 'No follow up', name: 'No follow up' },
  { id: 'Rejected', name: 'Rejected' },
];

const RESEND_EMAIL_TIMEOUT = 60000;

interface Props {
  user: User;
  profileProgress: IProfileProgress | null;
}

export const UserInfo = ({ user, profileProgress }: Props) => {
  const notify = useNotify();
  const { width } = useWindowSize();
  const [isEmailSendDisabled, setIsEmailSendDisabled] = useState(false);

  const isSmallScreen = (width || 0) < 1700;
  const rfcLength = 13;
  const rfcCompanyLength = 12;
  const validateRfc = [minLength(rfcLength), maxLength(rfcLength)];
  const validateRfcCompany = [minLength(rfcCompanyLength), maxLength(rfcCompanyLength)];
  const isEmailActivated = user.isEmailActivated;
  const userId = user.id;

  const sendEmailActivation = async () => {
    try {
      const result = await userHttp.resendEmailActivateLink(userId);

      if (result) {
        notify('Email sent successfully');
        setIsEmailSendDisabled(true);

        setTimeout(() => {
          setIsEmailSendDisabled(false);
        }, RESEND_EMAIL_TIMEOUT);
      }
    } catch (e) {
      notify(axiosErrorToString(e), { type: 'error' });
    }
  };

  if (!user) return null;

  return (
    <InitialCard autoHeight extraPadding>
      <Row gutter={[24, 24]} className={cls.box}>
        <Col sm={24} md={12}>
          {/* LEFT */}
          <div className={cls.leftBox}>
            {/* LEFT HEADER */}
            <div className={cls.avatar}>
              {user.avatar ? (
                <img alt="avatar" src={user.avatar} className={cls.avatarImg} />
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                  <path d="M40 0C17.9143 0 0 17.9143 0 40C0 62.0857 17.9143 80 40 80C62.0857 80 80 62.0857 80 40C80 17.9143 62.0857 0 40 0ZM40 20C49 20 56.3143 27.3143 56.3143 36.3143C56.3143 45.3143 49 52.6286 40 52.6286C31 52.6286 23.6857 45.3143 23.6857 36.3143C23.6857 27.3143 31 20 40 20ZM40 74.8571C31.8857 74.8571 24.4 72.0571 18.4571 67.3714C20.5714 64.3429 24.0571 62.5143 27.7714 62.5143H52.2286C55.9429 62.5143 59.4286 64.3429 61.5429 67.3714C55.6 72.0571 48.1143 74.8571 40 74.8571Z" fill="#042E6B" />
                </svg>
              )}
            </div>
            <div className={cls.header}>
              <InitialLine title="Verqor code:">{user.code}</InitialLine>
              <InitialLine title="Tags:" widthContent={18}>
                <UserTags />
              </InitialLine>
            </div>

            {/* LEFT FORM */}
            <div className={cls.activityBlock}>
              <UserStatusForm hasLogged={user.hasLogged} />
              <InitialLine
                title="Inactivity Reason:"
                full={true}
                wrapClass={cls.reason}
                contentClass={clsx(!isSmallScreen && cls.reasonContentFull)}>
                <SelectInput label={false} source="inactiveReason" choices={inactivityReasons} />
              </InitialLine>
            </div>
            <InitialLine
              title="Reason for rejection:"
              widthContent={18}
              right={!user.rejectReason ? undefined : (
                <Link to={`/credit/${user.deniedCredit?.id}?initial`} className={cls.link}>
                  {user.deniedCredit?.code}
                </Link>
              )}>
              <SelectInput
                label={false}
                source="rejectReason"
                disabled={!user.rejectReason}
                choices={rejectReasonOptions}
              />
            </InitialLine>
            <InitialLine title="First Name*:" widthContent={18}>
              <TextInput
                label=""
                source="firstName"
                inputProps={{ maxLength: 30, required: true, onInput: transformNameInput }}
              />
            </InitialLine>
            <InitialLine title="Middle Name:" widthContent={18}>
              <TextInput
                label=""
                source="middleName"
                inputProps={{ maxLength: 30, onInput: transformNameInput }}
              />
            </InitialLine>
            <InitialLine title="Last Name*:" widthContent={18}>
              <TextInput
                label=""
                source="lastName"
                inputProps={{ maxLength: 30, required: true, onInput: transformNameInput }}
              />
            </InitialLine>
            <InitialLine title="Gender:" widthContent={18}>
              <SelectInput label="" source="gender" choices={genderOptions} />
            </InitialLine>
            <InitialLine title="Civil status:" widthContent={18}>
              <SelectInput label="" source="marriage" choices={marriageOptions} />
            </InitialLine>
            <InitialLine title="Birthday*:" widthContent={18}>
              <DateInput label="" source="birthday" />
            </InitialLine>
            <InitialLine title="Phone*:" widthContent={18}>
              <PhoneMaskedInput source="phone" />
            </InitialLine>
            <InitialLine title="Email*:" right={(
              <div className={cls.rightActions}>
                <SwitchInput source="isEmailActivated" label="activated" />
                <div className={cls.resend}>
                  <Button onClick={sendEmailActivation} size="small" disabled={isEmailActivated || isEmailSendDisabled} variant="contained">
                  Resend
                  </Button>
                  <LightTooltip extraWidth
                    title="Send the verification mail again to customer">
                    <InfoCircleFilled className={cls.tooltipIcon} />
                  </LightTooltip>
                </div>
              </div>
            )}>
              <TextInput label="" source="email" type="email" />
            </InitialLine>
            <InitialLine title="RFC:">
              <TextInput
                source="rfc"
                label=""
                inputProps={{ maxLength: rfcLength, onInput: transformNumberInput }}
                validate={validateRfc}
                format={v => (v || '').toUpperCase()}
              />
            </InitialLine>
            <InitialLine title="CURP:">
              <TextInput
                source="curp"
                label=""
                inputProps={{ maxLength: 18, onInput: transformNumberInput }}
                format={v => (v || '').toUpperCase()}
              />
            </InitialLine>
            <InitialLine title="CIEC:">
              <TextInput
                source="ciec"
                label=""
                inputProps={{ maxLength: 8, onInput: transformCiecInput }}
              />
            </InitialLine>
            <InitialLine title="Owner:" widthTitle={6} widthContent={10}>
              <SuggestsInput
                source="contactOwnerId"
                reference="admin"
                className={cls.suggetsInput}
              />
            </InitialLine>
          </div>
        </Col>
        <Col sm={24} md={12} className={cls.rightCol}>
          <div>
            <InitialLine title="Zip codes:" widthTitle={6} widthContent={18}>
              <TextInput source="zipCodes" label="" />
            </InitialLine>
            <InitialLine title="State:" widthTitle={6} widthContent={18}>
              <ReferenceInput source="stateId" reference="state" label="">
                <SelectInput optionText="name" label="" />
              </ReferenceInput>
            </InitialLine>
            <InitialLine title="Crops:" widthTitle={6} widthContent={18}>
              <ManyTagsInput source="cropIds" reference="crop" />
            </InitialLine>
            <InitialLine title="Company:" right={(
              <SwitchInput source="isCompany" label="is company" />
            )}>
              <TextInput label="" source="companyName" />
            </InitialLine>
            <InitialLine title="RFC (Company)" widthTitle={6} widthContent={18}>
              <TextInput
                source="rfcCompany"
                label=""
                inputProps={{ maxLength: rfcCompanyLength, onInput: transformNumberInput }}
                validate={validateRfcCompany}
                format={v => (v || '').toUpperCase()}
              />
            </InitialLine>
            <InitialLine title="Buyers:" widthTitle={6} widthContent={18}>
              <ManyTagsInput source="buyerIds" mainSource="mainBuyerId"
                reference={`dealer/by-type/${DealerType.BUYER}`} optionLabel="nameTier" />
              <SwitchInput source="generalBuyer" label="Venta al público en general"
                className={cls.buyerSwitcher} />
            </InitialLine>
            <InitialLine title="Retailers:" widthTitle={6} widthContent={18}>
              <ManyTagsInput source="retailerIds"
                reference={`dealer/by-type/${DealerType.RETAILER}`} />
            </InitialLine>
            <InitialLine title="Associates:" widthTitle={6} widthContent={18}>
              <ManyTagsInput source="associateIds"
                reference={`dealer/by-type/${DealerType.ASSOCIATE}`} />
            </InitialLine>
            <InitialLine title="Channel:" widthTitle={6} widthContent={18}>
              <ReferenceInput source="source" reference="user/sources" label="">
                <SelectInput optionText="name" label="" />
              </ReferenceInput>
            </InitialLine>
            <InitialLine title="Manager:" widthTitle={6} widthContent={18}>
              <ReferenceInput source="responsibleAdminId" reference="admin/managers" label="">
                <SelectInput optionText="name" label="" />
              </ReferenceInput>
            </InitialLine>
            <InitialLine title="Activation:" widthTitle={6} widthContent={18}>
              <SuggestsInput source="activationId"
                reference="finance-activation" mui nullable />
            </InitialLine>
            <InitialLine title="SalesForce Contact ID:" widthTitle={12} widthContent={12}>
              <TextInput source="sfContactId" label="" />
            </InitialLine>
          </div>
          <Row className={cls.indicators} gutter={[20, 20]} justify="center">
            <Col xs={24} xl={12} className={cls.indicatorsCol}>
              <ProfileProgress profileProgress={profileProgress} />
            </Col>
            <Col xs={24} xl={12} className={cls.indicatorsCol}>
              <ActivationCpl />
            </Col>
          </Row>
        </Col>
      </Row>
    </InitialCard>
  );
};

const transformNameInput = (e: any) => {
  if (e.target && e.target.value) {
    const val = e.target.value.replace(/\d+/g, '');
    if (val.includes(' ')) {
      e.target.value = val.split(' ').map((i: string) => i.length
        ? i[0].toUpperCase() + i.slice(1)
        : '').join(' ');
    } else {
      e.target.value = val ? val[0].toUpperCase() + val.slice(1) : '';
    }
  }
};

const transformNumberInput = (e: any) => {
  if (e.target && e.target.value) {
    e.target.value = e.target.value.toUpperCase().replace(/[^0-9A-Z]/g, '');
  }
};

const transformCiecInput = (e: any) => {
  if (e.target && e.target.value) {
    e.target.value = e.target.value.replace(/[^0-9A-Za-z]/g, '');
  }
};
