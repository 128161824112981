import React from 'react';

export const DownloadIconMini = () => (
  <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.2834 0.727662C7.2834 0.325785 7.62244 0 8.04067 0C8.45891 0 8.79795 0.325785 8.79795 0.727662V8.73195C8.79795 9.13382 8.45891 9.45961 8.04067 9.45961C7.62244 9.45961 7.2834 9.13382 7.2834 8.73195V0.727662Z"
      fill="white" />
    <path d="M4.2218 6.69966C3.92607 6.41549 3.92607 5.95476 4.2218 5.67059C4.51754 5.38642 4.99702 5.38642 5.29275 5.67059L8.50561 8.7578C8.80135 9.04197 8.80135 9.5027 8.50561 9.78687C8.20988 10.071 7.7304 10.071 7.43466 9.78687L4.2218 6.69966Z"
      fill="white" />
    <path d="M11.7782 6.69966C12.0739 6.41549 12.0739 5.95476 11.7782 5.67059C11.4825 5.38642 11.003 5.38642 10.7072 5.67059L7.49439 8.7578C7.19865 9.04197 7.19865 9.5027 7.49439 9.78687C7.79012 10.071 8.26961 10.071 8.56534 9.78687L11.7782 6.69966Z"
      fill="white" />
    <rect y="11" width="17" height="2" rx="1" fill="white" />
    <rect x="2" y="6" width="7" height="2" rx="1" transform="rotate(90 2 6)" fill="white" />
    <rect x="17" y="6" width="7" height="2" rx="1" transform="rotate(90 17 6)" fill="white" />
  </svg>
);
