import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { Credit, CreditStatus, Order } from '@types';
import {
  InitialCard,
  InitialRow,
  ReferenceInput,
  SelectInput,
  SwitchInput,
} from '@components';
import { centsFormatter, rbac } from '@utils';
import { ordersHttp } from '@network';
import { getCreditStatusOptions } from '@pages/credit/CreditStatusField';
import { ExportOrdersButton } from '@pages/credit/ExportOrdersButton';

export const rejectReasonOptions = [
  { id: 'Credit Bureau', name: 'Credit Bureau' },
  { id: 'No Invoices', name: 'No Invoices' },
  { id: 'No collateral', name: 'No collateral' },
  { id: 'Looking for other products', name: 'Looking for other products' },
  { id: 'Looking for cash only', name: 'Looking for cash only' },
  { id: 'Rejected by Credit Risk analysis', name: 'Rejected by Credit Risk analysis' },
  { id: 'No Guarantor', name: 'No Guarantor' },
  { id: 'No Retention', name: 'No Retention' },
  { id: 'Unqualified', name: 'Unqualified' },
  { id: 'Conditions not accepted', name: 'Conditions not accepted' },
  { id: 'No follow up', name: 'No follow up' },
];

export const CreditInfo = ({ credit, editable }: {credit: Credit, editable: boolean}) => {
  const cls = useStyles();
  const [orders, setOrders] = useState<Order[]>([]);
  const status = useWatch({ name: 'status' });
  const rejectReasonEnabled = status === CreditStatus.DENIED;

  useEffect(() => {
    (async () => {
      const nextOrders = await ordersHttp.getOrdersByUser(credit.userId);
      setOrders(nextOrders.sort((a, b) => b.id - a.id));
    })();
  }, [credit.userId]);

  const ordersTotal = orders.reduce((acc, curr) => acc + curr.totalCost, 0);
  const statusOptions = getCreditStatusOptions();

  return (
    <InitialCard left="Credit" autoHeight extraPadding>
      <div className={cls.box}>
        <InitialRow title="Balance:">
          {centsFormatter.format(credit.balance)}
        </InitialRow>
        <InitialRow title="Request amount:">
          {centsFormatter.format(credit.requestAmount || 0)}
        </InitialRow>
        <InitialRow title="Status:">
          <SelectInput
            label={false}
            source="status"
            choices={statusOptions}
            required
            disabled={credit.isDenied || !editable}
          />
        </InitialRow>
        <InitialRow title="Reason for rejection:">
          <SelectInput
            label={false}
            source="rejectReason"
            disabled={!rejectReasonEnabled || !editable}
            choices={rejectReasonOptions}
          />
        </InitialRow>
        <InitialRow title="Risk rejection reason:">
          {credit.riskRejectReason}
        </InitialRow>
        <InitialRow title="Status before rejection:">
          <SelectInput
            label={false}
            source="statusBeforeRejection"
            choices={statusOptions}
            disabled
          />
        </InitialRow>
        <InitialRow title="Bank:">
          <ReferenceInput reference="bank" source="bankId" allowEmpty>
            <SelectInput optionText="nameMx" label={false} disabled={!editable} />
          </ReferenceInput>
        </InitialRow>
        <InitialRow title="Code:">
          {credit.code}
        </InitialRow>
        {rbac.isSuperadmin() && (
          <InitialRow title="Stop Tasks generation:">
            <SwitchInput label={false} source="noCalls" />
          </InitialRow>
        )}
        <InitialRow title="Is Fixed:"
          tip="Is Fixed for updates of Risk Manager answers, Questionario">
          <SwitchInput label={false} source="isFixed" disabled />
        </InitialRow>
        <InitialRow title="Orders totales:">
          {centsFormatter.format(ordersTotal)}
        </InitialRow>
        <div className={cls.footer}>
          <ExportOrdersButton credit={credit} buttonClass={cls.exportBtn} />
        </div>
      </div>
    </InitialCard>
  );
};

const useStyles = makeStyles({
  box: {
    marginTop: 15,
    fontFamily: 'Outfit, sans-serif',
    color: '#272727',
    '& .MuiSelect-select.Mui-disabled': {
      '-webkit-text-fill-color': 'unset !important',
    },
  },
  footer: {
    marginTop: 25,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  exportBtn: {
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#FFF',
    width: '254px !important',
    height: 37,
    color: '#042E6B',
    fontFamily: 'Outfit, sans-serif',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&[disabled] path:not(:last-child)': {
      fill: 'grey',
    },
  },
});
