import { Admin } from '@types';

const rbacDisabled = process.env.REACT_APP_RBAC_DISABLED === 'true';
export type Method = 'get' | 'post' | 'put' | 'delete';

export enum ROLES {
  CREDIT_ADMIN = 'Credit admin',
  CREDIT_STATUS_CONTROL = 'Credit Status Control',
  DRAFT_CREDIT = 'Draft Credit',
  SEND_TO_ANALYSIS = 'Send to Analysis',
  DOC_REVISION = 'Doc Revision',
  RESTRICTED_ACCESS = 'Restricted Access',
  DASHBOARD = 'Dashboard',
}

class Rbac {

  checkAccess(admin: Partial<Admin> | null, resourceName: string, mode: 'edit' | 'readonly') {
    if (rbacDisabled || admin?.isSuperadmin) return true;

    const permissionKeys = (admin?.permissionKeys || null) as Record<string, boolean>;
    if (!permissionKeys) return false;

    const resource = resourceName.includes('/')
      ? resourceName.split('/')[0]
      : resourceName;
    const keyAll = `${resource}|ALL`;
    const keyReadonly = `${resource}|READONLY`;

    if (mode === 'edit') {
      return permissionKeys[keyAll] ? true : false;
    }

    if (mode === 'readonly') {
      return permissionKeys[keyAll] || permissionKeys[keyReadonly] ? true : false;
    }

    return false;
  }

  readonlyAccess(resourceName: string, adminArg?: Partial<Admin> | null): boolean {
    const admin = adminArg || this.getAdmin();

    return this.checkAccess(admin, resourceName, 'readonly');
  }

  canShowList(resourceName: string, adminArg?: any): boolean {
    const admin = adminArg || this.getAdmin();
    if (resourceName === 'risk' && this.hasRiskAdminReadonlyPerm()) return true;
    if (resourceName === 'credit-risk' && this.hasRiskManagerReadonlyPerm()) return true;

    return this.checkAccess(admin, resourceName, 'readonly');
  }

  canView(resourceName: string, adminArg?: any) {
    if (resourceName === 'risk') {
      if (this.hasRiskAdminReadonlyPerm()) return true;
    }
    if (resourceName === 'credit-risk') {
      if (this.hasRiskManagerReadonlyPerm()) return true;
    }
    const admin = adminArg || this.getAdmin();

    return this.checkAccess(admin, resourceName, 'readonly');
  }

  canEdit(resourceName: string, adminArg?: any) {
    if (resourceName === 'risk') {
      if (this.hasRiskAdminReadonlyPerm()) return true;
    }
    if (resourceName === 'credit-risk') {
      if (this.hasRiskManagerReadonlyPerm()) return true;
    }
    const admin = adminArg || this.getAdmin();

    return this.checkAccess(admin, resourceName, 'edit');
  }

  canEditCredit() {
    return this.canEdit('credit');
  }

  canCreate(admin: Partial<Admin> | null, resourceName: string) {
    return this.checkAccess(admin, resourceName, 'edit');
  }

  getAdmin(): Partial<Admin> | null {
    const localAdmin: string | null = localStorage.getItem('admin');

    return localAdmin ? JSON.parse(localAdmin) : null;
  }

  hasPermission(admin: Partial<Admin> | null, permission: string): boolean {
    const permissions = (admin?.permissionKeys || null) as Record<string, boolean>;

    return permissions && permissions[permission] ? true : false;
  }

  isSuperadmin(): boolean {
    const admin = this.getAdmin();

    return admin?.isSuperadmin ? true : false;
  }

  hasRole(admin: Partial<Admin> | null, roleName: string): boolean {
    if (admin?.isSuperadmin) {
      return true;
    }
    const roles = admin?.roles || [];

    return roles.find(role => role.name === roleName) ? true : false;
  }

  hasPermissionOrSuperadmin(permissionKey: string): boolean {
    const admin = this.getAdmin();
    const permissionKeys = (admin?.permissionKeys || {}) as Record<string, boolean>;

    if (admin?.isSuperadmin) return true;

    return permissionKeys[permissionKey] ?? false;
  }

  hasFinanceBalancePerm(): boolean {
    return this.hasPermissionOrSuperadmin('finance-dashboard|ALL');
  }

  hasFinanceSettingsAll(): boolean {
    return this.hasPermissionOrSuperadmin('finance-settings|ALL');
  }

  hasFinanceSettingsReadonly(): boolean {
    return this.hasPermissionOrSuperadmin('finance-settings|READONLY');
  }

  hasSendToAnalysisRole(): boolean {
    return this.hasRole(this.getAdmin(), ROLES.SEND_TO_ANALYSIS);
  }

  hasDocRevisionRole(): boolean {
    return this.hasRole(this.getAdmin(), ROLES.DOC_REVISION);
  }

  hasRestrictedAccess(): boolean {
    return this.hasRole(this.getAdmin(), ROLES.RESTRICTED_ACCESS);
  }

  hasDashboardRole(): boolean {
    return this.hasRole(this.getAdmin(), ROLES.DASHBOARD);
  }

  hasCreditAdminRole(): boolean {
    return this.hasRole(this.getAdmin(), ROLES.CREDIT_ADMIN);
  }

  hasDraftCredit(): boolean {
    return this.hasRole(this.getAdmin(), ROLES.DRAFT_CREDIT);
  }

  hasCreditStatusControl(): boolean {
    return this.hasRole(this.getAdmin(), ROLES.CREDIT_STATUS_CONTROL);
  }

  hasRiskAdminReadonlyPerm(): boolean {
    return this.hasPermission(this.getAdmin(), 'risk-admin-readonly|ALL');
  }

  hasRiskManagerReadonlyPerm(): boolean {
    return this.hasPermission(this.getAdmin(), 'risk-manager-readonly|ALL');
  }
}

export const rbac = new Rbac();
